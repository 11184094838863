import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Button, Collapse, Modal } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import AddIcon from '@mui/icons-material/Add';
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import '../css/school.css'
import '../css/global.css'
import { getRoles } from '../functions/getData';
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
const header = [
    { id: 'role', label: 'Role' },
    { id: 'description', label: 'Description' },
    { id: 'users', label: 'Users' },
    { id: 'createdBy', label: 'Created By:' },
    { id: 'modifiedBy', label: 'Modified By:' }
]
export default function Role() {
    document.title = 'Role'
    const [roleTable, setRoleTable] = useState()
    const [roles, setRoles] = useState()
    useEffect(() => {
        getRoles().then(d => setRoleTable({ getter: Object.getOwnPropertyNames(d[0]).slice(), table: d }))
        getRoles().then(d => setRoles({ getter: Object.getOwnPropertyNames(d[0]).slice(), table: d }))
    }, [])
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [onDelete, setOnDelete] = useState(false)
    const [filtered, setFiltered] = useState({ Role: '', "Created By": '', "Modified By": '' })
    const [viewOptions, setViewOptions] = useState({ Description: false, "Created By": false, "Modified By": false })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchRole, setSearchRole] = useState('')
    useEffect(() => {
        if (roleTable) {
            if (!searchRole) {
                setRoleTable(roles)
                return
            }
            setRoleTable({
                getter: roles.getter,
                table: roles.table.filter(s => s.role.toLowerCase().includes(searchRole.toLowerCase()))
            })
        }
    }, [searchRole])
    const viewOption = () => {
        setViewing(!viewing)
        setFiltering(false)
    }
    const filter = () => {
        setFiltering(!filtering)
        setViewing(false)
    }
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} setViewOptions={setViewOptions} rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage} />
            </Collapse>
            <Collapse in={filtering}>
                {roleTable && <Filter type='collapse'
                    filterOptions={[roleTable.table.map(s => s.role), ['red', 'green', 'blue'], ['red', 'green', 'blue']]}
                    filtered={filtered} setFiltered={setFiltered} />}
            </Collapse>
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Role' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42} onClick={viewOption} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42} onClick={filter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Role' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchRole} setState={setSearchRole} onBlur />
                    <div style={{ marginRight: 40 }}>
                        <Button className='btn1' style={{ marginRight: 20 }} startIcon={<AddIcon />}>
                            <Link to='/addrole' style={{ textDecoration: 'none', color: '#FFF' }}>Add Role</Link>
                        </Button>
                    </div>
                </Grid>
                {roleTable && <HeavyTable headers={header} data={roleTable.table} getters={roleTable.getter} title='roles'
                    rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} onDelete={onDelete} setOnDelete={setOnDelete}
                    selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected}
                />}
            </Grid>
            <Modal open={onDelete} onClose={() => {
                setSelecting([])
                setSelected([])
                setOnDelete(false)
            }}>
                <DeleteModal selecting={selecting} setOnDelete={setOnDelete} />
            </Modal>
        </Box>
    )
}