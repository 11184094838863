import React, { useState, useEffect } from 'react'
import { Grid, IconButton, Divider, RadioGroup, Radio, FormControlLabel, Modal, Box } from '@mui/material'
import { useHistory } from 'react-router-dom'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import BuildIcon from '@mui/icons-material/Build'
import { useSelector, useDispatch } from 'react-redux'
import { addCompetition, deleteCompetition, updateCompetition, resetCompetition } from '../actions'
import { getUsers, getCollections, getDomains, getDifficulties, getCompetitions } from '../functions/getData'
import { addCompetition as addCompetitionToDB } from '../functions/postData'
import { formatDateTime, formatDate, getDateDiff, dateHasPassed } from '../functions/general'
import { isEmpty, warningMessage, showWarningSwal, showInfoSwal } from '../functions/alert'
import DescriptionEditor from './DescriptionEditor'
import NunitoText from './general/NunitoText'
import ReusableTextField from './general/ReusableTextField'
import ReusableDatePicker from './general/ReusableDatePicker'
import ReusableButton from './general/ReusableButton'
import ReusableToggle from './general/ReusableToggle'
import ReusableAutocomplete from './general/ReusableAutocomplete'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import TitleBar from './general/TitleBar'
import LightTooltip from './general/LightTooltip'
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
const getDaysArray = startAndEndArr => {
    for (var arr = [], dt = new Date(startAndEndArr[0]); dt <= startAndEndArr[1]; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt))
    }
    return arr
}
const EnhancedHover = ({ date }) => {
    const titleDivStyle = {
        backgroundColor: '#5E75C3', border: '1px solid #707070', borderRadius: 6, textAlign: 'center',
        width: 150
    }
    let dateList = date.length === 2 ? getDaysArray(date) : date
    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={titleDivStyle}>
                <NunitoText value='Dates' fontSize={20} fontWeight={600} color='#FFF' />
            </div>
            {dateList.map((date, i) =>
                i < 5 && <NunitoText key={i} value={formatDate(date, '/')} fontSize={18} fontWeight={400} color='#000' />
            )}
            {dateList.length > 6 && <NunitoText value='...' fontSize={18} fontWeight={400} color='#000' />}
            {dateList.length > 5 && <NunitoText value={formatDate(dateList[dateList.length - 1], '/')} fontSize={18} fontWeight={400} color='#000' />}
        </div>
    )
}
const languageOptions = ['Chinese', 'Malay', 'Tamil', 'Japanese', 'German']
export function OverallAward({ count, competition }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    return (
        <Grid container style={{ backgroundColor: '#FFF', margin: 30, border: '1px solid #707070', borderRadius: 12, padding: 30 }}>
            <NunitoText value='Overall Awards' fontSize={20} fontWeight={600} italic textDecoration='underline' />
            <Grid container alignItems='flex-end' style={{
                border: '1px solid #707070', borderRadius: 12, padding: 20, marginBlock: 30,
                boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
            }}>
                <ReusableToggle type='reduxText' width={270} falseValue='Percentage' trueValue='Position'
                    state={competition.overallAwards.usePosition} setState={update} count={[count]} head='overallAwardBoolean'
                    target='usePosition' marginBottom={10} />
                <ReusableTextField type='default' width={340} placeholder='Minimum Points' state={competition.overallAwards.minPoints}
                    setState={update} count={count} head='overallAwardText' target='minPoints' marginLeft={50} marginRight={45}
                    marginBottom={10} />
                {competition.rounds.map((r, index) => (
                    <div key={index} style={{ marginRight: 45, marginBottom: 10 }}>
                        <NunitoText value='Award Criteria' fontSize={14} />
                        <ReusableAutocomplete type='redux' placeholder={`Round ${index + 1}`} width={275} height={55}
                            bgColor='#FFF' btnBgColor='#144A94' btnWidth={64} state={r.awardCriteria} setState={update} count={[count, index]}
                            head='roundText' target='awardCriteria' border='#707070' options={['Gold', 'Silver', 'Bronze']}
                        />
                    </div>
                ))}
            </Grid>
            <Grid container alignItems='center' style={{ marginTop: 10 }}>
                <div style={{ width: 100 }}><NunitoText value='Default Award' fontSize={25} fontWeight={600} /></div>
                <ReusableTextField type='default' width={280} placeholder='Label' state={competition.overallAwards.defaultAward}
                    setState={update} marginLeft={20} count={count} head='overallAwardText' target='defaultAward'
                    required={!competition.overallAwards.defaultAward} />
            </Grid>
        </Grid>
    )
}
export function RoundAward({ count, round, roundCount, roundAward, roundAwardCount }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    useEffect(() => {
        if (round.awards.useForAll) update([count, roundCount, roundAwardCount], 'awardText', 'minPoints', round.awards.minPoints)
    }, [round.awards.minPoints, round.awards.useForAll])
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={{ marginBottom: 30 }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                {Boolean(roundAwardCount) && <CancelIcon style={{ color: '#E83D4D', fontSize: 30, cursor: 'pointer' }}
                    onClick={() => update([count, roundCount, roundAwardCount], 'deleteAward', 'roundAwards')} />}
                <NunitoText value={`${roundAwardCount + 1}`} fontSize={30} marginLeft={Boolean(roundAwardCount) ? 30 : 60} />
            </div>
            <div style={{
                width: 1100, border: '1px solid #707070', borderRadius: 12, padding: 20,
                boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
            }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableTextField type='default' width={470} placeholder='Label' state={roundAward.label} setState={update}
                        count={[count, roundCount, roundAwardCount]} head='awardText' target='label' />
                    {!round.awards.usePosition && <ReusableTextField type='default' width={240} placeholder='Percentage'
                        state={roundAward.percentage} setState={update}
                        count={[count, roundCount, roundAwardCount]} head='awardText' target='percentage' marginLeft={30} />}
                    {round.awards.useGradeToAssignPoints && <ReusableTextField type='default' width={240} placeholder='Points'
                        state={roundAward.points} setState={update}
                        count={[count, roundCount, roundAwardCount]} head='awardText' target='points' marginLeft={30} />}
                </div>
                {!round.awards.usePosition && <ReusableTextField type='default' width={340} placeholder='Minimum Points'
                    state={roundAward.minPoints} setState={update} count={[count, roundCount, roundAwardCount]} head='awardText' target='minPoints'
                    marginTop={40} readOnly={round.awards.useForAll} />}
            </div>
            <ReusableButton text='Add Award' height={48} bgColor='#5E75C3' fontSize={16}
                onClick={() => update([count, roundCount, roundAwardCount + 1], 'addAward', 'roundAwards')} iconType='add' />
        </Grid>
    )
}
export function Award({ count, round, roundCount }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    useEffect(() => {
        let usingPosition = round.awards.usePosition
        if (usingPosition) update([count, roundCount], 'roundBoolean', 'useForAll', true)
    }, [round.awards.usePosition])
    return (
        <Grid container style={{ backgroundColor: '#FFF', margin: 30, border: '1px solid #707070', borderRadius: 12, padding: 30 }}>
            {/* <Grid item xs={12}>{JSON.stringify(round.awards)}</Grid> */}
            <NunitoText value={`Round ${roundCount + 1}`} fontSize={20} fontWeight={600} italic textDecoration='underline' />
            <Grid container alignItems='center' style={{
                border: '1px solid #707070', borderRadius: 12, padding: 20, marginBlock: 30,
                boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
            }}>
                <ReusableToggle type='reduxText' width={270} falseValue='Percentage' trueValue='Position'
                    state={round.awards.usePosition} setState={update} count={[count, roundCount]} head='roundBoolean'
                    target='usePosition' />
                <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40 }}>
                    <ReusableToggle type='reduxIcon' width={124} state={round.awards.useGradeToAssignPoints} setState={update}
                        count={[count, roundCount]} head='roundBoolean' target='useGradeToAssignPoints' />
                    <NunitoText value='Use grade to assign points' fontSize={18} marginLeft={10} />
                </div>
                <ReusableTextField type='default' width={150} placeholder='Minimum Points' state={round.awards.minPoints} setState={update}
                    count={[count, roundCount]} head='roundText' target='minPoints' marginLeft={50} />
                {!round.awards.usePosition && <div style={{ display: 'flex', alignItems: 'center', marginLeft: 40 }}>
                    <ReusableToggle type='reduxIcon' width={124} state={round.awards.useForAll} setState={update}
                        count={[count, roundCount]} head='roundBoolean' target='useForAll' />
                    <NunitoText value='Use for all' fontSize={18} marginLeft={10} />
                </div>}
            </Grid>
            {round.awards.roundAwards.map((roundAward, index) => (
                <RoundAward key={index} count={count} round={round} roundCount={roundCount} roundAward={roundAward} roundAwardCount={index} />
            ))}
            <Grid container alignItems='center' style={{ marginTop: 10 }}>
                <div style={{ width: 100 }}><NunitoText value='Default Award' fontSize={25} fontWeight={600} /></div>
                <ReusableTextField type='default' width={280} placeholder='Label' state={round.awards.defaultAward} setState={update}
                    marginLeft={20} count={[count, roundCount]} head='roundText' target='defaultAward' required={!round.awards.defaultAward} />
            </Grid>
        </Grid >
    )
}
const containerStyle = {
    backgroundColor: '#F3F0F4', marginInline: 40, border: '1px solid', borderTopStyle: 'none', borderRadius: '0 0 12px 12px'
}
const titleContainerStyle = { backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2, }
export function Awards({ count, competition }) {
    const [expandAwards, setExpandAwards] = useState(false)
    return (
        <Grid container style={{ marginTop: 20 }}>
            <TitleBar title='Awards' state={expandAwards} setState={setExpandAwards} marginBottom={!expandAwards && 20} />
            {expandAwards &&
                <Grid container style={containerStyle}>
                    {competition.rounds.map((round, index) => <Award key={index} count={count} round={round} roundCount={index} />)}
                    <OverallAward count={count} competition={competition} />
                </Grid>}
        </Grid>
    )
}
export function Level({ count, competition, round, roundCount, level, levelCount }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    const collectionOptions = ['Collection 1', 'Collection 2']
    // useEffect(() => {
    //     getCollections().then(d => setCollectionOption(d))
    // }, [])
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={{ paddingInline: 10, marginBottom: 20 }}>
            <ClearIcon style={{ fontSize: 36, cursor: 'pointer' }}
                onClick={() => round.levels.length > 1 && update([count, roundCount, levelCount], 'deleteLevel', 'levels')} />
            <ReusableTextField type='default' width={300} placeholder='Level' state={level.level} height={55} bgColor='#F2F2F2'
                borderColor='#707070' setState={update} count={[count, roundCount, levelCount]} head='levelText' target='level' />
            {collectionOptions && <ReusableAutocompleteWithID type='default' placeholder='Collection' width={300} height={55} bgColor='#FFF'
                btnBgColor='#144A94' btnWidth={64} state={level.collection} setState={update} count={[count, roundCount, levelCount]}
                head='levelText' target='collection' borderColor='#707070'
                options={collectionOptions.map((c, i) => ({ id: i + 1, option: c }))} />}
            <ReusableButton text='Preview Collection' height={48} bgColor='#144A94' fontSize={16} />
            <ReusableButton text='Assign Difficulty and Points' height={48} bgColor='#008ECC' fontSize={14}
                to={level.collection && `/assignDifficulty/${competition.competitionID}/${roundCount}/${levelCount}/${level.collection}`}
                blank />
            <ReusableAutocompleteWithID type='reduxMultiple' placeholder='Grade' width={300} height={55} bgColor='#FFF' btnBgColor='#144A94'
                btnWidth={64} state={level.grade} setState={update} count={[count, roundCount, levelCount]} head='levelText' target='grade'
                borderColor='#707070' options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} limitMultipleDisplay='Grade' />
            <ReusableButton text='Add Level' height={48} bgColor='#5E75C3' fontSize={16}
                onClick={() => update([count, roundCount, levelCount + 1], 'addLevel', 'levels')} iconType='add' />
        </Grid>
    )
}
export function Round({ count, competition, round, roundCount }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    const [expandRound, setExpandRound] = useState(false)
    const roundContainerStyle = { backgroundColor: '#144A94', borderRadius: 8, lineHeight: 1 }
    return (
        <Grid container style={{ marginBottom: 20, paddingInline: 20, marginTop: !roundCount && 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={roundContainerStyle}>
                <NunitoText value={`Round ${roundCount + 1}`} fontSize={16} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <IconButton style={{ color: '#FFF' }} onClick={() => setExpandRound(!expandRound)} edge="start">
                    {expandRound ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Grid>
            {expandRound && <Grid container style={{ ...containerStyle, marginInline: 16 }}>
                <Grid container justifyContent='flex-end' style={{ marginRight: 40, marginBlock: '10px 20px' }}>
                    <ReusableButton text='Delete Round' bgColor='#E83D4D' fontSize={16} marginRight={40}
                        onClick={() => competition.rounds.length > 1 && update([count, roundCount], 'deleteRound', 'rounds')}
                        iconType='delete' />
                    <ReusableButton text='Duplicate Round' bgColor='#F16774' fontSize={16}
                        onClick={() => update([count, roundCount + 1], 'duplicateRound', 'rounds', round)} iconType='copy' />
                </Grid>
                <Grid container style={{ marginInline: 50 }}>
                    <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                        <NunitoText value='Round Label' fontSize={20} fontWeight={600} marginTop={20} />
                        <NunitoText value='Competition Confiuration' fontSize={20} fontWeight={600} marginTop={70} />
                        <NunitoText value='Contribute to individual score?' fontSize={20} fontWeight={600} marginTop={70} />
                    </Grid>
                    <Divider orientation="vertical" flexItem sx={{ height: 100, width: 2, backgroundColor: '#000', mt: 3 }} />
                    <Grid item xs style={{ marginLeft: 150, display: 'flex', flexDirection: 'column' }}>
                        <ReusableTextField type='default' placeholder='Round Label' state={round.roundLabel} height={45} bgColor='#F2F2F2'
                            borderColor='#707070' setState={update} count={[count, roundCount]} head='roundText' target='roundLabel'
                            marginTop={12} onBlur />
                        <div style={{ display: 'flex', marginTop: 48 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <ReusableToggle type='reduxText' width={270} falseValue='Individual' trueValue='Team'
                                    state={round.individualConfiguration} setState={update} count={[count, roundCount]} head='roundBoolean'
                                    target='individualConfiguration' />
                                <ReusableToggle type='reduxIcon' width={124} state={round.contributeToIndividualScore} setState={update}
                                    count={[count, roundCount]} head='roundBoolean' target='contributeToIndividualScore' marginTop={48} />
                            </div>
                            {!round.individualConfiguration && <RadioGroup style={{
                                display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: 30,
                                backgroundColor: '#FFF', width: 450, border: '1px solid', borderRadius: 16, marginLeft: 36
                            }} value={round.teamOptions}
                                onChange={(e) => update([count, roundCount], 'roundText', 'teamOptions', e.target.value)}>
                                <FormControlLabel value="1 Account answers tasks" control={<Radio style={{ color: '#000' }} />}
                                    label="1 Account answers tasks" />
                                <FormControlLabel value="Tasks assigned by leader" control={<Radio style={{ color: '#000' }} />}
                                    label="Tasks assigned by leader" />
                                <FormControlLabel value="Free For All" control={<Radio style={{ color: '#000' }} />}
                                    label="Free For All" />
                            </RadioGroup>}
                        </div>
                    </Grid>
                </Grid>
                <Grid container style={{ marginTop: 40 }}>
                    <Grid item xs={12}><NunitoText value='Levels' fontSize={20} fontWeight={600} marginLeft={50} marginBottom={10} /></Grid>
                    {round.levels.map((level, index) => (
                        <Level key={index} count={count} competition={competition} round={round} roundCount={roundCount}
                            level={level} levelCount={index} />
                    ))}
                </Grid>
            </Grid>}
        </Grid>
    )
}
export function Rounds({ count, competition }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    const [expandRounds, setExpandRounds] = useState(false)
    return (
        <Grid container style={{ marginTop: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleContainerStyle}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <NunitoText value='Rounds' fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                    <BuildIcon style={{ color: "#FFF", marginLeft: 10 }} />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='New Round' height={42} bgColor='#5E75C3' fontSize={16} marginRight={60}
                        onClick={() => update(count, 'addRound', 'rounds')} iconType='add' />
                    <IconButton style={{ color: '#FFF' }} onClick={() => setExpandRounds(!expandRounds)} edge="start">
                        {expandRounds ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {expandRounds && competition.rounds.map((round, index) => (
                <Round key={index} count={count} competition={competition} round={round} roundCount={index} />
            ))}
        </Grid >
    )
}
export function CountryPartner({ count, competition, countryPartner, countryPartnerCount, userOptions }) {
    const dispatch = useDispatch()
    const countryOptions = useSelector(state => state.countryOptions)
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    let minCompetitionDate = countryPartner.competitionDates.sort((a, b) => a - b)[0] || competition.competitionStart
    // let maxRegisterDate = new Date(minCompetitionDate).setDate(new Date(minCompetitionDate).getDate()
    //     - Number(countryPartner.registration_days_apart))
    let maxRegisterDate = new Date(new Date(competition.competitionStart).setDate(new Date(competition.competitionStart).getDate() - 1))
    const StatusDisplay = ({ status }) => {
        let color, bgColor
        switch (status) {
            case 'active':
                color = 'rgb(26, 152, 72)'
                bgColor = 'rgb(26, 152, 72, .25)'
                break;
            case 'ready':
                color = 'rgb(0, 142, 204)'
                bgColor = 'rgb(0, 142, 204, .25)'
                break;
            case 'lock':
                color = 'rgb(232, 61, 77)'
                bgColor = 'rgb(232, 61, 77, .25)'
                break;
            default: return
        }
        const divStyle = {
            color: color, backgroundColor: bgColor,
            fontSize: 12, width: 84, height: 39, borderRadius: 20, marginBottom: 10,
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }
        return <div style={divStyle}>{status}</div>
    }
    const dayDiff = getDateDiff(countryPartner.competitionDates.sort((a, b) => a - b)[0], countryPartner.registrationOpen)
    const languageOptions = useSelector(state => state.languageOptions)
    return (
        <Grid container alignItems='flex-end' justifyContent='space-between' style={{ marginBottom: 30, paddingInline: 20 }}>
            <ClearIcon style={{ fontSize: 36, marginBottom: 12, cursor: 'pointer' }}
                onClick={() => competition.countryPartners.length > 1 &&
                    update([count, countryPartnerCount], 'deleteCountryPartner', 'countryPartners')} />
            <ReusableAutocompleteWithID type='default' placeholder='Country*' width={280} height={55} bgColor='#FFF' required
                btnBgColor='#144A94' btnWidth={64} state={countryPartner.country_id} setState={update} borderColor='#707070'
                count={[count, countryPartnerCount]} head='countryPartnerText' target='country_id' marginTop={48}
                options={countryOptions.map(o => ({ id: o.id, option: o.display_name }))} />
            <ReusableAutocompleteWithID type='default' placeholder='Country Partner*' width={280} height={55} bgColor='#FFF' required
                btnBgColor='#144A94' btnWidth={64} state={countryPartner.partner_id} setState={update} borderColor='#707070'
                count={[count, countryPartnerCount]} head='countryPartnerText' target='partner_id' marginTop={48}
                options={userOptions.filter(u => u.country_id === countryPartner.country_id).map(u => ({ id: u.id, option: u.name }))} />
            <div>
                <NunitoText value='Registration Opens' fontSize={15} />
                <ReusableDatePicker type='redux' width={210} state={countryPartner.registrationOpen} required allowExceedMinMax
                    setState={update} count={[count, countryPartnerCount]} head='countryPartnerDate' target='registrationOpen'
                    minDate={new Date()} maxDate={maxRegisterDate} />
            </div>
            <div>
                <NunitoText value='Days between' fontSize={15} />
                <ReusableTextField type='default' width={160} placeholder='Day between' height={55} bgColor='#FFF' number
                    state={countryPartner.registration_days_apart} setState={update} count={[count, countryPartnerCount]}
                    head='countryPartnerText' target='registration_days_apart' borderColor='#707070' />
            </div>
            <div>
                <LightTooltip title={<EnhancedHover date={countryPartner.competitionDates} />} fixPosition>
                    <div><NunitoText value='Competition Dates' fontSize={15} /></div>
                </LightTooltip>
                <ReusableDatePicker type='redux' width={210} state={countryPartner.competitionDates} multiple required allowExceedMinMax
                    setState={update} count={[count, countryPartnerCount]} head='countryPartnerDate' target='competitionDates'
                    minDate={new Date(competition.competitionStart)} maxDate={new Date(competition.competitionEnd)} />
            </div>
            <StatusDisplay status={dayDiff >= countryPartner.registration_days_apart ? 'active' : 'lock'} />
            <Grid container justifyContent='space-between' alignItems='center' style={{ marginTop: 30 }}>
                <NunitoText value='Allow Session Edits By Partners' fontSize={20} fontWeight={600} italic />
                <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                    state={countryPartner.allowEdit} setState={update} count={[count, countryPartnerCount]}
                    head='countryPartnerBoolean' target='allowEdit' />
                <NunitoText value='Languages To Translate' fontSize={20} fontWeight={600} />
                <ReusableAutocompleteWithID type='multiple' placeholder='Languages' width={590} height={55}
                    bgColor='#FFF' btnBgColor='#144A94' btnWidth={64} state={countryPartner.languagesToTranslate} setState={update}
                    count={[count, countryPartnerCount]} head='countryPartnerMultiple' target='languagesToTranslate'
                    borderColor='#707070' options={languageOptions.map(l => ({ id: l.id, option: l.name }))} limitMultiple={3} />
            </Grid>
        </Grid>
    )
}
export function CountryPartners({ count, competition, userOptions }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    const [expandCountryPartners, setExpandCountryPartners] = useState(false)
    return (
        <Grid container style={{ marginTop: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleContainerStyle}>
                <NunitoText value='Country Partner' fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Country Partner' bgColor={competition.rerun ? '#8D8D8D' : '#5E75C3'} height={46}
                            fontSize={16} marginRight={20} iconType='add'
                            onClick={() => competition.rerun ? showInfoSwal('No need to fill in anything',
                                'Unselect rerun if you wish to make changes') :
                                update([count, competition.countryPartners.length], 'addCountryPartner', 'countryPartners')} />
                    </div>
                    <IconButton style={{ color: '#FFF' }} onClick={() => setExpandCountryPartners(!expandCountryPartners)} edge="start">
                        {expandCountryPartners ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {Boolean(expandCountryPartners && competition.countryPartners.length) &&
                <Grid container style={{ ...containerStyle, pointerEvents: competition.rerun && 'none' }}>
                    {competition.rerun && <Grid container justifyContent='center'>
                        <NunitoText value='You cannot edit anything beyond this point for competitions that are reruns' fontSize={20} />
                    </Grid>}
                    <Grid container alignItems='center' justifyContent='space-around' style={{ paddingInline: 20, marginTop: 30 }}>
                        <NunitoText value='Allow all partners edit sessions' fontSize={20} fontWeight={600} italic />
                        <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                            state={competition.allowPartnerEditSessions} setState={update} count={count} head='competitionBoolean'
                            target='allowPartnerEditSessions' />
                    </Grid>
                    {competition.countryPartners.map((countryPartner, index) => (
                        <CountryPartner key={index} count={count} competition={competition} countryPartner={countryPartner}
                            countryPartnerCount={index} userOptions={userOptions} />
                    ))}
                </Grid>}
        </Grid>
    )
}
export function Competition({ count, competition, tagOptions, difficultyGroupOptions, userOptions, competitionOptions }) {
    let history = useHistory()
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    const onChangeReRun = (count, head, target, text, event) => {
        dispatch(updateCompetition(count, head, target, text, event))
        dispatch(updateCompetition(count, 'settingText', 'rerunMarkingPreference', ''))
        if (competition.rerun) {
            dispatch(updateCompetition(count, 'competitionBoolean', 'format', false))
        }
    }
    const onChangeMarkingPreference = (count, head, target, text, event) => {
        dispatch(updateCompetition(count, head, target, text, event))
        // Set all the partners, rounds and awards here
    }
    const [expandSetting, setExpandSetting] = useState(false)
    const [notifyModal, setNotifyModal] = useState(false)
    const [notifyMsg, setNotifyMsg] = useState([])
    const onSubmit = () => {
        let arr = [
            { name: 'Competition Name', state: competition.competitionName },
            { name: 'Competition Start Date', state: competition.competitionStart },
            { name: 'Competition End Date', state: competition.competitionEnd }
        ]
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        if (dateHasPassed(new Date(competition.competitionStart), new Date())) {
            showWarningSwal('Competition Start Date must be today or later')
            return
        }
        if (new Date(competition.competitionStart).getTime() > new Date(competition.competitionEnd).getTime()) {
            showWarningSwal('Competition End Date must be after Competition Start Date')
            return
        }
        if (competition.countryPartners.length === 0) {
            showWarningSwal('Please add at least one country partner to the competition')
            return
        }
        let notifyMsg = []
        let partnerIssueMsg = ''
        let earliestPartnerCompetitionDate, dayDiff
        competition.countryPartners.forEach((partner, i) => {
            if (partner.partner_id === '') {
                partnerIssueMsg += `Partner ${i + 1}'s name is empty. `
            }
            if (partner.registrationOpen === '') {
                partnerIssueMsg += `Partner ${i + 1}'s registration open date is empty. `
            }
            if (partner.competitionDates.length === 0) {
                partnerIssueMsg += `Partner ${i + 1}'s competition dates is empty. `
            }
            earliestPartnerCompetitionDate = partner.competitionDates.sort((a, b) => a - b)[0]
            dayDiff = getDateDiff(earliestPartnerCompetitionDate, partner.registrationOpen)
            if (dayDiff < partner.registration_days_apart) {
                notifyMsg.push(`Partner ${i + 1}'s registration open date is only ${dayDiff} day(s) away from`
                    + ` the earliest competition date when it should be at least ${partner.registration_days_apart} day(s) away.`)
            }
        })
        if (partnerIssueMsg.length) {
            showWarningSwal(partnerIssueMsg)
            return
        }
        if (notifyMsg.length) {
            setNotifyMsg(notifyMsg)
            setNotifyModal(true)
            return
        }
        actuallySubmit()
    }
    const justProceed = () => {
        actuallySubmit()
        setNotifyModal(false)
    }
    const actuallySubmit = () => {
        let payload = {
            name: competition.competitionName,
            competition_start_date: formatDateTime(competition.competitionStart, '/'),
            competition_end_date: formatDateTime(competition.competitionEnd, '/'),
            format: Number(competition.format),
            "re-run": 0,
            session_allow: Number(competition.allowPartnerEditSessions),
            parent_competiton_id: null,
            partners: competition.countryPartners.map(p => ({
                partner_userid: p.partner_id,
                registration_open_date: formatDateTime(p.registrationOpen, '/'),
                competition_dates: p.competitionDates.length === 2 ?
                    getDaysArray(p.competitionDates.sort((a, b) => a - b)).map(d => formatDateTime(d, '/')) :
                    p.competitionDates.sort((a, b) => a - b).map(d => formatDateTime(d, '/')),
                registration_days_apart: p.registration_days_apart,
                session_alow: p.allowEdit,
                languages_translate: p.languagesToTranslate
            }))
        }
        console.log(JSON.stringify(payload))
        console.log(payload)
        addCompetitionToDB(payload).then(d => {
            console.log(d)
            // dispatch(resetCompetition())
            if (d.status === 201) {
                dispatch(resetCompetition())
                history.push('./competitions')
            }
        })
    }
    const competitionTitleContainerStyle = { backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2.4 }
    const competitionContainerStyle = { marginInline: 24, backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
    const allComponentsContainerStyle = {
        backgroundColor: '#FFF', marginInline: 40, marginTop: 40,
        border: '1px solid #707070', borderRadius: 12
    }
    return (
        <Grid container>
            <Grid container alignItems='center' justifyContent='space-between' style={competitionTitleContainerStyle}>
                <NunitoText value={`Competition ${count + 1}`} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='New Competition' bgColor='#5E75C3' height={46} fontSize={16} marginRight={20}
                            onClick={() => dispatch(addCompetition(count + 1))} iconType='add' />
                        {Boolean(count) && <ReusableButton text='Delete Competition' bgColor='#E83D4D' height={46} fontSize={16}
                            marginRight={20} onClick={() => dispatch(deleteCompetition(count))} iconType='delete' />}
                    </div>
                    <IconButton style={{ color: '#FFF' }}
                        onClick={() => update(count, 'competitionBoolean', 'expandCompetition')} edge="start">
                        {competition.expandCompetition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {competition.expandCompetition &&
                <Grid container style={competitionContainerStyle}>
                    <Grid container style={allComponentsContainerStyle}>
                        <TitleBar title='Setting' state={expandSetting} setState={setExpandSetting} />
                        {expandSetting && <Grid container style={containerStyle}>
                            <Grid container style={{ marginInline: 40, marginBlock: 42 }}>
                                <Grid item xs={2.5} style={{ display: 'flex', flexDirection: 'column' }}>
                                    <NunitoText value='Competition Name*' fontSize={20} fontWeight={600} marginTop={20} />
                                    <NunitoText value='Competition Date' fontSize={20} fontWeight={600} marginTop={70} />
                                    <NunitoText value='Re-Run of Competition?' fontSize={20} fontWeight={600} marginTop={70} />
                                    <NunitoText value='Competition Format' fontSize={20} fontWeight={600} marginTop={70} />
                                    <NunitoText value='Grades Participating' fontSize={20} fontWeight={600} marginTop={70} />
                                    {/* <NunitoText value='Tags' fontSize={20} fontWeight={600} marginTop={70} /> */}
                                    {/* <NunitoText value='Difficulty Group' fontSize={20} fontWeight={600} marginTop={70} /> */}
                                    <NunitoText value='Competition Instructions' fontSize={20} fontWeight={600} marginTop={250} />
                                </Grid>
                                <Divider orientation="vertical" flexItem
                                    style={{ height: 600, width: 2, backgroundColor: '#000', marginTop: 16 }} />
                                {/* 750/250 */}
                                <Grid item xs style={{ marginInline: '210px 30px', display: 'flex', flexDirection: 'column' }}>
                                    <ReusableTextField type='default' placeholder='Competition Name' state={competition.competitionName}
                                        setState={update} count={count} head='settingText' target='competitionName' marginTop={12}
                                        borderColor='#000' required onBlur />
                                    <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <ReusableDatePicker type='redux' width={250} state={competition.competitionStart}
                                            setState={update} count={count} head='settingDate' target='competitionStart' required
                                            minDate={new Date()}
                                            maxDate={new Date(competition.competitionEnd)} />
                                        <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                                        <ReusableDatePicker type='redux' width={250} state={competition.competitionEnd}
                                            setState={update} count={count} head='settingDate' target='competitionEnd' required
                                            minDate={new Date(competition.competitionStart)}
                                        />
                                    </div>
                                    <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                                            state={competition.rerun} setState={onChangeReRun} count={count} head='competitionBoolean'
                                            target='rerun' />
                                        {competition.rerun && <ReusableAutocompleteWithID type='default' placeholder='Marking Preference'
                                            width={450} height={55} bgColor='#FFF' btnBgColor='#144A94' btnWidth={64}
                                            state={competition.rerunMarkingPreference} setState={onChangeMarkingPreference} count={count}
                                            head='settingText' target='rerunMarkingPreference' borderColor='#707070'
                                            options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />}
                                    </div>
                                    <ReusableToggle type='reduxText' width={250} falseValue='Local' trueValue='Global'
                                        state={competition.format} setState={update} count={count} head='competitionBoolean'
                                        target='format' marginTop={40} disabled={competition.rerun} />
                                    <ReusableAutocompleteWithID type='multiple' placeholder='Grades Participating' width={590} height={55}
                                        btnBgColor='#144A94' btnWidth={64} state={competition.gradesParticipating} setState={update}
                                        count={count} head='settingMultiple' target='gradesParticipating' marginTop={48}
                                        bgColor='#FFF' borderColor='#707070'
                                        options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} limitMultiple={5} />
                                    {/* {<ReusableAutocompleteWithID type='reduxMultiple' placeholder='Tags' width={590}
                                        height={55} bgColor='#FFF' btnBgColor='#144A94' btnWidth={64} state={competition.tags}
                                        setState={update} count={count} head='settingMultiple' target='tags' marginTop={48} border='#707070'
                                        options={['Tag 1', 'Tag 2'].map((d, i) => ({ id: i + 1, tag: d }))} />}
                                    <div style={{ marginTop: 42, display: 'flex', alignItems: 'center' }}>
                                        <ReusableToggle type='reduxText' width={160} falseValue='No' trueValue='Yes'
                                            state={competition.rerun} setState={update} count={count} head='competitionBoolean'
                                            target='rerun' />
                                        {competition.rerun && <ReusableAutocomplete type='redux' placeholder='Marking Preference'
                                            width={450} height={55} bgColor='#FFF' btnBgColor='#144A94' btnWidth={64}
                                            state={competition.rerunMarkingPreference} setState={update} count={count}
                                            head='settingText' target='rerunMarkingPreference' marginLeft={120} border='#707070'
                                            options={['Singapore', 'China', 'Malaysia']} />}
                                    </div>
                                    {<ReusableAutocompleteWithID type='default' placeholder='Difficulty Group'
                                        width={260} height={45} bgColor='#F2F2F2' btnBgColor='#144A94' btnWidth={56}
                                        state={competition.difficultyGroup} setState={update} count={count}
                                        head='settingText' target='difficultyGroup' marginTop={48} border='#707070'
                                        options={['Difficulty 1', 'Difficulty 2'].map((d, i) => ({ id: i + 1, difficultyName: d }))} />} */}
                                    <DescriptionEditor state={competition.instructions} setState={update} height={400}
                                        placeholder='Competition Instructions' marginTop={50} count={count} head='settingText'
                                        target='instructions' readOnly={competition.rerun} />
                                </Grid>
                            </Grid>
                        </Grid>}
                        <CountryPartners count={count} competition={competition} userOptions={userOptions} />
                        {/* <Rounds count={count} competition={competition} /> */}
                        {/* <Awards count={count} competition={competition} /> */}
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{ marginRight: 30, marginBlock: 20 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} marginRight={20} />
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
                    </Grid>
                </Grid>}
            <Modal open={notifyModal} onClose={() => setNotifyModal(false)}>
                <Box className='scrollablePopUpModal' style={{ width: 650, minHeight: 200 }}>
                    <div>
                        <NunitoText value='Are you sure' fontSize={40} fontWeight={700} color='#5E75C3' marginTop={10} marginBottom={10} />
                        {notifyMsg.map((msg, i) => (
                            <NunitoText key={i} value={msg} fontSize={20} fontWeight={400} marginBottom={10} />
                        ))}
                        <NunitoText value='Only admin would be able to upload participants to the competition on behalf on 
                        these partner(s).' fontSize={20} fontWeight={400} marginBottom={10} />
                    </div>
                    <div>
                        <NunitoText value='Proceed anyway?' fontSize={30} fontWeight={600} color='#5E75C3' marginBottom={20} />
                        <Grid container justifyContent='space-evenly' style={{ marginBottom: 20, paddingInline: 50 }}>
                            <ReusableButton text='Cancel' fontSize={22} height={50} width={200} bgColor='#8D8D8D'
                                onClick={() => setNotifyModal(false)} />
                            <ReusableButton text='Proceed' fontSize={22} height={50} width={200} bgColor='#5E75C3'
                                onClick={() => justProceed()} />
                        </Grid>
                    </div>
                </Box>
            </Modal>
            {/* {Object.keys(competition).map((key, i) => {
                let output = ['Start', 'End'].some(a => key.includes(a)) && competition[key] !== null ?
                    JSON.stringify(formatDateTime(competition[key], '/')) : key === 'countryPartners' ?
                        competition[key].map(k => (
                            <Grid container style={{ border: '1px solid blue' }}>
                                {Object.keys(k).map(key2 => (
                                    <div style={{ marginRight: 10 }}>
                                        {key2}: {['registrationOpen', 'competitionDates'].includes(key2) ?
                                            typeof k[key2] === 'number' ? formatDateTime(k[key2], '/') : k[key2].map(l => formatDateTime(l, '/'))
                                            : JSON.stringify(k[key2])}
                                    </div>
                                ))}
                            </Grid>
                        )) :
                        JSON.stringify(competition[key])
                return (
                    <Grid container alignItems='center' key={i}
                        style={{
                            border: `1px solid red`,
                            lineHeight: !['countryPartners', 'rounds'].some(el => key.includes(el)) && 0.1
                        }}>
                        <h4>{key}: {output}</h4>
                    </Grid >
                )
            })} */}
        </Grid>
    )
}
export default function Competitions() {
    const competitions = useSelector(state => state.competition)
    const [competitionOptions, setCompetitionOptions] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [difficultyGroupOptions, setDifficultyGroupOptions] = useState([])
    useEffect(() => {
        getUsers('?role_id=2&limits=1000').then(d => setUserOptions(d.userLists.data))
        getCompetitions('?status=closed&limits=50').then(c => setCompetitionOptions(c.competitionList.data))
        getDomains('?limits=50').then(d => setTagOptions(d.tagLists.data.filter(t => t.is_tag)))
    }, [])
    return competitions.map((competition, index) => (
        <Competition key={index} count={index} competition={competition} tagOptions={tagOptions}
            difficultyGroupOptions={difficultyGroupOptions} userOptions={userOptions} competitionOptions={competitionOptions} />
    ))
}