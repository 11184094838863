import React, { useState } from 'react'
import { Autocomplete, TextField, IconButton, Grid } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
export default function ReusableAutocomplete({ type, width, height, bgColor, borderColor, btnBgColor, btnWidth,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target, divMarginLeft, divMarginRight,
    // For ReusableAutocompleteWith
    options, freeSolo }) {
    const [open, setOpen] = useState(false)
    const expand = () => !readOnly && setOpen(true)
    const collapse = () => !readOnly && setOpen(false)
    const toggle = () => !readOnly && setOpen(!open)
    const checkError = () => {
        if (errorFunction) return errorFunction(state)
        else return false
    }
    let border = `1px solid ${required && !state ? '#E83D4D' : borderColor ? borderColor : bgColor}`
    const searchIconStyle = {
        fontSize: ['normal', 'default'].includes(type) ? 26 : 32,
        color: ['normal'].includes(type) ? '#000' : 'rgb(112, 112, 112, 0.5)',
        marginLeft: 12
    }
    const withLabelDivStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: divMarginLeft,
        marginRight: divMarginRight,
        minWidth: width,
    }
    const autoCompleteStyle = {
        marginLeft, marginRight, marginTop, marginBottom, width
    }
    const textFieldStyle = {
        height: 60,
        borderRadius: 12,
        border: checkError() ? '1px solid #E83D4D' : border,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = {
        marginLeft: type !== 'table' && 20,
        textAlign: ['default', 'withLabel', 'chip'].includes(type) && 'center',
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const btnStyle = {
        color: '#FFF',
        backgroundColor: '#144A94',
        borderRadius: ['search'].includes(type) ? 12 : '0 10px 10px 0',
        height: 58,
        width: 64,
        opacity: grayedOut && 0.5, margin: 0
    }
    const iconStyle = { fontSize: 42 }
    const onChange = value => {
        console.log(value)
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux autocompletes
            setState(count, head, target, value)
            return
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            setState(count, target, value)
            return
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    const Option = ({ option }) => {
        const [bgColor, setBgColor] = useState('#FFF')
        return <Grid container alignItems='center'
            onClick={() => onChange(option)} onMouseEnter={() => setBgColor('aliceblue')} onMouseLeave={() => setBgColor('#FFF')}
            style={{ height: 36, paddingLeft: 15, cursor: 'pointer', backgroundColor: state === option ? 'aliceblue' : bgColor }}>
            {option}
        </Grid>
    }
    const autoCompleteAttributes = {
        value: state,
        onChange: (e, n) => onChange(n),
        options: options.map(o => o.option),
        // renderOption: (props, option, state) => {
        //     return <Option option={option} />
        // },
        open: open, onClose: () => collapse(), onOpen: () => expand(),
        selectOnFocus: true, disableClearable: true, forcePopupIcon: false,
        style: autoCompleteStyle,
        freeSolo, disabled, clearOnBlur: !freeSolo
    }
    if (freeSolo) {
        autoCompleteAttributes.inputValue = state
        autoCompleteAttributes.onInputChange = (e, n) => onChange(n)
        // autoCompleteAttributes.filterOptions = (options) => {
        //     if (options.find(o => o.toLowerCase() === state.toLowerCase())) return options
        //     else return options.filter(o => o.toLowerCase().includes(state.toLowerCase()))
        // }
    }
    const textFieldAttributes = {
        variant: 'standard',
        placeholder: placeholder
    }
    const textFieldInputProps = {
        disableUnderline: true,
        hiddenLabel: true,
        style: textFieldStyle,
        readOnly: readOnly,
        startAdornment: <SearchIcon style={searchIconStyle} />,
        endAdornment: <IconButton onClick={() => toggle()} style={btnStyle}>
            {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
        </IconButton>
    }
    switch (type) {
        case 'default':
            return (
                <Autocomplete {...autoCompleteAttributes}
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle}>
                    <label style={{ color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }}>
                        {placeholder}
                    </label>
                    <Autocomplete {...autoCompleteAttributes}
                        renderInput={params => <TextField {...params} {...textFieldAttributes}
                            InputProps={{
                                ...params.InputProps,
                                ...textFieldInputProps
                            }}
                            inputProps={{ ...params.inputProps, style: inputStyle }}
                        />}
                    />
                </div>
            )
        case 'table':
            return (
                <Autocomplete {...autoCompleteAttributes}
                    renderInput={(params) => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'redux':
            return (
                <Autocomplete freeSolo value={state} onChange={(e, n) => setState(count, head, target, n)} options={options}
                    open={open} onClose={() => collapse()} onOpen={() => expand()}
                    selectOnFocus disableClearable
                    renderInput={params => <TextField variant="standard" {...params} placeholder={placeholder}
                        onChange={(e) => setState(count, head, target, e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        default: return null
    }
}