const defaultState = [{
    collectionID: 1,
    competitionName: '',
    expandCollection: false,
    collectionIdentifier: '',
    collectionDescription: '',
    timeToSolve: '',
    initialPoints: '',
    sortRandomly: false,
    tags: [],
    difficultyGroup: '',
    languages: [],
    expandRecommendations: false,
    recommendations: [{
        grade: '',
        difficulty: ''
    }],
    sections: [{
        sectionID: 1,
        expandSection: false,
        sortRandomly: false,
        skip: false,
        sectionDescription: '',
        taskGroups: []
    }]
}]
const collectionState = defaultState
const reset = defaultState
const collectionReducer = (state = collectionState, action) => {
    let newState = state.slice()
    switch (action.type) {
        case 'ADD_COLLECTION':
            let temp = JSON.parse(JSON.stringify(defaultState[0]))
            temp.collectionID = newState.map(n => n.collectionID).reduce((a, b) => Math.max(a, b), 0) + 1
            newState.splice(action.count, 0, temp)
            return newState
        case 'DELETE_COLLECTION':
            newState.splice(action.count, 1)
            return newState
        case 'DUPLICATE_COLLECTION':
            let a = JSON.parse(JSON.stringify(action.collection))
            a.collectionID = newState.map(n => n.collectionID).reduce((a, b) => Math.max(a, b), 0) + 1
            a.collectionIdentifier = ''
            newState.splice(action.count, 0, a)
            return newState
        case 'UPDATE_COLLECTION':
            switch (action.head) {
                //Settings
                case 'settingBoolean':
                    newState[action.count][action.target] = !newState[action.count][action.target]
                    return newState
                case 'settingText':
                    newState[action.count][action.target] = action.text
                    return newState
                case 'settingMultiple':
                    newState[action.count][action.target] = typeof action.text === 'string' ? action.text.split(',') : action.text
                    return newState
                //Recommendations
                case 'addRecommendation':
                    newState[action.count[0]][action.target].splice(action.count[1], 0, { grade: '', difficulty: '' })
                    return newState
                case 'deleteRecommendation':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'recommendationText':
                    newState[action.count[0]].recommendations[action.count[1]][action.target] = action.text
                    return newState
                //Sections
                case 'addSection':
                    let b = JSON.parse(JSON.stringify(defaultState[0].sections[0]))
                    b.sectionID = newState[action.count].sections.map(s => s.sectionID).reduce((a, b) => Math.max(a, b), 0) + 1
                    newState[action.count][action.target].push(b)
                    return newState
                case 'deleteSection':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'sectionBoolean':
                    newState[action.count[0]].sections[action.count[1]][action.target] =
                        !newState[action.count[0]].sections[action.count[1]][action.target]
                    return newState
                case 'sectionText':
                    newState[action.count[0]].sections[action.count[1]][action.target] = action.text
                    return newState
                //Task Group and Tasks
                case 'addTaskGroup':
                    //Splice in a question group
                    newState[action.count[0]].sections[action.count[1]][action.target].splice(action.count[2], 0, action.text)
                    return newState
                case 'deleteTaskGroup':
                    //Splice away a question group
                    newState[action.count[0]].sections[action.count[1]][action.target].splice(action.count[2], 1)
                    return newState
                case 'addTaskToGroup':
                    //Splice in a question to a question group
                    newState[action.count[0]].sections[action.count[1]].taskGroups[action.count[2]][action.target]
                        .splice(action.count[3], 0, ...action.text)
                    return newState
                case 'deleteTaskFromGroup':
                    // Splice away a question from a question group
                    let index = newState[action.count[0]].sections[action.count[1]].taskGroups[action.count[2]].taskIDs
                        .findIndex(z => z === action.text)
                    newState[action.count[0]].sections[action.count[1]].taskGroups[action.count[2]].taskIDs.splice(index, 1)
                    if (newState[action.count[0]].sections[action.count[1]].taskGroups[action.count[2]].taskIDs.length === 0) {
                        //Splice away the entire question group if the question group no longer has any questions
                        newState[action.count[0]].sections[action.count[1]].taskGroups.splice(action.count[2], 1)
                    }
                    return newState
                case 'sortTaskGroup':
                    [newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.oldIndex)],
                    newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.newIndex)]] =
                        [newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.newIndex)],
                        newState[action.count[0]].sections[action.count[1]][action.target][Number(action.event.oldIndex)]]
                    return newState
                case 'sortTask':
                    [newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                        .taskIDs[Number(action.event.oldIndex)], newState[action.count[0]].sections[action.count[1]]
                        [action.target][action.count[2]].taskIDs[Number(action.event.newIndex)]]
                        = [newState[action.count[0]].sections[action.count[1]][action.target][action.count[2]]
                            .taskIDs[Number(action.event.newIndex)], newState[action.count[0]].sections[action.count[1]]
                            [action.target][action.count[2]].taskIDs[Number(action.event.oldIndex)]]
                    return newState
                default: return state
            }
        case 'RESET_COLLECTIONS':
            newState = reset
            return newState
        default: return state
    }
}
export default collectionReducer