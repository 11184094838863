import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { IconButton, Card, CardMedia, Grid, Box, TextField, Paper } from '@mui/material'
import img from '../images/signin2.jpg'
import topimg from '../images/signintop.png'
import Visibility from "@mui/icons-material/Visibility"
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import { useDispatch } from 'react-redux'
import '../css/signin.css'
import '../css/global.css'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
export default function SignIn({ onLogin }) {
    document.title = 'Sign In'
    const dispatch = useDispatch()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [wrongLogin, setWrongLogin] = useState(false)
    //Login function
    const onSubmit = () => onLogin(username, password, setWrongLogin)
    const handleUsernameChange = e => setUsername(e.target.value)
    const handlePasswordChange = e => setPassword(e.target.value)
    const onPressEnter = e => e.key === 'Enter' && onSubmit()
    const textFieldAttributes = {
        variant: "filled",
        onKeyDown: onPressEnter,
        fullWidth: true
    }
    const textFieldStyle = { backgroundColor: '#FFF', borderRadius: 12, border: '1px solid', width: '100%' }
    const inputStyle = { fontSize: 22 }
    return (
        <Box style={{ flexGrow: 1, minHeight: '95vh' }}>
            <Grid container style={{ height: '100%' }}>
                <Grid item xs={false} sm={4} md={6} style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center',
                    backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat',
                    backgroundSize: '80%', backgroundPosition: 'center'
                }} />
                <Grid item xs={12} sm={8} md={6} component={Paper} elevation={2} square>
                    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Card style={{ width: '100%' }}><CardMedia component='img' image={topimg} /></Card>
                        <Grid container style={{ paddingInline: 120 }}>
                            <Grid container alignItems="center" style={{ marginTop: 60 }}>
                                <NunitoText value='Username or Email' fontSize={28} fontWeight={600} italic />
                                <TextField {...textFieldAttributes} placeholder='Enter username or email' onChange={handleUsernameChange}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        style: textFieldStyle,
                                    }}
                                    inputProps={{ style: inputStyle }}
                                />
                            </Grid>
                            <Grid container alignItems="center" style={{ marginTop: 40 }}>
                                <NunitoText value='Password' fontSize={28} fontWeight={600} italic />
                                <TextField {...textFieldAttributes} placeholder='Enter password' onChange={handlePasswordChange}
                                    type={showPassword ? "text" : "password"}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        style: textFieldStyle,
                                        endAdornment:
                                            <IconButton style={{ color: '#000' }} onClick={() => setShowPassword(!showPassword)}>
                                                {showPassword ? <Visibility /> : <VisibilityOff />}
                                            </IconButton>
                                    }}
                                    inputProps={{ style: inputStyle }}
                                />
                            </Grid>
                            <Grid container alignItems='center' justifyContent={wrongLogin ? 'space-between' : 'flex-end'}>
                                {wrongLogin && <NunitoText value='Invalid credentials!' fontSize={16} fontWeight={700} color='red' />}
                                {/* <Link to='/forgotpassword' className='forgetpassword'>
                                    Forgot password?
                                </Link> */}
                            </Grid>
                            <ReusableButton text='Login' fontSize={32} bgColor='#F16774' height={70} width='100%' br={16}
                                marginTop={60} onClick={() => onSubmit()} />
                        </Grid>
                        {/* <div style={{ marginTop: 80, color: '#868484', fontSize: 20 }}>
                            <Link to='/signup' style={{ color: '#29A5EC' }}>
                                Sign Up
                            </Link> here to begin your epic journey with us!
                        </div> */}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}