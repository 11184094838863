import React from 'react'
import { Grid } from '@mui/material'
import NunitoText from './general/NunitoText'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import ReusableSelect from './general/ReusableSelect'
import ReusableButton from './general/ReusableButton'
export function Dropdown({ header, label, state, multiple, options, filtered, onChangeFiltered }) {
    const filtering = (target, value) => {
        let newFiltered = [...filtered]
        let index = newFiltered.findIndex(f => f.header === target)
        //Select single
        if (typeof newFiltered[index].state === 'string') newFiltered[index].state = value
        //Multiple
        else newFiltered[index].state = typeof value === 'string' ? value.split(',') : value
        onChangeFiltered(newFiltered)
    }
    return (
        <ReusableSelect type={multiple ? 'multipleFilter' : 'filter'} label={label} width={200} height={44} btnWidth={46} marginRight={40}
            bgColor='#FFF' btnBgColor='#144A94' state={state} setState={filtering} target={header}
            options={options.map(o => ({ value: o.id, option: o.option }))} />
    )
}
export default function Filter({ filterOptions, filtered, onChangeFiltered }) {
    const collapseWrapper = {
        backgroundColor: '#F5F5F5',
        paddingLeft: 30,
        paddingBottom: 15
    }
    const dropDownWrapperStyle = {
        paddingTop: 20,
        marginBottom: 50,
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start'
    };
    const dropDownStyle = {
        marginTop: 20
    }
    const reset = () => {
        let newFiltered = [...filtered]
        newFiltered.forEach(f => typeof f.state === 'object' ? f.state = [] : f.state = '')
        onChangeFiltered(newFiltered)
    }
    return (
        <Grid container style={collapseWrapper}>
            <Grid item xs={12} style={dropDownWrapperStyle}>
                <NunitoText value='Filter and Sort' fontSize={20} fontWeight={700} />
                <div style={dropDownStyle}>
                    {filtered.map(({ label, state, header }, index) => (
                        <Dropdown key={index} header={header} label={label} state={state} multiple={typeof state === 'object'}
                            options={filterOptions[index]} filtered={filtered} onChangeFiltered={onChangeFiltered} />
                    ))}
                    {/* {JSON.stringify(filtered)} */}
                </div>
            </Grid>
            <ReusableButton text='Reset filter' bgColor='#F16774' fontSize={16} br={8} onClick={reset} iconType='reset' />
        </Grid>
    )
}