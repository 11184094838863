// Date functions
export const formatDate = (date, divider = '/') => {
    let c = new Date(date)
    return `${c.getDate() < 10 ? '0' : ''}${c.getDate()}${divider}${c.getMonth() < 9 ? '0' : ''}${c.getMonth() + 1}${divider}${c.getFullYear()}`
}
export const formatDateTimeForDisplay = (date, divider = '/') => {
    let c = new Date(date)
    return `${c.getDate() < 10 ? '0' : ''}${c.getDate()}${divider}${c.getMonth() < 9 ? '0' : ''}${c.getMonth() + 1}${divider}${c.getFullYear()} ${c.toLocaleString('en-US', {
        hour: 'numeric', minute: 'numeric', second: 'numeric', hourCycle: 'h23'
    })}`
}
export const formatDateTime = (date, divider = '/') => {
    let c = new Date(date)
    return `${c.getFullYear()}${divider}${c.getMonth() < 9 ? '0' : ''}${c.getMonth() + 1}${divider}${c.getDate() < 10 ? '0' : ''}${c.getDate()} ${c.toLocaleString('en-US', {
        hour: 'numeric', minute: 'numeric', second: 'numeric', hourCycle: 'h23'
    })}`
}
export const getDateDiff = (d1, d2) => {
    const date1 = d1
    const date2 = d2
    const diffTime = Math.abs(date2 - date1)
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24))
    return diffDays
}
export const dateHasPassed = (firstDate, secondDate) => {
    if (firstDate.setHours(0, 0, 0, 0) < secondDate.setHours(0, 0, 0, 0)) return true
    return false
}
// Ensure always return an array(even if an object is parsed in)
// Only using for now because when getting data from APIs, sometimes can get
// data: [{}, {}, {}] or data: {"0": {}, "1": {}, "2": {}}, so need ensure to always return the former
export const makeSureIsArray = arr => arr.length === undefined ? Object.values(arr) : arr
// Ensure all filter options have options
export const ensureObjectOfArraysArePopulated = obj => {
    let length = 0
    Object.keys(obj).forEach(key => length += obj[key].length)
    return length
}
// Check if all selected rows are pending so can approve
// Check if all selected users are enabled so can disable
// Check if all selected users are disabled so can enable
export const checkIfAllSelectedRowsMatchStatus = (selected, data, status, user) => {
    if (selected.length === 0) return false
    if (user) {
        for (let i = 0; i < selected.length; i++) {
            let row = makeSureIsArray(data).find(d => d.id === selected[i])
            if (row.status !== status || row.created_by_userid === user.user_id) return false
        }
    } else {
        for (let i = 0; i < selected.length; i++) {
            let row = makeSureIsArray(data).find(d => d.id === selected[i])
            if (row.status !== status) return false
        }
    }
    return true
}
export const makeOptions = (obj, title) => {
    if (obj[title].length && typeof obj[title][0] === 'object') {
        return obj[title].filter(item => item.id !== null).sort((a, b) => {
            let first = a[Object.keys(a)[1]]
            let second = b[Object.keys(b)[1]]
            if (first === null) return -1
            else if (second === null) return 1
            else return first.localeCompare(second)
        }).map(item => ({
            id: item.id, option: item[Object.keys(item)[1]]
        }))
    }
    return obj[title].map(item => {
        let id = item
        let option
        if (typeof item === 'object') {
            id = item.id
            option = item[Object.keys(item)[1]]
        } else if (typeof item === 'number') {
            if (['schooltype', 'private'].includes(title)) option = item ? 'Tuition Centre' : 'School'
            else if (title === 'format') option = item ? 'Global' : 'Local'
            else if (title === 'grade') option = item.toString()
        } else option = item
        return ({ id, option })
    })
}
// Not using this for now
export const checkIfLastPage = (currentPage, links) => currentPage !== links.filter(l => !isNaN(l.label)).length
// Check if password meets requirement, which is
// At least 8 characters, have both upper and lowercase, have a number and contains one of the following:
// !$#%@
export const checkPassword = str => {
    return str.length < 8 || str.toUpperCase() === str || str.toLowerCase() === str
        || !'!$#%@'.split('').some(el => str.includes(el)) || !/\d/.test(str)
}
export const checkSchoolName = str => {
    return str === undefined || str === null || str.length === 0 || `!@#$%^&*_+-*/=:"<>?|\\`.split('').some(el => str.includes(el))
}
export const checkParticipantName = str => {
    return str === undefined || str === null || str.length === 0 || `!@#$%^&*_+-*/=:"<>?|\\`.split('').some(el => str.includes(el))
}
export const invalidName = str => str.length === 0 || !/^[\.\,\s\(\)\[\]\w-]*$/.test(str.trim())
export const invalidPhoneOrGrade = str => !/^[\d]*$/.test(str)
export const checkID = str => {
    return str === undefined || str === null || str.length === 0 || isNaN(str)
}
let emailDomains = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com',
    'hotmail.co.uk', 'hotmail.fr', 'msn.com', 'yahoo.fr',
    'wanadoo.fr', 'orange.fr', 'comcast.net', 'yahoo.co.uk',
    'yahoo.com.br', 'yahoo.co.in', 'live.com', 'rediffmail.com',
    'free.fr', 'gmx.de', 'web.de', 'yandex.ru',
    'ymail.com', 'libero.it', 'outlook.com', 'uol.com.br',
    'bol.com.br', 'mail.ru', 'cox.net', 'hotmail.it',
    'sbcglobal.net', 'sfr.fr', 'live.fr', 'verizon.net',
    'live.co.uk', 'googlemail.com', 'yahoo.es', 'ig.com.br',
    'live.nl', 'bigpond.com', 'terra.com.br', 'yahoo.it',
    'neuf.fr', 'yahoo.de', 'alice.it', 'rocketmail.com',
    'att.net', 'laposte.net', 'facebook.com', 'bellsouth.net',
    'yahoo.in', 'hotmail.es', 'charter.net', 'yahoo.ca',
    'yahoo.com.au', 'rambler.ru', 'hotmail.de', 'tiscali.it',
    'shaw.ca', 'yahoo.co.jp', 'sky.com', 'earthlink.net',
    'optonline.net', 'freenet.de', 't-online.de', 'aliceadsl.fr',
    'virgilio.it', 'home.nl', 'qq.com', 'telenet.be',
    'me.com', 'yahoo.com.ar', 'tiscali.co.uk', 'yahoo.com.mx',
    'voila.fr', 'gmx.net', 'mail.com', 'planet.nl',
    'tin.it', 'live.it', 'ntlworld.com', 'arcor.de',
    'yahoo.co.id', 'frontiernet.net', 'hetnet.nl', 'live.com.au',
    'yahoo.com.sg', 'zonnet.nl', 'club-internet.fr', 'juno.com',
    'optusnet.com.au', 'blueyonder.co.uk', 'bluewin.ch', 'skynet.be',
    'sympatico.ca', 'windstream.net', 'mac.com', 'centurytel.net',
    'chello.nl', 'live.ca', 'aim.com', 'bigpond.net.au'
]
export const invalidEmail = str => {
    // if (!str.includes('@') || !str.includes('.')) return true
    // if (`\`~!@#$%^&*()+-*/_={}[]:;'<>?,`.split('').some(el => str.split('@')[0].includes(el))) return true
    // if (!emailDomains.includes(str.split('@')[1])) return true
    // return false
    return !/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(str)
}
// Not sure how the regex works, but it helps to remove any single or double quotes in a cell 
// before storing the 
export const delimitCSVRow = str => {
    if (str) {
        let splitted = str.match(/(".*?"|[^",]+)(?=\s*,|\s*$)/g)
        return splitted
    } return ['']
}
export const sortErrorByRow = obj => {
    let sorted = Object.keys(obj).sort((a, b) => a.split('.')[0] - b.split('.')[0])
    let output = []
    sorted.forEach(s => {
        if (output.findIndex(o => o.id === s.split('.')[0]) > -1) {
            output[output.findIndex(o => o.id === s.split('.')[0])][[s.split('.')[1]]] = true
        } else output.push({ id: s.split('.')[0], [s.split('.')[1]]: true })
    })
    return output
}
export const sortErrorByType = obj => {
    let newOrder = []
    let sorted = Object.keys(obj)
    let output = []
    sorted.forEach(s => {
        if (output.findIndex(o => o.errorType === s.split('.')[1]) > -1) {
            output[output.findIndex(o => o.errorType === s.split('.')[1])].ids.push(s.split('.')[0])
        } else output.push({ errorType: s.split('.')[1], ids: [s.split('.')[0]] })
    })
    output.forEach(o => o.ids.forEach(id => newOrder.push(id)))
    return output
}
// Functions for show data for table
export const changeViewOptions = (originalHeader, setViewOptions, setHeader, value) => {
    setViewOptions(value)
    let newHeader = originalHeader
    let donShow = value.map(v => !v.state && v).filter(v => v)
    donShow.forEach(d => {
        originalHeader.forEach(s => {
            if (d.key === s.id) {
                newHeader = newHeader.filter(n => n.id !== d.key)
            }
        })
    })
    setHeader(newHeader)
}
export const changeRowsPerPage = (initial, filtered, search, setRowsPerPage, params, setParams, getData, value) => {
    // Don't do anything if they attempt to set rowsPerPage to less than 10 or more than 50
    if (value < 10 || value > 50) return
    // Might not need setRowsPerPage cos alr finish setting infront, need check
    setRowsPerPage(value)
    let newParams = initial
    if (newParams.length > 1) newParams += '&'
    filtered.forEach(({ key, state }) => {
        if (state.toString().length) newParams += `${key}=${state}&`
    })
    if (search.length) newParams += `search=${search}&`
    newParams += `limits=${value}`
    setParams(newParams)
    // If there is no changes between the new params and the current params, don't call the API
    if (newParams !== params) getData(newParams)
}
export const changeFiltered = (initial, filtered, rowsPerPage, setSearch, params, setParams, getData) => {
    let newParams = initial
    if (newParams.length > 1) newParams += '&'
    filtered.forEach(({ key, state }) => {
        if (state.toString().length) newParams += `${key}=${state}&`
    })
    newParams += `limits=${rowsPerPage}`
    setSearch('')
    setParams(newParams)
    // If there is no changes between the new params and the current params, don't call the API
    if (newParams !== params) getData(newParams)
}
export const changeSearch = (initial, filtered, rowsPerPage, setSearch, params, setParams, getData, value) => {
    setSearch(value)
    let newParams = initial
    if (newParams.length > 1) newParams += '&'
    filtered.forEach(({ key, state }) => {
        if (state.toString().length) newParams += `${key}=${state}&`
    })
    if (value.length) newParams += `search=${value}&`
    newParams += `limits=${rowsPerPage}`
    setParams(newParams)
    // If there is no changes between the new params and the current params, don't call the API
    if (newParams !== params) getData(newParams)
}
export const loadData = (setLoading, setFirstLoaded, getFunction, params, setTable, setFilterOptions, loadSingle) => {
    setLoading(true)
    getFunction(params).then(d => {
        // The object d is something like { filterOptions: {}, SchoolOptions: {} }
        let output = Object.values(d)
        loadSingle ? setTable(output[1].data) : setTable(output[1])
        if (setFilterOptions) setFilterOptions(output[0])
        setLoading(false)
        setFirstLoaded(true)
    }).catch(e => {
        console.log(e)
        setLoading(false)
        setFirstLoaded(true)
    }).finally(() => {
        setLoading(false)
        setFirstLoaded(true)
    })
}
export const controlView = (viewing, setViewing, setFiltering) => {
    setViewing(!viewing)
    setFiltering(false)
}
export const controlFilter = (filtering, setFiltering, setViewing) => {
    setFiltering(!filtering)
    setViewing(false)
}
export const cancelAction = (setAction, setSelected, setSelecting) => {
    setAction(false)
    setSelected([])
    setSelecting([])
}