import React, { useState, useEffect } from 'react';
import { Grid, Checkbox, FormControlLabel, Box } from '@mui/material';
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from './general/NunitoText'
import ReusableButton from './general/ReusableButton'
export default function ViewOption({ viewOptions, rowsPerPage, onChangeRowsPerPage, onChangeViewOptions, viewing }) {
    const [pageNo, setPageNo] = useState(rowsPerPage)
    const checked = count => {
        let newViewOptions = [...viewOptions]
        newViewOptions[count].state = !newViewOptions[count].state
        onChangeViewOptions(newViewOptions)
    }
    const setAll = state => {
        let newViewOptions = [...viewOptions]
        newViewOptions.forEach(n => n.state = state)
        onChangeViewOptions(newViewOptions)
    }
    useEffect(() => {
        if (viewing) setPageNo(rowsPerPage)
    }, [viewing, rowsPerPage])
    const containerStyle = {
        backgroundColor: '#F5F5F5',
        paddingLeft: 30,
        paddingBottom: 15
    }
    const h3Style = { marginBottom: 0 }
    return (
        <Grid container style={containerStyle}>
            <Grid item xs={12}>
                <h3 style={h3Style}>Columns</h3>
                {viewOptions.map(({ label, state }, index) => (
                    <FormControlLabel
                        key={index}
                        value={state}
                        control={<Checkbox style={{ color: '#000' }} size="small" checked={state} />}
                        label={label}
                        labelPlacement="start"
                        style={{ position: 'relative', right: 15 }}
                        onChange={() => checked(index)}
                    />
                ))}
            </Grid>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <ReusableButton text='Show All' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    marginTop={10} marginRight={20} onClick={() => setAll(true)} />
                <ReusableButton text='Hide All' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    marginTop={10} onClick={() => setAll(false)} />
            </Grid>
            <Grid item xs={12}>
                <h3 style={h3Style}>Pagination</h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p>Number of entries per page:</p>
                    <Box display="flex" justifyContent="center" alignItems="center" style={{ paddingLeft: 10 }}>
                        <input type='number' style={{ width: '52px', height: '20px', textAlign: 'center' }}
                            value={pageNo} onChange={e => setPageNo(e.target.value)} />
                    </Box>
                    {Number(pageNo) < 10 && <NunitoText value='Minimum entries per page is 10' color='#E83042' marginLeft={20} />}
                    {Number(pageNo) > 50 && <NunitoText value='Maximum entries per page is 50' color='#E83042' marginLeft={20} />}
                </div>
                <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={44} width={120} br={8}
                    onClick={() => onChangeRowsPerPage(pageNo)} />
            </Grid>
        </Grid>
    )
}