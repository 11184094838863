import React, { useState, useEffect } from 'react'
import {
    Box, Grid, TextField, Collapse, Typography, Modal, Checkbox, Tooltip, tooltipClasses, styled, Chip
} from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import CancelIcon from '@mui/icons-material/Cancel'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import CustomNestedSelectSingle from '../components/CustomNestedSelectSingle'
import { useSelector, useDispatch } from 'react-redux'
import {
    makeOptions, ensureObjectOfArraysArePopulated, makeSureIsArray, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import '../css/school.css'
import '../css/global.css'
import { resetTask } from '../actions'
import { getTasks, getCompetitions } from '../functions/getData'
import { deleteTask } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableCheckbox from '../components/general/ReusableCheckbox'
import Widget from '../components/general/Widget'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import CustomSelectMultiple from '../components/CustomNestedSelectMultiple'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#144A94',
        position: 'relative',
        top: -10,
        left: 50,
        border: '1px solid #707070',
        borderRadius: 16,
        maxWidth: 950,
        boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
    }
}));
const languageList = ["Chinese (Simplified)", "Chinese (Traditional)", "English", "Italian", "Dutch", "German"]
const sectionList = ['Section 1', 'Section 2', 'Section 3', 'Section 4', 'Section 5']
const difficultyList = ['Easy', 'Intermediate', 'Hard', 'Very Hard']
const userList = [{ role: 'Admin', users: ['testUser1', 'testUser612', 'testUser910'] },
{ role: 'Country Partner', users: ['testUser2', 'testUser712', 'testUser210'] },
{ role: 'School Coordinator', users: ['testUser3', 'testUser812', 'testUser340'] },
{ role: 'Testers', users: ['testUser4', 'testUser412', 'eugene'] },
{ role: 'General User', users: ['testUser5', 'testUser512', 'eugene6', 'grayson', 'eugene7'] }]
const tooltipTitleStyle = {
    margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid #707070', textAlign: 'center',
    borderRadius: 6, paddingInline: 4, paddingBlock: 2, marginInline: 3, marginTop: 5, fontWeight: 400
}
const tooltipContentStyle = {
    margin: '0 0 0 3px', fontWeight: 300, color: '#000',
    textAlign: 'left'
}
export function AssignTasksToCollection({ task, count, selected, setSelected, selecting, setSelecting, massSection, massDifficulty,
    assignMassSection, setAssignMassSection, assignMassDifficulty, setAssignMassDifficulty }) {
    const [section, setSection] = useState('')
    const [difficulty, setDifficulty] = useState('')
    const [correct, setCorrect] = useState([0, 0, 0])
    const [wrong, setWrong] = useState(0)
    const [blank, setBlank] = useState(0)
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        if (checked && assignMassSection) setSection(massSection); setAssignMassSection(false)
    }, [massSection, assignMassSection, checked])
    useEffect(() => {
        if (checked && assignMassDifficulty) setDifficulty(massDifficulty); setAssignMassDifficulty(false)
    }, [massDifficulty, assignMassDifficulty, checked])
    const pointStyle = {
        border: '1px solid #707070', borderRadius: 12, width: 135, height: 60,
        backgroundColor: '#F2F2F2', display: 'flex', alignItems: 'center',
        justifyContent: 'center'
    }
    return (
        <Grid container style={{ border: '1px solid #000', borderRadius: 16, marginBottom: 20, padding: '0 15px' }}>
            <Grid item xs={0.3} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox style={{ color: '#000' }} size="medium" onChange={() => setChecked(!checked)} />
            </Grid>
            <Grid item xs={11.4}>
                <Grid container style={{ marginBottom: 8 }}>
                    <Grid item xs={6.2} />
                    <Grid item xs={3.4}>
                        <NunitoText value='Correct Answer' fontSize={14} fontWeight={700} />
                    </Grid>
                    <Grid item xs={1.2}>
                        <NunitoText value='Wrong Answer' fontSize={14} fontWeight={700} />
                    </Grid>
                    <Grid item xs={1.2}>
                        <NunitoText value='Blank Answer' fontSize={14} fontWeight={700} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1.9} style={{ textAlign: 'left' }}>
                        <NunitoText value={`${task.taskTitle} - Task ${count + 1}`} fontSize={22} fontWeight={600} />
                    </Grid>
                    <Grid item xs={4.3} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                        <ReusableAutocomplete type='modal' state={section} setState={setSection} width={240} height={46}
                            bgColor='#F2F2F2' btnBgColor='#5E75C3' border='#707070' placeholder='Section' options={sectionList}
                            marginRight={20} />
                        <ReusableAutocomplete type='modal' state={difficulty} setState={setDifficulty} width={240} height={46}
                            bgColor='#F2F2F2' btnBgColor='#5E75C3' border='#707070' placeholder='Difficulty' options={difficultyList} />
                    </Grid>
                    <Grid item xs={5.8} style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                        <div style={{
                            width: 410, display: 'flex',
                            justifyContent: task.answerType === 'MCQ' && ['Group'].includes(task.answerStructure) ? 'space-between'
                                : 'space-evenly', alignItems: 'center'
                        }}>
                            {correct.map((c, index) => (
                                <TextField
                                    key={index}
                                    style={{
                                        ...pointStyle,
                                        display: !(task.answerType === 'MCQ' && ['Group'].includes(task.answerStructure)) &&
                                            index !== 1 && 'none'
                                    }}
                                    type="number"
                                    variant="filled"
                                    fullWidth
                                    inputProps={{ style: { textAlign: 'center', width: '100%' } }}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        onBlur: (e) => {
                                            let newCorrect = [...correct]
                                            newCorrect[index] = e.target.value
                                            setCorrect(newCorrect)
                                        }
                                    }} />
                            ))}
                        </div>
                        <ReusableTextField type='default' state={wrong} setState={setWrong} width={135} height={60}
                            bgColor='#F2F2F2' number />
                        <ReusableTextField type='default' state={blank} setState={setBlank} width={135} height={60}
                            bgColor='#F2F2F2' number />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={1.2}>
                        <LightTooltip title={
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: 210 }}>
                                {Object.keys(task.recommendedDifficulty[0]).map((a, index) => (
                                    <div key={index}>
                                        <h2 style={tooltipTitleStyle}>
                                            {a === 'partner' ? 'Country Partner' : a.charAt(0).toUpperCase() + a.slice(1)}</h2>
                                        {task.recommendedDifficulty.map((d, index) => (
                                            <h2 key={index} style={tooltipContentStyle}>{d[a]}{a === 'percentage' && '%'}</h2>
                                        ))}
                                    </div>
                                ))}
                            </div>}>
                            <Typography style={{ fontSize: 14, color: '#707070', textDecoration: 'underline' }}>Recommendations</Typography>
                        </LightTooltip>
                    </Grid>
                    <Grid item xs={1.2}>
                        <NunitoText value={`Task Type - ${task.answerType}`} color='#144A94' fontSize={14} fontWeight={400} italic />
                    </Grid>
                    <Grid item xs={1.6}>
                        <NunitoText value={`Task Structure - ${task.answerStructure}`} color='#144A94' fontSize={14}
                            fontWeight={400} italic />
                    </Grid>
                    <Grid item xs={8} />
                </Grid>
            </Grid>
            <Grid item xs={0.3} style={{ display: 'flex', alignItems: 'center' }}>
                <CancelIcon style={{ color: '#E83D4D', fontSize: 32, cursor: 'pointer' }}
                    onClick={() => {
                        setSelected(selected.filter(s => s !== task.id))
                        setSelecting(selecting.filter(s => s !== task.taskIdentifier))
                    }} />
            </Grid>
        </Grid>
    )
}
export function ModerateLanguagesForTask({ taskTable, selectedTasks, count, usersToModerateLanguage, setUsersToModerateLanguage }) {
    const setUser = (value) => {
        let newUsersToModerateLanguage = [...usersToModerateLanguage]
        newUsersToModerateLanguage[count].user = value
        setUsersToModerateLanguage(newUsersToModerateLanguage)
    }
    const setTasks = (value) => {
        let newUsersToModerateLanguage = [...usersToModerateLanguage]
        newUsersToModerateLanguage[count].tasks = value
        setUsersToModerateLanguage(newUsersToModerateLanguage)
    }
    const setLanguage = (value) => {
        let newUsersToModerateLanguage = [...usersToModerateLanguage]
        newUsersToModerateLanguage[count].languages = value
        setUsersToModerateLanguage(newUsersToModerateLanguage)
    }
    const duplicateUser = () => {
        let newUsersToModerateLanguage = [...usersToModerateLanguage]
        newUsersToModerateLanguage.splice(count, 0, JSON.parse(JSON.stringify(usersToModerateLanguage[count])))
        setUsersToModerateLanguage(newUsersToModerateLanguage)
    }
    const removeUser = () => {
        let newUsersToModerateLanguage = [...usersToModerateLanguage]
        newUsersToModerateLanguage.splice(count, 1)
        setUsersToModerateLanguage(newUsersToModerateLanguage)
    }
    return (
        <Grid container alignItems="center" style={{ marginTop: 50, paddingInline: 80 }}>
            <Grid item xs={0.5} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <CancelIcon style={{ color: '#E83D4D', fontSize: 36, cursor: 'pointer' }} onClick={() => removeUser()} />
            </Grid>
            <Grid item xs={11.5}>
                <Grid container alignItems='center' style={{ border: '1px solid #707070', borderRadius: 20, paddingBlock: 16 }}>
                    <Grid item xs={3.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomNestedSelectSingle state={usersToModerateLanguage[count].user} setState={setUser} placeholder='Search for User' data={userList}
                            width={380} outerKey='role' innerKey='users' />
                    </Grid>
                    <Grid item xs={3.5} style={{ display: 'flex', justifyContent: 'center' }}>
                        <ReusableAutocompleteWithID type='multiple' state={usersToModerateLanguage[count].tasks} setState={setTasks}
                            width={380} height={60} bgColor='#F2F2F2' btnBgColor='#5E75C3' placeholder='Search for Tasks(s)'
                            options={selectedTasks.map(s => {
                                let t = taskTable.table.find(task => task.id === s)
                                return { id: s, task: `${t.taskIdentifier} - ${t.taskTitle}` }
                            })} limitMultiple={3} />
                    </Grid>
                    <Grid item xs={3.5} style={{ display: 'flex', justifyContent: 'center' }}>
                        <ReusableAutocompleteWithID type='multiple' state={usersToModerateLanguage[count].languages} setState={setLanguage} width={380} height={60}
                            bgColor='#F2F2F2' btnBgColor='#5E75C3' placeholder='Search for Language(s)'
                            options={languageList.map((l, i) => ({ id: i, language: l }))} limitMultiple={3} />
                    </Grid>
                    <Grid item xs={1.5}>
                        <ReusableButton text='Duplicate User' bgColor='#F16774' fontSize={14} height={44} iconType='copy'
                            onClick={() => duplicateUser()} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
export function AssignTaskVerification({ taskTable, selectedTasks, count, usersToAssignTaskVerification, setAssignTaskVerification }) {
    const setUser = (value) => {
        let newUsersToAssignTaskVerification = [...usersToAssignTaskVerification]
        newUsersToAssignTaskVerification[count].user = value
        setAssignTaskVerification(newUsersToAssignTaskVerification)
    }
    const setTasks = (value) => {
        let newUsersToAssignTaskVerification = [...usersToAssignTaskVerification]
        newUsersToAssignTaskVerification[count].tasks = value
        setAssignTaskVerification(newUsersToAssignTaskVerification)
    }
    const removeUser = () => {
        let newUsersToAssignTaskVerification = [...usersToAssignTaskVerification]
        newUsersToAssignTaskVerification.splice(count, 1)
        setAssignTaskVerification(newUsersToAssignTaskVerification)
    }
    return (
        <Grid container alignItems="center" style={{ marginTop: 50, paddingInline: 40 }}>
            <Grid item xs={0.6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <CancelIcon style={{ color: '#E83D4D', fontSize: 36, cursor: 'pointer' }} onClick={() => removeUser()} />
            </Grid>
            <Grid item xs={11.4}>
                <Grid container alignItems='center' style={{ border: '1px solid #707070', borderRadius: 20, paddingBlock: 16 }}>
                    <Grid item xs={3.4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <CustomNestedSelectSingle state={usersToAssignTaskVerification[count].user} setState={setUser} placeholder='Search for User' data={userList}
                            width={380} outerKey='role' innerKey='users' />
                    </Grid>
                    <Grid item xs={4} style={{ display: 'flex', justifyContent: 'center' }}>
                        <ReusableAutocompleteWithID type='multiple' state={usersToAssignTaskVerification[count].tasks} setState={setTasks} width={380} height={60}
                            bgColor='#F2F2F2' btnBgColor='#5E75C3' placeholder='Search for Tasks(s)'
                            options={selectedTasks.map(s => {
                                let t = taskTable.table.find(task => task.id === s)
                                return { id: s, task: `${t.taskIdentifier} - ${t.taskTitle}` }
                            })} limitMultiple={3} />
                    </Grid>
                    <Grid item xs={1.2}>
                        <NunitoText value='Select Task(s):' fontSize={20} color='#144A94' />
                    </Grid>
                    <Grid item xs={2.2}>
                        {selectedTasks.map((s, index) => (
                            index < 2 && <NunitoText key={index} value={`Task 2 - dcoiadminoadm`} fontSize={20} color='#144A94' />
                        ))}
                    </Grid>
                    <Grid item xs={1.2}>
                        <LightTooltip title={
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <div>
                                    <h2 style={tooltipTitleStyle}>Tasks</h2>
                                    {selectedTasks.map((t, index) => (
                                        <h2 key={index} style={tooltipContentStyle}>
                                            Task {index + 1} - {taskTable.table.find(task => task.id === t).taskTitle}</h2>
                                    ))}
                                </div>
                            </div>}>
                            <Chip label='+3' style={{ backgroundColor: '#F16774', color: '#FFF' }} />
                        </LightTooltip>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}
const taskHeader = [
    { id: 'identifier', label: 'Task Identifier' },
    { id: 'title', label: 'Task Title' },
    { id: 'description', label: 'Description' },
    { id: 'languages', label: 'No. of Language(s)' },
    { id: 'domain', label: 'Domain' },
    { id: 'tags', label: 'Tags' },
    { id: 'grade_difficulty', label: 'Recommended Difficulty' },
    { id: 'answer_type', label: 'Answer Type' },
    { id: 'answer_structure', label: 'Answer Structure' },
    { id: 'answer_sorting', label: 'Display Order' },
    { id: 'answers', label: 'No. of Correct Answers' },
    // { id: 'collections', label: 'Collections' },
    // { id: 'competitions', label: 'Competitions' },
    { id: 'created_by', label: 'Created By' },
    { id: 'last_modified_by', label: 'Last Modified By' },
    { id: 'modulation', label: 'Last Verified By (last 5)' },
    { id: 'status', label: 'Status' }
]
const initialParams = '?'
export default function Task() {
    document.title = 'Task'
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(taskHeader)
    const [taskTable, setTaskTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Language', state: '', key: 'lang_id', header: 'lang' },
        { label: 'Domain', state: '', key: 'tag_id', header: 'domains' },
        { label: 'Tags', state: [], key: 'tag_id', header: 'tags' },
        // { label: 'Collection', state: '', key: 'collections', header: '' },
        // { label: 'Competition', state: '', key: 'competitions', header: '' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: 'No. of Langauge(s)', state: true, key: 'task_content' },
        { label: 'Domain', state: true, key: 'domain' },
        { label: 'Tags', state: true, key: 'tags' },
        { label: 'Recommended Difficulty', state: true, key: 'recommended_difficulty' },
        { label: 'Answer Type', state: true, key: 'answer_type' },
        { label: 'Answer Structure', state: true, key: 'answer_structure' },
        { label: 'Display Order', state: true, key: 'display_order' },
        { label: 'No. of answers', state: true, key: 'answers' },
        { label: 'Collections', state: true, key: 'collections' },
        { label: 'Competitions', state: true, key: 'competitions' },
        { label: 'Created By', state: true, key: 'created_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Last Verified By(Last 5)', state: true, key: 'verified_by_username' },
        { label: 'Status', state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchTask, setSearchTask] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteTask({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getTasks, params, setTaskTable, setFilterOptions)
    const [onAddTaskToCollection, setOnAddTaskToCollection] = useState(false)
    const [onModerateLanguages, setOnModerateLanguages] = useState(false)
    const [usersToModerateLanguage, setUsersToModerateLanguage] = useState([{ user: '', tasks: [], languages: [] }])
    const [onAssignTaskVerification, setOnAssignTaskVerification] = useState(false)
    const [usersToAssignTaskVerification, setAssignTaskVerification] = useState([{ user: '', tasks: [] }])
    const closeModerateLanguageModal = () => {
        setUsersToModerateLanguage([{ user: '', tasks: [], languages: [] }])
        setOnModerateLanguages(false);
    }
    const closeAssignTaskVerificationModal = () => {
        setAssignTaskVerification([{ user: '', tasks: [] }])
        setOnAssignTaskVerification(false);
    }
    const dispatch = useDispatch()
    const cancelIconStyle = { color: '#E83D4D', fontSize: 32, cursor: 'pointer' }

    const onChangeViewOptions = value => changeViewOptions(taskHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchTask, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        // changeFiltered(initialParams, filtered, rowsPerPage, setSearchTask, params, setParams, getData)
        // Need write different functions to handle filtered for Tasks because of the way domain and
        // tag are both under ?tag_id
        let newFiltered = value
        let newParams = initialParams
        if (newParams.length > 1) newParams += '&'
        let tag_id = []
        newFiltered.forEach(({ key, state }) => {
            if (key === 'tag_id') {
                if (typeof state === 'object') state.forEach(s => tag_id.push(s))
                else {
                    if (state.toString().length) tag_id.push(state)
                }
            }
            else {
                if (state.toString().length) newParams += `${key}=${state}&`
            }
        })
        console.log(tag_id)
        if (tag_id.length) newParams += `tag_id=${tag_id.join(',')}&`
        newParams += `limits=${rowsPerPage}`
        setSearchTask('')
        setParams(newParams)
        // If there is no changes between the new params and the current params, don't call the API
        if (newParams !== params) getData(newParams)
    }
    const onChangeSearchTask = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchTask, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        getData(params)
    }, [])
    const onClickMassApprove = () => {
        return taskTable && checkIfAllSelectedRowsMatchStatus(selected, taskTable.data, 'pending', user) ?
            '' : showWarningSwal('Please select pending tasks only to mass approve')
    }
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one task to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} viewing={viewing} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Tasks' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => dispatch(resetTask())} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            {JSON.stringify(params)}
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Tasks' fontSize={40} fontWeight={700} italic color='#144A94' />
                {/* {filtered.find(f => f.key === 'competitions').state !== '' &&
                    <Widget title='Competition' content={filtered.find(f => f.key === 'competition').state}
                        caption='Individual Competition Name' marginBottom={40} />}
                {filtered.find(f => f.key === 'collections').state !== '' &&
                    <Widget title='Collection' content={filtered.find(f => f.key === 'collection').state}
                        caption='Collection used for competition' marginBottom={40} />} */}
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchTask} setState={onChangeSearchTask} onBlur />
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Task' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            to='/addtask' blank iconType='add' />
                        <ReusableButton text='Add Task To Collection' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => Boolean(selected.length) ? setOnAddTaskToCollection(true) :
                                showWarningSwal('Please select a task to add to collection')} iconType='add' />
                        <ReusableButton text='Assign Task Verification' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => Boolean(selected.length) ? setOnAssignTaskVerification(true) :
                                showWarningSwal('Please select a task to assign verification for')} iconType='add' />
                        <ReusableButton text='Moderate Task Language(s)' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => Boolean(selected.length) ? setOnModerateLanguages(true) :
                                showWarningSwal('Please select a task to moderate language for')} iconType='add' />
                        <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => !Boolean(selected.length) && showWarningSwal('Please select a task to approve')}
                            iconType='check' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => !Boolean(selected.length) && showWarningSwal('Please select a task to mass delete')}
                            iconType='delete' />
                    </div>
                </Grid>
                <NunitoText value='Search only in "Task Identifier" and "Task Title" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Loader height={600} /> : taskTable && makeSureIsArray(taskTable.data).length ?
                    <HeavyTable headers={header} list={taskTable} title='task' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </Modal>
            {/* <Modal open={onAddTaskToCollection} onClose={() => setOnAddTaskToCollection(false)}>
                <Box className='paddedScrollablePopUpModal' style={{ width: 1700, height: 800 }}>
                    <div>
                        <Grid container alignItems="center">
                            <Grid item xs={7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NunitoText value='ASSIGN TASKS TO:' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                            </Grid>
                            <Grid item xs={5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CancelIcon style={cancelIconStyle} onClick={() => setOnAddTaskToCollection(false)} />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 40 }}>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <NunitoText value='Competition' fontSize={24} fontWeight={600} italic marginLeft={60} marginRight={100} />
                                <ReusableAutocomplete type='modal' width={400} height={60} bgColor='#F2F2F2' btnWidth={56}
                                    btnBgColor='#5E75C3' placeholder='Search for Competition'
                                    state={competition} setState={setCompetition} options={competitions.table.map(c => c.competitionName)} />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <NunitoText value='Collection:' fontSize={24} fontWeight={600} italic marginLeft={60} marginRight={100} />
                                <ReusableAutocomplete type='modal' width={400} height={60} bgColor='#F2F2F2' btnWidth={56}
                                    btnBgColor='#5E75C3' placeholder='Search for Collection'
                                    state={collection} setState={setCollection} options={competitions.table.map(c => c.competitionName)}
                                    readOnly={!competition} grayedOut={!competition} />
                            </Grid>
                        </Grid>
                        <Grid container style={{ marginTop: 40 }} justifyContent="center">
                            <Grid item xs={6}
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', paddingInline: 30 }}>
                                <ReusableAutocomplete type='modal' width={360} height={50} bgColor='#F2F2F2' btnWidth={50}
                                    btnBgColor='#5E75C3' placeholder='Assign Section' state={section} setState={setSection}
                                    options={sectionList} readOnly={!collection} grayedOut={!collection} />
                                <ReusableButton text='Mass Assign Section' bgColor='#144A94'
                                    onClick={() => { setMassSection(section); setAssignMassSection(true) }} iconType='add' />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', paddingInline: 30 }}>
                                <ReusableAutocomplete type='modal' width={360} height={50} bgColor='#F2F2F2' btnWidth={50}
                                    btnBgColor='#5E75C3' placeholder='Assign Difficulty' state={difficulty} setState={setDifficulty}
                                    options={difficultyList} readOnly={!collection} grayedOut={!collection} />
                                <ReusableButton text='Mass Assign Difficulty' bgColor='#144A94'
                                    onClick={() => { setMassDifficulty(difficulty); setAssignMassDifficulty(true) }} iconType='add' />
                            </Grid>
                            <Grid container justifyContent='flex-end'>
                                <ReusableCheckbox type='modal' state={useSameCorrect} setState={setUseSameCorrect}
                                    label='Use Same' fontSize={17} marginRight={180} />
                                <ReusableCheckbox type='modal' state={useSameWrong} setState={setUseSameWrong}
                                    label='Use Same' fontSize={17} marginRight={40} />
                                <ReusableCheckbox type='modal' state={useSameBlank} setState={setUseSameBlank}
                                    label='Use Same' fontSize={17} marginRight={80} />
                            </Grid>
                            {taskTable && selected.map((s, index) => (
                                <AssignTasksToCollection key={index} task={taskTable.table.filter(t => t.id === s)[0]} count={index}
                                    selected={selected} setSelected={setSelected} selecting={selecting} setSelecting={setSelecting}
                                    massSection={massSection} massDifficulty={massDifficulty} assignMassSection={assignMassSection}
                                    setAssignMassSection={setAssignMassSection} assignMassDifficulty={assignMassDifficulty}
                                    setAssignMassDifficulty={setAssignMassDifficulty} />
                            ))}
                        </Grid>
                    </div>
                    <Grid container justifyContent="center" style={{ marginBottom: 30 }}>
                        <ReusableButton text='Submit' fontSize={22} bgColor='#5E75C3' width={300} height={60} />
                    </Grid>
                </Box>
            </Modal>
            <Modal open={onModerateLanguages} onClose={() => closeModerateLanguageModal()}>
                <Box className='scrollablePopUpModal' style={{ width: 1680, height: 760 }}>
                    <div>
                        <Grid container alignItems="center" style={{ marginBottom: 30 }}>
                            <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NunitoText value='MODERATE LANGUAGES FOR TASKS:' color='#5E75C3' fontSize={30} marginTop={20} />
                            </Grid>
                            <Grid item xs={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CancelIcon style={cancelIconStyle} onClick={() => closeModerateLanguageModal()} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent='center'>
                            <div style={{ display: 'flex', alignItems: 'center', border: '1px solid red' }}>
                                {selecting.map((s, index) => (
                                    <NunitoText key={index} value={`${index < selecting.length - 1 ? `${s},` : s}`} color='#F16774'
                                        fontSize={30} fontWeight={700} />
                                ))}
                                <ReusableButton text='Add User' fontSize={16} bgColor='#5E75C3' width={160} iconType='add'
                                    onClick={() => setUsersToModerateLanguage([...usersToModerateLanguage, { user: '', tasks: [], languages: [] }])} />
                            </div>
                        </Grid>
                        {JSON.stringify(usersToModerateLanguage)}
                        {usersToModerateLanguage.map((userRow, index) => (
                            <ModerateLanguagesForTask key={index} taskTable={taskTable} selectedTasks={selected} count={index}
                                usersToModerateLanguage={usersToModerateLanguage}
                                setUsersToModerateLanguage={setUsersToModerateLanguage} />
                        ))}
                    </div>
                    <Grid container justifyContent="space-around" style={{ paddingInline: 360, marginBlock: 30 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={22} width={300} height={60} />
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={22} width={300} height={60} />
                    </Grid>
                </Box>
            </Modal>
            <Modal open={onAssignTaskVerification} onClose={() => closeAssignTaskVerificationModal()}>
                <Box className='scrollablePopUpModal' style={{ width: 1600, height: 860 }}>
                    <div>
                        <Grid container alignItems="center" style={{ marginBottom: 30 }}>
                            <Grid item xs={7.6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NunitoText value='ASSIGN TASK VERIFICATION' color='#5E75C3' fontSize={30} marginTop={20} />
                            </Grid>
                            <Grid item xs={4.4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CancelIcon style={cancelIconStyle} onClick={() => closeAssignTaskVerificationModal()} />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                            <NunitoText value={`TASKS:\u00a0`} color='#F16774' fontSize={30} fontWeight={700} />
                            {selecting.map((s, index) => (
                                <NunitoText key={index} value={`${index < selecting.length - 1 ? `${s},` : s}`} color='#F16774'
                                    fontSize={30} fontWeight={700} />
                            ))}
                        </Grid>
                        <Grid container justifyContent="center" style={{ marginTop: -40 }}>
                            <Grid item xs={6} />
                            <ReusableButton text='Add User' fontSize={16} bgColor='#5E75C3' width={160} iconType='add'
                                onClick={() => setAssignTaskVerification([...usersToAssignTaskVerification, { user: '', tasks: [] }])} />
                        </Grid>
                        {JSON.stringify(usersToAssignTaskVerification)}
                        {usersToAssignTaskVerification.map((userRow, index) => (
                            <AssignTaskVerification key={index} taskTable={taskTable} selectedTasks={selected} count={index}
                                usersToAssignTaskVerification={usersToAssignTaskVerification}
                                setAssignTaskVerification={setAssignTaskVerification} />
                        ))}
                    </div>
                    <Grid container justifyContent="space-around" style={{ paddingInline: 360, marginBlock: 30 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={22} width={300} height={60} />
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={22} width={300} height={60} />
                    </Grid>
                </Box>
            </Modal> */}
        </Box>
    )
}