import React, { useState, useEffect, useRef } from 'react'
import { Grid, Autocomplete, TextField, IconButton, Chip, Checkbox, Collapse, List, CircularProgress } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import ClearIcon from '@mui/icons-material/Clear'
import NunitoText from './NunitoText'
import LightTooltip from './LightTooltip'
import '../../css/colors.css'
const chipStyle = { backgroundColor: '#F16774', color: '#FFF' }
// For nested autocompletes for one or more multiple selections
const GroupOptions = ({ id, group, children, state, setState, allowSelectHead, selectAll, multiple, search }) => {
    const [open, setOpen] = useState(false)
    return (
        <div>
            {multiple ? <GroupHeadMultiple id={id} group={group} children={children} open={open} setOpen={setOpen} state={state}
                setState={setState} allowSelectHead={allowSelectHead} selectAll={selectAll} /> :
                <GroupHead id={id} group={group} children={children} open={open} setOpen={setOpen} setState={setState}
                    allowSelectHead={allowSelectHead} />}
            <Collapse in={open}>
                {Boolean(children.length) && <List>
                    {children.map((c, i) => (
                        multiple ? <IndividualOptionsMultiple key={i} id={c.id} child={c.option} state={state} setState={setState}
                            search={search} /> :
                            <IndividualOptions key={i} id={c.id} child={c.option} setState={setState} search={search} />
                    ))}
                </List>}
            </Collapse>
        </div>
    )
}
const OpenCloseIcon = ({ open, setOpen }) => {
    const toggle = e => {
        e.stopPropagation()
        setOpen(!open)
    }
    return (
        <IconButton onClick={toggle}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
    )
}
const GroupHead = ({ id, group, open, setOpen, setState, allowSelectHead }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const divStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        cursor: 'pointer',
        paddingLeft: 10,
        backgroundColor: bgColor,
        height: 40,
    }
    return (
        <div style={divStyle} onClick={() => allowSelectHead && setState(id)}
            onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            {group}
            <OpenCloseIcon open={open} setOpen={setOpen} />
        </div>
    )
}
const IndividualOptions = ({ id, child, setState, search }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const divStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        cursor: 'pointer',
        paddingLeft: 30,
        backgroundColor: bgColor,
        height: 40,
    }
    return (
        child.toLowerCase().includes(search.toLowerCase()) ? <div style={divStyle}
            onClick={() => setState(id)} onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            {child}
        </div> : null
    )
}
const GroupHeadMultiple = ({ id, group, open, setOpen, state, setState, allowSelectHead, children, selectAll }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const selectedCondition = selectAll ? children.every(el => state.includes(el.id)) && children.length : state.includes(id)
    const containerStyle = {
        cursor: 'pointer',
        backgroundColor: selectedCondition ? '#F0F8FF' : bgColor
    }
    const divStyle = { display: 'flex', alignItems: 'center' }
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={containerStyle}
            onClick={() => allowSelectHead && selectAll ? setState(children.map(c => c.id)) : setState(id)}
            onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            <div style={divStyle}>
                {selectAll ? <Checkbox indeterminate={children.some(el => state.includes(el.id)) && !children.every(el => state.includes(el.id))}
                    checked={children.every(el => state.includes(el.id)) && children.length} style={{ color: '#000' }} />
                    : <Checkbox checked={state.includes(id)} style={{ color: '#000' }} />}
                {group}
            </div>
            <OpenCloseIcon open={open} setOpen={setOpen} />
        </Grid>
    )
}
const IndividualOptionsMultiple = ({ id, child, state, setState, search }) => {
    const [bgColor, setBgColor] = useState('#FFF')
    const onHover = () => setBgColor('#F2F2F2')
    const unHover = () => setBgColor('#FFF')
    const isSelected = state.includes(id)
    const containerStyle = {
        cursor: 'pointer',
        paddingLeft: 30,
        backgroundColor: isSelected ? '#F0F8FF' : bgColor
    }
    return (
        child.toLowerCase().includes(search.toLowerCase()) ? <Grid container style={containerStyle} alignItems='center'
            onClick={() => setState(id)} onMouseEnter={() => onHover()} onMouseLeave={() => unHover()}>
            <Checkbox checked={isSelected} style={{ color: '#000' }} /> {child}
        </Grid> : null
    )
}
const SelectedChip = ({ label, onDelete }) => (
    <Chip label={label} clickable style={chipStyle} deleteIcon={<CancelIcon style={{ color: '#FFF' }} />}
        onDelete={() => onDelete()} onMouseDown={(e) => e.stopPropagation()} />
)
// For one layer autocompletes with multiple selections
const OptionForMultiple = ({ option, name, isSelected, onChangeStateMultiple }) => {
    const [color, setColor] = useState('#FFF')
    const containerStyle = {
        height: 52, cursor: 'pointer',
        backgroundColor: isSelected ? '#F0F8FF' : color
    }
    return <Grid container alignItems='center' style={containerStyle} onClick={() => onChangeStateMultiple(option)}
        onMouseEnter={() => setColor('#F2F2F2')} onMouseLeave={() => setColor('#FFF')}>
        <Checkbox checked={isSelected} style={{ color: '#000' }} />{name}
    </Grid>
}
export default function ReusableAutocompleteWithID({ type, width, height, bgColor, borderColor, btnBgColor, btnWidth,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target, divMarginLeft, divMarginRight,
    // For ReusableAutocompleteWithID
    options, inputValue, setInputValue, noClear, freeSolo, nested, allowSelectHead, selectAll, limitMultiple }) {
    const [tempState, setTempState] = useState('')
    const [search, setSearch] = useState(inputValue ? inputValue : '')
    const [open, setOpen] = useState(false)
    const expand = () => !readOnly && setOpen(true)
    const collapse = () => !readOnly && setOpen(false)
    const toggle = () => !readOnly && setOpen(!open)
    const checkError = () => errorFunction && errorFunction(state)
    let border = checkError() || (required && state === '') ? '1px solid #E83D4D' : `1px solid ${borderColor || bgColor}`
    const searchIconStyle = {
        fontSize: 30,
        color: 'rgb(112, 112, 112, 0.5)',
        marginLeft: 12
    }
    const withLabelDivStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: divMarginLeft,
        marginRight: divMarginRight,
        width: width,
    }
    const autoCompleteStyle = {
        marginLeft, marginRight, marginTop, marginBottom,
        width, height: 60
    }
    const textFieldStyle = {
        // marginLeft, marginRight, marginTop, marginBottom,
        height: 60,
        border, borderRadius: 12,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = {
        marginLeft: 20,
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const btnStyle = {
        color: '#FFF',
        backgroundColor: '#144A94',
        borderRadius: '0 10px 10px 0',
        height: 58, // If there is border, -2 for the button height
        width: 64,
        opacity: grayedOut && 0.5
    }
    const longInputStyle = {
        backgroundColor: '#F16774', color: '#FFF',
        marginInline: 30, borderRadius: 8, textAlign: 'center'
    }
    const chipInputStyle = {
        textIndent: '100%', whiteSpace: 'no-wrap', overflow: 'hidden'
    }
    const iconStyle = { fontSize: 42 }
    // useEffect(() => onChangeState(state), [state])
    const onChangeState = (value, noSetTemp) => {
        // Manually close the autocomplete
        if (type !== 'multiple' && value !== '' && nested) collapse()
        if (noSetTemp === undefined) {
            let output = ''
            if (nested) {
                console.log(value)
                output = OptionsForNested().find(o => o.id === value)?.option || search
                console.log(output)
                setSearch(output)
                if (setInputValue) setInputValue(output)
            } else {
                output = actualOptions.find(o => o.id === value)?.option || search
                console.log(value)
                console.log(output)
                setSearch(output)
                if (setInputValue) setInputValue(output)
            }
        }
        if (count !== undefined && head !== undefined && target !== undefined) {
            // For redux autocompletes
            setState(count, head, target, value)
            return
        }
        if (count !== undefined && target !== undefined) {
            // For states in an array of objects
            setState(count, target, value)
            return
        }
        // For states in an array only, object only or by itself
        if (count !== undefined) setState(count, value)
        else if (target !== undefined) setState(target, value)
        else setState(value)
    }
    const onChangeInput = (e, value) => {
        // NOTE: Because for some reason, when using nested single select, it will automatically blur 
        // and change to option back to default if I don't explicitly state that 
        // this functions ONLY OCCURS ON CHANGE
        if (e && e.type === 'change') {
            console.log(value)
            setSearch(value)
            if (setInputValue) setInputValue(value)
            // if (actualOptions.find(o => o.option === value)) {
            //     onChangeState(actualOptions.find(o => o.option === value)?.id)
            // }
            if (!noClear && value === '') onChangeState('')
        }
    }
    const onDelete = () => {
        setTempState('')
        setState(count, head, target, '')
    }
    const [actualOptions, setActualOptions] = useState([])
    const loading = open && actualOptions.length === 0 && options.length !== 0
    useEffect(() => {
        let active = true
        if (!loading) return undefined
        if (active) {
            if (options.length > 200) {
                setTimeout(() => {
                    setActualOptions([...options])
                }, [500])
            } else setActualOptions([...options])
        }
        return () => active = false
    }, [loading])
    useEffect(() => {
        if (!open) setActualOptions([])
    }, [open])
    const autoCompleteAttributes = {
        value: state,
        onChange: (e, n) => onChangeState(n),
        // inputValue: inputValue === undefined ? search : inputValue,
        onInputChange: (e, n) => onChangeInput(e, n),
        options: actualOptions.map(o => o.id),
        getOptionLabel: option => options.find(o => o.id === option)?.option || '',
        // renderOption: (object, option) => {
        //     console.log({ object, option })
        //     return <Grid key={option} style={{zIndex: 800}} container>{options.find(o => o.id === option)?.option}</Grid>
        // },
        open: open, onClose: () => collapse(), onOpen: () => expand(),
        selectOnFocus: true, disableClearable: true, forcePopupIcon: false, freeSolo, disabled,
        style: autoCompleteStyle, loading: loading
    }
    if (inputValue) autoCompleteAttributes.inputValue = inputValue
    else {
        if (freeSolo) autoCompleteAttributes.inputValue = search
    }
    const textFieldAttributes = {
        variant: 'standard',
        placeholder: placeholder
    }
    const textFieldInputProps = {
        disableUnderline: true,
        hiddenLabel: true,
        style: textFieldStyle,
        readOnly: readOnly,
        startAdornment: <SearchIcon style={searchIconStyle} />,
        endAdornment: <>
            {loading ? <CircularProgress style={{ color: '#000', marginRight: 20 }} size={20} /> : null}
            <IconButton onClick={() => toggle()} style={btnStyle}>
                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
            </IconButton>
        </>
    }
    const onChangeStateMultiple = value => {
        let newState = [...state]
        if (typeof value === 'object') {
            if (!value.length) newState = value
            else if (value.every(el => newState.includes(el))) value.forEach(v => newState = newState.filter(f => f !== v))
            else value.forEach(v => newState.push(v))
        } else {
            if (newState.includes(value)) newState = newState.filter(f => f !== value)
            else newState.push(value)
        }
        onChangeState(newState, true)
    }
    const autoCompleteAttributeForMultiple = {
        // value: state, 
        // Don't show value because by default the some of the displayed items cannot be shown due to how
        // the options are parsed in
        onChange: (e, n) => setState(n),
        multiple: true,
        options: actualOptions.map(t => t.id),
        open: open, onClose: () => setOpen(false), onOpen: () => setOpen(true),
        getOptionLabel: o => options.find(t => t.id === o)?.option || '',
        renderOption: (object, option) => {
            return <OptionForMultiple key={option} option={option} name={options.find(t => t.id === option)?.option}
                isSelected={state.includes(option)} onChangeStateMultiple={onChangeStateMultiple} />
        },
        disableCloseOnSelect: true, disableClearable: true, forcePopupIcon: false,
        style: autoCompleteStyle, loading: loading
    }

    const textFieldInputPropsForMultiple = (params, options) => ({
        disableUnderline: true,
        hiddenLabel: true,
        style: textFieldStyle,
        readOnly: readOnly,
        startAdornment: <>
            {params.InputProps.startAdornment}
            <SearchIcon style={searchIconStyle} />
            <MultipleItems state={state} options={options} />
        </>,
        endAdornment: <>
            {loading ? <CircularProgress style={{ color: '#000' }} size={20} /> : null}
            <IconButton onClick={() => onChangeStateMultiple([])}>
                <ClearIcon style={{ backgroundColor: bgColor }} />
            </IconButton>
            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
            </IconButton>
        </>
    })
    if (nested) {
        const filteredNestedResult = (options, value) => {
            return options.filter(o => o.option.toLowerCase().includes(value.toLowerCase()) ||
                o.children.map(c => c.option.toLowerCase()).some(el => el.includes(value.toLowerCase())))
        }
        if (type === 'multiple') {
            autoCompleteAttributeForMultiple.options = actualOptions
            autoCompleteAttributeForMultiple.getOptionLabel = option => option?.option || ''
            autoCompleteAttributeForMultiple.renderOption = (props, option, s) => {
                return <GroupOptions key={option.id} id={option.id} group={option.option} children={option.children} state={state}
                    setState={onChangeStateMultiple} allowSelectHead={allowSelectHead} selectAll={selectAll} multiple
                    search={s.inputValue} />
            }
            autoCompleteAttributeForMultiple.filterOptions = (options, state) => filteredNestedResult(options, state.inputValue)
        } else {
            autoCompleteAttributes.options = actualOptions
            autoCompleteAttributes.getOptionLabel = option => OptionsForNested().find(o => o.id === option)?.option || ''
            autoCompleteAttributes.renderOption = (props, option, state) => {
                return <GroupOptions key={option.id} id={option.id} group={option.option} children={option.children} state={state}
                    setState={onChangeState} allowSelectHead={allowSelectHead} search={state.inputValue} />
            }
            autoCompleteAttributes.filterOptions = (options, state) => filteredNestedResult(options, state.inputValue)
        }
    }
    const [elementWidth, setElementWidth] = useState(0)
    const ref = useRef()
    useEffect(() => {
        if (ref.current) setElementWidth(ref.current.getBoundingClientRect().width)
    }, [])
    let limitBy = 0
    const ItemInMultiple = ({ id, options }) => (
        <Chip style={{ ...chipStyle, marginRight: 10 }} label={options.find(t => t.id === id)?.option || ''}
            onDelete={() => onChangeStateMultiple(id)} deleteIcon={<CancelIcon style={{ color: '#FFF' }} />} />
    )
    const MultipleItems = ({ state, options }) => {
        let breakingPoint = 0
        return (
            <>
                {state.map((s, i) => {
                    limitBy += options.find(t => t.id === s)?.option.length
                    if (limitBy < 40 && i < limitMultiple) breakingPoint++
                    return limitBy < 40 && i < limitMultiple ? <ItemInMultiple key={i} id={s} options={options} /> : ''
                })}
                {(limitBy >= 40 || state.length > limitMultiple) && '...'}
                {/* {(limitBy >= 40 || state.length > limitMultiple) && <LightTooltip placement='bottom-start' rollover
                    title={<BehindItems state={state} options={options} breakingPoint={breakingPoint} />}>
                    <div>...</div>
                </LightTooltip>} */}
            </>
        )
    }
    const BehindItems = ({ state, options, breakingPoint }) => {
        return (
            <div style={{ minWidth: 150 }}>
                <NunitoText value={placeholder} fontSize={18} color='#000' />
                {state.map((s, i) => (
                    <Grid container key={i} justifyContent='center'>
                        {i >= breakingPoint && <NunitoText value={options.find(t => t.id === s)?.option} fontSize={16} />}
                    </Grid>
                ))}
            </div>
        )
    }
    const OptionsForNested = () => {
        if (nested) {
            let newArr = []
            options.forEach(o => {
                let { children, ...owo } = o
                newArr.push(owo)
                children.forEach(c => newArr.push(c))
            })
            return newArr
        }
        return options
    }
    switch (type) {
        case 'default':
            return (
                <Autocomplete {...autoCompleteAttributes} ref={ref}
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle} ref={ref}>
                    <label style={{ color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }}>
                        {placeholder}
                    </label>
                    <Autocomplete {...autoCompleteAttributes}
                        renderInput={params => <TextField {...params} {...textFieldAttributes}
                            InputProps={{
                                ...params.InputProps,
                                ...textFieldInputProps
                            }}
                            inputProps={{ ...params.inputProps, style: inputStyle }}
                        />}
                    />
                </div>
            )
        case 'multiple':
            return (
                <Autocomplete {...autoCompleteAttributeForMultiple} ref={ref}
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputPropsForMultiple(params, OptionsForNested())
                        }}
                        inputProps={{ ...params.inputProps, style: inputStyle }}
                    />}
                />
            )
        case 'reduxChip':
            return (<Autocomplete {...autoCompleteAttributes}
                renderInput={params => <TextField {...params} {...textFieldAttributes}
                    value={tempState}
                    InputProps={{
                        ...params.InputProps,
                        ...textFieldInputProps,
                        startAdornment: <div style={{ display: 'flex', alignItems: 'center' }}>
                            <SearchIcon style={searchIconStyle} />
                            {options.find(o => o.id === state) &&
                                <SelectedChip label={options.find(o => o.id === state)?.option} onDelete={onDelete} />}
                        </div>
                    }}
                    inputProps={{
                        ...params.inputProps, style: options.find(o => o.id === state) ? chipInputStyle : inputStyle
                    }}
                />}
            />)
        case 'reduxMultiple':
            return (<Autocomplete multiple disableCloseOnSelect
                value={state} onChange={(e, n) => setState(count, head, target, n)}
                options={options.map(o => o.id)} getOptionLabel={option => options.find(o => o.id === option)?.option || ''}
                open={open} onClose={() => collapse()} onOpen={() => expand()} selectOnFocus disableClearable forcePopupIcon={false}
                // {...autoCompleteAttributes}
                renderTags={(value, getTagProps) =>
                    value.length === 0 ? '' : value.map((option, index) => (
                        <Chip key={index} label={options.find(o => o.id === option)?.option} clickable {...getTagProps({ index })}
                            style={chipStyle}
                            deleteIcon={<CancelIcon style={{ color: '#FFF' }} onMouseDown={(event) => event.stopPropagation()} />}
                        />
                    ))
                }
                renderInput={params => <TextField {...params} {...textFieldAttributes}
                    InputProps={{
                        ...params.InputProps,
                        ...textFieldInputProps,
                        startAdornment: (<>
                            <SearchIcon style={searchIconStyle} />
                            {params.InputProps.startAdornment}
                        </>)
                    }}
                    inputProps={{ ...params.inputProps, style: inputStyle }}
                />}
            />)
        case 'chip':
            return (<Autocomplete freeSolo {...autoCompleteAttributes}
                renderInput={params => <TextField {...params} {...textFieldAttributes}
                    InputProps={{
                        ...params.InputProps,
                        ...textFieldInputProps
                    }}
                    inputProps={{
                        ...params.inputProps, style: options.map(o => o?.option).includes(tempState) ?
                            { ...inputStyle, ...longInputStyle } : inputStyle
                    }}
                />}
            />)
        default: return null
    }
}