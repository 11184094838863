import React, { useState } from 'react'
import { Grid, IconButton, Checkbox } from '@mui/material'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import { useDispatch } from 'react-redux'
import { updateTask } from '../actions'
import { getBase64 } from '../functions/upload'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from './general/NunitoText'
import ReusableTextField from './general/ReusableTextField'
import ReusableToggle from './general/ReusableToggle'
import ReusableButton from './general/ReusableButton'
import TitleBar from './general/TitleBar'
import AddPageWrapper from './general/AddPageWrapper'
const Preview = ({ width, count, answerCount, image, update }) => {
    const previewImage = () => {
        var newTab = window.open();
        newTab.document.title = 'Image Preview'
        newTab.document.body.innerHTML = `<div style="text-align:center;">
            <h1>Image preview</h1>
            <img src=${image} alt="Preview of image" width=600 />
        </div>`
    }
    return (
        <Grid container alignItems="center" style={{ width }}>
            <ReusableButton text='Preview Image' height={44} bgColor='#144A94' fontSize={15}
                onClick={() => previewImage()} iconType='preview' />
            <ReusableButton text='Clear' height={40} bgColor='#E83D4D' fontSize={15} marginLeft={10}
                onClick={() => update([count, answerCount], 'answerKeyText', 'image', '')} iconType='delete' />
        </Grid>
    )
}
function Arrows({ count, answerCount, task, update }) {
    if (task.answers.length > 1) {
        if (!answerCount) {
            //First answer with only downward arrow
            return (
                <IconButton edge="start" onClick={() => update([count, answerCount], 'moveDown', 'answers')} style={{ marginLeft: 18 }}>
                    <ArrowDownwardSharpIcon style={{ color: "#000" }} />
                </IconButton>
            )
        } else if (answerCount === task.answers.length - 1) {
            //Last answer with only upward arrow
            return (
                <IconButton edge="start" onClick={() => update([count, answerCount], 'moveUp', 'answers')} style={{ marginRight: 28 }}>
                    <ArrowUpwardSharpIcon style={{ color: "#000" }} />
                </IconButton>
            )
        } else {
            //Any answers in between would have both upward and downward arrows
            return (
                <div>
                    <IconButton edge="start" onClick={() => update([count, answerCount], 'moveUp', 'answers')}>
                        <ArrowUpwardSharpIcon style={{ color: "#000" }} />
                    </IconButton>
                    <IconButton edge="start" onClick={() => update([count, answerCount], 'moveDown', 'answers')}>
                        <ArrowDownwardSharpIcon style={{ color: "#000" }} />
                    </IconButton>
                </div>
            )
        }
    }
    //Don't show any arrow if there is only 1 answer
    return null
}
function Answer({ count, answerCount, task, answer, update }) {
    const handleFileInputChange = (counts, file) => {
        getBase64(file)
            .then(result => {
                console.log(result)
                update(counts, 'answerKeyText', 'image', result)
            })
            .catch(err => {
                console.log(err);
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            });
    }
    const openFile = () => document.getElementById(`file-upload-${count}${answerCount}`).click()
    const arrowWrapperStyle = {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    }
    const TaskLabelOrImageTextField = () => <ReusableTextField type='default'
        placeholder={task.uploadImage ? "Click to upload image" : "Enter Task Label"}
        state={task.uploadImage ? answer.image : answer.taskLabel} setState={update}
        count={[count, answerCount]} head='answerKeyText' target='taskLabel' onClick={task.uploadImage && openFile}
        readOnly={task.uploadImage} onBlur={!task.uploadImage} />
    const HiddenInput = () => <input id={`file-upload-${count}${answerCount}`} type="file" style={{ display: 'none' }}
        onChange={e => handleFileInputChange([count, answerCount], e.target.files[0])} />
    const AddAndRemoveAnswerButtons = () => (
        <>
            <IconButton color="inherit"
                onClick={() => update([count, answerCount + 1], 'answerKeyAddAnswers', 'answers')}>
                <AddCircleSharpIcon />
            </IconButton>
            {Boolean(answerCount) && <IconButton color="inherit"
                onClick={() => update([count, answerCount], 'answerKeyDeleteAnswers', 'answers')}>
                <RemoveCircleSharpIcon />
            </IconButton>}
        </>
    )
    const titleStyle = { display: 'flex', justifyContent: 'center' }
    const taskLabelContainerStyle = { display: 'flex', flexDirection: 'column', paddingInline: 30 }
    if (task.type === 1) {
        if (task.structure === 3) {
            return (
                <>
                    {answerCount === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Sequence' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={7.5} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <NunitoText value='-' fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <NunitoText value={(answerCount + 1).toString()} fontSize={25} marginRight={20} />
                            <Arrows count={count} answerCount={answerCount} task={task} update={update} />
                        </Grid>
                        <Grid item xs={4.5 + ((answer.image === '' || !task.uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && task.uploadImage && <Grid item xs={3}>
                            <Preview count={count} answerCount={answerCount} image={answer.image} update={update} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        } else {
            //Default and Group are similar in MCQ
            return (
                <>
                    {answerCount === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={5.5} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Correct Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <NunitoText value='-' fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} answerCount={answerCount} task={task} update={update} />
                        </Grid>
                        <Grid item xs={2.5 + ((answer.image === '' || !task.uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && task.uploadImage && <Grid item xs={3}>
                            <Preview count={count} answerCount={answerCount} image={answer.image} update={update} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <Checkbox style={{ color: '#000' }} checked={answer.correct}
                                onChange={() => update([count, answerCount], 'answerKeyBoolean', 'correct')} />
                        </Grid>
                    </Grid>
                </>
            )
        }
    } else {
        if (task.structure === 4) {
            return (
                <>
                    {answerCount === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={7.5} style={titleStyle}>
                            <NunitoText value='Task Label' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <NunitoText value='-' fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} answerCount={answerCount} task={task} update={update} />
                        </Grid>
                        <Grid item xs={4.5 + ((answer.image === '' || !task.uploadImage) && 3)} style={taskLabelContainerStyle}>
                            {TaskLabelOrImageTextField()}
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && task.uploadImage && <Grid item xs={3}>
                            <Preview count={count} answerCount={answerCount} image={answer.image} update={update} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        } else {
            //Default and Group are similar as well
            return (
                <>
                    {answerCount === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={5.5} style={titleStyle}>
                            <NunitoText value='Task Label' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center" justifyContent='space-between'>
                        <Grid item xs={2} style={{ textAlign: 'center' }}>
                            <NunitoText value='-' fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} answerCount={answerCount} task={task} update={update} />
                        </Grid>
                        <Grid item xs={2.5 + ((answer.image === '' || !task.uploadImage) && 3)} style={taskLabelContainerStyle}>
                            {TaskLabelOrImageTextField()}
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && task.uploadImage && <Grid item xs={3}>
                            <Preview count={count} answerCount={answerCount} image={answer.image} update={update} />
                        </Grid>}
                        <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <ReusableTextField type='default' placeholder="Enter answer" state={answer.answer} setState={update}
                                count={[count, answerCount]} head='answerKeyText' target='answer' onBlur />
                        </Grid>
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
}
export default function AnswerKey({ count, task, update }) {
    const [expandAnswerKey, setExpandAnswerKey] = useState(true)
    return (
        <Grid container style={{ marginTop: 20 }}>
            <TitleBar title='Answer Key' state={expandAnswerKey} setState={setExpandAnswerKey} />
            {expandAnswerKey && <AddPageWrapper>
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 30 }}>
                    <NunitoText value='Upload Answers as Image' fontSize={22} fontWeight={600} marginRight={10} />
                    <ReusableToggle type='reduxIcon' width={124} state={task.uploadImage} setState={update}
                        count={count} head='taskBoolean' target='uploadImage' marginRight={20} />
                </Grid>
                <Grid container style={{ margin: 30, marginTop: 10 }}>
                    {task.answers.map((answer, index) => (
                        <Answer key={index} count={count} answerCount={index} task={task} answer={answer} update={update} />
                    ))}
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}