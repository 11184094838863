import React from 'react'
import { Grid, IconButton } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsIcon from '@mui/icons-material/Settings';
import { FaUser, FaTrophy } from 'react-icons/fa'
import BuildIcon from '@mui/icons-material/Build';
import NunitoText from './NunitoText'
export default function TitleBar({ title, count, head, target, state, setState, marginBottom }) {
    let icon;
    const iconStyle = {
        color: '#FFF', marginLeft: 10, fontSize: 20
    }
    switch (title) {
        case 'Setting': icon = <SettingsIcon style={iconStyle} />; break;
        case 'Country Partner': icon = <FaUser style={iconStyle} />; break;
        case 'Rounds': icon = <BuildIcon style={iconStyle} />; break;
        case 'Awards': icon = <FaTrophy style={iconStyle} />; break;
        default:
    }
    const containerStyle = { backgroundColor: '#144A94', borderRadius: 10, height: 60, marginBottom }
    return (
        <Grid container alignItems='center' justifyContent='space-between' style={containerStyle}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <NunitoText value={title} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                {icon}
            </div>
            <IconButton style={{ color: '#FFF' }} onClick={() => setState(!state)} edge="start">
                {state ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
        </Grid>
    )
}