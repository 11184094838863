import React from 'react'
import { Grid, Box } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Collections from '../components/Collections'
import { useDispatch } from 'react-redux'
import { resetCollection } from '../actions'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
export default function AddCollection() {
    document.title = 'Add Collection'
    const dispatch = useDispatch()
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard'  iconType='home'/>
                <ChevronRightIcon />
                <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18}
                    onClick={() => dispatch(resetCollection())} />
            </div>
            <Grid item xs={12} style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Add Collection' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Collections />
            </Grid>
        </Box>
    )
}