import React, { useState } from "react"
import { Grid } from '@mui/material'
import { useDispatch } from 'react-redux'
import { updateTask } from '../actions'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import TitleBar from "./general/TitleBar"
import AddPageWrapper from "./general/AddPageWrapper"
import DescriptionEditor from "./DescriptionEditor"
import ReusableTextField from "./general/ReusableTextField"
export default function TaskContent({ count, task }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const [expandTaskContent, setExpandTaskContent] = useState(true)
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <TitleBar title='Task Content' state={expandTaskContent} setState={setExpandTaskContent} />
            {expandTaskContent && <AddPageWrapper>
                <Grid container style={{ margin: '36px 60px' }}>
                    <Grid container alignItems='center' style={{ marginBottom: 40 }}>
                        <Grid item xs={3}>
                            <ReusableTextField type='withLabel' placeholder='Language' state='English' setState={() => { }}
                                grayedOut fullWidth />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title' state={task.taskTitle} setState={update}
                                grayedOut readOnly fullWidth />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={task.language} setState={update} height={500}
                        placeholder='Task Content For English' count={count} head='taskText' target='language' />
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}