import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import AddParticipantModal from '../components/modal/AddParticipantModal'
import EditParticipantModal from '../components/modal/EditParticipantModal'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import { CSVLink } from 'react-csv'
import {
    makeSureIsArray, makeOptions, ensureObjectOfArraysArePopulated,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getParticipants } from '../functions/getData'
import { deleteParticipant } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const participantHeader = [
    { id: 'name', label: 'Name' },
    { id: 'index_no', label: 'Index' },
    { id: 'country_name', label: 'Country' },
    { id: 'school_name', label: 'School' },
    { id: 'tuition_centre_name', label: 'Tuition Centre' },
    { id: 'grade', label: 'Grade' },
    { id: 'class', label: 'Class' },
    { id: 'competition_name', label: 'Competition/Assessment' },
    { id: 'partner_name', label: 'Partner' },
    // { id: 'teacher', label: 'Teacher' },
    { id: 'status', label: 'Status' },
    { id: 'created_by_user', label: 'Created By' },
    { id: 'modified_by_user', label: 'Last Modified By' }
]
const initialParams = '?'
export default function Participant() {
    document.title = 'Participant'
    const user = useSelector(state => state.user)
    // const [forms, setForms] = useState([{
    //     id: 0,
    //     name: '',
    //     studentClass: '',
    //     competition: '',
    //     competitionPartnerData: '',
    //     country: '',
    //     taggedUser: '',
    //     school: '',
    //     grade: '',
    //     isTuitionCentre: false,
    //     tuitionCentre: '',
    // }])
    // const openNewForm = () => setForms([...forms, {
    //     id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
    //     name: '',
    //     studentClass: '',
    //     competition: '',
    //     competitionPartnerData: '',
    //     country: '',
    //     taggedUser: '',
    //     school: '',
    //     grade: '',
    //     isTuitionCentre: false,
    //     tuitionCentre: '',
    // }])
    const [header, setHeader] = useState(participantHeader)
    const [participantTable, setParticipantTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Country', state: '', key: 'country_id', header: 'countries' },
        { label: 'Competition', state: '', key: 'competition_id', header: 'competition' },
        { label: 'Grade', state: '', key: 'grade', header: 'grade' },
        { label: 'Status', state: '', key: 'status', header: 'status' },
        { label: 'Private', state: '', key: 'private', header: 'private' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Country", state: true, key: 'country_name' },
        { label: 'Grade', state: true, key: 'grade' },
        { label: 'School', state: true, key: 'school_name' },
        { label: "Class", state: true, key: 'class' },
        { label: 'Tuition Centre', state: true, key: 'tuition_centre_name' },
        { label: 'Competition/Assessment', state: true, key: 'competition_name' },
        // { label: "Certificate No.", state: true, key: 'created_by_username' },
        { label: 'Partner', state: true, key: 'partner_name' },
        { label: 'Teacher', state: true, key: 'teacher' },
        { label: "Created By", state: true, key: 'created_by_user' },
        { label: 'Last Modified By', state: true, key: 'modified_by_user' },
        { label: 'Status', state: true, key: 'status' },
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchParticipant, setSearchParticipant] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddParticipant, setOnAddParticipant] = useState(false)
    const [onEditParticipant, setOnEditParticipant] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelEdit = () => cancelAction(setOnEditParticipant, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteParticipant({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getParticipants, params, setParticipantTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(participantHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchParticipant, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchParticipant, params, setParams, getData)
    }
    const onChangeSearchParticipant = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchParticipant, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        getData(params)
        if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
    }, [])
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one participant to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Participants' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Participants' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchParticipant} setState={onChangeSearchParticipant} onBlur />
                    <div>
                        {Boolean(participantTable && selected.length) ?
                            <CSVLink style={{ textDecoration: 'none' }} data={makeSureIsArray(participantTable.data).filter(p => selected.includes(p.id))}>
                                <ReusableButton text='Export CSV' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20} iconType='export' />
                            </CSVLink> :
                            <ReusableButton text='Export CSV' fontSize={15} bgColor='#707070' height={46} marginRight={20} iconType='export'
                                onClick={() => showWarningSwal('Select a participant to export')} />}
                        <ReusableButton text='Import CSV' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            iconType='import' to='csvupload?upload=participant' />
                    </div>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Participant' fontSize={15} bgColor='#5E75C3' height={48} marginRight={20}
                            onClick={() => setOnAddParticipant(true)} iconType='add' />
                        {user.role_id === 1 && <ReusableButton text='Delete / Reject' fontSize={15} bgColor='#E83042' height={48}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                    </div>
                </Grid>
                <NunitoText value='Search only in "Name", "Index", "School" and "Tuition Centre" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {/* {JSON.stringify(forms)}
                {competitionOptions && countryOptions && schoolOptions && userOptions && forms.map((form, index) => (
                    <AddParticipantForm key={index} count={index} afterAddingParticipant={getData}
                        competitionOptions={competitionOptions} countryOptions={countryOptions} schoolOptions={schoolOptions}
                        userOptions={userOptions} forms={forms} setForms={setForms} form={form} />
                ))} */}
                {loading ? <Loader height={600} /> : participantTable && makeSureIsArray(participantTable.data).length ?
                    <HeavyTable headers={header} list={participantTable} title='participants' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setOnEdit={setOnEditParticipant}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </Modal>
            <Modal open={onAddParticipant} onClose={() => setOnAddParticipant(false)}>
                <AddParticipantModal setOnAddParticipant={setOnAddParticipant} afterAddingParticipant={() => getData(params)} />
            </Modal>
            <Modal open={onEditParticipant} onClose={() => cancelEdit()}>
                {participantTable && selected.length && <EditParticipantModal afterEditingParticipant={() => getData(params)}
                    id={selected[0]} participants={makeSureIsArray(participantTable.data)} cancelEdit={cancelEdit} />}
            </Modal>
        </Box>
    )
}