import { createStore } from 'redux'
import allReducers from './index'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import hardSet from 'redux-persist/lib/stateReconciler/hardSet'
const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: hardSet
}
const persistedReducer = persistReducer(persistConfig, allReducers)
const store = createStore(
    persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() //For development purpose
)
export default store