const defaultState = [{
    expandTask: false,
    taskID: 1,
    taskTitle: '',
    taskIdentifier: '',
    domain: [],
    topic: [],
    tags: [],
    description: '',
    languages: [],
    imgName: '',
    image: '',
    recommendations: [{
        grade: '',
        difficulty: ''
    }],
    solution: '',
    language: '',
    type: 1,
    structure: 1,
    layout: 1,
    sorting: 1,
    uploadImage: false,
    answers: [{
        answerID: 1,
        taskLabel: '',
        answer: '',
        image: '',
        correct: false
    }]
}]
const taskState = defaultState
const reset = defaultState
const taskReducer = (state = taskState, action) => {
    let newState = state.slice()
    switch (action.type) {
        case 'ADD_TASK':
            let temp = JSON.parse(JSON.stringify(defaultState[0]))
            temp.taskID = newState.map(n => n.taskID).reduce((a, b) => Math.max(a, b), 0) + 1
            newState.splice(action.count, 0, temp)
            return newState
        case 'DELETE_TASK':
            newState.splice(action.count, 1)
            return newState
        case 'DUPLICATE_TASK':
            let a = JSON.parse(JSON.stringify(action.task))
            a.taskID = newState.map(n => n.taskID).reduce((a, b) => Math.max(a, b), 0) + 1
            a.taskIdentifier = ''
            newState.splice(action.count, 0, a)
            return newState
        case 'UPDATE_TASK':
            switch (action.head) {
                //Setting
                case 'taskBoolean':
                    newState[action.count][action.target] = !newState[action.count][action.target]
                    return newState
                case 'taskText':
                    newState[action.count][action.target] = action.text
                    //Reset answer layout, structure and sorting when type is changed
                    if (action.target === 'type') {
                        newState[action.count].structure = 1
                        newState[action.count].layout = 1
                        newState[action.count].sorting = 1
                    }
                    if (action.target === 'taskTitle') {
                        newState[action.count].taskIdentifier = action.text.toLowerCase().trim().replace(/\s/g, '_')
                    }
                    if (action.target === 'domain') newState[action.count].topic = []
                    return newState
                case 'taskMultiple':
                    if (action.target === 'languages') {
                        //Deleting langauges(If current array is larger then the resulting array after the change,
                        //means something is being deleted)
                        //English is default and cannot be removed from languages
                        if (newState[action.count][action.target].length > action.text.length) {
                            let removed = newState[action.count][action.target].filter(l => !action.text.includes(l.language))[0].language
                            if (removed !== 1) {
                                newState[action.count][action.target] =
                                    newState[action.count][action.target].filter(l => l.language !== removed)
                                if (newState[action.count][action.target].length === 0) newState[action.count].translateAnswer = false
                                for (let answer of newState[action.count].answers) {
                                    for (let l of newState[action.count].languages) {
                                        answer.translatedAnswers[l.language] = ''
                                    }
                                }
                            }
                        } else {
                            //Adding languages
                            newState[action.count][action.target].push({
                                language: '', taskTitle: ''
                            })
                            for (let answer of newState[action.count].answers) {
                                for (let l of newState[action.count].languages) {
                                    answer.translatedAnswers[l.language] = ''
                                }
                            }
                        }
                    } else {
                        //Other normal multiple selects
                        newState[action.count][action.target] = typeof action.text === 'string' ? action.text.split(',') : action.text
                    }
                    return newState
                //Recommendations
                case 'addRecommendation':
                    newState[action.count[0]][action.target].splice(action.count[1], 0, { grade: '', difficulty: '' })
                    return newState
                case 'deleteRecommendation':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'recommendationText':
                    newState[action.count[0]].recommendations[action.count[1]][action.target] = action.text
                    return newState
                //Language
                case 'languageText':
                    newState[action.count[0]].languages[action.count[1]][action.target] = action.text
                    return newState
                //Answers
                case 'answerKeyBoolean':
                    newState[action.count[0]].answers[action.count[1]][action.target] =
                        !newState[action.count[0]].answers[action.count[1]][action.target]
                    return newState
                case 'answerKeyAddAnswers':
                    let b = JSON.parse(JSON.stringify(defaultState[0].answers[0]))
                    b.answerID = newState[action.count[0]].answers.map(a => a.answerID).reduce((a, b) => Math.max(a, b), 0) + 1
                    newState[action.count[0]][action.target].splice(action.count[1], 0, b)
                    return newState
                case 'answerKeyDeleteAnswers':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'answerKeyText':
                    newState[action.count[0]].answers[action.count[1]][action.target] = action.text
                    return newState
                case 'moveDown':
                case 'moveUp':
                    let tempAnswer = newState[action.count[0]].answers[action.count[1]]
                    let newCount = action.head === 'moveDown' ? action.count[1] + 1 : action.count[1] - 1
                    newState[action.count[0]].answers[action.count[1]] = newState[action.count[0]].answers[newCount]
                    newState[action.count[0]].answers[newCount] = tempAnswer
                    return newState
                case 'translateLanguageText':
                    newState[action.count[0]].answers[action.count[1]].translatedAnswers[action.target] = action.text
                    return newState
                default: return state
            }
        case 'RESET_TASK':
            newState = reset
            return newState
        default: return state
    }
}
export default taskReducer