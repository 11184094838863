import Swal from 'sweetalert2'
export const showInfoSwal = (title, text) => {
    Swal.fire({
        title, text, icon: 'info',
        customClass: { container: 'swal' }
    })
}
export const showWarningSwal = text => {
    Swal.fire({
        title: 'Warning!', text, icon: 'warning',
        customClass: { container: 'swal' }
    })
}
export const functionError = text => {
    Swal.fire({
        title: 'An error has occurred', text, icon: 'error',
        customClass: { container: 'swal' }
    })
}
export const isEmpty = arr => {
    for (var i = 0; i < arr.length; i++) if (arr[i] === '' && arr[i] !== false) return true
    return false
}
export const warningMessage = array => {
    let warning = 'Please fill in'
    let arr = array.filter(a => !a.state)
    arr.forEach(({ name }, i) => warning += ` ${name}${i < arr.length - 1 ? i === arr.length - 2 ? ' and' : ',' : '.'}`)
    return Swal.fire({
        title: 'Warning!', text: warning, icon: 'warning',
        customClass: { container: 'swal' }
    })
}