import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { CSVLink } from 'react-csv'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import CSVUploadTable from '../components/table/CSVUploadTable'
import CSVUploadFilter from '../components/CSVUploadFilter'
import ReusableToggle from '../components/general/ReusableToggle'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableSelect from '../components/general/ReusableSelect'
import ReusableButton from '../components/general/ReusableButton'
import ReusableCheckbox from '../components/general/ReusableCheckbox'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import schoolFile from '../files/school_template.csv'
import participantFileForAdmin from '../files/participant_template_admin.csv'
import participantFileForPartner from '../files/participant_template_partner.csv'
import participantFileForTeacher from '../files/participant_template_teacher.csv'
import { invalidName, invalidPhoneOrGrade, invalidEmail, delimitCSVRow } from '../functions/general'
import { getUsers, getSchools, getCompetitions } from '../functions/getData'
import { addSchool, addParticipant } from '../functions/postData'
import { showWarningSwal, isEmpty, warningMessage } from '../functions/alert'
import { showNotification } from '../functions/snackbar'
import { isAdmin, isPartner, isTeacher } from '../functions/checkrole'
import { useSnackbar } from 'notistack'
export function MapField({ count, id, label, firstRow, csvHeaders, csvMapFields, setCSVMapFields,
    csvArray, copyCSVArray, setCopyCSVArray, noClick }) {
    const changeCSVMapField = value => {
        let newCSVMapFields = { ...csvMapFields }
        newCSVMapFields[id] = value
        setCSVMapFields(newCSVMapFields)
    }
    useEffect(() => {
        const newCopyCSVArray = copyCSVArray.map((c, i) => ({
            ...c,
            [csvHeaders[count]]: csvArray[i][csvMapFields[id]]
        }));
        setCopyCSVArray(newCopyCSVArray)
    }, [csvMapFields[id]])
    const checkUnique = arr => new Set(arr).size !== arr.length
    // Check if all values in csvMapFields are unique. If not, set border to red
    return (
        <Grid container alignItems='center' style={{ border: '1px solid', paddingBlock: 10, pointerEvents: noClick && 'none' }}>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                <NunitoText value={label || ''} fontSize={16} fontWeight={600} />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                {firstRow !== undefined && csvMapFields !== undefined &&
                    <NunitoText value={firstRow[csvHeaders[count]] || ''} fontSize={16} fontWeight={600} />}
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
                {csvMapFields[id] && <ReusableSelect type='type2' width={230} state={csvMapFields[id]} setState={changeCSVMapField}
                    btnBgColor='#5E75C3' height={45} options={csvHeaders.map(c => ({ value: c, option: c }))}
                    required={checkUnique(Object.values(csvMapFields))} borderColor='#707070' />}
            </Grid>
        </Grid>
    )
}
const schoolHeader = [
    { id: 'name', label: 'School Name', key: 'name', },
    { id: 'address', label: 'Address', key: 'address' },
    { id: 'postal', label: 'Postal Code', key: 'postal' },
    { id: 'phone', label: 'Phone Number', key: 'phone' },
    { id: 'province', label: 'Province/State', key: 'province' },
    { id: 'email', label: 'Email', key: 'email' }
]
const participantHeader = role_id => ([
    { id: 'name', label: 'Name', key: 'name', },
    role_id < 3 && { id: 'school', label: 'School', key: 'school' },
    role_id < 3 && { id: 'tuition_centre', label: 'Tuition', key: 'tuition_centre' },
    role_id === 1 && { id: 'partner', label: 'Partner', key: 'partner' },
    { id: 'grade', label: 'Grade', key: 'grade' },
    { id: 'class', label: 'Class', key: 'class' }
].filter(Boolean))
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
const uploadToSchool = [
    { label: 'Country', state: '', key: 'country_id', header: 'countries' },
    { label: 'Tuition Centre', state: false, key: 'private', header: 'tuition centre' }
]
const uploadToParticipant = [
    { label: 'Competition', state: '', key: 'competition_id', header: 'competitions' },
    { label: 'Country', state: '', key: 'country_id', header: 'countries' },
    { label: 'Partner', state: '', key: 'partner', header: 'partners' },
    { label: 'School', state: '', key: 'school', header: 'schools' },
    { label: 'Tuition Centre', state: '', key: 'tuition_centre', header: 'tuition centres' },
    { label: 'Belongs to partner?', state: false, key: 'forPartner', header: 'tuition centre' }
]
export default function CSVUpload() {
    document.title = 'CSV Upload'
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const user = useSelector(state => state.user)
    const history = useHistory()
    const [uploadSchool, setUploadSchool] = useState(!(user.role_id === 3 || history.location.search === '?upload=participant'))
    const [schoolFiltered, setSchoolFiltered] = useState([])
    const [participantFiltered, setParticipantFiltered] = useState([])

    const [competitionOptions, setCompetitionOptions] = useState([])
    const allCountryOptions = useSelector(state => state.countryOptions)
    const [countryOptions, setCountryOptions] = useState(allCountryOptions)
    const [allSchoolOptions, setAllSchoolOptions] = useState([])
    const [schoolOptions, setSchoolOptions] = useState([])
    const [allTuitionCentreOptions, setAllTuitionCentreOptions] = useState([])
    const [tuitionCentreOptions, setTuitionCentreOptions] = useState([])
    const [partnerCentreOptions, setPartnerCentreOptions] = useState([])
    const [allPartnerOptions, setAllPartnerOptions] = useState([])
    const [partnerOptions, setPartnerOptions] = useState([])

    const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)
    const [headers, setHeaders] = useState(uploadSchool ? schoolHeader : participantHeader(user.role_id))
    const [csvFile, setCSVFile] = useState(null)
    const [csvHeaders, setCSVHeaders] = useState()
    const [csvMapFields, setCSVMapFields] = useState()
    const [csvArray, setCSVArray] = useState([]);
    const [copyCSVArray, setCopyCSVArray] = useState([])
    const [list, setList] = useState()
    const [massAssignGrade, setMassAssignGrade] = useState('')
    const [massAssignPartner, setMassAssignPartner] = useState('')
    const [massAssignSchool, setMassAssignSchool] = useState('')
    const [massAssignTuition, setMassAssignTuition] = useState('')
    const [massPartnerOptions, setMassPartnerOptions] = useState([])
    const [massSchoolOptions, setMassSchoolOptions] = useState([])
    const [massTuitionCentreOptions, setMassTuitionCentreOptions] = useState([])
    //Table
    const [rowsPerPage, setRowsPerPage] = useState(10)
    //Select from the table
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [sort, setSort] = useState('')
    const [okToHvDupes, setOkToHvDupes] = useState(false)
    let allowedStatus = ['active', user.role_id < 3 && 'ready', user.role_id < 2 && 'lock'].filter(Boolean)
    useEffect(() => {
        getCompetitions('?limits=50&status=active').then(c => {
            if (user.role_id === 1) setCompetitionOptions(c.competitionList.data)
            else if (user.role_id === 2) {
                setCompetitionOptions(c.competitionList.data.filter(d => d.partners.filter(d => ['ready', 'active']
                    .includes(d.partnerRegDateStatus)).map(d => d.id).includes(user.user_id)))
            } else {
                setCompetitionOptions(c.competitionList.data.filter(d => d.partners.filter(d => ['active']
                    .includes(d.partnerRegDateStatus)).map(d => d.id).includes(user.parent_id)))
            }
        })
        if (user.role_id === 1) {
            setSchoolFiltered(uploadToSchool)
            setParticipantFiltered(uploadToParticipant)
            getUsers(`?limits=100&status=active`).then(u => {
                setAllPartnerOptions(u.userLists.data)
                setPartnerCentreOptions(u.userLists.data.filter(d => d.role_id === 2).map((c, i) => (
                    { id: c.school || i, name: c.school_name || `owo - ${i}`, user_id: c.id }
                )))
            })
        } else if (user.role_id === 2) {
            setSchoolFiltered(uploadToSchool.filter(s => s.key !== 'country_id'))
            setParticipantFiltered(uploadToParticipant.filter(f => !['partners', 'countries'].includes(f.header)))
            getSchools('?limits=100&?status=active').then(s => {
                setAllSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                setAllTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
            })
            setPartnerCentreOptions([{ id: user.school_id, name: `${user.username} - School`, user_id: user.user_id }])
        } else if (user.role_id === 3) {
            setParticipantFiltered(uploadToParticipant.filter(p => ['competition_id', 'forPartner'].includes(p.key)))
            getSchools(`?country_id=${user.country_id}&limits=100&status=active`).then(s => {
                setTeacherBelongsToTuitionCentre(s.SchoolLists.data.find(a => a.id === user.school_id).private)
                if (s.SchoolLists.data.find(a => a.id === user.school_id).private) {
                    setSchoolOptions(s.SchoolLists.data.filter(d => !d.private))
                    setParticipantFiltered(uploadToParticipant.filter(p => ['competition_id', 'school', 'forPartner'].includes(p.key)))
                } 
            })
        }
    }, [])
    // Upload to related functions
    const getPartners = value => {
        let partnerIDs = competitionOptions.find(c => c.id === value).partners
            .filter(p => allowedStatus.includes(p.partnerRegDateStatus) && new Date(p.partnerCompetitionDates.at(-1)) > new Date())
        let userOutput = []
        userOutput = allPartnerOptions.filter(a => partnerIDs.map(p => p.id).includes(a.id))
        return userOutput
    }
    const onChangeUploadSchool = value => {
        setUploadSchool(value)
        cancelCSVFile()
        setHeaders(value ? schoolHeader : participantHeader(user.role_id))
    }
    const onChangeSchoolFiltered = (count, identifier, value) => {
        let newSchoolFiltered = [...schoolFiltered]
        newSchoolFiltered[count][identifier] = value
        setSchoolFiltered(newSchoolFiltered)
    }
    const onChangeCompetition = value => {
        let newParticipantFiltered = [...participantFiltered]
        newParticipantFiltered.forEach(n => {
            if (!['competition_id', 'forPartner'].includes(n.key)) {
                if (n.key === 'tuition_centre') {
                    if (user.role_id !== 2) n.state = ''
                } else n.state = ''
            }
        })
        setParticipantFiltered(newParticipantFiltered)
        setCountryOptions([])
        setSchoolOptions([])
        if (user.role_id !== 2) setTuitionCentreOptions([])
        setPartnerOptions([])
        if (value !== '') {
            if (user.role_id === 1) {
                let countryIDs = [...new Set(competitionOptions.find(c => c.id === value).partners
                    .filter(f => ['active', 'ready', 'lock'].includes(f.partnerRegDateStatus)).map(p => p.country_id))]
                let output = []
                countryIDs.forEach(c => output.push(allCountryOptions.find(d => d.id === c)))
                setCountryOptions(output)
            } else if (user.role_id === 2) {
                getSchools('?limits=100&status=active').then(s => {
                    setSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                    setTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
                })
            }
        } else setCountryOptions([])
    }
    const onChangeCountry = value => {
        let newParticipantFiltered = [...participantFiltered]
        newParticipantFiltered.filter(n => ['school', 'tuition_centre', 'partner'].includes(n.key)).forEach(n => n.state = '')
        setParticipantFiltered(newParticipantFiltered)
        setPartnerOptions([])
        if (value !== '') {
            getSchools(`?country_id=${value}&status=active&limits=100`).then(s => {
                setAllSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                setAllTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
                setSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                setTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
            })
            setPartnerOptions(getPartners(newParticipantFiltered.find(p => p.key === 'competition_id')?.state).filter(u => u.country_id === value))
        }
    }
    const onChangePartner = value => {
        let newParticipantFiltered = [...participantFiltered]
        newParticipantFiltered.forEach(n => {
            if (!['competition_id', 'country_id', 'partner', 'forPartner'].includes(n.key)) n.state = ''
        })
        setParticipantFiltered(newParticipantFiltered)
        if (value !== '') {
            let selectedUser = partnerOptions.find(u => u.id === value)
            console.log(selectedUser)
            console.log(partnerCentreOptions)
            setSchoolOptions(allSchoolOptions.filter(s => s.country_id === selectedUser.country_id))
            setTuitionCentreOptions(allTuitionCentreOptions.filter(t => t.country_id === selectedUser.country_id))
            if (newParticipantFiltered.find(n => n.key === 'forPartner').state) {
                onChangeTuitionCentre(partnerCentreOptions.find(p => p.user_id === selectedUser.id).id)
            }
        }
    }
    const onChangeForPartner = value => {
        if (user.role_id === 1 && participantFiltered.find(p => p.key === 'partner').state === '') return
        let newParticipantFiltered = [...participantFiltered]
        if (value) {
            newParticipantFiltered.find(n => n.key === 'school').state = ''
            if (user.role_id === 1) {
                let partner = newParticipantFiltered.find(n => n.key === 'partner').state
                if (partnerOptions.find(u => u.id === partner)) {
                    newParticipantFiltered.find(n => n.key === 'tuition_centre').state = partnerCentreOptions.find(p => p.user_id === partner).id
                }
            } else {
                newParticipantFiltered.find(n => n.key === 'tuition_centre').state = partnerCentreOptions.find(p => p.user_id === user.user_id).id
            }
        } else {
            newParticipantFiltered.find(n => n.key === 'tuition_centre').state = ''
        }
        setParticipantFiltered(newParticipantFiltered)
    }
    const onChangeTuitionCentre = value => {
        let newParticipantFiltered = [...participantFiltered]
        newParticipantFiltered.find(p => p.key === 'tuition_centre').state = value
        setParticipantFiltered(newParticipantFiltered)
    }
    const onChangeParticipantFiltered = (count, identifier, value) => {
        let newParticipantFiltered = [...participantFiltered]
        newParticipantFiltered[count][identifier] = value
        switch (newParticipantFiltered[count].key) {
            case 'competition_id': onChangeCompetition(value); break;
            case 'country_id': onChangeCountry(value); break;
            case 'partner': onChangePartner(value); break;
            case 'school': break;
            case 'tuition_centre': onChangeTuitionCentre(value); break;
            case 'forPartner': onChangeForPartner(value); break;
            default: break;
        }
        setParticipantFiltered(newParticipantFiltered)
    }
    // CSV File related functions
    const openFileInput = () => document.getElementById(`file-upload-for-csv`).click()
    const onChangeCSVFile = (e, file) => {
        e.target.value = null
        console.log(file)
        setCSVFile(file)
        const reader = new FileReader()
        try {
            reader.onload = e => {
                const text = e.target.result
                processCSV(text)
            }
            reader.readAsText(file)
            console.log('onChangeCSVFile once')
        } catch (err) {
            console.log(err.message)
        }
    }
    const processCSV = (str, delim = ',') => {
        //Get the header from the large chunk of string read from the csv file
        const header = str.slice(0, str.indexOf('\n')).trim().replace(/['"]+/g, '').split(delim)
        console.log(`header from CSV: ${header}`)
        setCSVHeaders(header)
        //Set an object of mapFields
        let newMapFields = {}
        headers.forEach((h, i) => {
            if (header[i]) newMapFields[h.id] = header[i]
        })
        setCSVMapFields(newMapFields)
        console.log('csvMapFields')
        console.log(newMapFields)
        //Get all the data from the large chunk of string read from the csv file as an array
        const rows = str.slice(str.indexOf('\n') + 1).split('\n')
        console.log(rows)
        const newArray = rows.map(row => {
            // const values = row.split(',')
            const values = delimitCSVRow(row)
            const eachObject = header.reduce((obj, header, i) => {
                //This function will return an array with the last item being an object with undefined values
                //So we check if the object is undefined before using string functions on the object
                obj[header] = values[i] ? values[i].trim().replace(/['"]+/g, '') : values[i];
                return obj;
            }, {})
            return eachObject;
        })
        //We do not want to set the last item as the csvArray since the last item is just undefined
        //as we are setting the rows by checking if there is a next row
        const a = newArray.filter((n, i) => i < newArray.length - 1)
        setCSVArray(a)
        //Keep the original as reference and use the copyCSVArray to decide which columns to map to which field
        setCopyCSVArray(a)
        if (user.role_id > 1) submitCSVAsList(a)
    }
    const cancelCSVFile = () => {
        setCSVFile(null)
        setCSVHeaders()
        setCSVMapFields()
        setCSVArray([])
        setCopyCSVArray([])
        setList()
        setSort('')
    }
    const submitCSVAsList = data => {
        let newArr = []
        data.forEach((row, index) => {
            let newObj = {}
            newObj.id = index
            // Load all selected options from the Upload To: section
            if (uploadSchool) schoolFiltered.forEach(f => newObj[f.key] = f.state)
            else participantFiltered.forEach(f => {
                switch (f.key) {
                    case 'partner':
                        newObj[f.key] = partnerOptions.find(s => s.id === f.state)?.name || ''
                        break;
                    case 'school':
                        newObj[f.key] = [{ id: -1, name: 'Home School' }, ...schoolOptions].find(s => s.id === f.state)?.name || ''
                        break;
                    case 'tuition_centre':
                        newObj[f.key] = partnerOrTuitionCentreOptions.find(s => s.id === f.state)?.name || ''
                        break;
                    default: newObj[f.key] = f.state
                }
            })
            headers.forEach(({ key }, i) => {
                if (['partner', 'school', 'tuition_centre'].includes(key)) {
                    if (newObj[key] === '') newObj[key] = Object.values(row)[i]
                } else newObj[key] = Object.values(row)[i]
            })
            console.log(JSON.stringify(newObj))
            console.log(newObj)
            newArr.push(newObj)
        })
        console.log(newArr)
        setList(newArr)
        setMassPartnerOptions(partnerOptions)
        setMassSchoolOptions(schoolOptions)
        setMassTuitionCentreOptions(tuitionCentreOptions)
    }
    // Handle changes to dataset
    const cancelList = () => {
        setList()
        setSort('')
    }
    const massUpdateList = (identifier, value) => {
        let newList = [...list]
        newList.forEach(n => {
            if (selected.includes(n.id)) n[identifier] = value
        })
        setList(newList)
    }
    const downloadTemplate = () => {
        var a = document.createElement("a")
        let file = uploadSchool ? schoolFile : isAdmin () ? participantFileForAdmin : 
        isPartner() ? participantFileForPartner : participantFileForTeacher
        let suffix = isAdmin() ? '-for-admin' : isPartner() ? '-for-partner' : '-for-teacher'
        a.setAttribute("href", file)
        a.setAttribute("download", `upload-${uploadSchool ? 'school' : 'participant'}-template${uploadSchool ? '' : suffix}`)
        a.click()
    }
    const checkSelect = () => {
        let arr = []
        if (uploadSchool) {
            arr = schoolFiltered.filter(s => s.key !== 'private').map(s => ({ name: s.label, state: s.state }))
        } else {
            arr = participantFiltered.filter(s => !['forPartner', 'school', 'tuition_centre', 'partner']
                .includes(s.key)).map(p => ({ name: p.label, state: p.state }))
        }
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        return openFileInput()
    }
    const getGradeID = value => gradeOptions.find(o => o.name === value).id
    const getPartnerID = value => massPartnerOptions.find(o => o.name === value).id
    const getSchoolID = value => massSchoolOptions.find(s => s.name === value).id
    const getTuitionCentreID = value => partnerOrTuitionCentreOptions.find(t => t.name === value).id
    const onSubmit = () => {
        if (list && sortOptions().length) {
            showWarningSwal('There are errors in your data')
            return
        }
        if (!uploadSchool && list.map(l => isDuplicate(list, l)).includes(true) && !okToHvDupes) {
            showWarningSwal('There are duplicate particiant entries! Please check the "I acknowledge there are duplicate participant entries checkbox"')
            return
        }
        let passedInList = []
        let newList = []
        list.sort((a, b) => a.id - b.id).forEach((l, i) => {
            let { id, ...obj } = l
            passedInList.push(obj)
            obj.id = i
            newList.push(obj)
        })
        let payload = []
        if (uploadSchool) {
            passedInList.forEach(p => {
                let obj = {
                    name: p.name, country_id: user.role_id === 1 ? p.country_id : user.country_id, private: Number(p.private),
                    address: p.address, postal: p.postal, phone: p.phone, province: p.province, email: p.email
                }
                payload.push(obj)
            })
        } else {
            passedInList.forEach(p => {
                let obj = {
                    name: p.name, grade: getGradeID(p.grade),
                    competition_id: p.competition_id, for_partner: user.role_id === 3 ? 0 : Number(p.forPartner)
                }
                if (![undefined, ''].includes(obj.class)) obj.class = p.class
                // Handle country_id and partner
                let addedPartner = user.role_id === 1 ? getPartnerID(p.partner) : user.role_id === 2 ? user.user_id : user.parent_id
                obj.parent_userid = addedPartner
                if (user.role_id === 1) obj.country_id = p.country_id
                else obj.country_id = user.country_id
                // Handle school and tuition_centre
                if (user.role_id < 3) {
                    obj.tuition_centre_id = p.tuition_centre === '' ? null : getTuitionCentreID(p.tuition_centre)
                    obj.school_id = ['', 'Home School'].includes(p.school) ? null : getSchoolID(p.school)
                } else {
                    if (teacherBelongsToTuitionCentre) {
                        obj.tuition_centre_id = user.school_id
                        obj.school_id = ['', 'Home School'].includes(p.school) ? null : getSchoolID(p.school)
                    } else {
                        obj.tuition_centre_id = null
                        obj.school_id = user.school_id
                    }
                }
                let competitionPartners = competitionOptions.find(c => c.id === p.competition_id).partners
                obj.competition_partner_id = competitionPartners.find(p => p.id === addedPartner).partnerRegDateId
                payload.push(obj)
            })
        }
        console.log(payload)
        console.log(JSON.stringify(payload))
        let addRecords = uploadSchool ? addSchool : addParticipant
        addRecords(payload).then(d => {
            console.log(d)
            if (d.status !== 201) {
                console.log(d)
            } else {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                history.push(uploadSchool ? '/schools' : '/participants')
            }
        })
    }
    const massDeleteList = () => {
        let newList = [...list]
        selected.forEach(s => newList = newList.filter(n => n.id !== s))
        setList(newList)
        setSelected([])
        setSelecting([])
    }
    const noCondition = () => false
    const schoolErrors = [
        { id: 'name', label: 'School Name', condition: invalidName },
        { id: 'address', label: 'Address', condition: noCondition },
        { id: 'postal', label: 'Postal Code', condition: noCondition },
        { id: 'phone', label: 'Phone Number', condition: invalidPhoneOrGrade },
        { id: 'province', label: 'Province/State', condition: noCondition },
        { id: 'email', label: 'Email', condition: invalidEmail }
    ]
    const partnerOrTuitionCentreOptions = participantFiltered.find(p => p.key === 'forPartner')?.state ? partnerCentreOptions : massTuitionCentreOptions
    const checkAutocomplete = (id, value) => {
        switch (id) {
            case 'school': return ![{ id: -1, name: 'Home School' }, ...massSchoolOptions].find(s => s.name === value)
            case 'tuition_centre': return !partnerOrTuitionCentreOptions.find(t => t.name === value)
            case 'partner': return !massPartnerOptions.find(p => p.name === value)
            case 'grade': return !gradeOptions.find(g => g.name === value)
            default:
        }
    }
    const participantErrors = [
        { id: 'name', label: 'Name', condition: value => invalidName(value) },
        user.role_id < 3 && { id: 'school', label: 'School', condition: value => checkAutocomplete('school', value) },
        user.role_id < 3 && {
            id: 'tuition_centre', label: 'Tuition',
            condition: (value, school) => {
                if (school === 'Home School') return checkAutocomplete('tuition_centre', value)
                if (!checkAutocomplete('school', school)) {
                    if (value === '') return false
                    return checkAutocomplete('tuition_centre', value)
                }
                return checkAutocomplete('tuition_centre', value)
            }
        },
        user.role_id === 1 && { id: 'partner', label: 'Partner', condition: value => checkAutocomplete('partner', value) },
        { id: 'grade', label: 'Grade', condition: value => checkAutocomplete('grade', value) },
        { id: 'class', label: 'Class', condition: () => noCondition() }
    ].filter(Boolean)
    const uploadError = uploadSchool ? schoolErrors : participantErrors
    const sortOptions = () => {
        let options = []
        uploadError.forEach(e => {
            list.forEach(l => {
                if (e.id === 'tuition_centre') {
                    if (e.condition(l[e.id], l.school) && !options.includes(e)) options.push(e)
                } else {
                    if (e.condition(l[e.id]) && !options.includes(e)) options.push(e)
                }
            })
        })
        return options
    }
    const onChangeSort = value => {
        setSort(value)
        if (list) {
            let newList = [...list]
            let ids = []
            newList.forEach(l => {
                if (sortOptions().find(s => s.id === value).condition(l[value])) ids.push(l.id)
            })
            ids.forEach(id => {
                let newOne = newList.find(n => n.id === Number(id))
                if (newOne !== undefined) {
                    newList = newList.filter(n => n.id !== Number(id))
                    newList.unshift(newOne)
                }
            })
            setList(newList)
        }
    }
    const checkOrUncheck = () => {
        let rowsWithError = []
        list.forEach(row => {
            if (checkForErrorInRow(row)) rowsWithError.push(row)
        })
        let newSelected = [...selected]
        let newSelecting = [...selecting]
        for (let i = 0; i < rowsWithError.length; i++) {
            if (newSelected.includes(rowsWithError[i].id)) {
                let index = newSelected.indexOf(rowsWithError[i].id)
                newSelected.splice(index, 1)
                newSelecting.splice(index, 1)
            } else {
                newSelected.push(Number(rowsWithError[i].id))
                newSelecting.push(list.find(l => l.id === Number(rowsWithError[i].id)).name)
            }
        }
        setSelected(newSelected)
        setSelecting(newSelecting)
    }
    const checkForErrorInRow = row => {
        // return true upon encountering error at any column
        for (let i = 0; i < uploadError.length; i++) {
            if (uploadError[i].id === 'tuition_centre') {
                if (uploadError[i].condition(row[uploadError[i].id], row.school)) return true
            } else {
                if (uploadError[i].condition(row[uploadError[i].id])) return true
            }
        }
        return false
    }
    const checkForError = list => {
        if (list === undefined) return false
        // return true upon encountering error at any row
        for (let i = 0; i < list.length; i++) if (checkForErrorInRow(list[i])) return true
        return false
    }
    const isDuplicate = (list, row) => {
        let occurences = 0
        list.forEach(l => {
            if (l.name === row.name && l.school === row.school && l.tuition_centre === row.tuition_centre
                && l.grade === row.grade && l.class == row.class && l.partner === row.partner) {
                occurences += 1
            }
        })
        return occurences > 1
    }
    const templateDivStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly',
        border: '1px solid #707070', borderRadius: 12, height: 220, width: 700
    }
    const correctColumnsInCSV = arr => {
        return Object.keys(csvHeaders).length === headers.length * new Set(arr).size !== arr.length
            && new Set(arr).size === arr.length
    }
    const massAssignStyle = { display: 'flex', alignItems: 'center', marginRight: 30 }
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='CSV Upload' fontSize={14} bgColor='#F16774' height={36} br={18} />
            </div>
            <Grid container justifyContent='space-between' alignItems='center' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='CSV Upload' fontSize={40} fontWeight={700} italic color='#144A94' />
                {user.role_id < 3 && <div style={{ display: 'flex', alignItems: 'center' }}>
                    <NunitoText value='Upload Type' fontSize={20} fontWeight={600} marginRight={120} />
                    <ReusableToggle type='text' width={250} falseValue='Participants' trueValue='Schools'
                        state={uploadSchool} setState={onChangeUploadSchool} />
                </div>}
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 30 }}>
                    {uploadSchool ? Boolean(schoolFiltered.length) &&
                        <CSVUploadFilter filtered={schoolFiltered} options={[null, allCountryOptions]}
                            setState={onChangeSchoolFiltered} uploadingFor='school' noClick={list}
                            setFiltered={setSchoolFiltered} /> :
                        Boolean(participantFiltered.length) &&
                        <CSVUploadFilter filtered={participantFiltered}
                            options={[competitionOptions, countryOptions, [{ id: -1, name: 'Home School' }, ...schoolOptions],
                                tuitionCentreOptions, partnerCentreOptions, partnerOptions]}
                            setState={onChangeParticipantFiltered} uploadingFor='participant' noClick={list}
                            setFiltered={setParticipantFiltered} />}
                </Grid>
                {/* school: {JSON.stringify(schoolFiltered)}<br /><br /> */}
                {/* participant: {JSON.stringify(participantFiltered)}<br /><br /> */}
                <Grid container justifyContent='space-between' style={{ marginTop: 15 }}>
                    <div>
                        <NunitoText value='File Select:' fontSize={20} fontWeight={600} color='#144A94' align='left' />
                        <input id='file-upload-for-csv' type="file" accept='.csv' onChange={e => onChangeCSVFile(e, e.target.files[0])}
                            style={{ display: 'none' }} />
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                            <ReusableButton text='Select File' bgColor='#144A94' fontSize={16} height={59} width={160} iconType='file'
                                onClick={() => checkSelect()} />
                            <ReusableTextField type='clickOnly' width={500} height={60} bgColor='#F2F2F2'
                                marginLeft={120} onClick={() => checkSelect()} />
                        </div>
                        {Boolean(csvArray.length) && user.role_id === 1 &&
                            <NunitoText value='Map Fields' fontSize={30} fontWeight={700} color='#144A94'
                                marginTop={60} marginLeft={30} align='left' />}
                    </div>
                    <div style={templateDivStyle}>
                        <NunitoText value='PLEASE DOWNLOAD AND USE TEMPLATE HERE' fontSize={24} fontWeight={400} color='#F16774' />
                        <ArrowDownwardIcon style={{ color: '#F16774', fontSize: 45 }} />
                        <ReusableButton text='Download Template' bgColor='#F16774' fontSize={20} height={57} width={325}
                            iconType='download' br={24} onClick={() => downloadTemplate()} />
                    </div>
                </Grid>
            </Grid>
            {/* <div>
                {(uploadSchool ? schoolFiltered : participantFiltered).map(({ label, state, key, header }, i) => (
                    <div key={i} style={{ border: '1px solid red', paddingLeft: 20, fontSize: 20, display: 'flex' }}>
                        <b style={{ width: uploadSchool ? 240 : 380 }}>label: {label}</b>
                        <p style={{ margin: 0, width: 120 }}>state: {JSON.stringify(state)}</p>
                        <p style={{ margin: 0, width: 220 }}>key: {key}</p>
                        <p style={{ margin: 0, width: 220 }}>header: {header}</p>
                    </div>
                ))}
            </div> */}
            {user.role_id === 1 && csvHeaders && Boolean(csvArray.length) &&
                <Grid container style={{ paddingInline: 42, marginTop: 20 }}>
                    <Grid container alignItems='center' style={{ border: '1px solid', paddingBlock: 24 }}>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <NunitoText value='Field' fontSize={25} fontWeight={800} italic color='#144A94' />
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <NunitoText value='First row of data' fontSize={25} fontWeight={800} italic color='#144A94' />
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                            <NunitoText value='CSV Header' fontSize={25} fontWeight={800} italic color='#144A94' />
                        </Grid>
                    </Grid>
                    {Boolean(copyCSVArray.length) && headers && csvHeaders && headers.map((header, index) => (
                        <MapField key={index} count={index} id={header.key} label={header.label} firstRow={copyCSVArray[0]}
                            csvHeaders={csvHeaders} csvMapFields={csvMapFields} setCSVMapFields={setCSVMapFields}
                            csvArray={csvArray} copyCSVArray={copyCSVArray} setCopyCSVArray={setCopyCSVArray} noClick={list} />
                    ))}
                    <Grid container justifyContent="flex-end" style={{ marginBlock: 20 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                            onClick={() => cancelCSVFile()} />
                        <ReusableButton text='Submit'
                            bgColor={correctColumnsInCSV(Object.values(csvMapFields)) && !list ? '#5E75C3' : '#8D8D8D'}
                            fontSize={16} height={50} width={130}
                            onClick={() => correctColumnsInCSV(Object.values(csvMapFields)) ? list ? null : submitCSVAsList(copyCSVArray) :
                                showWarningSwal('Wrong amount of columns')} />
                    </Grid>
                </Grid>}
            {list && <Grid container justifyContent='space-between' alignItems='center' style={{ marginTop: 60 }}>
                <NunitoText value={uploadSchool ? 'Schools' : 'Participants'} fontSize={30} fontWeight={800} italic
                    color='#144A94' marginLeft={40} />
                <div style={{ marginTop: 20, paddingRight: 55, display: 'flex', alignItems: 'center' }}>
                    {checkForError(list) && <>
                        <ReusableSelect type='type2' state={sort} setState={onChangeSort} btnBgColor='#144A94' height={45}
                            width={230} options={sortOptions().map(o => ({ value: o.id, option: o.label }))} marginRight={20} />
                        <ReusableButton text='Check/Uncheck Errors' bgColor='#5E75C3' fontSize={16} height={50} width={240} iconType='check'
                            marginRight={20} onClick={() => checkOrUncheck()} />
                    </>}
                    <ReusableButton text='Mass Delete' bgColor='#E83D4D' fontSize={16} height={50} iconType='delete'
                        marginRight={20} onClick={() => massDeleteList()} />
                    {Boolean(selected.length) ?
                        <CSVLink style={{ textDecoration: 'none' }} data={list.filter(p => selected.includes(p.id))}>
                            <ReusableButton text={`Export Selected ${uploadSchool ? 'School' : 'Participant'}(s)`}
                                fontSize={16} bgColor='#5E75C3' height={50} iconType='export' />
                        </CSVLink> :
                        <ReusableButton text={`Export Selected ${uploadSchool ? 'School' : 'Participant'}(s)`} bgColor='#707070'
                            fontSize={16} height={50} iconType='export'
                            onClick={() => showWarningSwal(`Please select at least one ${uploadSchool ? 'school' : 'participant'}(s) to export`)} />}
                </div>
            </Grid>}
            {list && !uploadSchool && <Grid container alignItems='center' justifyContent='space-between'
                style={{ marginTop: 20, paddingInline: 42 }}>
                {user.role_id < 3 && <Grid container alignItems='center'>
                    {user.role_id === 1 && <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignPartner} setState={setMassAssignPartner}
                            placeholder='Partner' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={massPartnerOptions.map(s => ({ id: s.id, option: s.name }))} />
                        <ReusableButton text='Mass Assign Partner' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('partner', massAssignPartner)} />
                    </div>}
                    <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignSchool} setState={setMassAssignSchool}
                            placeholder='School' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={[{ id: -1, name: 'Home School' }, ...massSchoolOptions].map(s => ({ id: s.id, option: s.name }))} />
                        <ReusableButton text='Mass Assign School' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('school', massAssignSchool)} />
                    </div>
                    <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignTuition} setState={setMassAssignTuition}
                            placeholder='Tuition' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={(participantFiltered.find(p => p.key === 'forPartner').state ? partnerCentreOptions :
                                massTuitionCentreOptions).map(t => ({ id: t.id, option: t.name }))} />
                        <ReusableButton text='Mass Assign Tuition' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('tuition_centre', massAssignTuition)} />
                    </div>
                </Grid>}
                <Grid container alignItems='center' style={{ marginTop: 20 }}>
                    <div style={massAssignStyle}>
                        <ReusableAutocomplete type='table' state={massAssignGrade} setState={setMassAssignGrade}
                            placeholder='Grade' width={240} borderColor='#000' marginRight={30} freeSolo
                            options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />
                        <ReusableButton text='Mass Assign Grade' fontSize={15} bgColor='#5E75C3' height={50}
                            onClick={() => massUpdateList('grade', massAssignGrade)} />
                    </div>
                </Grid>
            </Grid>}

            {list && <Grid container style={{ paddingInline: 42 }}>
                <CSVUploadTable headers={headers} data={list} error={uploadError}
                    rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage}
                    selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} setState={setList}
                    schoolOptions={[{ id: -1, name: 'Home School' }, ...massSchoolOptions]}
                    tuitionCentreOptions={massTuitionCentreOptions}
                    partnerCentreOptions={partnerCentreOptions}
                    partnerOptions={massPartnerOptions}
                    gradeOptions={gradeOptions}
                    checkForErrorInRow={checkForErrorInRow} isDuplicate={isDuplicate} uploadSchool={uploadSchool}
                />
                {Boolean(list && list.map(l => isDuplicate(list, l)).includes(true) && !uploadSchool) &&
                    <ReusableCheckbox type='default' state={okToHvDupes} setState={setOkToHvDupes}
                        label='I acknowledege there are duplicate particiant entries' fontSize={20} />}
            </Grid>}
            {list && <Grid container justifyContent="flex-end" style={{ marginBlock: '50px 20px', paddingInline: 42 }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} height={50} width={130} marginRight={20}
                    onClick={() => cancelList()} />
                <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} height={50} width={130}
                    onClick={() => onSubmit()} />
            </Grid>}
            {/* {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> */}
            {/* {error && sortErrorByType(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br />
            {error && sortErrorByRow(error).map((e, i) => (
                <Grid key={i} container>{JSON.stringify(e)}</Grid>
            ))}<br /> */}
            {/* {JSON.stringify(selected)}
            {list && JSON.stringify(checkForError(list))}
            {list && list.map((l, i) => (
                <div key={i} style={{ border: '1px solid gold', marginBottom: 10 }}>
                    <p>row: {JSON.stringify(l)}</p>
                    <p>error: {JSON.stringify(checkForErrorInRow(l))}</p>
                </div>
            ))} */}
        </Box >
    )
}