import React, { useState, useEffect, useRef } from 'react'
import JoditEditor from "jodit-react";
import {
    Box, Grid, Button, TextField, IconButton, Typography, Modal, Chip, MenuItem, FormControl, Select,
    Tooltip, tooltipClasses, styled
} from '@mui/material';
import RejectModal from '../components/modal/RejectModal';
import { useLocation } from "react-router-dom";
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { FaTasks } from 'react-icons/fa'
import LanguageIcon from '@mui/icons-material/Language';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { useSelector } from 'react-redux'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#144A94',
        position: 'relative',
        top: -10,
        left: 50,
        border: '1px solid #707070',
        borderRadius: 16,
        maxWidth: 950,
        boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
    }
}));
export function Rollover({ task, identifier, title, limiter }) {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between', width: 100 }}>
            <div>
                <h2 style={{
                    margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid #707070', textAlign: 'center',
                    borderRadius: 6, paddingInline: 4, paddingBlock: 2, marginInline: 3, marginTop: 5, fontWeight: 400
                }}>{title}</h2>
                {task[identifier].map((d, index) => (
                    index > limiter && <h2 key={index} style={{
                        margin: '0 0 0 3px', fontWeight: 300, color: '#000',
                        textAlign: 'left'
                    }}>{d}</h2>
                ))}
            </div>
        </div>
    )
}
export function Widget({ task, identifier, title, color, bgColor, shortenedTopic }) {
    let icon;
    const iconStyle = {
        color: color, backgroundColor: bgColor, borderRadius: 8,
        width: 64, height: 64, padding: 6
    }
    switch (title) {
        case 'Domain': icon = <LanguageIcon style={iconStyle} />; break;
        case 'Task Name': icon = <LibraryBooksIcon style={iconStyle} />; break;
        case 'Topic(s)': icon = <FaTasks style={iconStyle} />; break;
        default:
    }
    return (
        shortenedTopic ? <Grid item xs={3} style={{
            backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 24, boxShadow: '0 0 5px #9E9E9E',
            textAlign: 'center', paddingBlock: '20px 10px', display: 'flex', flexDirection: 'column',
            justifyContent: 'space-around', alignItems: 'center', height: 210
        }}>
            {icon}
            <Grid container justifyContent="center">
                {shortenedTopic.map((topic, index) => (
                    <Typography key={index} variant='h5' sx={{ mt: 1 }}>
                        {topic}{index < shortenedTopic.length - 1 && `,\u00A0`}
                    </Typography>
                ))}
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
                <Typography variant='h6' sx={{ mt: 1, color: color, fontWeight: 'normal', mb: 2 }}>{title}</Typography>
                {task[identifier].length > 3 && <LightTooltip title={<Rollover task={task} identifier='topic' title='Topics' limiter={3} />}
                    placement='bottom-end'>
                    <Button id='approveBtn' style={{ borderRadius: 20, marginRight: 10, backgroundColor: '#569EFF' }}>
                        +{task[identifier].length - 3}</Button>
                </LightTooltip>}
            </Grid>
            <Typography variant='caption'>Created on: 26/02/2019</Typography>
        </Grid> : <Grid item xs={3} style={{
            backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 24, boxShadow: '0 0 5px #9E9E9E',
            textAlign: 'center', paddingBlock: '20px 10px', display: 'flex', flexDirection: 'column',
            justifyContent: 'space-around', alignItems: 'center', height: 210
        }}>
            {icon}
            <Typography variant='h4'>{task[identifier]}</Typography>
            <Typography variant='h6' sx={{ mt: 1, color: color, fontWeight: 'normal', mb: 2 }}>{title}</Typography>
            <Typography variant='caption'>Created on: 26/02/2019</Typography>
        </Grid >
    )
}
export function Content({ task, title, language, setLanguage }) {
    const [open, setOpen] = useState(false)
    const editor = useRef(null)
    const config = {
        readonly: true, // all options from https://xdsoft.net/jodit/doc/
        placeholder: 'Words and stuff here',
        editorCssClass: 'editorCss',
        height: "500",
        toolbarAdaptive: false,
        style: { color: '#D4D2D2', backgroundColor: '#707070' }
    }
    return (
        <>
            <Grid container style={{ backgroundColor: '#144A94', borderRadius: 10, lineHeight: 0.5 }} alignItems="center">
                <h2 style={{ color: '#FFF', paddingLeft: 42 }}>{title}</h2>
            </Grid>
            <Grid container style={{ marginBottom: 40 }}>
                <Grid item xs={0.2} />
                <Grid item xs={11.6} style={{
                    paddingInline: 48, backgroundColor: '#FFF', border: '1px solid #707070',
                    borderRadius: '0 0 12px 12px', borderTopStyle: 'none'
                }}>
                    {title === 'Task Image' ? <Grid container justifyContent="center" alignItems="center" style={{
                        paddingInline: 24, backgroundColor: '#FFF', border: '1px solid #000',
                        marginBlock: 30, height: 600
                    }}>Task Image if applicable.</Grid> :
                        title === 'Answers' ? <>
                            {task.answers.map((a, index) => (
                                <Grid key={index} container direction="column" style={{
                                    paddingInline: 16, backgroundColor: '#FFF', borderRadius: 6, boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)',
                                    marginBlock: 30
                                }}>
                                    {a.fieldLabel && <Typography variant='h5' style={{ color: '#144A94', marginTop: 10 }}>{a.fieldLabel}</Typography>}
                                    <TextField size='small' sx={{ border: '2px solid gray', borderRadius: 1, mt: 3 }}
                                        variant="filled" fullWidth placeholder="Task Title" value={a.answer}
                                        inputProps={{ style: { color: '#D4D2D2', textAlign: 'center' } }}
                                        InputProps={{
                                            disableUnderline: true, hiddenLabel: true, readOnly: true,
                                            style: { backgroundColor: '#707070' }
                                        }}
                                    />
                                    <TextField size='small' sx={{ border: '2px solid gray', borderRadius: 1, mt: 3, mb: 3 }}
                                        variant="filled" fullWidth placeholder="Task Title" value={a.answer}
                                        inputProps={{ style: { color: '#D4D2D2', textAlign: 'center' } }}
                                        InputProps={{
                                            disableUnderline: true, hiddenLabel: true, readOnly: true,
                                            style: { backgroundColor: '#707070' }
                                        }}
                                    />
                                </Grid>
                            ))}</> : <Grid container style={{ marginTop: 20, marginInline: 30 }}>
                            <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography sx={{ mt: 2 }}>Reference Language</Typography>
                                <Typography sx={{ mt: 5 }}>Task Title</Typography>
                            </Grid>
                            <Grid item xs style={{ display: 'flex', flexDirection: 'column', marginLeft: 160 }}>
                                <FormControl variant='outlined' size="small" sx={{ width: '60%' }}>
                                    <Select
                                        style={{ borderRadius: 12, height: 42 }}
                                        value={language}
                                        onChange={(e) => setLanguage(e.target.value)}
                                        open={open}
                                        onClose={() => setOpen(false)}
                                        onOpen={() => setOpen(true)}
                                        IconComponent={() => (
                                            <IconButton
                                                onClick={() => setOpen(!open)}
                                                sx={{ color: '#FFF', backgroundColor: '#144a94', borderRadius: '0 12px 12px 0' }}>
                                                {open ? <ExpandLess /> : <ExpandMore />}
                                            </IconButton>)}
                                        renderValue={() => (
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Chip key={language} label={language} sx={{
                                                    backgroundColor: '#f16774', color: '#FFF',
                                                    width: '80%', borderRadius: 2
                                                }} />
                                            </Box>
                                        )}
                                    >
                                        {task.language.map((l, index) => (
                                            <MenuItem key={index} value={l}>{l}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    size='small'
                                    sx={{ borderRadius: 1, mt: 3 }}
                                    variant="filled"
                                    fullWidth
                                    placeholder="Task Title"
                                    value={task.taskTitle}
                                    inputProps={{ style: { color: '#D4D2D2', textAlign: 'center' } }}
                                    InputProps={{
                                        disableUnderline: true,
                                        hiddenLabel: true,
                                        readOnly: true,
                                        style: { backgroundColor: '#707070' }
                                    }}
                                />
                            </Grid>
                            <div style={{
                                border: "1px solid black", padding: '2px', width: '100%',
                                backgroundColor: '#f3f0f4', borderRadius: '0 0 6px 6px', marginBlock: 30
                            }}>
                                <JoditEditor ref={editor} value={task.description} config={config} tabIndex={1} />
                            </div>
                        </Grid>}
                </Grid>
                <Grid item xs={0.2} />
            </Grid>
        </>
    )
}
export function ModerateTask({ id }) {
    const [tasks, setTasks] = useState([])
    const task = tasks.table.find(t => t.id === id)
    const [expand, setExpand] = useState(false)
    const [primaryLanguage, setPrimaryLanguage] = useState(task.language[0])
    const [referenceLanguage, setReferenceLanguage] = useState(task.language[1])
    const [shortenedTopic, setShortenedTopic] = useState([])
    useEffect(() => {
        let topicCharacter = 0;
        let newShortenedTopic = []
        for (const topic of task.topic) {
            topicCharacter += topic.length
            if (topicCharacter < 30) newShortenedTopic.push(topic)
        }
        setShortenedTopic(newShortenedTopic)
    }, [task])
    //Reject Modal
    const [reject, setReject] = useState(false)
    const [reason, setReason] = useState('')
    return (
        <Grid container>
            <Grid container justifyContent="space-between" style={{
                backgroundColor: '#144a94', borderRadius: 10,
                lineHeight: 0.5, paddingInline: 10, marginTop: 30
            }}>
                <h2 style={{ color: '#FFF', paddingLeft: 42 }}>{task.taskTitle}</h2>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ marginRight: 30 }}>
                        {task.tags.map((t, index) => (
                            index < 3 && <Button key={index} id='approveBtn' style={{ borderRadius: 20, marginRight: 10 }}>{t}</Button>
                        ))}
                        {task.tags.length > 3 && <LightTooltip title={<Rollover task={task} identifier='tags' title='Tags' limiter={2} />}
                            placement='bottom-end'>
                            <Button id='approveBtn' style={{ borderRadius: 20, marginRight: 10 }}>+{task.tags.length - 3}</Button>
                        </LightTooltip>}
                    </div>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => setExpand(!expand)}
                        edge="start"
                    >
                        {expand ? <ExpandLess style={{ color: '#FFF' }} /> : <ExpandMore style={{ color: '#FFF' }} />}
                    </IconButton>
                </div>
            </Grid>
            {expand &&
                <Grid container style={{
                    marginInline: 16, backgroundColor: '#F3F0F4', border: '1px solid #707070',
                    borderRadius: '0 0 12px 12px', borderTopStyle: 'none'
                }}>
                    <Grid container justifyContent="space-evenly" style={{ marginBlock: 50 }}>
                        <Widget task={task} identifier='domain' title='Domain' color='#1A9848' bgColor='rgb(26, 152, 72, .25)' />
                        <Widget task={task} identifier='taskTitle' title='Task Name' color='#F16774' bgColor='rgb(241, 103, 116, .25)' />
                        <Widget task={task} identifier='topic' title='Topic(s)' color='#569EFF' bgColor='rgb(86, 158, 255, .25)'
                            shortenedTopic={shortenedTopic} />
                    </Grid>
                    <Content task={task} title='Primary Language' language={primaryLanguage} setLanguage={setPrimaryLanguage} />
                    <Content task={task} title='Reference Language' language={referenceLanguage} setLanguage={setReferenceLanguage} />
                    <Content task={task} title='Task Image' />
                    <Content task={task} title='Answers' />
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginRight: 40, marginBottom: 40 }}>
                        <Button className='btn2' style={{ marginRight: 20, paddingInline: 30 }} onClick={() => setReject(true)}>
                            Reject
                        </Button>
                        <Button className='btn1' style={{ paddingInline: 30 }}>
                            Moderate
                        </Button>
                    </Grid>
                </Grid>}
            <Modal open={reject} onClose={() => setReject(false)}>
                <RejectModal rejectedItem={`Task ${task.taskIdentifier}`} setReason={setReason} setReject={setReject} />
            </Modal>
        </Grid >
    )
}
export default function ModerateTasks() {
    let { state } = useLocation()
    return (
        <Box sx={{ flexGrow: 1, mx: 5 }}>
            {state.map((id, index) => <ModerateTask key={index} id={id} />)}
        </Box>
    )
}