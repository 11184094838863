import React, { useState } from 'react'
import { Grid, IconButton } from '@mui/material'
import NunitoText from './general/NunitoText'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableButton from './general/ReusableButton'
import AddPageWrapper from './general/AddPageWrapper'
import Loader from './general/Loader'
import AddIcon from '@mui/icons-material/Add'
import CloseIcon from '@mui/icons-material/Close'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
const difficultyOptions = ['Easy', 'Intermediate', 'Hard', 'Advanced'].map((d, i) => ({ id: d, name: d }))
export default function Recommendations({ count, recommendations, update, add, remove, save, loading }) {
    const [expandRecommendations, setExpandRecommendations] = useState(true)
    const oso = (index, length) => ({
        marginTop: index ? 10 : 40, marginBottom: index === length - 1 ? 40 : 10
    })
    const iconWrapperStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    }
    const iconStyle = {
        fontSize: 36, color: '#000', cursor: 'pointer'
    }
    const containerStyle = {
        backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 16, paddingBlock: 20
    }
    const gradeOrDifficultyWrapperStyle = {
        display: 'flex', flexDirection: 'column', alignItems: 'flex-start', paddingInline: '30px 60px'
    }
    const addRecommendation = count => {
        return add ? add(count) : update(count, 'addRecommendation', 'recommendations')
    }
    const removeRecommendation = count => {
        return remove ? remove(count) : update(count, 'deleteRecommendation', 'recommendations')
    }
    const titleBarStyle = { backgroundColor: '#144A94', borderRadius: 10, height: 60 }
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value='Recommendations' fontSize={25} fontWeight={700} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <ReusableButton text='Add Recommendation' fontSize={16} bgColor='#5E75C3' marginRight={60}
                        onClick={() => addRecommendation(count === undefined ? recommendations.length : [count, recommendations.length])} iconType='add' />
                    <IconButton style={{ color: '#FFF' }}
                        onClick={() => setExpandRecommendations(!expandRecommendations)} edge="start">
                        {expandRecommendations ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {expandRecommendations && <AddPageWrapper>
                {loading ? <Loader height={600} /> : recommendations.map((recommendation, index) => (
                    <Grid key={index} container alignItems="center" style={oso(index, recommendations.length)}>
                        <Grid item xs={0.5} style={iconWrapperStyle}>
                            <IconButton onClick={() => removeRecommendation(count === undefined ? index : [count, index])}>
                                <CloseIcon style={iconStyle} />
                            </IconButton>
                        </Grid>
                        <Grid item xs={11}>
                            <Grid key={index} container style={containerStyle}>
                                <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                                    <NunitoText value='Recommended Grade*:' fontSize={20} marginBottom={10} fontWeight={600} italic />
                                    <ReusableAutocompleteWithID type='default' placeholder='Grade' width='100%' bgColor='#F2F2F2'
                                        btnBgColor='#5E75C3' btnWidth={56} state={recommendation.grade} setState={update}
                                        count={count === undefined ? index : [count, index]} required
                                        head={count === undefined ? undefined : 'recommendationText'} target='grade'
                                        options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />
                                </Grid>
                                <Grid item xs={6} style={gradeOrDifficultyWrapperStyle}>
                                    <NunitoText value='Recommended Difficulty*:' fontSize={20} marginBottom={10} fontWeight={600} italic />
                                    <ReusableAutocompleteWithID type='default' placeholder='Difficulty' width='100%' bgColor='#F2F2F2'
                                        btnBgColor='#5E75C3' btnWidth={56} state={recommendation.difficulty} setState={update}
                                        count={count === undefined ? index : [count, index]} required
                                        head={count === undefined ? undefined : 'recommendationText'} target='difficulty'
                                        options={difficultyOptions.map(d => ({ id: d.id, option: d.name }))} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.5} style={iconWrapperStyle}>
                            <IconButton onClick={() => addRecommendation(count === undefined ? index + 1 : [count, index + 1])}>
                                <AddIcon style={iconStyle} />
                            </IconButton>
                        </Grid>
                    </Grid>
                ))}
                {save && <Grid container justifyContent='flex-end' style={{ marginRight: 20, marginBottom: 20 }}>
                    <ReusableButton text='Save' bgColor='#5E75C3' width={140} height={50} fontSize={16}
                        onClick={() => save()} />
                </Grid>}
            </AddPageWrapper>}
            {JSON.stringify(recommendations)}
        </Grid>
    )
}