import React from 'react'
import { Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableCheckbox from './general/ReusableCheckbox'
import ReusableButton from './general/ReusableButton'
const EachAutoComplete = ({ count, filter, optionsInUse, setState, filtered, uploadingFor }) => {
    const user = useSelector(state => state.user)
    let options = []
    let grayCondition = false
    let require = false
    let nested = false
    let p = filter.label
    switch (filter.key) {
        case 'competition_id':
            options = optionsInUse[0].map(o => ({ id: o.id, option: o.name }))
            require = true
            break;
        case 'country_id':
            options = optionsInUse[1].map(o => ({ id: o.id, option: o.display_name }))
            require = true
            if (uploadingFor === 'participant') grayCondition = !filtered.find(f => f.key === 'competition_id').state
            break;
        case 'school':
            options = optionsInUse[2].map(o => ({ id: o.id, option: o.name }))
            if (user.role_id === 1) grayCondition = !filtered.find(p => p.key === 'partner').state
            else if (user.role_id === 2) grayCondition = !filtered.find(p => p.key === 'competition_id').state
            break;
        case 'tuition_centre':
            if (filtered.find(f => f.key === 'forPartner').state) options = optionsInUse[4].map(o => ({ id: o.id, option: o.name }))
            else options = optionsInUse[3].map(o => ({ id: o.id, option: o.name }))
            if (user.role_id === 1) grayCondition = !filtered.find(p => p.key === 'partner').state || filtered.find(f => f.key === 'forPartner').state
            else if (user.role_id === 2) grayCondition = !filtered.find(p => p.key === 'competition_id').state || filtered.find(f => f.key === 'forPartner').state
            break;
        case 'partner':
            options = optionsInUse[5].map(o => ({ id: o.id, option: o.name }))
            if (user.role_id === 1) {
                grayCondition = !filtered.find(p => p.key === 'country_id').state
            } else if (user.role_id === 2) {
                grayCondition = !filtered.find(p => p.key === 'competition_id').state
            }
            break;
        default:
    }
    let placeholder = require ? `${p}*` : p
    if (['private', 'forPartner'].includes(filter.key)) {
        if (user.role_id < 3) {
            return <ReusableCheckbox type='default' label={placeholder}
                count={count} target='state' state={filter.state} setState={setState} fontSize={20} />
        } return null
    } else {
        return <ReusableAutocompleteWithID type='withLabel' width={380} height={50}
            placeholder={placeholder} state={filter.state} setState={setState}
            count={count} target='state' bgColor='#FFF' btnBgColor='#144A94' borderColor='#707070'
            required={require} grayedOut={grayCondition} readOnly={grayCondition}
            options={options} divMarginRight={30} nested={nested} allowSelectHead={nested} />
    }
}
export default function CSVUploadFilter({ filtered, options, setState, uploadingFor, noClick, setFiltered }) {
    const selectContainerStyle = {
        border: '1px solid #000', borderRadius: 12,
        backgroundColor: '#F5F5F5', padding: 24,
        pointerEvents: noClick && 'none'
    }
    const isDefault = filtered.map(f => f.state).every(e => [false, ''].includes(e))
    const reset = () => {
        let newFiltered = [...filtered]
        newFiltered.forEach(f => typeof f.state === 'boolean' ? f.state = false : f.state = '')
        setFiltered(newFiltered)
    }
    return (
        <Grid container alignItems='center' style={selectContainerStyle}>
            {filtered.map((f, i) => (
                <EachAutoComplete key={i} count={i} filter={f} optionsInUse={options} setState={setState}
                    filtered={filtered} uploadingFor={uploadingFor} />
            ))}
            <Grid container style={{ marginTop: 24 }}>
                <ReusableButton text='Reset' bgColor='#F16774' fontSize={16} br={8}
                    onClick={!isDefault && reset} iconType='reset' />
            </Grid>
        </Grid>
    )
}