import React, { useState, useEffect } from 'react'
import { Grid, IconButton, Divider } from '@mui/material'
import { useHistory } from 'react-router-dom'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Language from './Language'
import Solution from './Solution'
import AnswerSetting from './AnswerSetting'
import { useSelector, useDispatch } from 'react-redux'
import { addTask, deleteTask, duplicateTask, updateTask, resetTask } from '../actions'
import { addTask as addTaskToDatabase } from '../functions/postData'
import { getDomains } from '../functions/getData'
import { getBase64 } from '../functions/upload'
import { isEmpty, showWarningSwal } from '../functions/alert'
import { invalidName } from '../functions/general'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from './general/NunitoText'
import ReusableTextField from './general/ReusableTextField'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableButton from './general/ReusableButton'
import AddPageWrapper from './general/AddPageWrapper'
import DescriptionEditor from './DescriptionEditor'
import TaskContent from './TaskContent'
import Recommendations from './Recommendations'
// For edit task later on
const Remarks = () => {
    const containerStyle = { margin: 20, backgroundColor: '#F3F0F4', border: '1px solid', borderRadius: 10 }
    const divStyle = { display: 'flex', alignItems: 'center', marginTop: 20, marginInline: 20 }
    return (
        <Grid container flexDirection='column' style={containerStyle}>
            <div style={{ ...divStyle, justifyContent: 'space-between' }}>
                <NunitoText value='Remarks' fontSize={25} fontWeight={800} color='#5e75c3' italic
                    borderBottom='3px solid #000' />
                <ReusableButton text='View in new tab' bgColor='#F16774' height={43} iconType='copy' />
            </div>
            <div style={divStyle}>
                <NunitoText value='Test Name(Chief Examiner)' fontSize={24} fontWeight={300}
                    color='#5e75c3' italic />
                <NunitoText value='(22/10/2021 14:00)' fontSize={15} fontWeight={300}
                    color='#f16774' italic marginLeft={10} />
            </div>
            <div style={{ marginInline: 20, marginBottom: 20 }}>
                <NunitoText value='Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt 
                    ut labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis leo
                    vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque
                    non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi
                    quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
                    integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate eu scelerisque felis
                    imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt.' fontSize={20}
                    marginTop={6} align='left' />
            </div>
        </Grid>
    )
}
export function Task({ count, task }) {
    const dispatch = useDispatch()
    const [allTopicOptions, setAllTopicOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    const history = useHistory()
    const update = (count, head, target, text, event) => dispatch(updateTask(count, head, target, text, event))
    const updateDomainAndTopic = (count, head, target, text, event) => {
        let newArr = text
        let old = task.domain
        let allDomains = allTopicOptions.map(a => a.id)
        let allTopics = allTopicOptions.map(a => a.children).flat().map(t => t.id)
        console.log({ old, newArr })
        if (newArr.length > old.length) {
            let added = newArr.at(-1)
            if (allTopics.includes(added)) {
                let domainOfAddedTopic = allTopicOptions.find(a => a.children.find(c => c.id === added)).id
                if (!newArr.includes(domainOfAddedTopic)) newArr.push(domainOfAddedTopic)
            }
        } else {
            let removed = old.filter(x => !newArr.includes(x))[0]
            if (allDomains.includes(removed)) {
                let topicsOfRemovedDomain = allTopicOptions.find(a => a.id === removed).children.map(c => c.id)
                console.log(topicsOfRemovedDomain)
                newArr = newArr.filter(n => !topicsOfRemovedDomain.includes(n))
            }
        }
        update(count, head, target, newArr, event)
    }
    const handleFileInputChange = (count, file) => {
        update(count, 'taskText', 'imgName', file.name)
        getBase64(file)
            .then(result => update(count, 'taskText', 'image', result))
            .catch(err => {
                console.log(err)
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            });
    }
    const onSubmit = () => {
        let warningMessage = ''
        if (invalidName(task.taskTitle)) warningMessage += 'Invalid Task Title. '
        if (invalidName(task.taskIdentifier)) warningMessage += 'Invalid Task Identifier. '
        if (isEmpty([task.language])) warningMessage += 'Task Content cannot be empty. '
        // if (task.recommendations.map(r => ((r.grade !== '' && r.difficulty === '') || (r.grade === '' && r.difficulty !== ''))).includes(true)) {
        //     warningMessage += 'Either empty grade and difficulty or fill in both. '
        // }
        if (task.recommendations.map(r => r.grade).includes('')) {
            warningMessage += 'Please fill in all grades fields or remove recommended grade. '
        }
        if (task.recommendations.map(r => r.difficulty).includes('')) {
            warningMessage += 'Please fill in all difficulty fields or remove recommended difficulty. '
        }
        if (task.answers.length === 0) warningMessage += 'Must have at least one answer for task. '
        if (task.type === 1) {
            if (task.answers.map(a => a.taskLabel === '').includes(true)) {
                warningMessage += 'Answer cannot be empty if answer type is MCQ. '
            }
            if ([1, 2].includes(task.structure) && !task.answers.map(a => a.correct).includes(true)) {
                warningMessage += 'Must have at least one correct answer if answer type is MCQ and answer structure is Default or Group. '
            }
        }
        if (task.type === 2) {
            if ([1, 2].includes(task.structure) && task.answers.map(a => a.answer === '').includes(true)) {
                warningMessage += 'Answer cannot be empty if answer type is Input and answer structure is Default or Group.'
            }
            if (task.structure === 4 && task.answers.map(a => a.taskLabel === '').includes(true)) {
                warningMessage += 'Task Label cannot be empty if answer type is Input and answer structure is Open.'
            }
        }
        if (warningMessage) {
            showWarningSwal(warningMessage)
            return
        }
        let payload = [
            {
                "title": task.taskTitle,
                "identifier": task.taskIdentifier,
                "tag_id": [task.domain, task.tags].flat(),
                "content": task.language,
                "description": task.description,
                "solutions": task.solution,
                "answer_type": task.type,
                "answer_structure": task.structure,
                "answer_sorting": task.type === 2 ? null : task.sorting,
                "answer_layout": task.type === 2 ? null : task.layout,
                "recommended_grade": task.recommendations.filter(r => r.grade !== '').map(r => r.grade),
                "recommended_difficulty": task.recommendations.filter(r => r.difficulty !== '').map(r => r.difficulty.toLowerCase()),
                "image_label": Number(task.uploadImage),
                "image": task.image
            }
        ]
        if (task.uploadImage) payload[0].labels = task.answers.map(a => a.image)
        else payload[0].labels = task.answers.map(a => a.taskLabel)
        if (task.type === 1) {
            // MCQ Sequence, parse in an array of empty strings
            if (task.structure === 3) payload[0].answers = task.answers.map(a => null)
            // MCQ Default and Group, parse in an array of 1 if answer is correct
            else payload[0].answers = task.answers.map(a => a.correct ? '1' : null)
        } else if (task.type === 2) {
            // Input Open, parse in an array of empty strings
            if (task.structure === 4) payload[0].answers = task.answers.map(a => null)
            // Input Default and Group, parse in an array of strings
            else payload[0].answers = task.answers.map(a => a.answer)
        }
        console.log(JSON.stringify(payload))
        console.log(payload)
        // addTaskToDatabase(payload).then(d => {
        //     console.log(d)
        //     if ([200, 201].includes(d.status)) {
        //         dispatch(resetTask())
        //         history.push('./task')
        //     }
        // })
    }
    useEffect(() => {
        getDomains('?limits=50').then(d => {
            let domains = d.tagLists.data.filter(c => !c.is_tag && c.domain_name === null)
            let topics = d.tagLists.data.filter(c => !c.is_tag && c.domain_name !== null)
            let outputTopics = domains.map(d => ({ id: d.id, option: d.name, children: [] }))
            outputTopics.map(o => {
                topics.map(t => {
                    if (t.domain_id === o.id) o.children.push({ id: t.id, option: t.name })
                })
            })
            setAllTopicOptions(outputTopics)
            setTagOptions(d.tagLists.data.filter(c => c.is_tag))
        })
    }, [])
    const titleBarStyle = { backgroundColor: '#144A94', borderRadius: 10, paddingBlock: 4, height: 60 }
    const taskContainerStyle = { marginInline: 24, backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value={`Task ${count + 1}`} fontSize={25} fontWeight={700} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='New Task' fontSize={16} bgColor='#5E75C3' marginRight={20}
                            onClick={() => dispatch(addTask(count + 1))} iconType='add' />
                        {Boolean(count) && <ReusableButton text='Delete Task' fontSize={16} bgColor='#E83D4D' marginRight={20}
                            onClick={() => dispatch(deleteTask(count))} iconType='delete' />}
                        <ReusableButton text='Duplicate Task' fontSize={16} bgColor='#F16774'
                            onClick={() => dispatch(duplicateTask(count + 1, task))} iconType='copy' />
                    </div>
                    <IconButton style={{ color: '#FFF' }}
                        onClick={() => update(count, 'taskBoolean', 'expandTask', !task.expandTask)} edge="start">
                        {task.expandTask ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {task.expandTask && <Grid container style={taskContainerStyle}>
                <AddPageWrapper>
                    <Grid container style={{ margin: '30px 36px' }}>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <NunitoText value='Task Title*' fontSize={20} fontWeight={600} marginTop={12} />
                                {['Task Identifier*', 'Domain and Topic', 'Tags', 'Description', 'Select Image'].map((t, i) => (
                                    <NunitoText key={i} value={t} fontSize={20} fontWeight={600} marginTop={[3, 4].includes(i) ? 235 : 75} />
                                ))}
                            </div>
                            <Divider orientation="vertical" flexItem
                                style={{ marginTop: 20, height: 850, width: 2, backgroundColor: '#000' }} />
                        </Grid>
                        <Grid item xs={10} style={{ paddingLeft: 120, display: 'flex', flexDirection: 'column' }}>
                            <ReusableTextField type='default' placeholder='Task Title' state={task.taskTitle} setState={update}
                                count={count} head='taskText' target='taskTitle' borderColor='#000' required onBlur />
                            <ReusableTextField type='default' placeholder='Task Identifier' state={task.taskIdentifier}
                                setState={update} count={count} head='taskText' target='taskIdentifier'
                                marginTop={48} borderColor='#000' required onBlur />
                            <ReusableAutocompleteWithID type='multiple' placeholder='Domain and Topic' width={610} height={55}
                                btnBgColor='#144A94' btnWidth={64} state={task.domain} setState={updateDomainAndTopic}
                                inputValue={inputValue} setInputValue={setInputValue} limitMultiple={3}
                                count={count} head='taskMultiple' target='domain' marginTop={48} borderColor='#707070' nested
                                options={allTopicOptions} allowSelectHead />
                            <ReusableAutocompleteWithID type='multiple' placeholder='Tags' width={610} height={55}
                                btnBgColor='#144A94' btnWidth={64} state={task.tags} setState={update} limitMultiple={3}
                                count={count} head='taskMultiple' target='tags' marginTop={46} borderColor='#707070'
                                options={tagOptions.map(t => ({ id: t.id, option: t.name }))} />
                            <DescriptionEditor state={task.description} setState={update} height={360}
                                placeholder='Description' marginTop={60} count={count} head='taskText' target='description' />
                            <input id={`raised-button-file-${count}`} accept="image/*" style={{ display: 'none' }} multiple
                                type="file" onChange={(e) => handleFileInputChange(count, e.target.files[0])} />
                            <ReusableButton text='Browse' bgColor='#5E75C3' width={140} height={50} fontSize={16} marginTop={52}
                                onClick={() => document.getElementById(`raised-button-file-${count}`).click()} iconType='upload' />
                        </Grid>
                        <Grid container flexDirection='column' style={{ marginTop: 20 }}>
                            <NunitoText value={task.image !== '' ? task.imgName : "Test.jpg"} fontSize={20} color='#707070' />
                            {task.image !== '' ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={task.image} alt="Task" style={{ maxHeight: 600, maxWidth: 1500 }} />
                            </div> : <ReusableTextField type='imagePlaceholder' placeholder='Image placeholder' bgColor='#F2F2F2'
                                height={600} />}
                        </Grid>
                        <Solution count={count} solution={task.solution} update={update} />
                    </Grid>
                </AddPageWrapper>
                <Recommendations count={count} recommendations={task.recommendations} update={update} />
                <TaskContent count={count} task={task} />
                {/* {languageOptions && task.languages.map(l => l.language).includes(1) && <Language title='Main Language' count={count}
                        options={languageOptions.map(l => ({ id: l.id, language: l.language }))} task={task} />}
                    {languageOptions && task.languages.length > 1 && <Language title='Reference Language' count={count}
                        options={languageOptions.map(l => ({ id: l.id, language: l.language }))} task={task} />} */}
                <AnswerSetting count={count} task={task} update={update} />
                <Grid container justifyContent="flex-end" style={{ marginRight: 20, marginBottom: 20 }}>
                    <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} marginRight={20} />
                    <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
                </Grid>
            </Grid>}
        </Grid>
    )
}
export default function Tasks() {
    const tasks = useSelector(state => state.task)
    return tasks.map((task, index) => <Task key={index} count={index} task={task} />)
}