import React, { useState, useEffect, useRef, useCallback } from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import { Link } from 'react-router-dom'
import {
    Box, IconButton, Button, Grid, TextField, Autocomplete, Popper, InputAdornment,
    InputLabel, MenuItem, FormControl, Select, Tooltip, Collapse, Paper, FormControlLabel, Switch,
    ListItem, ListItemIcon, ListItemText, List, Checkbox, Chip, CircularProgress, Divider
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import EventIcon from '@mui/icons-material/Event'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
import CustomSelectMultiple from '../components/CustomNestedSelectMultiple'
import NunitoText from '../components/general/NunitoText'
import Notification from '../components/Notification'
import DatePicker from "react-multi-date-picker"
import { default as Owo } from "react-multi-date-picker/plugins/date_panel"
import InputIcon from "react-multi-date-picker/components/input_icon"
import { formatDateTime, formatDate } from '../functions/general'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { default as Uwu } from '@mui/lab/DatePicker'
import LightTooltip from '../components/general/LightTooltip'
import ReusableSelect from '../components/general/ReusableSelect'
import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import useElementDimensions from '../components/ElementDimensions'
import useWindowDimensions from '../components/WindowDimensions'
// import DatePicker from '@mui/lab/DatePicker';
const SortableItem = SortableElement(({ value }) => (
    <li style={{ marginLeft: 100 }}>{value}</li>
))
const SortableList = SortableContainer(({ items }) => {
    return (
        <div style={{ border: '2px solid red', display: 'flex' }}>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} />
            ))}
        </div>
    );
})
export default function Homepage() {
    document.title = 'Homepage'
    const [itemsContainer, setItemsContainer] = useState([['Item 1', 'Item 2', 'Item 3', 'Item 4', 'Item 5', 'Item 6'],
    ['Item 21', 'Item 22', 'Item 23', 'Item 24', 'Item 25', 'Item 26']])
    const onSortEnd = ({ oldIndex, newIndex }) => {
        let newItemsContainer = [...itemsContainer]
        newItemsContainer[0] = arrayMoveImmutable(itemsContainer[0], oldIndex, newIndex)
        setItemsContainer(newItemsContainer)
    };
    const onSortEnd2 = ({ oldIndex, newIndex }) => {
        let newItemsContainer = [...itemsContainer]
        newItemsContainer[1] = arrayMoveImmutable(itemsContainer[1], oldIndex, newIndex)
        setItemsContainer(newItemsContainer)
    };
    const onSortEnd3 = ({ oldIndex, newIndex }) => {
        setItemsContainer(arrayMoveImmutable(itemsContainer, oldIndex, newIndex))
    };
    const [a, setA] = useState([
        {
            taskGroupID: '1',
            taskGroup: [
                {
                    id: 1
                },
                {
                    id: 4
                }
            ]
        },
        {
            taskGroupID: '2',
            taskGroup: [
                {
                    id: 2
                },
                {
                    id: 3
                },
                {
                    id: 5
                }
            ]
        }])
    const onSortA = ({ oldIndex, newIndex }) => setA(arrayMoveImmutable(a, oldIndex, newIndex))
    const onSortQuestion = (count, { oldIndex, newIndex }) => {
        // alert(JSON.stringify({ count, oldIndex, newIndex }))
        let newA = [...a]
        newA[count].taskGroup = arrayMoveImmutable(newA[count].taskGroup, oldIndex, newIndex)
        setA(newA)
    }
    const SortableGrpItem = SortableElement(({ level, value, groupIndex, questionIndex, items }) => {
        if (level) {
            return (
                <Button key={questionIndex} id='newQnBtn' style={{
                    margin: `10px ${questionIndex === value.length - 1 ? 28 : 28}px 10px 
                                ${items.length > 1 ? 14 : 0}px`
                }} tabIndex={0} endIcon={<>
                    {value.length > 1 && <IconButton sx={{ width: 26 }} >
                        <RemoveCircleIcon style={{ color: '#FFF' }} />
                    </IconButton>}
                    <IconButton sx={{ width: 26 }}>
                        <AddCircleIcon style={{ color: 'red' }} />
                    </IconButton>
                </>}>Question {groupIndex + 1}{items.length > 1 && `.${questionIndex + 1}`} QuestionID : {value.id}
                </Button>
            )
        }
        return (
            <div style={{ border: '2px solid gold', marginLeft: 50, display: 'flex' }}>
                <SortableGrpList level={1} items={value.taskGroup} groupIndex={groupIndex}
                    axis='x' onSortEnd={(e) => onSortQuestion(groupIndex, e)} />
            </div>
        )
    })
    const SortableGrpList = SortableContainer(({ level, items, groupIndex }) => {
        if (level) {
            return (
                <div style={{ border: '2px solid blue', display: 'flex' }}>
                    {items.map((value, index) =>
                        <SortableGrpItem level={1} key={`item-${index}`} index={index} value={value} groupIndex={groupIndex}
                            questionIndex={index} items={items} />
                    )}
                </div>
            )
        }
        return (
            <div style={{ border: '2px solid red', display: 'flex' }}>
                {items.map((value, index) => (
                    <SortableGrpItem level={0} key={`item-${index}`} index={index} value={value} groupIndex={index} />
                ))}
            </div>
        );
    })


    const [dates, setDates] = useState([new Date()])
    const [date, setDate] = useState(new Date())
    var getDaysArray = function (startAndEndArr) {
        for (var arr = [], dt = new Date(startAndEndArr[0]); dt <= startAndEndArr[1]; dt.setDate(dt.getDate() + 1)) {
            arr.push(new Date(dt));
        }
        return arr;
    }
    const divStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: 1200, border: '1px solid gold' }
    const HoverDiv = () => {
        return <div style={{ height: 400, width: 800, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h1>Uwu Owo</h1>
        </div>
    }

    const users = [{
        id: 1, role: 'Admin', name: 'admin1',
        children: ['testUser1', 'testUser612', 'testUser910'].map((t, i) => ({ id: 1 + '' + i, name: t }))
    },
    {
        id: 2, role: 'Country Partner', name: 'partner1',
        children: ['testUser2', 'testUser712', 'testUser210'].map((t, i) => ({ id: 2 + '' + i, name: t }))
    },
    {
        id: 3, role: 'School Coordinator', name: 'coordinator1',
        children: ['testUser3', 'testUser812', 'testUser340'].map((t, i) => ({ id: 3 + '' + i, name: t }))
    },
    {
        id: 4, role: 'Testers', name: 'tester1',
        children: ['testUser4', 'testUser412', 'eugene'].map((t, i) => ({ id: 4 + '' + i, name: t }))
    },
    {
        id: 5, role: 'General User', name: 'generaluser1',
        children: ['testUser5', 'testUser512', 'eugene6', 'grayson', 'eugene7'].map((t, i) => ({ id: 5 + '' + i, name: t }))
    }]
    // Reusable Single
    const [single, setSingle] = useState('')
    // Reusable Nested Single
    const [nestedSingle, setNestedSingle] = useState('')
    // Reusable Multiple
    const [multiple, setMultiple] = useState([])
    // Reusable Nested Multiple (Cannot select all from head)
    const [nestedMultiple, setNestedMultiple] = useState([])
    // Reusable Nested Multiple (Can select all from head)
    const [nestedMultiple2, setNestedMultiple2] = useState([])

    const [test, setTest] = useState('')
    const [search, setSearch] = useState('')
    const [open, setOpen] = useState(false)

    const IndividualOptions = ({ child, state, setState }) => {
        const [bgColor, setBgColor] = useState('#FFF')
        const isSelected = child.id === state
        const divStyle = {
            height: 40, display: 'flex', alignItems: 'center', paddingLeft: 20, cursor: 'pointer',
            backgroundColor: isSelected ? 'aliceblue' : bgColor
        }
        return (
            <div style={divStyle} onMouseEnter={() => setBgColor('#F2F2F2')} onMouseLeave={() => setBgColor('#FFF')}
                onClick={() => setState(child.id)}>
                {child.name}
            </div>
        )
    }
    const GroupedOptions = ({ params, children, state, setState }) => {
        const [open, setOpen] = useState(false)
        const [bgColor, setBgColor] = useState('#FFF')
        const containerStyle = {
            backgroundColor: bgColor
        }
        const divStyle = {
            height: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingLeft: 10
        }
        return (
            <div style={containerStyle}>
                <div style={divStyle} onMouseEnter={() => setBgColor('#F2F2F2')} onMouseLeave={() => setBgColor('#FFF')}>
                    {params.group}
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
                <Collapse in={open}>
                    {Boolean(children.length) && children.map((child, i) => (
                        <IndividualOptions key={i} child={child} state={state} setState={setState} />
                    ))}
                </Collapse>
            </div>
        )
    }
    const onChangeTest = value => {
        console.log({ value })
        console.log(groupedOptions.find(g => g.id === value).name)
        setTest(value)
        setSearch(groupedOptions.find(g => g.id === value).name)
    }
    const nestedTest = {
        value: test,
        // onChange: (e, n) => setTest(n),
        inputValue: search,
        onInputChange: (e, n) => {
            setSearch(n)
            if (n === '' && e !== null) setTest('')
        },
        options: groupedOptions,
        groupBy: o => o.role,
        getOptionLabel: option => groupedOptions.find(g => g.id === option)?.name || '',
        renderGroup: (params) => {
            return <GroupedOptions key={params.key} params={params}
                children={groupedOptions.filter(g => g.role === params.group)}
                state={test} setState={onChangeTest} />
        },
        filterOptions: (options, state) => {
            return options.filter(o => o.name.toLowerCase().includes(state.inputValue.toLowerCase()) ||
                o.role.toLowerCase().includes(state.inputValue.toLowerCase()))
        },
        open: open, onClose: () => setOpen(false), onOpen: () => setOpen(true),
        disableCloseOnSelect: true, selectOnFocus: true, disableClearable: true, forcePopupIcon: false
    }
    const resetAllAutocomplete = () => {
        setSingle('')
        setNestedSingle('')
        setMultiple([])
        setNestedMultiple([])
        setNestedMultiple2([])

        setTest('')
        setSearch('')
    }

    const textFieldAttributes = {
        variant: 'standard',
        placeholder: 'Nested Test Once Again'
    }
    const textFieldInputProps = {
        disableUnderline: true,
        hiddenLabel: true,
        style: {
            height: 60,
            borderRadius: 12,
            border: '1px solid'
        },
        startAdornment: <SearchIcon style={{}} />,
        endAdornment: <IconButton onClick={() => setOpen(!open)} style={{}}>
            {open ? <ExpandLessIcon style={{}} /> : <ExpandMoreIcon style={{}} />}
        </IconButton>
    }
    const { width: owo, height } = useWindowDimensions()
    const [width, setWidth] = useState(0)
    const ref = useRef(null)
    useEffect(() => {
        setWidth(ref.current.getBoundingClientRect().width);
    })

    function sleep(delay = 0) {
        return new Promise((resolve) => {
            setTimeout(resolve, delay);
        });
    }
    const [open5, setOpen5] = useState(false);
    const [options, setOptions] = useState([]);
    const loading = open5 && options.length === 0
    useEffect(() => {
        let active = true;
        if (!loading) {
            return undefined;
        }
        (async () => {
            await sleep(1000); // For demo purposes.
            if (active) setOptions([...top100Films2])
        })();

        return () => {
            active = false;
        };
    }, [loading])
    useEffect(() => {
        if (!open5) {
            setOptions([]);
        }
    }, [open5])
    const [value, setValue] = useState('uwu')
    const movieNotFound = value => !top100Films.map(t => t.name).includes(value)

    const [notifs, setNotifs] = useState([])
    const scrollToTop = () => window.scrollTo(0, 0)
    return (
        <Box style={{ flexGrow: 1 }}>
            {JSON.stringify(owo)}<br />
            ref width: {width}<br />
            {JSON.stringify(notifs)}
            <div style={{ marginTop: 60, border: '1px solid red', width: owo * .5 }}>
                ReusableAutocomplete(Use string throughout, find id at the end or something): {JSON.stringify({ value })}
                <ReusableAutocomplete type='table' state={value} setState={setValue} width={600} freeSolo
                    options={top100Films.slice(0, 11).map(t => ({ id: t.id, option: t.name }))}
                    errorFunction={movieNotFound} btnBgColor='#144A94' borderColor='#707070' />
                {!movieNotFound(value) &&
                    <h3>Movie is {value}, id {top100Films.find(t => t.name === value).id}</h3>}
                Test Async Loading Options:
                <Autocomplete
                    id="asynchronous-demo"
                    sx={{ width: 300 }}
                    open={open5}
                    onOpen={() => {
                        setOpen5(true);
                    }}
                    onClose={() => {
                        setOpen5(false);
                    }}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    getOptionLabel={(option) => option.title}
                    options={options}
                    loading={loading}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Asynchronous"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                        />
                    )}
                />
                Nested Test Once Again: {JSON.stringify({ test, search })}
                <Autocomplete {...nestedTest} ref={ref} style={{ width: 520 }}
                    renderInput={params => <TextField {...params} {...textFieldAttributes}
                        InputProps={{
                            ...params.InputProps,
                            ...textFieldInputProps
                        }}
                        inputProps={{ ...params.inputProps, style: { marginLeft: 20 } }}
                    />} />
                Reusable Single: {JSON.stringify({ single })}
                <ReusableAutocompleteWithID type='default' width={600} height={60} placeholder='Reusable Single' bgColor='#F2F2F2'
                    state={single} setState={setSingle} btnBgColor='#144A94' borderColor='#707070' required
                    options={top100Films.map(t => ({ id: t.id, option: t.name }))} />
                Reusable Nested Single: {JSON.stringify({ nestedSingle })}
                <ReusableAutocompleteWithID type='default' width={600} height={60} placeholder='Reusable Nested Single'
                    state={nestedSingle} setState={setNestedSingle} btnBgColor='#144A94' borderColor='#707070' nested allowSelectHead
                    options={partnerOrTeacherOptions.map(p => ({
                        id: p.id, role: p.role, option: p.name,
                        children: p.children.map(c => ({ id: c.id, role: c.role, option: c.name }))
                    }))} />
                Reusable Multiple: {JSON.stringify(multiple)}
                <ReusableAutocompleteWithID type='multiple' width={600} height={60} placeholder='Multiple'
                    state={multiple} setState={setMultiple} btnBgColor='#144A94' borderColor='#707070'
                    options={top100Films.map(t => ({ id: t.id, option: t.name }))} limitMultiple={3} />
                Reusable Nested Multiple (Cannot select all from head): {JSON.stringify({ nestedMultiple })}
                <ReusableAutocompleteWithID type='multiple' width={600} height={60} placeholder='Reusable Nested Multiple (Cannot select all from head)'
                    state={nestedMultiple} setState={setNestedMultiple} btnBgColor='#144A94' borderColor='#707070' nested
                    options={domainAndTopic.map(p => ({
                        id: p.id, option: p.name,
                        children: p.children.map(c => ({ id: c.id, option: c.name }))
                    }))} limitMultiple={3} allowSelectHead />
                Reusable Nested Multiple (Can select all from head): {JSON.stringify({ nestedMultiple2 })}
                <ReusableAutocompleteWithID type='multiple' width={600} height={60} placeholder='Reusable Nested Multiple (Can select all from head)'
                    state={nestedMultiple2} setState={setNestedMultiple2} btnBgColor='#144A94' borderColor='#707070' nested
                    options={partnerOrTeacherOptions.map(p => ({
                        id: p.id, role: p.role, option: p.name,
                        children: p.children.map(c => ({ id: c.id, role: c.role, option: c.name }))
                    }))} limitMultiple={3} allowSelectHead selectAll />
            </div>
            {/* <SortableGrpList level={0} items={a} axis='x' onSortEnd={onSortA} />
            <h1>{JSON.stringify(a)}</h1>
            <SortableList items={itemsContainer.map((item, index) => <SortableList items={item} axis='x'
                key={index} onSortEnd={index ? (e) => onSortEnd2(e) : (e) => onSortEnd(e)} />)}
                onSortEnd={(e) => onSortEnd3(e, 'owo')} axis='x' />
            <h1>{JSON.stringify(itemsContainer)}</h1>
            <Grid container direction="column" sx={{ mx: 5 }}>
                <CustomSelectMultiple data={users} state={select} setState={setSelect} width={800} outerKey={Object.keys(users[0])[0]}
                    innerKey={Object.keys(users[0])[1]} />
                <Grid container justifyContent="flex-end" sx={{ pr: 10 }}>
                    {JSON.stringify(select)}
                </Grid>
            </Grid> */}
            {/* <NunitoText value='Participants' fontSize={40} fontWeight={500} italic color='darkblue' />
            <NunitoText value='Schools' fontSize={40} fontWeight={500} color='green' />
            <ReusableDatePicker type='type1' width={250} state={value} setState={setValue} /> */}
            {/* {JSON.stringify(value)}
            {value && JSON.stringify(value.toUTCString())} */}
            {/* input z value: {JSON.stringify(inputZ)}<br />
            z value: {JSON.stringify(z)}<br />
            <Autocomplete freeSolo
                value={z} onChange={(e, newValue) => setZ(newValue)}
                inputValue={inputZ} onInputChange={(e, newValue) => {
                    setInputZ(newValue)
                    if (newValue === '') {
                        setZ('')
                    }
                }}
                options={zOptions.map(o => o.id)}
                getOptionLabel={option => zOptions.find(o => o.id === option)?.option || ''}
                open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)}
                selectOnFocus disableClearable style={textFieldStyle} forcePopupIcon={false}
                renderInput={(params) => {
                    return (
                        <TextField {...params} variant="standard" placeholder='Test'
                            InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                                hiddenLabel: true,
                                // style: textFieldStyle,
                                endAdornment: <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                    {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                                </IconButton>
                            }}
                            inputProps={{ ...params.inputProps, style: inputStyle }}
                        />
                    )
                }}
            /> */}
            {/* <FormControl style={formControlStyle}>
                <InputLabel style={{ backgroundColor: 'honeydew' }}>Age</InputLabel>
                <Select
                    style={selectStyle}
                    value={age}
                    label="Age"
                    onChange={handleChange}
                >
                    <MenuItem value={10}>Ten</MenuItem>
                    <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem>
                </Select>
            </FormControl><br /> */}
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>

                    <p>
                        <b>Value of Dates</b> {JSON.stringify(dates)}
                    </p>
                    <p>
                        <b>Dates</b> {JSON.stringify(dates.map(d => new Date(d)))}
                    </p>
                    <p>
                        <b>Locale</b> {JSON.stringify(dates.map(d => new Date(d).toLocaleString()))}
                    </p>
                    <p>
                        <b>Sorted Dates</b> {JSON.stringify(dates.sort((a, b) => a - b).map(a => formatDateTime(a, '/')))}
                    </p>
                </div>
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div>
                    <p>
                        <b>Value of Date</b> {JSON.stringify(date)}
                    </p>
                    <p>
                        <b>Date</b> {JSON.stringify(new Date(date))}
                    </p>
                    <p>
                        <b>Locale</b> {JSON.stringify(new Date(date).toLocaleString())}
                    </p>
                </div>

            </div>
            <div style={{ border: '1px solid violet', paddingBottom: 700 }}>
                <ReusableDatePicker state={date} setState={setDate} />
                <ReusableDatePicker state={dates} setState={setDates} multiple />
            </div>
            <button style={{ width: 180, height: 50, fontSize: 20, marginRight: 20 }} onClick={() => {
                setDate(new Date())
                setDates([])
            }}>Reset Date</button>
            <button style={{ width: 180, height: 50, fontSize: 20, marginRight: 20 }} onClick={() => resetAllAutocomplete()}
            >Reset Selects</button>
            <button style={{ width: 180, height: 50, fontSize: 20, marginRight: 20 }} onClick={() => scrollToTop()}>
                To Top</button>
        </Box >
    )
}
const partnerOrTeacherOptions = [
    {
        id: 1, role: 'Country Partner', name: 'partner1', parent_userid: null,
        children: ['teacher1', 'teacher512', 'teacher610', 'cher1', 'eugene'].map((t, i) =>
            ({ id: Number(1 + '' + i), role: 'Teacher', name: t, parent_userid: 1 }))
    },
    {
        id: 2, role: 'Country Partner', name: 'partner2', parent_userid: null,
        children: ['teacher2', 'teacher612', 'teacher710', 'cher2', 'jay'].map((t, i) =>
            ({ id: Number(2 + '' + i), role: 'Teacher', name: t, parent_userid: 2 }))
    },
    {
        id: 3, role: 'Country Partner', name: 'partner3', parent_userid: null,
        children: ['teacher3', 'teacher712', 'teacher810', 'cher3', 'jason'].map((t, i) =>
            ({ id: Number(3 + '' + i), role: 'Teacher', name: t, parent_userid: 3 }))
    },
    {
        id: 4, role: 'Country Partner', name: 'partner4', parent_userid: null,
        children: ['teacher4', 'teacher812', 'teacher910', 'cher4', 'dick', 'tim'].map((t, i) =>
            ({ id: Number(4 + '' + i), role: 'Teacher', name: t, parent_userid: 4 }))
    },
    {
        id: 5, role: 'Country Partner', name: 'partner5', parent_userid: null,
        children: []
    }
]
const domainAndTopic = [
    {
        id: 1, name: 'English',
        children: ['Creative Writing', 'Listening', 'Oral'].map((c, i) => ({ id: Number(1 + '' + i), name: c }))
    },
    {
        id: 2, name: 'Chinese',
        children: ['Creative Writing', 'Listening', 'Oral'].map((c, i) => ({ id: Number(2 + '' + i), name: c }))
    },
    {
        id: 3, name: 'Malay',
        children: ['Creative Writing', 'Listening', 'Oral'].map((c, i) => ({ id: Number(3 + '' + i), name: c }))
    },
    {
        id: 4, name: 'Japanese',
        children: ['Creative Writing', 'Listening', 'Oral'].map((c, i) => ({ id: Number(4 + '' + i), name: c }))
    },
    {
        id: 5, name: 'German',
        children: ['Creative Writing', 'Listening', 'Oral'].map((c, i) => ({ id: Number(5 + '' + i), name: c }))
    },
]
const namesForOptions = [
    ['Clark', 'Bruce', 'Barry', 'Diana', 'Hal', 'Oliver'],
    ['Conner', 'Dick', 'Wally', 'Donna', 'John', 'Roy'],
    ['Jonathan', 'Tim', 'Bart', 'Hipo', 'Kyle', 'Thea']
]
let o = 0
const groupedOptions = ['Admin', 'Country Partner', 'Teacher'].map((r, i) => (
    namesForOptions[i].map(n => ({ id: o++, name: n, role: r }))
)).flat()
const top100Films = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003, },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001, },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980, },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002, },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977, },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964, },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983, },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004, },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
].map((t, i) => ({ id: i + 1, name: t.title, year: t.year }))
const top100Films2 = [
    { title: 'The Shawshank Redemption', year: 1994 },
    { title: 'The Godfather', year: 1972 },
    { title: 'The Godfather: Part II', year: 1974 },
    { title: 'The Dark Knight', year: 2008 },
    { title: '12 Angry Men', year: 1957 },
    { title: "Schindler's List", year: 1993 },
    { title: 'Pulp Fiction', year: 1994 },
    { title: 'The Lord of the Rings: The Return of the King', year: 2003, },
    { title: 'The Good, the Bad and the Ugly', year: 1966 },
    { title: 'Fight Club', year: 1999 },
    { title: 'The Lord of the Rings: The Fellowship of the Ring', year: 2001, },
    { title: 'Star Wars: Episode V - The Empire Strikes Back', year: 1980, },
    { title: 'Forrest Gump', year: 1994 },
    { title: 'Inception', year: 2010 },
    { title: 'The Lord of the Rings: The Two Towers', year: 2002, },
    { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { title: 'Goodfellas', year: 1990 },
    { title: 'The Matrix', year: 1999 },
    { title: 'Seven Samurai', year: 1954 },
    { title: 'Star Wars: Episode IV - A New Hope', year: 1977, },
    { title: 'City of God', year: 2002 },
    { title: 'Se7en', year: 1995 },
    { title: 'The Silence of the Lambs', year: 1991 },
    { title: "It's a Wonderful Life", year: 1946 },
    { title: 'Life Is Beautiful', year: 1997 },
    { title: 'The Usual Suspects', year: 1995 },
    { title: 'Léon: The Professional', year: 1994 },
    { title: 'Spirited Away', year: 2001 },
    { title: 'Saving Private Ryan', year: 1998 },
    { title: 'Once Upon a Time in the West', year: 1968 },
    { title: 'American History X', year: 1998 },
    { title: 'Interstellar', year: 2014 },
    { title: 'Casablanca', year: 1942 },
    { title: 'City Lights', year: 1931 },
    { title: 'Psycho', year: 1960 },
    { title: 'The Green Mile', year: 1999 },
    { title: 'The Intouchables', year: 2011 },
    { title: 'Modern Times', year: 1936 },
    { title: 'Raiders of the Lost Ark', year: 1981 },
    { title: 'Rear Window', year: 1954 },
    { title: 'The Pianist', year: 2002 },
    { title: 'The Departed', year: 2006 },
    { title: 'Terminator 2: Judgment Day', year: 1991 },
    { title: 'Back to the Future', year: 1985 },
    { title: 'Whiplash', year: 2014 },
    { title: 'Gladiator', year: 2000 },
    { title: 'Memento', year: 2000 },
    { title: 'The Prestige', year: 2006 },
    { title: 'The Lion King', year: 1994 },
    { title: 'Apocalypse Now', year: 1979 },
    { title: 'Alien', year: 1979 },
    { title: 'Sunset Boulevard', year: 1950 },
    { title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb', year: 1964, },
    { title: 'The Great Dictator', year: 1940 },
    { title: 'Cinema Paradiso', year: 1988 },
    { title: 'The Lives of Others', year: 2006 },
    { title: 'Grave of the Fireflies', year: 1988 },
    { title: 'Paths of Glory', year: 1957 },
    { title: 'Django Unchained', year: 2012 },
    { title: 'The Shining', year: 1980 },
    { title: 'WALL·E', year: 2008 },
    { title: 'American Beauty', year: 1999 },
    { title: 'The Dark Knight Rises', year: 2012 },
    { title: 'Princess Mononoke', year: 1997 },
    { title: 'Aliens', year: 1986 },
    { title: 'Oldboy', year: 2003 },
    { title: 'Once Upon a Time in America', year: 1984 },
    { title: 'Witness for the Prosecution', year: 1957 },
    { title: 'Das Boot', year: 1981 },
    { title: 'Citizen Kane', year: 1941 },
    { title: 'North by Northwest', year: 1959 },
    { title: 'Vertigo', year: 1958 },
    { title: 'Star Wars: Episode VI - Return of the Jedi', year: 1983, },
    { title: 'Reservoir Dogs', year: 1992 },
    { title: 'Braveheart', year: 1995 },
    { title: 'M', year: 1931 },
    { title: 'Requiem for a Dream', year: 2000 },
    { title: 'Amélie', year: 2001 },
    { title: 'A Clockwork Orange', year: 1971 },
    { title: 'Like Stars on Earth', year: 2007 },
    { title: 'Taxi Driver', year: 1976 },
    { title: 'Lawrence of Arabia', year: 1962 },
    { title: 'Double Indemnity', year: 1944 },
    { title: 'Eternal Sunshine of the Spotless Mind', year: 2004, },
    { title: 'Amadeus', year: 1984 },
    { title: 'To Kill a Mockingbird', year: 1962 },
    { title: 'Toy Story 3', year: 2010 },
    { title: 'Logan', year: 2017 },
    { title: 'Full Metal Jacket', year: 1987 },
    { title: 'Dangal', year: 2016 },
    { title: 'The Sting', year: 1973 },
    { title: '2001: A Space Odyssey', year: 1968 },
    { title: "Singin' in the Rain", year: 1952 },
    { title: 'Toy Story', year: 1995 },
    { title: 'Bicycle Thieves', year: 1948 },
    { title: 'The Kid', year: 1921 },
    { title: 'Inglourious Basterds', year: 2009 },
    { title: 'Snatch', year: 2000 },
    { title: '3 Idiots', year: 2009 },
    { title: 'Monty Python and the Holy Grail', year: 1975 },
]