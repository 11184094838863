import { functionError, showWarningSwal } from "./alert"
import store from '../reducers/store'
// import axios from "axios"
import { userLogout, setLanguageOptions, setRoleOptions, setCountryOptions } from '../actions/index'
const headers = {
    'Content-type': 'application/json',
    Accept: 'application/json',
}
// Set Content-type and Accept in headers now, later when setting options in fetch request then set Authorization by
// doing { headers: {...headers, Authorization: `Bearer ${store.getState().user.token}` } }
// I'm setting the Authorization in headers this way because if I defined it in the headers object above, 
// it would use the outdated user.token for the first API call after logging in, which will result in unauthenticated
// const url = 'http://192.168.1.3/laravel-sanctum/public/api'
const url = 'http://cms.adstarks.com/laravel/public/api'
const checkResponse = res => {
    if (res.status === 401) {
        store.dispatch(userLogout())
        store.dispatch(setLanguageOptions([]))
        store.dispatch(setCountryOptions([]))
        store.dispatch(setRoleOptions([]))
        return
    }
}
let status = ''
export const getMethod = (path, params) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/${path}${params}`, {
            headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` }
        }).then(res => {
            console.log(res)
            checkResponse(res)
            status = res.status
            return res.json()
        }).then(data => {
            console.log(data)
            if (status !== 200) {
                showWarningSwal(`Error ${status} for ${`${url}/${path}${params}`}: ${data.message}`)
                resolve([])
                return
            }
            resolve(data.data)
        }).catch(error => {
            console.log(error)
            functionError(`Error at getMethod ${path} ${JSON.stringify(error)}`)
            reject(error)
        })
    })
}
export const postMethod = (path, data) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/${path}`, {
            method: 'POST',
            headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
            body: JSON.stringify(data)
        }).then(res => {
            checkResponse(res)
            status = res.status
            return res.json()
        }).then(data => {
            console.log(data)
            if (![200, 201].includes(status)) {
                if (data.errors === undefined) {
                    showWarningSwal(`Error ${status}: ${data.message}`)
                } else {
                    let msg = ''
                    Object.keys(data.errors).forEach(key => msg += data.errors[key])
                    showWarningSwal(`Error ${status}: ${msg}`)
                }
                resolve(data)
                return
            }
            resolve(data)
        }).catch(error => {
            console.log(error)
            functionError(`Error at postMethod ${path} ${JSON.stringify(error)}`)
            reject(error)
        })
    })
}
export const patchMethod = (path, data, params) => {
    let finalPath = `${url}/${path}`
    let patchOptions = {
        method: 'PATCH',
        headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` }
    }
    if (params !== undefined) finalPath = `${url}/${path}/${params}`
    if (data !== undefined) patchOptions.body = JSON.stringify(data)
    console.log(finalPath)
    return new Promise((resolve, reject) => {
        fetch(finalPath, patchOptions)
            .then(res => {
                checkResponse(res)
                status = res.status
                return res.json()
            }).then(data => {
                console.log(data)
                if (![200, 201].includes(status)) {
                    if (data.errors === undefined) {
                        showWarningSwal(`Error ${status}: ${data.message}`)
                    } else {
                        let msg = ''
                        Object.keys(data.errors).forEach(key => msg += data.errors[key])
                        showWarningSwal(`Error ${status}: ${msg}`)
                    }
                    resolve(data)
                    return
                }
                resolve(data)
            })
            .catch(error => {
                console.log(error)
                functionError(`Error at patchMethod ${path} ${JSON.stringify(error)}`)
                reject(error)
            })
    })
}
export const putMethod = (path, data) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/${path}`, {
            method: 'PUT',
            headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
            body: JSON.stringify(data)
        }).then(res => {
            checkResponse(res)
            return res.json()
        }).then(data => resolve(data))
            .catch(error => {
                console.log(error)
                functionError(`Error at putMethod ${path} ${JSON.stringify(error)}`)
                reject(error)
            })
    })
}
export const deleteMethod = (path, data) => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/${path}`, {
            method: 'DELETE',
            headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` },
            body: JSON.stringify(data)
        }).then(res => {
            checkResponse(res)
            return res.json()
        }).then(data => resolve(data))
            .catch(error => {
                console.log(error)
                functionError(`Error at deleteMethod ${path} ${JSON.stringify(error)}`)
                reject(error)
            })
    })
}
export const login = data => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/login`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data)
        }).then(res => res.json()).then(data => resolve(data))
            .catch(error => {
                console.log(error)
                functionError(`Error at login ${JSON.stringify(error)}`)
                reject(error)
            })
    })
}
export const logout = () => {
    return new Promise((resolve, reject) => {
        fetch(`${url}/user/logout`, {
            headers: { ...headers, Authorization: `Bearer ${store.getState().user.token}` }
        }).then(res => res.json()).then(data => resolve(data)).catch(error => {
            console.log(error)
            functionError(`Error at logout ${JSON.stringify(error)}`)
            reject(error)
        })
    })
}