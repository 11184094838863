import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { patchParticipant } from '../../functions/patchData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
export default function EditParticipant({ afterEditingParticipant, id, participants, cancelEdit }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const user = useSelector(state => state.user)
    const participant = participants.find(p => p.id === id)

    const [name, setName] = useState(participant.name || '')
    const [studentClass, setStudentClass] = useState(participant.class || '')
    const [competition, setCompetition] = useState(1)
    const [country, setCountry] = useState(participant.country_id || '')
    const [taggedUser, setTaggedUser] = useState(participant.parent_userid || '')
    const [school, setSchool] = useState(participant.school_id || '')
    const [grade, setGrade] = useState(participant.grade || '')
    const [tuitionCentre, setTuitionCentre] = useState(participant.tuition_centre_id || '')

    const original = {
        name: participant.name || '', studentClass: participant.class || '', grade: participant.grade || ''
    }
    const current = {
        name, studentClass, grade
    }
    const unChanged = JSON.stringify(original) === JSON.stringify(current)
    const onSubmit = () => {
        if (unChanged) {
            showWarningSwal('Please make some changes before submitting')
            return
        }
        let arr = [
            { name: 'Name', state: name },
            { name: 'Class', state: studentClass },
            { name: 'Grade', state: grade }
        ]
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        let payload = {
            id, name, class: studentClass, grade, competition_id: competition
        }
        console.log(payload)
        patchParticipant(payload).then(d => {
            console.log(d)
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                afterEditingParticipant()
                cancelEdit()
            }
        })
    }
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 400 }}>
            <div>
                <NunitoText value='EDIT PARTICIPANT' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Name*'
                        state={name} setState={setName} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Class'
                        state={studentClass} setState={setStudentClass} />
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Grade*'
                        state={grade} setState={setGrade} btnBgColor='#144A94' borderColor='#707070' required
                        options={gradeOptions.map(g => ({ id: g.id, option: g.name }))} />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Competition*'
                        state={competition} setState={setCompetition} btnBgColor='#144A94' borderColor='#707070' required
                        options={[{ id: 1, option: participant.competition_name }]} grayedOut readOnly />
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country*'
                        state={country} setState={setCountry} btnBgColor='#144A94' borderColor='#707070' required
                        options={[{ id: country, option: participant.country_name }]} grayedOut readOnly />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Partner*'
                        state={taggedUser} setState={setTaggedUser} btnBgColor='#144A94' borderColor='#707070' required
                        options={[{ id: taggedUser, option: participant.partner_name }]} grayedOut readOnly />
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='School'
                        state={school} setState={setSchool} btnBgColor='#144A94' borderColor='#707070'
                        options={[{ id: school, option: participant.school_name }]} grayedOut readOnly />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Tuition Centre'
                        state={tuitionCentre} setState={setTuitionCentre} btnBgColor='#144A94' borderColor='#707070'
                        options={[{ id: tuitionCentre, option: participant.tuition_centre_name }]} grayedOut readOnly />
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}