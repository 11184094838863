import React, { useState, useEffect } from 'react'
import { Grid, ButtonGroup, Button } from '@mui/material'
import AnswerKey from './AnswerKey'
import TitleBar from './general/TitleBar'
import AddPageWrapper from './general/AddPageWrapper'
const typeOptions = [{ id: 1, name: 'MCQ' }, { id: 2, name: 'Input' }]
const structureOptions = [
    { id: 1, name: 'Default' }, { id: 2, name: 'Group' },
    { id: 3, name: 'Sequence' }, { id: 4, name: 'Open' }
]
const layoutOptions = [{ id: 1, name: 'Vertical' }, { id: 2, name: 'Horizontal' }]
const sortingOptions = [{ id: 1, name: 'Fix Order' }, { id: 2, name: 'Random' }]
export default function AnswerSetting({ count, task, update }) {
    const buttonGroupWrapperStyle = {
        border: '1px solid', borderRadius: 5,
        backgroundColor: '#F3F0F4', width: 240, marginRight: 60
    }
    const buttonGroupStyle = { paddingInline: 8, paddingBottom: 10 }
    const titleWrapperStyle = { display: 'flex', justifyContent: 'space-around' }
    const titleUnderlineStyle = { borderBottom: '1px solid' }
    const [expandAnswerSetting, setExpandAnswerSetting] = useState(true)
    const [settingOptions, setSettingOptions] = useState([{ target: 'type', options: typeOptions }])
    const normalStyle = { textTransform: 'none' }
    const selectedStyle = { textTransform: 'none', color: '#E83D4D' }
    useEffect(() => {
        if (task.type === 1) {
            setSettingOptions([
                { target: 'type', options: typeOptions }, { target: 'layout', options: layoutOptions },
                { target: 'structure', options: structureOptions.filter(s => s.id !== 4) },
                { target: 'sorting', options: sortingOptions }
            ])
        } else if (task.type === 2) {
            setSettingOptions([
                { target: 'type', options: typeOptions },
                { target: 'structure', options: structureOptions.filter(s => s.id !== 3) }
            ])
        } else setSettingOptions([typeOptions])
    }, [task.type])
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <TitleBar title='Answer Setting' state={expandAnswerSetting} setState={setExpandAnswerSetting} />
            {expandAnswerSetting && <AddPageWrapper>
                <Grid container style={{ margin: 36 }}>
                    <div style={{ display: 'flex' }}>
                        {settingOptions.map(({ target, options }, index) => (
                            <div key={index} style={buttonGroupWrapperStyle}>
                                <div style={titleWrapperStyle}>
                                    <h2 style={titleUnderlineStyle}>{target.charAt(0).toUpperCase() + target.slice(1)}</h2>
                                </div>
                                <ButtonGroup fullWidth variant="outlined" style={buttonGroupStyle}>
                                    {options.map(({ id, name }, i) => (
                                        <Button key={i} style={id === task[target] ? selectedStyle : normalStyle}
                                            onClick={() => update(count, 'taskText', target, id)}>{name}</Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                    {(task.type === 1 && task.layout !== '' && task.sorting !== '' && task.structure !== '') ||
                        (task.type === 2 && task.structure !== '') ? <AnswerKey count={count} task={task} update={update} /> : null}
                </Grid>
            </AddPageWrapper>}
        </Grid >
    )
}