import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { patchDomain } from '../../functions/patchData'
import { makeSureIsArray } from '../../functions/general'
import { showWarningSwal } from '../../functions/alert'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
export default function EditDomainModal({ afterEditingDomain, domains, id, cancelEdit }) {
    const domain = makeSureIsArray(domains).find(u => u.id === id)
    const [name, setName] = useState(domain.name)
    const placeholder = domain.is_tag ? 'Tag*' : domain.domain_name ? 'Topic*' : 'Domain*'
    const onSubmit = () => {
        if (name === '') {
            showWarningSwal('Please enter something for the field')
            return
        }
        let payload = { id: id, name: name }
        patchDomain(payload).then(d => {
            console.log(d)
            afterEditingDomain()
            cancelEdit()
        })
    }
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 600 }}>
            <div>
                <NunitoText value='EDIT DOMAIN / TAG' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2'
                        placeholder={placeholder} state={name} setState={setName} required />
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}