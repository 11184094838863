const defaultState = {
    loggedIn: false,
    username: '',
    role_id: '',
    user_id: '',
    country_id: '',
    school_id: '',
    parent_id: '',
    token: null
}
const userState = defaultState
const reset = defaultState
const userReducer = (state = userState, action) => {
    switch (action.type) {
        case 'LOG_IN':
            return {
                ...state, ...{
                    loggedIn: action.loggedIn,
                    username: action.username,
                    role_id: action.role_id,
                    user_id: action.user_id,
                    country_id: action.country_id,
                    school_id: action.school_id,
                    parent_id: action.parent_id,
                    token: action.token
                }
            }
        case 'LOG_OUT': return reset
        default: return state
    }
}
export default userReducer