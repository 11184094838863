import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import {
    formatDateTime, makeSureIsArray, makeOptions, ensureObjectOfArraysArePopulated,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { useDispatch } from 'react-redux'
import { resetCompetition } from '../actions'
import '../css/school.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import { getCompetitions, getUsers } from '../functions/getData'
import { addCompetitionPartner } from '../functions/postData'
import { deleteCompetition } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const competitionHeader = [
    { id: 'name', label: 'Competition' },
    // { id: 'description', label: 'Description' },
    { id: 'competition_start_date', label: 'Competition Start' },
    { id: 'competition_end_date', label: 'Competition End' },
    { id: 'partners', label: 'Country Partners' },
    { id: 'format', label: 'Format' },
    // { id: 'rounds', label: 'Rounds' },
    // { id: 'tags', label: 'Tags' },
    // { id: 'levels', label: 'Levels' },
    // { id: 'awards', label: 'Awards' },
    { id: 'status', label: 'Status' }
]
let initialParams = '?'
export const AddCompetitionPartnerModal = ({ setOnAddPartner, setSelected, setSelecting, selected, getData,
    countryOptions, countryPartnerOptions, competitionTable }) => {
    const [country, setCountry] = useState('')
    const [countryPartner, setCountryPartner] = useState('')
    const [regStartDate, setRegStartDate] = useState(new Date())
    const [regEndDate, setRegEndDate] = useState(new Date())
    const [daysBetween, setDaysBetween] = useState(7)
    const [filteredCountryPartnerOptions, setFilteredCountryPartnerOptions] = useState(countryPartnerOptions)
    const onChangeCountry = value => {
        setCountryPartner('')
        setCountry(value)
        setFilteredCountryPartnerOptions(countryPartnerOptions.filter(c => c.country_id === value))
    }
    const onSubmit = () => {
        addCompetitionPartner({
            "competition_id": selected[0],
            "registration_start_date": formatDateTime(regStartDate, '-'),
            "registration_end_date": formatDateTime(regEndDate, '-'),
            "partner_userid": countryPartner
        }).then(d => {
            console.log(d)
            setOnAddPartner(false)
            setSelected([])
            setSelecting([])
            getData('')
        })
    }
    const cancelAddPartner = () => {
        setOnAddPartner(false)
        setSelected([])
        setSelecting([])
    }
    let competition = makeSureIsArray(competitionTable.data).find(c => c.id === selected[0])
    useEffect(() => {
        setRegStartDate(new Date(makeSureIsArray(competitionTable.data).find(c => c.id === selected[0]).registration_start_date))
        setRegEndDate(new Date(makeSureIsArray(competitionTable.data).find(c => c.id === selected[0]).registration_end_date))
    }, [])
    let minCompetitionDate = countryPartner.competitionDates.sort((a, b) => a - b)[0] || competition.competitionStart
    let maxRegisterDate = new Date(minCompetitionDate).setDate(new Date(minCompetitionDate).getDate()
        - Number(daysBetween))
    return (
        <Box className='popUpModal' style={{ width: 800, minHeight: 400 }}>
            <NunitoText value='ADD COUNTRY PARTNER TO COMPETITION' fontSize={30} fontWeight={600} color='#5E75C3'
                marginTop={20} />
            <Grid container alignItems='center' justifyContent='space-evenly' style={{ paddingInline: 40 }}>
                <ReusableAutocompleteWithID type='withLabel' width={320} height={55} btnWidth={63}
                    btnBgColor='#144A94' borderColor='#000' placeholder='Country*' state={country}
                    setState={onChangeCountry} required
                    options={countryOptions.map(r => ({ id: r.id, option: r.display_name }))} />
                {filteredCountryPartnerOptions.length && <ReusableAutocompleteWithID type='withLabel' width={320} height={55} btnWidth={63}
                    btnBgColor='#144A94' borderColor='#000' placeholder='Country Partner*' state={countryPartner}
                    setState={setCountryPartner} required
                    options={filteredCountryPartnerOptions.map(r => ({ id: r.id, option: r.name }))}
                    grayedOut={!country} readOnly={!country} />}
            </Grid>
            <Grid container alignItems='center' justifyContent='space-evenly'>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <NunitoText value='Registration Start Date*' fontSize={14} fontWeight={600} />
                    <ReusableDatePicker width={250} state={regStartDate} setState={setRegStartDate} required
                        minDate={new Date()} />
                </div>
                <ReusableTextField type='withLabel' width={160} placeholder='Day betweens' height={55} bgColor='#FFF' number
                    state={daysBetween} setState={setDaysBetween} borderColor='#707070' />
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                    <NunitoText value='Registration End Date*' fontSize={14} fontWeight={600} />
                    <ReusableDatePicker width={250} state={regEndDate} setState={setRegEndDate} required
                        maxDate={new Date(makeSureIsArray(competitionTable.data).find(c => c.id === selected[0]).competition_end_date)} />
                </div>
            </Grid>
            <Grid container justifyContent='space-around' style={{ marginBottom: 30, paddingInline: 20 }}>
                <ReusableButton text='Cancel' fontSize={20} height={50} width={280} bgColor='#8D8D8D'
                    onClick={() => cancelAddPartner()} />
                <ReusableButton text='Submit' fontSize={20} height={50} width={280} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}
export default function Competitions() {
    document.title = 'Competition'
    const [header, setHeader] = useState(competitionHeader)
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const countryOptions = useSelector(state => state.countryOptions)
    const [competitionTable, setCompetitionTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Format", state: '', key: 'format', header: 'format' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Competition Start", state: true, key: 'competition_start_date' },
        { label: "Competition End", state: true, key: 'competition_end_date' },
        // { label: "Rounds", state: true, key: 'created_by_username' },
        // { label: "Levels", state: true, key: 'created_by_username' },
        // { label: "Awards", state: true, key: 'created_by_username' },
        { label: "Country Partners", state: true, key: 'partners' },
        { label: "Format", state: true, key: 'format' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchCompetition, setSearchCompetition] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddPartner, setOnAddPartner] = useState(false)
    const [countryPartnerOptions, setCountryPartnerOptions] = useState([])
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelAddPartner = () => cancelAction(setOnAddPartner, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteCompetition({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getCompetitions, params, setCompetitionTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(competitionHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchCompetition, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchCompetition, params, setParams, getData)
    }
    const onChangeSearchCompetition = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchCompetition, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        getData(params)
        getUsers('?role_id=2&limits=100').then(u => setCountryPartnerOptions(u.userLists.data))
    }, [])
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one competition to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => dispatch(resetCompetition())} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Competitions' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchCompetition} setState={onChangeSearchCompetition} onBlur />
                    {user.role_id < 2 && <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Competition' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            to='/addcompetition' blank iconType='add' />
                        {user.role_id === 1 && <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                    </div>}
                </Grid>
                <NunitoText value='Search only in "Competition" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Loader height={600} /> : competitionTable && makeSureIsArray(competitionTable.data).length ?
                    <HeavyTable headers={header} list={competitionTable} title='competitions' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} selecting={selecting} setSelecting={setSelecting} onChangePage={onChangePage}
                        selected={selected} setSelected={setSelected} setOnAddUser={setOnAddPartner}
                        countryOptions={countryOptions}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            <Modal open={onAddPartner} onClose={() => cancelAddPartner()}>
                {Boolean(selected.length) && countryOptions && countryPartnerOptions && competitionTable && <AddCompetitionPartnerModal
                    setOnAddPartner={setOnAddPartner} setSelected={setSelected} setSelecting={setSelecting}
                    selected={selected} getData={getData} countryOptions={countryOptions}
                    countryPartnerOptions={countryPartnerOptions} competitionTable={competitionTable} />}
            </Modal>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </Modal>
        </Box>
    )
}