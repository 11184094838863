import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { checkPassword, makeSureIsArray } from '../../functions/general'
import { showWarningSwal, isEmpty, warningMessage } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { getUsers, getSchools } from '../../functions/getData'
import { patchUser } from '../../functions/patchData'
import { useSelector } from 'react-redux'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const organisationOptions = ['organization 1', 'random organization', 'organization 3']
export default function EditUserModal({ afterEditingUser, users, id, cancelEdit }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const editingUser = makeSureIsArray(users).find(u => u.id === id)
    const user = useSelector(state => state.user)
    const [name, setName] = useState(editingUser?.name || '')
    const [role, setRole] = useState(editingUser?.role_id || '')
    const [username, setUsername] = useState(editingUser?.username || '')
    const [password, setPassword] = useState(editingUser?.password || '')
    const [confirmPassword, setConfirmPassword] = useState(editingUser?.password || '')
    const [organization, setOrganization] = useState(editingUser?.organization_id || '')
    const [email, setEmail] = useState(editingUser?.email || '')
    const [school, setSchool] = useState(editingUser?.school_id || '')
    const [country, setCountry] = useState(editingUser?.country_id || '')
    const [partner, setPartner] = useState(editingUser?.parent_id || '')

    const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)

    const countryOptions = useSelector(state => state.countryOptions)
    const roleOptions = useSelector(state => state.roleOptions)
    const [partnerOptions, setPartnerOptions] = useState([])
    useEffect(() => {
        if (editingUser.role_id === 3) {
            getUsers(`?status=active&role_id=2&country_id=${country}&limits=100`).then(d => {
                setPartnerOptions(d.userLists.data.filter(u => u.id === editingUser.parent_id)
                    .map(u => ({ id: u.id, name: u.name })))
            })
            getSchools(`?status=active&country_id=${country}&limits=100&search=${editingUser.school_name}`).then(d => {
                setTeacherBelongsToTuitionCentre(makeSureIsArray(d.SchoolLists.data).find(s => s.id === editingUser.school_id).private)
            })
        }
    }, [])
    const original = {
        name: editingUser.name || '', username: editingUser.username || '', email: editingUser.email || ''
    }
    const current = {
        name, username, email
    }
    const unChanged = JSON.stringify(original) === JSON.stringify(current)
    const onSubmit = () => {
        if (unChanged) {
            showWarningSwal('Please make some changes before submitting')
            return
        }
        let arr = [
            { name: 'Full Name', state: name },
            { name: 'Username', state: username },
            { name: 'Role', state: role }
        ]
        if (role === 2) arr.push({ name: 'Organization', state: organization })
        if (user.role_id === 1 && role !== 1) arr.push({ name: 'Country', state: country })
        if (role === 3) {
            arr.push({ name: teacherBelongsToTuitionCentre ? 'Tuition Centre' : 'School', state: school })
            if (user.role_id === 1) arr.push({ name: 'Partner', state: partner })
        }
        arr.push({ name: 'E-Mail', state: email })
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        if (password !== '') {
            if (checkPassword(password)) {
                showWarningSwal('Password does not meet requirement')
                return
            }
            if (password !== confirmPassword) {
                showWarningSwal('Password and Confirm Password must match')
                return
            }
        }
        let payload = {
            id, // not required if edit own info
            name, username, about: "xxxEdited", organization
        }
        if (email !== editingUser.email) payload.email = email
        if (password !== '') {
            payload.password = password
            payload.password_confirmation = confirmPassword
        }
        console.log(payload)
        console.log(JSON.stringify(payload))
        patchUser(payload).then(d => {
            console.log(d)
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                cancelEdit()
                afterEditingUser()
            }
        })
    }
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 600 }}>
            {/* {JSON.stringify(editingUser)}<br/>{JSON.stringify(partnerList)} */}
            <div>
                <NunitoText value='EDIT USER' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Full Name*'
                        state={name} setState={setName} required={!name} />
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#000' placeholder='Role' state={role} setState={setRole} grayedOut readOnly
                        options={roleOptions.map(r => ({ id: r.id, option: r.name }))} />
                </Grid>
                {role === 3 ? <Grid container alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                        state={username} setState={setUsername} required divMarginLeft={110} />
                    <NunitoText value={`Teacher belongs to a ${teacherBelongsToTuitionCentre ? 'tuition centre' : 'school'}`}
                        fontSize={20} marginLeft={110} />
                </Grid> : <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                        state={username} setState={setUsername} required />
                    {role === 2 ? <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#000' placeholder='Organization' state={organization} setState={setOrganization}
                        options={organisationOptions.map((o, i) => ({ id: i + 1, option: o }))} grayedOut readOnly /> :
                        <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                            state={email} setState={setEmail} required />}
                </Grid>}
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 10 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Password'
                        state={password} setState={setPassword} />
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Confirm Password'
                        state={confirmPassword} setState={setConfirmPassword}
                        required={password !== '' && (checkPassword(password) || password !== confirmPassword)} />
                </Grid>
                {password !== '' && <>
                    <NunitoText value='Minimum 8 characters with one uppercase and lowercase letter and 1 of the following 
                letters: "!$#%@"'  fontSize={16} fontWeight={400} marginTop={10} color={checkPassword(password) && '#E83D4D'} />
                    <NunitoText value='Password must match confirm password' fontSize={16} fontWeight={400} marginTop={10}
                        color={(checkPassword(password) || password !== confirmPassword) && '#E83D4D'} />
                </>}
                {role === 3 && <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                        state={email} setState={setEmail} required />
                    {[2, 3].includes(role) && user.role_id === 1 ? <ReusableAutocompleteWithID type='withLabel'
                        width={435} height={55} btnWidth={63} btnBgColor='#144A94' borderColor='#000' placeholder='Country*'
                        state={country} setState={setCountry} grayedOut readOnly
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} required /> :
                        role === 3 && <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63}
                            btnBgColor='#144A94' borderColor='#000'
                            placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre' : 'School'} state={school} setState={setSchool}
                            options={[{ id: editingUser?.school_id, option: editingUser?.school_name }]}
                            grayedOut readOnly />}
                </Grid>}
                {role === 3 && user.role_id === 1 && <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63}
                        btnBgColor='#144A94' borderColor='#000' state={school} setState={setSchool}
                        placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'}
                        options={[{ id: editingUser?.school_id, option: editingUser?.school_name }]}
                        required grayedOut readOnly />
                    {user.role_id === 1 && <ReusableAutocompleteWithID type='withLabel' width={435} height={55}
                        btnWidth={63} btnBgColor='#144A94' borderColor='#000' placeholder='Country Partner*' state={partner}
                        setState={setPartner} options={partnerOptions.map(p => ({ id: p.id, option: p.name }))}
                        required grayedOut readOnly />}
                </Grid>}
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => afterEditingUser()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}