import React, { useState } from 'react';
import { Link as Linking, Redirect } from 'react-router-dom'
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography, Card, CardMedia, styled } from '@mui/material';
import img from '../images/signin2.jpg'
import topimg from '../images/signintop.png'
import '../css/signin.css'
import '../css/global.css'
const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: #FFF;
    border: 1px solid #000;
  }`
export default function ForgotPassword() {
    document.title = 'Forgot Password'
    const [email, setEmail] = useState('')
    const [resetted, setResetted] = useState(false)
    const emailSent = () => {
        fetch(`http://localhost:5000/user?email=${email}`)
            .then(res => res.json())
            .then((data) => {
                if (data.length) {
                    alert(`Email has been sent to ${email}`)
                    setResetted(true)
                    return
                }
                alert(`Email ${email} is not found in the user base, please register an account`)
                setResetted(false)
            })
            .catch((error) => {
                alert(`Error at emailSent: ${JSON.stringify(error)}`)
            })
    }
    return (
        <Grid container>
            {resetted && <Redirect to='/signin' />}
            <Grid item xs={6} sx={{
                backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat',
                backgroundColor: '#FFFFFF', backgroundSize: '80%', backgroundPosition: 'center'
            }} />
            <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 10.1 }} >
                    <Card>
                        <CardMedia component='img' image={topimg} />
                    </Card>
                    <Typography variant='h4' id='resetTitle' sx={{ mt: 5 }}>
                        Reset Password
                    </Typography>
                    <Box sx={{ mt: 5, width: 800 }}>
                        <Grid container direction="column" alignItems="center">
                            <StyledTextField
                                variant="filled"
                                margin="normal"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                autoComplete="email"
                                autoFocus
                                InputProps={{
                                    style: { borderRadius: 12, width: 557 },
                                    disableUnderline: true,
                                    hiddenLabel: true,
                                }}
                                inputProps={{ style: { backgroundColor: '#FFF', borderRadius: 12, height: 32, fontSize: 28 } }}
                            />
                            <div style={{ width: 557 }}>
                                <Typography variant='subtitle1'>
                                    Please enter the email you used to sign up. A password reset link will be sent to you.
                                </Typography>
                            </div>
                        </Grid>
                        <Grid container direction="column" alignItems="center">
                            <Button type="submit" onClick={() => emailSent()} variant="contained" id='submitBtn'>
                                Confirm
                            </Button>
                            <Grid item xs={12} sx={{ mt: 10, color: '#868484', fontSize: 20 }}>
                                <Linking to='/signup' className='signup'>
                                    Sign Up
                                </Linking> here to begin your epic journey with us!
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 2, color: '#868484', fontSize: 20 }}>
                                <Linking to='/signin' className='signup'>
                                    Login
                                </Linking> here to continue your epic journey with us!
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}