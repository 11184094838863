import React, { useState } from 'react'
import { Link } from "react-router-dom"
import {
    Grid, IconButton, Checkbox, Table, TableRow, TableHead, TableCell, TableContainer, TableBody, Paper, Chip, Modal, Box
} from '@mui/material'
import { useSelector } from 'react-redux'
import AddIcon from '@mui/icons-material/Add'
import FileCopyIcon from '@mui/icons-material/FileCopy'
import BuildIcon from '@mui/icons-material/Build'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import DeleteIcon from '@mui/icons-material/Delete'
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash'
import CheckIcon from '@mui/icons-material/Check'
import CancelIcon from '@mui/icons-material/Cancel'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import SpellcheckIcon from '@mui/icons-material/Spellcheck'
import LanguageIcon from '@mui/icons-material/Language'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { FiEdit } from 'react-icons/fi'
import { IoPersonAdd } from 'react-icons/io5'
import { IoIosCopy } from 'react-icons/io'
import { FaUserSlash, FaUserCheck, FaWindowClose } from 'react-icons/fa'
import { swapIndex } from '../../functions/patchData'
import { formatDate, formatDateTimeForDisplay, makeSureIsArray, getDateDiff, dateHasPassed } from '../../functions/general'
import NunitoText from '../general/NunitoText'
import ReusableSelect from '../general/ReusableSelect'
import LightTooltip from '../general/LightTooltip'
import useWindowDimensions from '.././WindowDimensions'
function descendingComparator(a, b, orderBy) {
    // If type is string, make it case insensitive by lowercasing everything
    // If type if object, compare by length
    let c, d
    if (typeof a[orderBy] === 'object') {
        c = a[orderBy] !== undefined && a[orderBy] !== null ? a[orderBy].length : a[orderBy]
        d = b[orderBy] !== undefined && b[orderBy] !== null ? b[orderBy].length : b[orderBy]
    } else if (typeof a[orderBy] === 'string') {
        c = a[orderBy] !== undefined && a[orderBy] !== null ? a[orderBy].toLowerCase() : a[orderBy]
        d = b[orderBy] !== undefined && b[orderBy] !== null ? b[orderBy].toLowerCase() : b[orderBy]
    } else {
        c = a[orderBy]
        d = b[orderBy]
    }
    // c = typeof a[orderBy] === 'string' ? a[orderBy].toLowerCase() : a[orderBy]
    // d = typeof b[orderBy] === 'string' ? b[orderBy].toLowerCase() : b[orderBy]
    if (d === c) return 0
    else if (c === null || c === undefined) return 1
    else if (d === null || d === undefined) return -1
    else if (d < c) return -1
    else if (d > c) return 1
}
function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}
function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead({ handleRequestSort, headers }) {
    // Use position sticky to ensure the first column's position is fixed when the table is scrolled horizontally
    const tableHeadStyle = index => ({
        fontSize: 20, fontWeight: 'bolder', color: '#144A94', cursor: 'pointer', height: 61,
        zIndex: index === 0 && 999,
        backgroundColor: '#FFF',
        position: 'sticky', left: 0, backgroundClip: 'padding-box'
    })
    const tableCellDivStyle = {
        display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'
    }
    const arrowWrapperStyle = { display: 'flex', flexDirection: 'column' }
    const arrowStyle = { color: '#000', position: 'relative' }
    return (
        <TableHead>
            <TableRow>
                {headers.map((header, index) => (
                    <TableCell key={index} style={tableHeadStyle(index)} onClick={() => handleRequestSort(header.id)}
                    // sortDirection={orderBy === header.id ? order : false}
                    >
                        <div style={tableCellDivStyle}>
                            {header.label}
                            <div style={arrowWrapperStyle}>
                                <ArrowDropUpIcon style={{ ...arrowStyle, top: 8 }} />
                                <ArrowDropDownIcon style={{ ...arrowStyle, bottom: 8 }} />
                            </div>
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export const PaginationButton = ({ isSelected, label, onChangePage, active, setSelected, setSelecting, url }) => {
    const buttonStyle = {
        backgroundColor: isSelected && '#144A94', borderRadius: 32, width: 32,
        marginInline: 8, height: 32, cursor: url && !active && 'pointer',
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    }
    const labelStyle = { color: isSelected ? '#FFF' : '#000', fontWeight: 'normal' }
    const onClick = label => {
        setSelected([])
        setSelecting([])
        onChangePage(label)
    }
    return (
        <div style={buttonStyle} onClick={() => url && !active && onClick(label)}>
            <h4 style={labelStyle}>{label}</h4>
        </div>
    )
}
export const Pagination = ({ list, title, rowsPerPage, onChangePage, setSelected, setSelecting }) => {
    if (rowsPerPage >= 1) {
        const pageChange = url => {
            setSelected([])
            setSelecting([])
            onChangePage(url.substring(url.indexOf('?') + 6))
        }
        return (
            <Grid container justifyContent="flex-end" alignItems="center" sx={{ mt: 6, mb: 6 }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {title !== 'qqweqw' ? list.links.map(({ url, label, active }, i) => {
                        if (label.includes('Previous')) {
                            return <IconButton key={i} disabled={!url} onClick={() => pageChange(url)}>
                                <NavigateBeforeIcon />
                            </IconButton>
                        } else if (label.includes('Next')) {
                            return <IconButton key={i} disabled={!url} onClick={() => pageChange(url)}>
                                <NavigateNextIcon />
                            </IconButton>
                        } else {
                            return <PaginationButton key={i} isSelected={active} label={label} onChangePage={onChangePage}
                                active={active} setSelected={setSelected} setSelecting={setSelecting} url={url} />
                        }
                    }) :
                        <>
                            <h3>owo</h3>
                        </>}
                </div>
            </Grid>
        )
    } return null
}
export default function HeavyTable({ headers, list, title, rowsPerPage, onChangePage,
    setOnDelete, selecting, setSelecting, selected, setSelected, filtered,
    setOnMerge, setOnApprove, setOnReject, setOnRestore, setOnEdit, setOnModerateLanguages, setOnManagePermission, setOnAddUser,
    setOnAddParticipant, setOnEnable, setOnDisable, countryOptions }) {
    const getters = ['id', ...headers.map(h => h.id)]
    const data = makeSureIsArray(list.data)
    const [order, setOrder] = useState('asc')
    const [orderBy, setOrderBy] = useState('id')
    const user = useSelector(state => state.user)
    const handleRequestSort = property => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    const handleSelectAllClick = event => {
        if (event.target.checked) {
            setSelected(stableSort(data, getComparator(order, orderBy)).map(d => d.id))
            setSelecting(stableSort(data, getComparator(order, orderBy)).map(d => d[getters[1]]))
            return
        }
        setSelected([])
        setSelecting([])
    }
    const handleClick = (id, name) => {
        const selectedIndex = selected.indexOf(id)
        let newSelected = []
        let newSelecting = []
        if (selectedIndex === -1) {
            // If cannot find
            newSelected = newSelected.concat(selected, id)
            newSelecting = newSelecting.concat(selecting, name)
        } else if (selectedIndex === 0) {
            // If first
            newSelected = newSelected.concat(selected.slice(1))
            newSelecting = newSelecting.concat(selecting.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            // If last
            newSelected = newSelected.concat(selected.slice(0, -1))
            newSelecting = newSelecting.concat(selecting.slice(0, -1))
        } else if (selectedIndex > 0) {
            // If in between
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            )
            newSelecting = newSelecting.concat(
                selecting.slice(0, selectedIndex),
                selecting.slice(selectedIndex + 1),
            )
        }
        setSelected(newSelected)
        setSelecting(newSelecting)
    }
    const handleShift = id => {
        let sortedData = stableSort(data, getComparator(order, orderBy))
        // Find the earliest occurence in sortedData whr it is also in selected
        let firstToLast = sortedData.filter(s => selected.includes(s.id)).map(s => s.id)
        let [selectingIndex, firstIndex, lastIndex] = [sortedData.findIndex(d => d.id === id),
        sortedData.findIndex(d => d.id === firstToLast[0]), sortedData.findIndex(d => d.id === firstToLast.slice(-1)[0])]
        if (selectingIndex >= firstIndex && selectingIndex <= lastIndex) {
            // If clicked between(inclusive of first and last), just check/uncheck the selected row
            // By right it should have its own set of behaviour(If u test by shift clicking around in file explorer)
            // But for now just leave it like this first because shift click is not required function
            handleClick(id, sortedData[selectingIndex][getters[1]])
            return
        }
        let newSelected = selected
        let newSelecting = selecting
        if (selectingIndex < firstIndex) {
            // If shift click from the front, check all until the last row
            for (let i = selectingIndex; i <= lastIndex; i++) {
                if (!newSelected.includes(sortedData[i].id)) {
                    newSelected = [...newSelected, sortedData[i].id]
                    newSelecting = [...newSelecting, sortedData[i][getters[1]]]
                }
            }
        } else if (selectingIndex > lastIndex) {
            // If shift click from the back, check all from start till selected row
            for (let i = firstIndex; i <= selectingIndex; i++) {
                if (!newSelected.includes(sortedData[i].id)) {
                    newSelected = [...newSelected, sortedData[i].id]
                    newSelecting = [...newSelecting, sortedData[i][getters[1]]]
                }
            }
        }
        setSelected(newSelected)
        setSelecting(newSelecting)
    }
    const handleSelectClick = (event, id, name) => event.shiftKey && selected.length ? handleShift(id) : handleClick(id, name)
    const isSelected = id => selected.indexOf(id) !== -1
    const EnhancedHover = ({ getter, data, type, structure, isObject, output }) => {
        if (isObject) {
            const titleDivStyle = {
                backgroundColor: '#5E75C3', border: '1px solid #707070', borderRadius: 6,
                paddingInline: 10, minWidth: 120
            }
            const languageColor = status => status === 'active' ? '#000' : status === 'pending translation' ? '#F3797E' : 'orange'
            switch (getter) {
                case 'languages':
                case 'competition_date':
                case 'teachers':
                case 'collections':
                case 'competitions':
                case 'difficulty':
                case 'topic':
                case 'tags':
                case 'users':
                    return (
                        data &&
                        <div>
                            <div style={titleDivStyle}>
                                <NunitoText value={getter.charAt(0).toUpperCase() + getter.slice(1)} fontSize={15} fontWeight={400}
                                    italic color='#FFF' />
                            </div>
                            {data.map((d, index) => {
                                switch (getter) {
                                    case 'teachers':
                                        return <NunitoText key={index} value={d.name || ''} color='#000' align='left' />
                                    case 'languages':
                                        return <NunitoText key={index}
                                            value={`${d.name} (${d.status.charAt(0).toUpperCase()}${d.status.slice(1)})` || ''}
                                            color={languageColor(d.status)} align='left' />

                                    default: return <NunitoText key={index} value={d || ''} color='#000' align='left' />
                                }
                            })}
                        </div>
                    )
                case 'grade_difficulty':
                case 'partners':
                case 'awards':
                case 'levels':
                case 'noOfQuestions':
                case 'noOfSection':
                    let getters = []
                    if (data.length) {
                        switch (getter) {
                            case 'partners':
                                getters = [{ getter: 'country_id', title: 'Country' }, { getter: 'name', title: 'Partner' }]
                                break;
                            case 'grade_difficulty':
                                getters = [{ getter: 'grade', title: 'Grade' }, { getter: 'difficulty', title: 'Difficulty' }]
                                break;
                            default:
                                getters = Object.keys(data[0]).map(o =>
                                    ({ getter: o, title: `${o.charAt(0).toUpperCase()}${o.slice(1)}` }))
                                break;
                        }
                    }
                    return (
                        <Grid container>
                            {getters.map(({ getter, title }, i) => {
                                return (
                                    <div key={i} style={{ marginRight: i < getters.length - 1 && 30 }}>
                                        <div style={titleDivStyle}>
                                            <NunitoText value={`${title.charAt(0).toUpperCase()}${title.slice(1)}` || ''}
                                                fontSize={18} fontWeight={400} italic color='#FFF' />
                                        </div>
                                        {data.map((d, i2) => {
                                            let value = d[getter].toString()
                                            if (getter === 'country_id') value = countryOptions.find(c => c.id === d[getter]).display_name
                                            return (
                                                <NunitoText key={`${i} -${i2}`} value={value || ''} fontSize={16} color='#000' />
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Grid>
                    )
                case 'answers':
                    if (type === 'MCQ') {
                        if (structure === 'Sequence') {
                            return (
                                data && <div style={{ minWidth: 200 }}>
                                    <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>MCQ</h1>
                                    <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Sequence correct answers</h2>
                                    <div style={{
                                        display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Order</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.order}</h2>
                                            ))}
                                        </div>
                                        <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Answers</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                            ))}
                                        </div>
                                        {filtered.Collection !== '' && <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Points</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                            ))}
                                        </div>}
                                    </div>
                                </div>
                            )
                        } else {
                            return (
                                data && <div style={{ minWidth: 180 }}>
                                    <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>MCQ</h1>
                                    <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>{structure === 'Default' ? 'Single/Multiple' : structure} correct answers</h2>
                                    <div style={{
                                        display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                        justifyContent: 'space-between'
                                    }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Answers</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                            ))}
                                        </div>
                                        {filtered.Collection !== '' && <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Points</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                            ))}
                                        </div>}
                                    </div>
                                </div>
                            )
                        }
                    } else {
                        if (structure === 'Open') {
                            return (
                                data && <div style={{ minWidth: 180 }}>
                                    <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Input</h1>
                                    <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Open</h2>
                                    <div style={{
                                        display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Field Label</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.fieldLabel}</h2>
                                            ))}
                                        </div>
                                        {filtered.Collection !== '' && <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Points</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                            ))}
                                        </div>}
                                    </div>
                                </div>

                            )
                        } else {
                            return (
                                data && <div style={{ minWidth: 280 }}>
                                    <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Input</h1>
                                    <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>In sequence answers{structure === 'Default'
                                        ? ' with different points per field' : ', with fixed points'}</h2>
                                    <div style={{
                                        display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                        justifyContent: 'space-evenly'
                                    }}>
                                        <div style={{ textAlign: 'center' }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Field Label</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.fieldLabel}</h2>
                                            ))}
                                        </div>
                                        <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Answers</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                            ))}
                                        </div>
                                        {filtered.Collection !== '' && <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                            <h2 style={{
                                                margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                                borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                                paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                            }}>
                                                Points</h2>
                                            {data.map((d, index) => (
                                                <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                            ))}
                                        </div>}
                                    </div>
                                </div>
                            )
                        }
                    }
                default: return JSON.stringify({ getter, data })
            }
        } else return output
    }
    const openRowModal = (name, id, setOpenModal) => {
        setOpenModal(true)
        setSelecting([name])
        setSelected([id])
    }
    const Btns = ({ title, row, getter }) => {
        let icons = []
        switch (title) {
            case 'schools':
                icons = [
                    user.role_id < 3 && { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row[getter], row.id, setOnEdit) },
                    ((user.role_id === 1 && row.status !== 'deleted') ||
                    (user.role_id === 2 && row.status === 'pending')) &&
                    { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) },
                    user.role_id === 1 && row.status === 'deleted' &&
                    { title: 'Restore', item: <RestoreFromTrashIcon />, fn: () => openRowModal(row[getter], row.id, setOnRestore) },
                    user.role_id === 1 && row.status === 'pending' && user.user_id !== row.created_by_userid &&
                    { title: 'Approve', item: <CheckIcon />, fn: () => openRowModal(row[getter], row.id, setOnApprove) },
                    user.role_id < 3 && row.status === 'active' &&
                    { title: 'Add Teacher', item: <AddIcon />, fn: () => openRowModal(row[getter], row.id, setOnAddUser) },
                    row.status === 'active' &&
                    { title: 'Add Participant', item: <IoPersonAdd />, fn: () => openRowModal(row[getter], row.id, setOnAddParticipant) },
                    user.role_id === 1 && row.status === 'pending' && user.user_id !== row.created_by_userid &&
                    { title: 'Reject', item: <FaWindowClose />, fn: () => openRowModal(row[getter], row.id, setOnReject) }
                    // row.status !== 'Pending' && { title: 'Merge', item: <MergeTypeIcon onClick={() => setOnMerge(true)} /> }
                ]
                break;
            case 'participants':
                icons = [
                    { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row[getter], row.id, setOnEdit) },
                    user.role_id < 3 && { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) },
                    // { title: 'Duplicate', item: <IoIosCopy /> }, { title: 'Edit Answers', item: <EditIcon /> }
                ]
                break;
            case 'difficulty':
            case 'domain':
                icons = [
                    { title: 'Edit', item: <FiEdit />, fn: () => openRowModal(row.name, row.id, setOnEdit) },
                    { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row.name, row.id, setOnDelete) },
                    row.status !== 'active' && { title: 'Approve', item: <CheckIcon /> }
                ]
                break;
            case 'task':
            case 'translation':
                icons = [{ title: 'Edit', item: <FiEdit />, fn: () => window.open(`/edittask?id=${row.id}`, '_blank') },
                { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) },
                { title: 'Duplicate', item: <IoIosCopy /> },
                // { title: 'Verify', item: <CheckCircleIcon /> },
                // { title: 'Assign Permission', item: <BuildIcon /> },
                { title: 'Moderate Languages', item: <LanguageIcon />, fn: () => openRowModal(row[getter], row.id, setOnModerateLanguages) },
                    // {
                    //     title: 'Translate Task', item: <Link to={`/translateTasks/${row[getters[0]]}`} style={{ color: '#000' }}>
                    //         <img src={languageIcon} alt='Language Icon' /></Link>
                    // }
                ]
                break;
            case 'competitions':
                icons = [
                    user.role_id === 1 && { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) },
                    user.role_id === 1 && { title: 'Duplicate', item: <IoIosCopy />, fn: () => window.open(`/addcompetition?duplicate_id=${row.id}`, '_blank') },
                    user.role_id < 2 && { title: 'Edit', item: <FiEdit />, fn: () => window.open(`/editcompetition?id=${row.id}`, '_blank') },
                    user.role_id === 1 && { title: 'Add Country Partner', item: <AddIcon />, fn: () => openRowModal(row[getter], row.id, setOnAddUser) }
                    // { title: 'Session', item: <AccountBoxOutlinedIcon /> },
                    // { title: 'Mark', item: <EditIcon /> },
                    // { title: 'View Tasks', item: <FaTasks /> },
                    // { title: 'View Collections', item: <CollectionsBookmarkIcon /> }
                ]
                break;
            case 'pending partners competition date':
                icons = [
                    { title: 'Approve', item: <CheckIcon />, fn: () => openRowModal(row[getter], row.id, setOnApprove) },
                    row.status === 'pending' && { title: 'Reject', item: <FaWindowClose />, fn: () => openRowModal(row[getter], row.id, setOnReject) }
                ]
                break;
            case 'taskModeration':
                icons = [{
                    title: 'Moderate Task', item: <Link to={{ pathname: `/moderateTask`, state: [row[getters[0]]] }}
                        style={{ color: '#000' }}> <SpellcheckIcon /></Link>
                }]
                break;
            case 'roles':
                icons = [{ title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) }]
                break;
            case 'collection':
                icons = [{ title: 'Edit', item: <FiEdit /> },
                { title: 'Delete', item: <DeleteIcon /> },
                { title: 'Copy File', item: <FileCopyIcon /> },
                { title: 'Assign Permision', item: <BuildIcon />, fn: () => openRowModal(row[getter], row.id, setOnManagePermission) },
                row['status'] === 'Pending Moderation' && { title: 'Approve', item: <CheckIcon /> },
                { title: 'Verify', item: <CheckCircleIcon /> }]
                break;
            case 'users':
                let canEditOtherUser = (user.user_id === 1 || (user.role_id < row.role_id)) && user.user_id !== row.id
                icons = canEditOtherUser ? [
                    { title: 'Edit User', item: <FiEdit />, fn: () => openRowModal(row[getter], row.id, setOnEdit) },
                    { title: 'Delete', item: <DeleteIcon />, fn: () => openRowModal(row[getter], row.id, setOnDelete) },
                    row['status'] === 'active' && user.role_id === 1 ? {
                        title: 'Disable User', item: <FaUserSlash />, fn: () => openRowModal(row[getter], row.id, setOnDisable)
                    } : row.status === 'disabled' && user.role_id === 1 ? {
                        title: 'Enable User', item: <FaUserCheck />, fn: () => openRowModal(row[getter], row.id, setOnEnable)
                    } : null,
                    user.role_id === 1 && { title: 'View User Permissions', item: <Link to={`/userpermissions/${row.id}`} style={{ color: '#000' }}><VisibilityIcon /></Link> }
                ] : []
            default:
        }
        return (
            icons.map((icon, index) => (
                icon && <LightTooltip key={`${row.id}-${index}`} title={icon.title} placement='bottom-start' type='Button'>
                    <IconButton onClick={() => icon.fn && icon.fn()} style={{ color: '#000' }}>{icon.item}</IconButton>
                </LightTooltip>
            ))
        )
    }
    const chipStyle = {
        backgroundColor: '#F16774',
        color: '#FFF', marginRight: 3, border: '0.5px solid gray'
    }
    const divStyle = {
        whiteSpace: "nowrap", textOverflow: "ellipsis",
        display: "block", overflow: "hidden", minWidth: 150, maxWidth: 300
    }
    const DisplayChipCell = ({ row, getter }) => {
        let arr = title === 'task' ? row.task_tags.filter(t => t.is_tag) : row[getter]
        return (
            <div style={{ display: 'flex' }}>
                {arr.map((tag, index) => (
                    index < 4 ? index === 3 ? <LightTooltip title={<Topics title='Tags' topics={arr.slice(3)} />}
                        placement='bottom-start' rollover topic>
                        <Chip key={index} style={chipStyle} label={`+${arr.length - index}`} />
                    </LightTooltip> : <Chip key={index} style={chipStyle} label={title === 'task' ? tag.name : tag} /> : null
                ))}
            </div>
        )
    }
    const IndexDropdown = ({ row, getter }) => {
        const [index, setIndex] = useState(row[getter])
        const onChange = e => {
            console.log({
                index: index,
                indexToSwap: e
            })
            swapIndex({
                index: index,
                indexToSwap: e
            }).then(d => {
                console.log(d)
                if (![200, 201].includes(d.status)) return
                setIndex(e)
                onChangePage(1)
            })
        }
        // Filter out from all indexes to form the available options for the index by
        // checking for competition name, country id,
        // if is school candidate then compare both school id and tuition centre id,
        // if is private candidate then just compare tuition centre id
        const indexOptions = makeSureIsArray(list.data).filter(d => d.country_id === row.country_id &&
            d.competition_name === row.competition_name &&
            (row.private ? d.tuition_centre_id === row.tuition_centre_id :
                d.school_id === row.school_id && d.tuition_centre_id === row.tuition_centre_id))
            .map(d => ({ index: d.index_no, name: d.name }))
        return (
            <div style={divStyle}>
                <ReusableSelect type='type2' state={index} setState={onChange} btnBgColor='#144A94' height={45} width={280}
                    options={indexOptions.map(i => ({ value: i.index, option: `${i.index} - ${i.name}` }))} />
            </div>
        )
    }
    const [open, setOpen] = useState(false)
    const [rejectReason, setRejectReason] = useState('')
    const onHoverRejectReason = data => {
        setOpen(true)
        setRejectReason(data)
    }
    const closeRejectReason = () => {
        setOpen(false)
        setRejectReason('')
    }
    const Topics = ({ title, topics }) => {
        return (
            <div style={{ textAlign: 'center', padding: '10px 4px' }}>
                <NunitoText value={title} fontSize={18} marginBottom={10} />
                {topics.map((t, i) => (
                    <NunitoText key={i} value={t.name} color='#000' fontSize={16} />
                ))}
            </div>
        )
    }
    const Cell = ({ count, row, getter, isItemSelected, color }) => {
        const tableCellStyle = index => ({
            paddingLeft: 15, position: 'sticky', left: 0, zIndex: index === 1 && 999,
            backgroundColor: isItemSelected ? '#EDF4FB' : color, backgroundClip: 'padding-box'
        })
        let output = row[getter]
        let hoverData = row[getter]
        if (['reject_reason', 'format', 'private'].includes(getter)) {
            switch (getter) {
                case 'reject_reason':
                    output = <p onMouseEnter={() => row[getter].length && onHoverRejectReason(row[getter])}>{row[getter].length}</p>
                    break
                case 'format':
                    output = row[getter] ? 'global' : 'local'
                    break
                case 'private':
                    output = row[getter] ? 'Yes' : 'No'
                default:
            }
        } else if (title === 'task' && getter === 'domain') {
            output = <div style={{ display: 'flex', flexWrap: 'wrap', width: 300 }}>
                {row.task_tags.filter(t => t.domain_id === null && !t.is_tag).map((t, i) =>
                    <LightTooltip key={i}
                        title={<Topics title='Topics' topics={row.task_tags.filter(tag => tag.domain_id === t.id && !tag.is_tag)} />}
                        placement='bottom-start' rollover topic
                        hide={!row.task_tags.filter(tag => tag.domain_id === t.id && !tag.is_tag).length}>
                        <p style={{ margin: 0 }}>{t.name}{i === row.task_tags.filter(t => t.domain_id === null && !t.is_tag).length - 1 ? '' : `,\u00a0`}</p>
                    </LightTooltip>
                )}
            </div>
        } else if (title === 'pending partners competition date' && ['registration_open_date', 'competition_date'].includes(getter)) {
            if (getter === 'competition_date') {
                output = new Date(row[getter][0]).toLocaleDateString()
                hoverData = row[getter].slice(1)
            } else if (getter === 'registration_open_date') {
                // output = <p style={{ color: 'green', margin: 0 }}>{getDateDiff(new Date(row.competition_date[0]).getTime(),
                //     new Date(row.registration_open_date).getTime())}</p>
                let regDay = new Date(row.registration_open_date)
                let lastDay = new Date(new Date(row.competition_date[0]).setDate(new Date(row.competition_date[0]).getDate()
                    - row.registration_days_apart))
                output = <p style={{ margin: 0 }}>
                    <span style={{ color: dateHasPassed(lastDay, regDay) ? '#D96474' : '#6FC9B6' }}>{regDay.toLocaleDateString()}</span> -
                    <span style={{ color: dateHasPassed(lastDay, new Date()) && '#D96474' }}>{lastDay.toLocaleDateString()}</span>
                </p>
            }
        } else if (title === 'domain' && ['domain_name', 'name', 'tag'].includes(getter)) {
            // Handle domain, topic and tag
            if (getter === 'tag') {
                if (row.is_tag === 1) output = row.name
                else output = '-'
            } else if (getter === 'domain_name') {
                if (row.is_tag === 0 && row.domain_name === null) output = row.name
                else output = row[getter]
            } else {
                if (row.domain_name !== null) output = row.name
                else output = '-'
            }
        } else if (row[getter] === null || row[getter] === '') {
            output = '-'
        } else if (typeof row[getter] === 'object') {
            if (getter === 'teachers' && row.teachers.length) {
                let to = user.role_id > 1 ? `/users?status=active&role_id=3&school_id=${row.id}` :
                    `/users?status=active&role_id=3&school_id=${row.id}&country_id=${row.country_id}`
                output = <Link to={to}>{row[getter].length}</Link>
            } else if (getter === 'partners' && row.partners.length && user.role_id === 1) {
                let to = `/users?status=active&role_id=2`
                output = <Link to={to}>{row[getter].length}</Link>
            } else output = row[getter].length
        } else if (['created', 'approved', 'modified', 'approved', 'rejected'].some(c => getter.includes(c))) {
            // Handle who and when of creation and modification of records
            let date = ''
            if (getter.includes('created')) {
                if (row.created_at) date = formatDate(row.created_at, '/')
            } else {
                if (row.updated_at) date = formatDate(row.updated_at, '/')
            }
            output = `${row[getter]} ${date}`
        }
        let hideCondition = output === '-' || count === 1 || ['reject_reason'].includes(getter)
            || (title === 'task' && getter === 'domain') || getter === 'tags'
        return (
            <LightTooltip key={count} title={EnhancedHover({
                getter, data: hoverData, type: row.answerType, structure: row.answerStructure,
                isObject: typeof row[getter] === 'object', output
            })} placement='bottom-start' hide={hideCondition}
                rollover={typeof hoverData === 'object'}>
                <TableCell align='left' component="th" scope="row" style={tableCellStyle(count)}>
                    {count === 1 ? <div style={divStyle}>
                        {output}
                        <div>
                            <Btns title={title} row={row} getter={getter} />
                        </div>
                    </div> : getter === 'index_no' && user.role_id === 1 ? <IndexDropdown row={row} getter={getter} /> :
                        getter === 'tags' ? <DisplayChipCell row={row} getter={getter} /> :
                            getter === 'language' ? ['task', 'taskModeration'].includes(title) ?
                                <div style={divStyle}>{output}</div> : <DisplayChipCell row={row} getter={getter} /> :
                                <div style={divStyle}>{output}</div>}
                </TableCell>
            </LightTooltip>
        )
    }
    const Row = ({ getters, row, isItemSelected }) => {
        const [color, setColor] = useState('#FFF')
        const tableRowStyle = {
            height: 80, overFlow: 'auto'
        }
        return (
            <TableRow hover tabIndex={-1} selected={isItemSelected} style={tableRowStyle}
                onMouseEnter={() => setColor('#F6F6F6')}
                onMouseLeave={() => setColor('#FFF')}>
                {getters.map((getter, index) => (
                    !['id'].includes(getter) &&
                    <Cell key={index} count={index} row={row} getter={getter} isItemSelected={isItemSelected} color={color} />
                ))}
            </TableRow >
        )
    }
    const { width } = useWindowDimensions()
    const checkBoxDivStyle = {
        display: 'flex', flexDirection: 'column', width: 60
    }
    const selectAllCheckBoxStyle = {
        color: "#000", height: 61
    }
    const checkBoxStyle = {
        color: "#000", height: 80
    }
    const paperStyle = {
        boxShadow: '0 0 3px #9E9E9E', minWidth: 800, maxWidth: 3200, width: (0.89 * width) - 60
    }
    const indeterminateCondition = selected.length > 0 && selected.length < data.length
    const checkedCondition = data.length > 0 && selected.length === data.length
    const firstRowStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        marginTop: 20, borderBottom: '1px solid #707070'
    }
    return (
        <Grid container alignItems='flex-start' style={{ marginTop: '2%', width: 0.89 * width }}>
            <div style={checkBoxDivStyle}>
                <Checkbox style={selectAllCheckBoxStyle} indeterminate={indeterminateCondition}
                    checked={checkedCondition} onChange={handleSelectAllClick} />
                {stableSort(data, getComparator(order, orderBy)).map((row, i) => (
                    <Checkbox key={i} style={checkBoxStyle} onClick={e => handleSelectClick(e, row.id, row[getters[1]])}
                        checked={isSelected(row.id)} />
                ))}
            </div>
            <Paper style={paperStyle}>
                <TableContainer>
                    <Table aria-labelledby="tableTitle" size='small'>
                        <EnhancedTableHead order={order} orderBy={orderBy} handleRequestSort={handleRequestSort} headers={headers} />
                        <TableBody>
                            {stableSort(data, getComparator(order, orderBy)).map((row, i) => (
                                <Row key={i} getters={getters} row={row} count={i} isItemSelected={isSelected(row.id)} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/* {list.links && list.links.map(l => (
                            <p>{JSON.stringify(l)}</p>
                        ))} */}
                <Pagination list={list} title={title} rowsPerPage={rowsPerPage} onChangePage={onChangePage}
                    setSelected={setSelected} setSelecting={setSelecting} />
            </Paper>
            {/* {JSON.stringify({ selecting, selected })}<br /> */}
            {/* {JSON.stringify(list)}<br /> */}
            {/* {JSON.stringify(getters)}<br /> */}
            <Modal open={open} onClose={() => closeRejectReason()} disableAutoFocus>
                {rejectReason && <Box className='scrollablePopUpModal'
                    style={{ width: 800, minHeight: 400, maxHeight: 600, display: 'block', padding: 20 }}>
                    <NunitoText value='Reason for rejection(Latest)' fontSize={30} fontWeight={600} color='#5E75C3' />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: -50 }}>
                        <IconButton onClick={() => closeRejectReason()} style={{ color: '#E83042' }}>
                            <CancelIcon style={{ fontSize: 30 }} />
                        </IconButton>
                    </div>
                    <div style={firstRowStyle}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <NunitoText value={`${rejectReason.at(-1).user.username}(${rejectReason.at(-1).role.name})`}
                                fontSize={24} fontWeight={300} color='#5E75C3' italic />
                            <NunitoText value={rejectReason.at(-1).created_by.slice(-20)}
                                fontSize={15} fontWeight={300} color='#F16774' italic marginLeft={10} />
                        </div>
                        <NunitoText value={`Has been rejected ${rejectReason.length} time(s)`} fontSize={20} fontWeight={300} />
                    </div>
                    <NunitoText value={rejectReason.at(-1).reason} fontSize={20} marginTop={16} align='left' />
                </Box>}
            </Modal>
        </Grid>
    )
}