import React, { useState } from 'react';
import { Link as Linking, Redirect } from 'react-router-dom'
import { IconButton, InputAdornment, Card, CardMedia, Button, Grid, Box, TextField, Typography, styled } from '@mui/material';
import img from '../images/signin2.jpg'
import topimg from '../images/signintop.png'
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff"
import '../css/signin.css'
import '../css/global.css'
const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    background-color: #FFF;
    border: 1px solid #000;
  }`
export default function SignUp() {
    document.title = 'Sign Up'
    const [user, setUser] = useState({ username: '', email: '', password: '', confirmPassword: '' })
    const [username, setUsername] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [registered, setRegistered] = useState(false)

    const update = (identifier, value) => {
        let newUser = { ...user }
        newUser[identifier] = value
        setUser(newUser)
    }
    const register = (e) => {
        e.preventDefault()
        if (user.password !== user.confirmPassword) {
            setRegistered(false)
            alert('make sure they are the same')
            return
        }
        delete user.confirmPassword
        fetch(`http://localhost:5000/user`, {
            method: 'POST',
            headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(user)
        })
            .then(res => res.json())
            .then((data) => {
                setRegistered(true)
            })
            .catch((error) => {
                alert(`Error at addDomain: ${JSON.stringify(error)}`)
            })
    }
    return (
        <Grid container>
            {registered && <Redirect to='/signin' />}
            <Grid item xs={6} sx={{
                backgroundImage: `url(${img})`, backgroundRepeat: 'no-repeat',
                backgroundColor: '#FFFFFF', backgroundSize: '80%', backgroundPosition: 'center'
            }} />
            <Grid item xs={6}>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    <Card>
                        <CardMedia component='img' image={topimg} />
                    </Card>
                    <Box component="form" onSubmit={register} sx={{ mt: 10, width: 800, mb: 3.6 }}>

                        {Object.keys(user).map((u, index) => (
                            <Grid key={index} container direction="row" alignItems="center">
                                <Grid item xs={4.5}>
                                    <Typography variant="h4">
                                        {index === 3 ? 'Confirm Password'
                                            : u.charAt(0).toUpperCase() + u.slice(1)}
                                    </Typography>
                                </Grid>
                                <Grid item xs>
                                    <StyledTextField
                                        variant="filled"
                                        margin="normal"
                                        value={user[u]}
                                        onChange={(e) => update(u, e.target.value)}
                                        required
                                        fullWidth
                                        type={u === 'password' ? showPassword ? "text" : "password" :
                                            u === 'confirmPassword' ? showConfirmPassword ? "text" : "password" : "text"}
                                        autoFocus
                                        InputProps={{
                                            style: { backgroundColor: '#FFF', borderRadius: 12 },
                                            disableUnderline: true,
                                            hiddenLabel: true,
                                            endAdornment:
                                                (u === 'password' ? <InputAdornment position="end">
                                                    <IconButton sx={{ color: '#000' }}
                                                        onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                                    </IconButton>
                                                </InputAdornment> : u === 'confirmPassword' ?
                                                    <InputAdornment position="end">
                                                        <IconButton sx={{ color: '#000' }}
                                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment> : null)
                                        }}
                                        inputProps={{ style: { backgroundColor: '#FFF', borderRadius: 12 } }}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                        <Grid container direction="column" alignItems="center">
                            <Button type="submit" fullWidth variant="contained" id='loginBtn'>
                                Register
                            </Button>
                            <Grid item xs={12} sx={{ mt: 5, color: '#868484', fontSize: 20 }}>
                                Already have an account? <Linking to='/signin' className='signup'>
                                    Login
                                </Linking>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}