import { deleteMethod } from './httpMethods'
// School
export const deleteSchool = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('school', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Organization
export const deleteOrganization = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('organisation', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// User
export const deleteUser = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Participant
export const deleteParticipant = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('participant', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Domain and Tag
export const deleteDomain = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Competition
export const deleteCompetition = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Task
export const deleteTask = data => {
    return new Promise((resolve, reject) => {
        deleteMethod('task', data).then(d => resolve(d)).catch(e => reject(e))
    })
}