const defaultState = [{
    competitionID: 1,
    expandCompetition: false,
    expandSettings: false,
    competitionName: '',
    competitionStart: new Date().setHours(0, 0, 0, 0),
    competitionEnd: new Date(new Date().setDate(new Date().getDate() + 7)).setHours(0, 0, 0, 0),
    format: false,
    tags: [],
    rerun: false,
    rerunMarkingPreference: '',
    gradesParticipating: [],
    difficultyGroup: '',
    instructions: '',
    allowPartnerEditSessions: false,
    expandCountryPartners: false,
    countryPartners: [],
    expandRounds: false,
    rounds: [{
        roundID: 1,
        expandRound: false,
        roundLabel: '',
        team: false,
        contributeToIndividualScore: false,
        teamOptions: '',
        levels: [{
            levelID: 1,
            level: '',
            collection: '',
            grade: []
        }],
        awards: {
            usePosition: false,
            useGradeToAssignPoints: false,
            minPoints: '',
            useForAll: false,
            roundAwards: [{
                label: '',
                percentage: '',
                points: '',
                minPoints: ''
            }],
            defaultAward: ''
        },
        awardCriteria: ''
    }],
    overallAwards: {
        usePosition: false,
        minPoints: '',
        roundAwards: [],
        defaultAward: ''
    }
}]
const competitionState = defaultState
const reset = defaultState
const competitionReducer = (state = competitionState, action) => {
    let newState = state.slice()
    switch (action.type) {
        case 'ADD_COMPETITION':
            let temp = JSON.parse(JSON.stringify(defaultState[0]))
            temp.competitionID = newState.map(n => n.competitionID).reduce((a, b) => Math.max(a, b), 0) + 1
            newState.splice(action.count, 0, temp)
            return newState
        case 'DELETE_COMPETITION':
            newState.splice(action.count, 1)
            return newState
        case 'UPDATE_COMPETITION':
            switch (action.head) {
                case 'competitionBoolean':
                    if (action.text !== undefined) newState[action.count][action.target] = action.text
                    else newState[action.count][action.target] = !newState[action.count][action.target]
                    if (action.target === 'allowPartnerEditSessions') {
                        //If setting allowPartnerEditSessions, set all allowEdit as well
                        newState[action.count].countryPartners.forEach(c => c.allowEdit = newState[action.count][action.target])
                    }
                    return newState
                //Settings and general competition
                case 'settingText':
                    //If changing the difficulty group, reset default difficulty
                    if (['difficultyGroup'].includes(action.target)) newState[action.count].defaultDifficulty = ''
                    newState[action.count][action.target] = action.text
                    return newState
                case 'settingDate':
                    newState[action.count][action.target] = action.event
                    return newState
                case 'settingMultiple':
                    newState[action.count][action.target] = typeof action.text === 'string' ? action.text.split(',') : action.text
                    return newState
                //Country Partner
                case 'setCountryPartners':
                    // When duplicating from DB
                    newState[action.count][action.target] = action.text
                    return newState
                case 'addCountryPartner':
                    // let b = JSON.parse(JSON.stringify(defaultState[0].countryPartners[0]))
                    newState[action.count[0]][action.target].splice(action.count[1], 0, {
                        country_id: '',
                        partner_id: '',
                        registrationOpen: new Date().setHours(0, 0, 0, 0),
                        competitionDates: [new Date(newState[action.count[0]].competitionEnd).setHours(0, 0, 0, 0)],
                        registration_days_apart: 7,
                        allowEdit: false,
                        languagesToView: [],
                        languagesToTranslate: [],
                    })
                    return newState
                case 'deleteCountryPartner':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'countryPartnerBoolean':
                    newState[action.count[0]].countryPartners[action.count[1]][action.target]
                        = !newState[action.count[0]].countryPartners[action.count[1]][action.target]
                    //If all allowEdit are true or false, set allowPartnerEditSessions as well
                    // Don't use for now
                    // if (new Set(newState[action.count[0]].countryPartners.map(c => c.allowEdit)).size === 1) {
                    //     newState[action.count[0]].allowPartnerEditSessions =
                    //         newState[action.count[0]].countryPartners[action.count[1]][action.target]
                    // }
                    return newState
                case 'countryPartnerText':
                    newState[action.count[0]].countryPartners[action.count[1]][action.target] = action.text
                    return newState
                case 'countryPartnerMultiple':
                    newState[action.count[0]].countryPartners[action.count[1]][action.target] =
                        typeof action.text === 'string' ? action.text.split(',') : action.text
                    return newState
                case 'countryPartnerDate':
                    newState[action.count[0]].countryPartners[action.count[1]][action.target] = action.event
                    return newState
                //Round
                case 'addRound':
                    let c = JSON.parse(JSON.stringify(defaultState[0].rounds[0]))
                    c.roundID = newState[action.count].rounds.map(r => r.roundID).reduce((a, b) => Math.max(a, b), 0) + 1
                    newState[action.count][action.target].push(c)
                    return newState
                case 'deleteRound':
                    newState[action.count[0]][action.target].splice(action.count[1], 1)
                    return newState
                case 'duplicateRound':
                    let duplicatedRound = JSON.parse(JSON.stringify(action.text))
                    duplicatedRound.roundID = newState[action.count[0]].rounds.map(r => r.roundID).reduce((a, b) => Math.max(a, b), 0) + 1
                    duplicatedRound.roundLabel = ''
                    newState[action.count[0]][action.target].splice(action.count[1], 0, duplicatedRound)
                    return newState
                case 'roundBoolean':
                    if (['usePosition', 'useGradeToAssignPoints', 'useForAll'].includes(action.target)) {
                        newState[action.count[0]].rounds[action.count[1]].awards[action.target]
                            = !newState[action.count[0]].rounds[action.count[1]].awards[action.target]
                    } else {
                        newState[action.count[0]].rounds[action.count[1]][action.target]
                            = !newState[action.count[0]].rounds[action.count[1]][action.target]
                    }
                    return newState
                case 'roundText':
                    if (['minPoints', 'defaultAward'].includes(action.target)) {
                        newState[action.count[0]].rounds[action.count[1]].awards[action.target] = action.text
                    } else {
                        newState[action.count[0]].rounds[action.count[1]][action.target] = action.text
                    }
                    return newState
                //Levels within a round
                case 'addLevel':
                    let d = JSON.parse(JSON.stringify(defaultState[0].rounds[0].levels[0]))
                    d.levelID = newState[action.count[0]].rounds[action.count[1]].levels.map(l => l.levelID).reduce((a, b) => Math.max(a, b), 0) + 1
                    newState[action.count[0]].rounds[action.count[1]][action.target].splice(action.count[2], 0, d)
                    return newState
                case 'deleteLevel':
                    newState[action.count[0]].rounds[action.count[1]][action.target].splice(action.count[2], 1)
                    return newState
                case 'levelText':
                    newState[action.count[0]].rounds[action.count[1]].levels[action.count[2]][action.target] = action.text
                    return newState
                //Awards within a round
                case 'addAward':
                    let e = JSON.parse(JSON.stringify(defaultState[0].rounds[0].awards.roundAwards[0]))

                    newState[action.count[0]].rounds[action.count[1]].awards[action.target].splice(action.count[2], 0, e)
                    return newState
                case 'deleteAward':
                    newState[action.count[0]].rounds[action.count[1]].awards[action.target].splice(action.count[2], 1)
                    return newState
                case 'awardText':
                    newState[action.count[0]].rounds[action.count[1]].awards.roundAwards[action.count[2]][action.target] = action.text
                    return newState
                //Overall Awards
                case 'addOverallAward':
                    let obj = { label: '', minPoints: '', percentage: '' }
                    //For each current round, attached a criteria to the overall award object
                    newState[action.count[0]].rounds.forEach((round, i) => {
                        obj[`criteriaForRound${i + 1}`] = ''
                    })
                    newState[action.count[0]].overallAwards.roundAwards.splice(action.count[1], 0, obj)
                    return newState
                case 'deleteOverallAward':
                    newState[action.count[0]].overallAwards.roundAwards.splice(action.count[1], 1)
                    return newState
                case 'overallAwardBoolean':
                    newState[action.count].overallAwards[action.target] = !newState[action.count].overallAwards[action.target]
                    return newState
                case 'overallAwardText':
                    newState[action.count].overallAwards[action.target] = action.text
                    return newState
                default: return state
            }
        case 'RESET_COMPETITIONS':
            newState = reset
            return newState
        default: return state
    }
}
export default competitionReducer