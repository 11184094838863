import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import AddDomainModal from '../components/modal/AddDomainModal'
import EditDomainModal from '../components/modal/EditDomainModal'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import {
    makeOptions, ensureObjectOfArraysArePopulated, checkIfAllSelectedRowsMatchStatus, makeSureIsArray,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getDomains } from '../functions/getData'
import { approveDomain } from '../functions/patchData'
import { deleteDomain } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const domainHeader = [
    { id: 'domain_name', label: 'Domain' },
    { id: 'name', label: 'Topic' },
    { id: 'tag', label: 'Tag' },
    { id: 'created_by_username', label: 'Created By' },
    { id: 'modified_by_username', label: 'Last Modified By' },
    { id: 'status', label: 'Status' }
]
const initialParams = '?'
export default function Domain() {
    document.title = 'Domains and Tags'
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(domainHeader)
    const [domainTable, setDomainTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Domain", state: '', key: 'domain_id', header: 'Domain' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Created By", state: true, key: 'created_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: "Status", state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchDomain, setSearchDomain] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddDomain, setOnAddDomain] = useState(false)
    const [domainOptions, setDomainOptions] = useState([])
    const [onEditDomain, setOnEditDomain] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelEdit = () => cancelAction(setOnEditDomain, setSelected, setSelecting)
    const cancelApprove = () => cancelAction(setOnApprove, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteDomain({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const approveFunction = () => {
        approveDomain({ id: selected }).then(d => {
            getData(params)
            cancelApprove()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getDomains, params, setDomainTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(domainHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchDomain, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchDomain, params, setParams, getData)
    }
    const onChangeSearchDomain = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchDomain, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    const loadTableAndOptions = () => {
        getData(params)
        getDomains('?status=active&limits=50').then(d => {
            setDomainOptions(d.tagLists.data.filter(t => t.domain_id === null && !t.is_tag))
        })
    }
    useEffect(() => {
        loadTableAndOptions()
    }, [])
    const onClickMassApprove = () => {
        return domainTable && checkIfAllSelectedRowsMatchStatus(selected, domainTable.data, 'pending') ?
            setOnApprove(true) : showWarningSwal('Please select pending domains / tags only to mass approve')
    }
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one domain to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Domain' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Domains and Tags' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchDomain} setState={onChangeSearchDomain} onBlur />
                    {searchDomain}
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Domain / Tag' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => setOnAddDomain(true)} iconType='add' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />
                        <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassApprove()} iconType='check' />
                    </div>
                </Grid>
                <NunitoText value='Search only in "Domain", "Topic", and "Tag" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Loader height={600} /> : domainTable && makeSureIsArray(domainTable.data).length ?
                    <HeavyTable headers={header} list={domainTable} title='domain' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setOnApprove={setOnApprove} setOnEdit={setOnEditDomain}
                    /> : firstLoaded && <NoData height={600} />}
                <Modal open={onDelete} onClose={() => cancelDelete()}>
                    <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
                </Modal>
                <Modal open={onApprove} onClose={() => cancelApprove()}>
                    <ApproveModal selecting={selecting} approveFunction={approveFunction} cancelApprove={cancelApprove}
                        object='domain/tags' />
                </Modal>
                <Modal open={onAddDomain} onClose={() => setOnAddDomain(false)}>
                    {domainOptions && <AddDomainModal setOnAddDomain={setOnAddDomain} afterAddingDomain={() => loadTableAndOptions()} 
                    domainOptions={domainOptions} />}
                </Modal>
                <Modal open={onEditDomain} onClose={() => cancelEdit()}>
                    {domainTable && <EditDomainModal afterEditingDomain={() => loadTableAndOptions()}
                        domains={domainTable.data} id={selected[0]} cancelEdit={cancelEdit} />}
                </Modal>
            </Grid>
        </Box>
    )
}