import React, { useState, useEffect } from 'react'
import {
    Box, Grid, TextField, InputAdornment, IconButton, Collapse, Typography, Modal, Autocomplete, Checkbox,
    Tooltip, tooltipClasses, styled, FormControlLabel, Divider
} from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import SearchIcon from '@mui/icons-material/Search'
import CancelIcon from '@mui/icons-material/Cancel'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import CustomNestedSelectMultiple from '../components/CustomNestedSelectMultiple';
import '../css/school.css'
import '../css/global.css'
import Swal from 'sweetalert2'
import { useSelector, useDispatch } from 'react-redux'
import { resetCollection } from '../actions'
import { getCollections, getCompetitions } from '../functions/getData';
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ReusableAutocomplete from '../components/general/ReusableAutocomplete'
import Widget from '../components/general/Widget'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: '#144A94',
        position: 'relative',
        top: -10,
        left: 50,
        border: '1px solid gray',
        borderRadius: 16,
        maxWidth: 950,
        boxShadow: '5px 5px 12px rgba(0, 0, 0, 16%)'
    }
}));
const header = [
    { id: 'collectionName', label: 'Collection Name' },
    { id: 'collectionIdentifier', label: 'Collection Identifier' },
    { id: 'description', label: 'Description' },
    { id: 'tags', label: 'Tags' },
    { id: 'recommendedDifficulty', label: 'Recommended Difficulty' },
    { id: 'noOfSection', label: 'No. of section' },
    { id: 'noOfQuestions', label: 'No. of questions' },
    { id: 'initialPoints', label: 'Initial Points' },
    { id: 'timeToSolve', label: 'Time to solve' },
    { id: 'competition', label: 'Competition' },
    { id: 'permission', label: 'Permission to Verify' },
    { id: 'createdBy', label: 'Created By' },
    { id: 'lastModifiedBy', label: 'Last Modified By' },
    { id: 'lastVerifiedBy', label: 'Last Verified By' },
    { id: 'status', label: 'Status' }
]
export function AssignCollectionsToCompetition({ collection, count, selected, setSelected, massRound, assignMassRound }) {
    const [openRound, setOpenRound] = useState(false)
    const [openLevel, setOpenLevel] = useState(false)
    const [round, setRound] = useState('')
    const [level, setLevel] = useState('')
    const [checked, setChecked] = useState(false)
    useEffect(() => {
        if (checked) setRound(massRound)
    }, [massRound, assignMassRound, checked])
    return (
        <Grid container style={{ marginTop: !count && 60, marginBottom: 40, paddingInline: 30 }}>
            <Grid item xs={0.5} style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox style={{ color: '#000' }} size="medium" onChange={() => setChecked(!checked)} />
            </Grid>
            <Grid item xs={11}>
                <Grid container>
                    <Grid item xs={3} style={{ display: 'flex', alignItems: 'center', textAlign: 'left', paddingRight: 10 }}>
                        <NunitoText value={`${collection.collectionName} - Collection ${count + 1}`} fontSize={20} />
                    </Grid>
                    <Grid item xs={3.2}>
                        <Autocomplete
                            size='small'
                            freeSolo
                            sx={{ width: 320, marginRight: 2 }}
                            value={round}
                            onChange={(e, newValue) => setRound(newValue)}
                            options={['Round 1', 'Round 2', 'Round 3']}
                            open={openRound}
                            onClose={() => setOpenRound(false)}
                            onOpen={() => setOpenRound(true)}
                            selectOnFocus
                            renderInput={(params) => <TextField variant="filled"
                                {...params}
                                onChange={(e) => setRound(e.target.value)}
                                placeholder="Round"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    hiddenLabel: true,
                                    startAdornment:
                                        (<InputAdornment position="start" style={{ marginBottom: 20, marginLeft: 4 }}>
                                            <SearchIcon style={{ fontSize: 28, marginBottom: 18 }} />
                                        </InputAdornment>),
                                    endAdornment:
                                        (<InputAdornment position="end">
                                            <IconButton onClick={() => setOpenRound(!openRound)}
                                                style={{
                                                    backgroundColor: '#5E75C3', borderRadius: '0 8px 8px 0', position: 'relative',
                                                    left: 40, bottom: 13, height: 48
                                                }}>
                                                {openRound ? <ExpandLessIcon style={{ fontSize: 36, color: 'white', height: 40 }} />
                                                    : <ExpandMoreIcon style={{ fontSize: 36, color: 'white', height: 40 }} />}
                                            </IconButton>
                                        </InputAdornment>),
                                    style: { borderRadius: 8, height: 50, border: '1px solid #707070' }
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { marginTop: -22, textAlign: 'center' }
                                }}
                            />}
                        />
                    </Grid>
                    <Grid item xs={3.6}>
                        <Autocomplete
                            size='small'
                            freeSolo
                            sx={{ width: 320, marginRight: 2 }}
                            value={level}
                            onChange={(e, newValue) => setLevel(newValue)}
                            options={['Level 1', 'Level 2', 'Level 3']}
                            open={openLevel}
                            onClose={() => setOpenLevel(false)}
                            onOpen={() => setOpenLevel(true)}
                            selectOnFocus
                            renderInput={(params) => <TextField variant="filled"
                                {...params}
                                onChange={(e) => setLevel(e.target.value)}
                                placeholder="Level"
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    hiddenLabel: true,
                                    startAdornment:
                                        (<InputAdornment position="start" style={{ marginBottom: 20, marginLeft: 4 }}>
                                            <SearchIcon style={{ fontSize: 28, marginBottom: 18 }} />
                                        </InputAdornment>),
                                    endAdornment:
                                        (<InputAdornment position="end">
                                            <IconButton onClick={() => setOpenLevel(!openLevel)}
                                                style={{
                                                    backgroundColor: '#5E75C3', borderRadius: '0 8px 8px 0', position: 'relative',
                                                    left: 40, bottom: 13, height: 48
                                                }}>
                                                {openLevel ? <ExpandLessIcon style={{ fontSize: 36, color: 'white', height: 40 }} />
                                                    : <ExpandMoreIcon style={{ fontSize: 36, color: 'white', height: 40 }} />}
                                            </IconButton>
                                        </InputAdornment>),
                                    style: { borderRadius: 8, height: 50, border: '1px solid #707070' }
                                }}
                                inputProps={{
                                    ...params.inputProps,
                                    style: { marginTop: -22, textAlign: 'center' }
                                }}
                            />}
                        />
                    </Grid>
                    <Grid item xs={2.2} style={{ display: 'flex', alignItems: 'center' }}>
                        <LightTooltip title={
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: 210 }}>
                                {Object.keys(collection.recommendedDifficulty[0]).map((a, index) => (
                                    <div key={index}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid gray', textAlign: 'center',
                                            borderRadius: 6, paddingInline: 4, paddingBlock: 2, marginInline: 3, marginTop: 5, fontWeight: 400
                                        }}>{a === 'partner' ? 'Country Partner' : a.charAt(0).toUpperCase() + a.slice(1)}</h2>
                                        {collection.recommendedDifficulty.map((d, index) => (
                                            <h2 key={index} style={{
                                                margin: '0 0 0 3px', fontWeight: 300, color: '#000',
                                                textAlign: 'left'
                                            }}>{d[a]}{a === 'percentage' && '%'}</h2>
                                        ))}
                                    </div>
                                ))}
                            </div>}>
                            <Typography style={{ fontSize: 14, color: 'gray', textDecoration: 'underline' }}>Recommendations</Typography>
                        </LightTooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={0.5} style={{ display: 'flex', alignItems: 'center' }}>
                <CancelIcon style={{ color: '#E83D4D', fontSize: 32, cursor: 'pointer' }}
                    onClick={() => setSelected(selected.filter(s => s !== collection.id))} />
            </Grid>
        </Grid>
    )
}
export function AssignLanguageToCollection({ country, count }) {
    const [expandLanguage, setExpandLanguage] = useState(false)
    const [languageSearch, setLanguageSearch] = useState('')
    const languageList = ["Chinese (Simplified)", "Chinese (Traditional)", "English", "Italian", "Dutch", "German"]
    const [languages, setLanguages] = useState(languageList.reduce((o, key) => ({ ...o, [key]: false }), {}))
    const changeLanguages = (identifier, value) => {
        let newLanguages = { ...languages }
        newLanguages[identifier] = value
        setLanguages(newLanguages)
    }
    return (
        <Grid container>
            <Grid container justifyContent="space-between" alignItems="center" style={{
                backgroundColor: '#144a94', borderRadius: 10,
                lineHeight: 0.5, paddingInline: 30, marginTop: !count ? 30 : 20, marginInline: 80
            }}>
                <NunitoText value={country} color='#FFF' fontSize={25} fontWeight={600} marginLeft={20} />
                <IconButton style={{ color: '#FFF' }} onClick={() => setExpandLanguage(!expandLanguage)} edge="start">
                    {expandLanguage ? <ExpandLessIcon style={{ fontSize: 36 }} /> : <ExpandMoreIcon style={{ fontSize: 36 }} />}
                </IconButton>
            </Grid>
            <Grid container style={{
                display: !expandLanguage && 'none', border: '1px solid #000', marginInline: 90,
                borderTopStyle: 'none', borderRadius: '0 0 10px 10px'
            }}>
                <TextField
                    style={{ paddingInline: 60, marginTop: 20 }}
                    fullWidth
                    variant="filled"
                    placeholder="Search For Language"
                    value={languageSearch}
                    onChange={(e) => setLanguageSearch(e.target.value)}
                    InputProps={{
                        disableUnderline: true,
                        hiddenLabel: true,
                        startAdornment:
                            (<InputAdornment position="start" style={{ marginBottom: 16, marginLeft: -4 }}>
                                <SearchIcon style={{ fontSize: 28, color: '#707070' }} />
                            </InputAdornment>),
                        style: { height: 42, border: '1px solid #707070', borderRadius: 12 }
                    }}
                    inputProps={{ style: { textAlign: 'center', width: '100%' } }}
                />
                <Grid container direction="column" style={{ marginBlock: 10 }}>
                    {languageList.map((language, index) => (
                        language.toLowerCase().includes(languageSearch.toLowerCase()) &&
                        <div key={index} style={{ display: 'flex', marginLeft: 60 }}>
                            <FormControlLabel size="small"
                                value={language}
                                control={<Checkbox style={{ color: '#000' }} />}
                                onChange={() => changeLanguages(language, !languages[language])}
                                label={<NunitoText value={language} fontSize={20} fontWeight={500} color='#5E75C3' marginBottom={4} />}
                                labelPlacement="end"
                            />
                        </div>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    )
}
export function AssignPermissionToUser({ role, username, select, setSelect }) {
    const [checkCreate, setCheckCreate] = useState(false)
    const [checkEdit, setCheckEdit] = useState(false)
    const [checkDelete, setCheckDelete] = useState(false)
    const [checkVerify, setCheckVerify] = useState(false)
    const removeUser = () => {
        let newSelect = [...select]
        //Remove user from the users array in the object
        newSelect.find(select => select.role === role).users =
            newSelect.find(select => select.role === role).users.filter(user => user !== username)
        //If there is no more user in the users array, remove the entire object
        if (!newSelect.find(select => select.role === role).users.length) newSelect = newSelect.filter(select => select.role !== role)
        setSelect(newSelect)
    }
    return (
        <Grid container style={{ marginTop: 18, paddingInline: 15 }}>
            <Grid item xs={1} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <CancelIcon style={{ color: '#E83D4D', fontSize: 36, cursor: 'pointer' }}
                    onClick={() => removeUser()} />
            </Grid>
            <Grid item xs={2.6} style={{ display: 'flex', justifyContent: 'flex-start' }}>
                <NunitoText value={username} fontSize={22} fontWeight={500} />
            </Grid>
            <Grid item xs={2.1}>
                <FormControlLabel control={<Checkbox style={{ color: '#000' }} checked={checkCreate} />}
                    label={<NunitoText value='Create' fontSize={22} fontWeight={500} />}
                    labelPlacement="end" onChange={() => setCheckCreate(!checkCreate)} />
            </Grid>
            <Grid item xs={2.1}>
                <FormControlLabel control={<Checkbox style={{ color: '#000' }} checked={checkEdit} />}
                    label={<NunitoText value='Edit' fontSize={22} fontWeight={500} />}
                    labelPlacement="end" onChange={() => setCheckEdit(!checkEdit)} />
            </Grid>
            <Grid item xs={2.1}>
                <FormControlLabel control={<Checkbox style={{ color: '#000' }} checked={checkDelete} />}
                    label={<NunitoText value='Delete' fontSize={22} fontWeight={500} />}
                    labelPlacement="end" onChange={() => setCheckDelete(!checkDelete)} />
            </Grid>
            <Grid item xs={2.1}>
                <FormControlLabel control={<Checkbox style={{ color: '#000' }} checked={checkVerify} />}
                    label={<NunitoText value='Verify' fontSize={22} fontWeight={500} />}
                    labelPlacement="end" onChange={() => setCheckVerify(!checkVerify)} />
            </Grid>
        </Grid>
    )
}
export default function Collection() {
    document.title = 'Collection'
    const [competitions, setCompetitions] = useState([])
    const [collectionTable, setCollectionTable] = useState()
    const [collections, setCollections] = useState()
    useEffect(() => {
        getCollections().then(d => setCollectionTable({ getter: Object.getOwnPropertyNames(d[0]).slice(), table: d }))
        getCollections().then(d => setCollections({ getter: Object.getOwnPropertyNames(d[0]).slice(), table: d }))
        getCompetitions('').then(c => setCompetitions(c.competitionList))
    }, [])
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [filtered, setFiltered] = useState({ Tags: [], "No. of Sections": '', "No. of Questions": '', Competition: '', Status: '' })
    const [viewOptions, setViewOptions] = useState({
        "No. of section": false, "No. of Questions": false, "Initial Points": false, "Time to Solve": false, Competition: false,
        "Permission to Verify": false, "Created By": false, "Last Modified By": false, "Last Verified By": false, Status: false
    })
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchCollection, setSearchCollection] = useState('')
    useEffect(() => {
        if (collectionTable) {
            if (!searchCollection) {
                setCollectionTable(collections)
                return
            }
            setCollectionTable({
                getter: collections.getter,
                table: collections.table.filter(c => c.collectionName.toLowerCase().includes(searchCollection.toLowerCase()))
            })
        }
    }, [searchCollection])
    const viewOption = () => {
        setViewing(!viewing)
        setFiltering(false)
    }
    const filter = () => {
        setFiltering(!filtering)
        setViewing(false)
    }
    //Select from the table
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    //Delete Modal
    const [onDelete, setOnDelete] = useState(false)
    //Add Collection To Competition Modal
    const [onAddCollectionToCompetition, setOnAddCollectionToCompetition] = useState(false)
    const [competition, setCompetition] = useState('')
    const [round, setRound] = useState('')
    const [openCompetition, setOpenCompetition] = useState(false)
    const [openRound, setOpenRound] = useState(false)
    const [massRound, setMassRound] = useState('')
    const [assignMassRound, setAssignMassRound] = useState(false)
    //Assign Language Modal
    const [onAssignLanguage, setOnAssignLanguage] = useState(false)
    const [assignForCountry, setAssignForCountry] = useState('')
    const countryList = ['Singapore', 'Malaysia', 'Thailand', 'China', 'Vietnam', 'Egypt', 'Laos', 'Germany', 'Russia', 'Japan']
    let competitionOptions = competitions.table.map(c => c.competitionName)
    competitionOptions.unshift('All', 'None')
    //Manage Permission Modal
    const [onManagePermission, setOnManagePermission] = useState(false)
    const users = [{ role: 'Admin', users: ['testUser1', 'testUser612', 'testUser910'] },
    { role: 'Country Partner', users: ['testUser2', 'testUser712', 'testUser210'] },
    { role: 'School Coordinator', users: ['testUser3', 'testUser812', 'testUser340'] },
    { role: 'Testers', users: ['testUser4', 'testUser412', 'eugene'] },
    { role: 'General User', users: ['testUser5', 'testUser512', 'eugene6', 'grayson', 'eugene7'] }]
    const [select, setSelect] = useState([])

    const showWarningSwal = (text) => {
        Swal.fire({
            title: 'Warning!', text: text, icon: 'warning'
        })
    }
    const dispatch = useDispatch()
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} setViewOptions={setViewOptions} rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage} />
            </Collapse>
            <Collapse in={filtering}>
                <Filter type='collapse'
                    filterOptions={[['Secondary 3', 'Hard', 'Easy', 'Too Easy', 'No More', 'Too Hard', 'RREGEFWWDEWFGEWFewdwed'],
                    [1, 2, 3, 4, 5, 6], [1, 2, 3, 4, 5, 6], ['SASMO 2019', 'SASMO 2020', 'SASMO 2021'], ['Pending', 'Approved']]}
                    filtered={filtered} setFiltered={setFiltered} />
            </Collapse>
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Collections' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => dispatch(resetCollection())} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42} onClick={viewOption} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42} onClick={filter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <Grid container alignItems="center" justifyContent="space-between">
                    <NunitoText value='Collections' fontSize={40} fontWeight={700} italic color='#144A94' />
                    <div style={{ position: 'relative', top: filtered.Competition && 80 }}>
                        <ReusableButton text='Assign/Edit Languages' fontSize={15} bgColor='#588BAE' height={46} marginRight={100}
                            onClick={() => !selected.length ? setOnAssignLanguage(true) :
                                showWarningSwal('Please do not select any collection')} iconType='add' />
                    </div>
                </Grid>
                {filtered.Competition !== '' &&
                    <Widget title='Competition' content={filtered.Competition} caption='Individual Competition Name'
                        marginBottom={40} />}
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchCollection} setState={setSearchCollection} onBlur />
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add to Competition' fontSize={15} bgColor='#144A94' height={46} marginRight={20}
                            onClick={() => Boolean(selected.length) ? setOnAddCollectionToCompetition(true) :
                                showWarningSwal('Please select a collection to add to competition')} iconType='add' />
                        <ReusableButton text='Add Collection' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            to='/addcollection' blank iconType='add' />
                        <ReusableButton text='Edit' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => !Boolean(selected.length) && showWarningSwal('Please select a collection to mass edit')}
                            to={Boolean(selected.length) && { pathname: `/addcollection`, state: selected }} iconType='edit' />
                        <ReusableButton text='Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => !Boolean(selected.length) && showWarningSwal('Please select a collection to mass delete')} />
                        <ReusableButton text='Approve' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => !Boolean(selected.length) && showWarningSwal('Please select a collection to approve')}
                            iconType='delete' />
                    </div>
                </Grid>
                {collectionTable && <HeavyTable headers={header} data={collectionTable.table} getters={collectionTable.getter} title='collection'
                    rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} onDelete={onDelete} setOnDelete={setOnDelete}
                    selecting={selecting} setSelecting={setSelecting} selected={selected} setSelected={setSelected} filtered={filtered}
                    setOnManagePermission={setOnManagePermission} />}
            </Grid>
            {JSON.stringify(selected)}
            <Modal open={onDelete} onClose={() => {
                setSelecting([])
                setSelected([])
                setOnDelete(false)
            }}>
                <DeleteModal selecting={selecting} setOnDelete={setOnDelete} />
            </Modal>
            <Modal open={onAddCollectionToCompetition} onClose={() => setOnAddCollectionToCompetition(false)}>
                <Box className='paddedScrollablePopUpModal' style={{ width: 1700, height: 800 }}>
                    <div>
                        <Grid container alignItems="center">
                            <Grid item xs={7.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NunitoText value='ASSIGN COLLECTIONS TO:' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                            </Grid>
                            <Grid item xs={4.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CancelIcon style={{ color: '#E83D4D', fontSize: 32, cursor: 'pointer' }}
                                    onClick={() => setOnAddCollectionToCompetition(false)} />
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 5, px: 5 }}>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center' }}>
                                <NunitoText value='Competition:' fontSize={24} fontWeight={600} marginLeft={60} marginRight={100} />
                                <Autocomplete
                                    size='small'
                                    freeSolo
                                    sx={{ width: 400 }}
                                    value={competition}
                                    onChange={(e, newValue) => setCompetition(newValue)}
                                    options={competitionOptions}
                                    open={openCompetition}
                                    onClose={() => setOpenCompetition(false)}
                                    onOpen={() => setOpenCompetition(true)}
                                    selectOnFocus
                                    renderInput={(params) => <TextField variant="filled"
                                        {...params}
                                        onChange={(e) => setCompetition(e.target.value)}
                                        placeholder="Search for Competition"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            hiddenLabel: true,
                                            startAdornment:
                                                (<InputAdornment position="start" style={{ marginBottom: 12, marginLeft: 4 }}>
                                                    <SearchIcon style={{ fontSize: 28, marginBottom: 18 }} />
                                                </InputAdornment>),
                                            endAdornment:
                                                (<InputAdornment position="end">
                                                    <IconButton onClick={() => setOpenCompetition(!openCompetition)}
                                                        style={{
                                                            backgroundColor: '#5E75C3', borderRadius: '0 8px 8px 0', position: 'relative',
                                                            left: 41, bottom: 9, height: 58, border: '1px solid gray', color: '#FFF'
                                                        }}>
                                                        {openCompetition ? <ExpandLessIcon style={{ fontSize: 36 }} />
                                                            : <ExpandMoreIcon style={{ fontSize: 36 }} />}
                                                    </IconButton>
                                                </InputAdornment>),
                                            style: { borderRadius: 8, height: 58 }
                                        }}
                                        inputProps={{ ...params.inputProps, style: { marginTop: -16 } }}
                                    />}
                                />
                            </Grid>
                            <Grid item xs={6} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', paddingInline: 30 }}>
                                <Autocomplete
                                    size='small'
                                    freeSolo
                                    sx={{ width: 360 }}
                                    value={round}
                                    onChange={(e, newValue) => setRound(newValue)}
                                    options={['Round 1', 'Round 2', 'Round 3']}
                                    open={openRound}
                                    onClose={() => setOpenRound(false)}
                                    onOpen={() => competition && setOpenRound(true)}
                                    selectOnFocus
                                    renderInput={(params) => <TextField variant="filled"
                                        {...params}
                                        onChange={(e) => setRound(e.target.value)}
                                        placeholder="Assign Round"
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                            hiddenLabel: true,
                                            endAdornment:
                                                (<InputAdornment position="end">
                                                    <IconButton onClick={() => competition && setOpenRound(!openRound)}
                                                        style={{
                                                            backgroundColor: '#5E75C3', borderRadius: '0 8px 8px 0', position: 'relative',
                                                            left: 41, bottom: 9, height: 50, border: '1px solid gray', color: '#FFF'
                                                        }}>
                                                        {openRound ? <ExpandLessIcon style={{ fontSize: 36 }} />
                                                            : <ExpandMoreIcon style={{ fontSize: 36 }} />}
                                                    </IconButton>
                                                </InputAdornment>),
                                            style: { borderRadius: 8, height: 50, backgroundColor: !competition && '#707070' },
                                            readOnly: !competition
                                        }}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                marginTop: -18, textAlign: 'center',
                                                color: !competition && '#B8B8B8'
                                            }
                                        }}
                                    />}
                                />
                                <ReusableButton text='Mass Assign Round' fontSize={16} height={50} bgColor='#144A94' marginRight={20}
                                    onClick={competition ? () => { setMassRound(round); setAssignMassRound(!assignMassRound) } : null}
                                    iconType='add' />
                            </Grid>
                        </Grid>
                        {collectionTable && selected.map((s, index) => (
                            <AssignCollectionsToCompetition key={index} collection={collectionTable.table.filter(t => t.id === s)[0]}
                                count={index} selected={selected} setSelected={setSelected} massRound={massRound}
                                assignMassRound={assignMassRound} />
                        ))}
                    </div>
                    <div style={{ marginBottom: 30 }}>
                        <ReusableButton text='Submit' fontSize={22} width={300} height={60} bgColor='#5E75C3' />
                    </div>
                </Box>
            </Modal>
            <Modal open={onAssignLanguage} onClose={() => setOnAssignLanguage(false)}>
                <Box className='scrollablePopUpModal' style={{ width: 900, height: 820 }}>
                    <div>
                        <Grid container alignItems="center">
                            <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <NunitoText value='ASSIGN / EDIT LANGUAGES' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                            </Grid>
                            <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <CancelIcon style={{ color: '#E83D4D', fontSize: 32, cursor: 'pointer' }}
                                    onClick={() => setOnAssignLanguage(false)} />
                            </Grid>
                        </Grid>
                        <ReusableTextField type='search' width={500} height={52} placeholder='Search For Country'
                            state={assignForCountry} setState={setAssignForCountry} onBlur />
                        <TextField
                            style={{ paddingInline: 30, marginTop: 20 }}
                            fullWidth
                            variant="filled"
                            placeholder="Search For Country"
                            value={assignForCountry}
                            onChange={(e) => setAssignForCountry(e.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                hiddenLabel: true,
                                startAdornment:
                                    (<InputAdornment position="start" style={{ marginBottom: 16, marginLeft: -4 }}>
                                        <SearchIcon style={{ fontSize: 28, color: '#707070' }} />
                                    </InputAdornment>),
                                style: { height: 52, border: '1px solid #707070', borderRadius: 12 }
                            }}
                            inputProps={{ style: { textAlign: 'center', width: '100%' } }}
                        />
                        {countryList.map((country, index) => (
                            country.toLowerCase().includes(assignForCountry.toLowerCase()) &&
                            <AssignLanguageToCollection key={index} country={country} count={index} />
                        ))}
                    </div>
                    <div style={{ marginTop: 40 }}>
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={22} height={60} width={300} marginBottom={16} />
                        <NunitoText value='*Selections will apply to all collections within the country.' fontSize={20} color='#144A94'
                            italic />
                    </div>
                </Box>
            </Modal>
            <Modal open={onManagePermission} onClose={() => setOnManagePermission(false)}>
                <Box className='popUpModal' style={{ width: 920, height: 720, display: 'block' }}>
                    <Grid container alignItems="center">
                        <Grid item xs={9} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <NunitoText value='MANAGE PERMISSIONS FOR' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                        </Grid>
                        <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <CancelIcon style={{ color: '#E83D4D', fontSize: 32, cursor: 'pointer' }}
                                onClick={() => setOnManagePermission(false)} />
                        </Grid>
                    </Grid>
                    <NunitoText value={selecting[0]} fontSize={30} fontWeight={600} color='#F16774' marginTop={10} marginBottom={20} />
                    <CustomNestedSelectMultiple placeholder="Search for user / role" data={users} select={select} setSelect={setSelect} width={800}
                        outerKey={Object.keys(users[0])[0]} innerKey={Object.keys(users[0])[1]} />
                    <Box style={{ border: '1px solid #707070', borderRadius: 6, margin: '20px 46px', height: 380, overflowY: 'scroll' }}>
                        <NunitoText value='Permissions' fontSize={30} fontWeight={600} color='#144A94' />
                        <Divider style={{ backgroundColor: '#000', borderBottomWidth: 1, margin: '8px 20px' }} />
                        {select.map(s => (
                            s.users.map((user, i) => (
                                <AssignPermissionToUser key={i} role={s.role} username={user} select={select} setSelect={setSelect} />
                            ))
                        ))}
                    </Box>
                </Box>
            </Modal>
        </Box >
    )
}