import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { invalidName, invalidPhoneOrGrade, invalidEmail } from '../../functions/general'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { patchSchool } from '../../functions/patchData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
export default function EditSchoolModal({ afterEditingSchool, id, schools, cancelEdit }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const user = useSelector(state => state.user)
    const school = schools.find(s => s.id === id)
    const [name, setName] = useState(school.name || '')
    const [address, setAddress] = useState(school.address || '')
    const [postalCode, setPostalCode] = useState(school.postal || '')
    const [province, setProvince] = useState(school.province || '')
    const [phoneNumber, setPhoneNumber] = useState(school.phone || '')
    const [email, setEmail] = useState(school.email || '')

    const original = {
        name: school.name || '', address: school.address || '', postalCode: school.postal || '', province: school.province || '',
        phoneNumber: school.phone || '', email: school.email || ''
    }
    const current = {
        name, address, postalCode, province, phoneNumber, email
    }
    const unChanged = JSON.stringify(original) === JSON.stringify(current)
    const onSubmit = () => {
        if (unChanged) {
            showWarningSwal('Please make some changes before submitting')
            return
        }
        let arr = [
            { name: 'Phone Number', state: phoneNumber },
            { name: 'Email', state: email }
        ]
        if (user.role_id === 1) arr.unshift({ name: 'School Name', state: name })
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        let msg = ''
        if (invalidName(name)) msg += 'Invalid school name. '
        if (invalidPhoneOrGrade(phoneNumber)) msg += 'Invalid phone number. '
        if (invalidEmail(email)) msg += 'Invalid email'
        if (msg.length) {
            showWarningSwal(msg)
            return
        }
        let payload = { id, name }
        if (address !== '') payload.address = address
        if (postalCode !== '') payload.postal = postalCode
        if (phoneNumber !== '') payload.phone = phoneNumber
        if (province !== '') payload.province = province
        if (email !== '') payload.email = email
        console.log(payload)
        patchSchool(payload).then(d => {
            console.log(d)
            if (d.status === 200) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                afterEditingSchool()
                cancelEdit()
            }
        })
    }
    const rejectionWrapperStyle = {
        backgroundColor: '#F3F0F4', border: '1px solid', borderRadius: 8,
        marginBottom: 20, marginInline: 30, paddingInline: 20, paddingBlock: '10px 20px'
    }
    const firstRowStyle = {
        display: 'flex', alignItems: 'center', justifyContent: 'space-between',
        marginTop: 20, borderBottom: '1px solid #707070'
    }
    const isPartner = user.role_id === 2
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 600 }}>
            <div>
                <NunitoText value='EDIT SCHOOL' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='School Name*'
                        state={name} setState={setName} required grayedOut={isPartner} readOnly={isPartner} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Address'
                        state={address} setState={setAddress} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Postal Code'
                        state={postalCode} setState={setPostalCode} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Province'
                        state={province} setState={setProvince} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                        state={phoneNumber} setState={setPhoneNumber} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                        state={email} setState={setEmail} required />
                </Grid>
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelEdit()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
            {Boolean(school.reject_reason.length) && <div style={rejectionWrapperStyle}>
                <NunitoText value='Reason for rejection(Latest)' fontSize={24} />
                <div style={firstRowStyle}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <NunitoText value={`${school.reject_reason.at(-1).user.username}(${school.reject_reason.at(-1).role.name})`}
                            fontSize={24} fontWeight={300} color='#5E75C3' italic />
                        <NunitoText value={school.reject_reason.at(-1).created_by.slice(-20)}
                            fontSize={15} fontWeight={300} color='#F16774' italic marginLeft={10} />
                    </div>
                    <NunitoText value={`Has been rejected ${school.reject_reason.length} time(s)`} fontSize={20} fontWeight={300} />
                </div>
                <NunitoText value={school.reject_reason.at(-1).reason} fontSize={20} marginTop={16} align='left' />
            </div>}
            {/* {JSON.stringify({
                id, name, address: address, postal: postalCode, phone: phoneNumber,
                province: province
            })} */}
        </Box>
    )
}