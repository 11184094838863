import React, { useState, useEffect } from 'react'
import { Link, useParams } from "react-router-dom";
import { Box, Grid, Button, TextField, InputAdornment, IconButton, Typography, styled } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import { getUser } from '../functions/getData';
import { FaUserAlt } from 'react-icons/fa'
import { FiUser } from 'react-icons/fi'
import NunitoText from '../components/general/NunitoText';
const StyledTextField = styled(TextField)`
  .MuiFilledInput-root {
    cursor: pointer;
  }
`
export function Permission({ index, permission, lastIndex, original, header, permissions, setPermissions }) {
    const updateByValue = (object, value, changedTo) => {
        let number = Object.keys(object).find(key => Object.keys(object[key])[0] === value);
        object[number][value] = changedTo
    }
    //Boolean value of permission
    let allow = permission[Object.keys(permission)]
    const updatePermission = (value) => {
        let newPermissions = [...permissions]
        newPermissions[index][Object.keys(newPermissions[index])] = value
        //If setting a permission to true
        //And the permission getting set to true is either 'View All' or 'View Own Entries Only', set the other one to false
        if (value && Object.keys(newPermissions[index])[0] === 'View All') updateByValue(permissions, 'View Own Entries Only', false)
        if (value && Object.keys(newPermissions[index])[0] === 'View Own Entries Only') updateByValue(permissions, 'View All', false)
        //If setting the permission 'Marking and Grades', set all of its related permission to the same condition
        if (Object.keys(newPermissions[index])[0] === 'Marking and Grades') {
            updateByValue(permissions, 'Read Only', value)
            updateByValue(permissions, 'Edit', value)
            updateByValue(permissions, 'Compute', value)
        }
        setPermissions(newPermissions)
    }
    //Check if the current boolean value of permission is different from the original, used to decide color of text
    let isChanged = allow !== original[index][Object.keys(original[index])]
    return (
        ['Read Only', 'Edit', 'Compute'].includes(Object.keys(permission)[0]) ?
            <Grid container justifyContent='space-between' alignItems='center' style={{
                marginInline: 30, marginTop: 20,
                marginBottom: index === lastIndex && 100
            }}>
                <div style={{ display: 'flex', alignItems: 'center', color: isChanged ? '#FF7600' : '#000' }}>
                    <SubdirectoryArrowRightIcon />
                    <Typography style={{ fontSize: 18, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic', marginLeft: 10 }}>
                        {Object.keys(permission)}</Typography>
                </div>
                <StyledTextField
                    variant="filled"
                    value={allow}
                    onClick={() => !index ? updatePermission(!allow) : header ? updatePermission(!allow) : null}
                    inputProps={{ style: { textAlign: 'center', width: '100%', visibility: 'hidden' } }}
                    InputProps={{
                        disableUnderline: true,
                        hiddenLabel: true,
                        readOnly: true,
                        startAdornment:
                            (!allow && <InputAdornment position="start">
                                <IconButton style={{
                                    backgroundColor: '#F16774', borderRadius: 10,
                                    marginBottom: '26%', marginLeft: '-23%', width: 60, height: 44
                                }}>
                                    <ClearIcon style={{ fontSize: 36, color: 'white', height: 25 }} />
                                </IconButton>
                            </InputAdornment>),
                        endAdornment:
                            (allow && <InputAdornment position="end">
                                <IconButton style={{
                                    backgroundColor: '#5e75c3', borderRadius: 10,
                                    marginLeft: '24%', width: 60, height: 44
                                }}>
                                    <CheckIcon style={{ fontSize: 36, color: 'white', height: 25 }} />
                                </IconButton>
                            </InputAdornment>),
                        style: {
                            border: '2px solid gray', borderRadius: 12, width: 120, height: 45,
                            backgroundColor: !index ? '#FFF' : header ? '#FFF' : '#707070'
                        }
                    }} />
            </Grid> :
            <Grid container justifyContent='space-between' alignItems='center' style={{
                marginInline: 30, marginTop: index ? 30 : 20,
                marginBottom: index === lastIndex && 100
            }}>
                {!index ? <Typography style={{
                    color: '#144A94', textDecoration: 'underline', fontSize: 30, fontFamily: 'Nunito', fontWeight: 700,
                    fontStyle: 'italic'
                }}>{Object.keys(permission)}</Typography> : Object.keys(permission)[0] === 'Enable Whitelist' ?
                    <div style={{ display: 'flex', alignItems: 'center', color: isChanged ? '#FF7600' : '#000' }}>
                        <Typography style={{
                            fontSize: 20, fontFamily: 'Nunito', fontWeight: 600,
                            fontStyle: 'italic'
                        }}>{Object.keys(permission)}</Typography>
                        <VisibilityIcon style={{ marginLeft: 30 }} /></div> :
                    <Typography style={{
                        color: isChanged ? '#FF7600' : '#000', fontSize: 20, fontFamily: 'Nunito', fontWeight: 600,
                        fontStyle: 'italic'
                    }}>{Object.keys(permission)}</Typography>}
                <StyledTextField
                    variant="filled"
                    value={allow}
                    onClick={() => !index ? updatePermission(!allow) : header ? updatePermission(!allow) : null}
                    inputProps={{ style: { textAlign: 'center', width: '100%', visibility: 'hidden' } }}
                    InputProps={{
                        disableUnderline: true,
                        hiddenLabel: true,
                        readOnly: true,
                        startAdornment:
                            (!allow && <InputAdornment position="start">
                                <IconButton style={{
                                    backgroundColor: '#F16774', borderRadius: 10,
                                    marginBottom: '26%', marginLeft: '-23%', width: 60, height: 44
                                }}>
                                    <ClearIcon style={{ fontSize: 36, color: 'white', height: 25 }} />
                                </IconButton>
                            </InputAdornment>),
                        endAdornment:
                            (allow && <InputAdornment position="end">
                                <IconButton style={{
                                    backgroundColor: '#5e75c3', borderRadius: 10,
                                    marginLeft: '24%', width: 60, height: 44
                                }}>
                                    <CheckIcon style={{ fontSize: 36, color: 'white', height: 25 }} />
                                </IconButton>
                            </InputAdornment>),
                        style: {
                            border: '2px solid gray', borderRadius: 12, width: 120, height: 45,
                            backgroundColor: !index ? '#FFF' : header ? '#FFF' : '#707070'
                        }
                    }} />
            </Grid>
    )
}
export function PermissionGroup({ permissionGroup }) {
    //Copy of permissions to check if any changes is made to the permissions later
    const original = permissionGroup.map(p => { return { [p]: false } })
    //Get an array of objects with label as key and boolean as value
    const [permissions, setPermissions] = useState(permissionGroup.map(p => { return { [p]: false } }))
    return (
        <Grid container
            style={{ border: '1px solid #707070', borderRadius: 16, boxShadow: '0 0 5px #9E9E9E', width: 735, marginBlock: '30px 40px' }}>
            {permissions.map((permission, index) => (
                <Permission key={index} index={index} permission={permission} lastIndex={permissions.length - 1} original={original}
                    header={permissions[0][Object.keys(permissions[0])]} permissions={permissions} setPermissions={setPermissions} />
            ))}
        </Grid>
    )
}
export default function UserPermission() {
    document.title = 'User Permission'
    let { id } = useParams()
    const [user, setUser] = useState()
    //Get user by id from parameter
    useEffect(() => {
        getUser(id).then(u => setUser(u))
    }, [])
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ padding: 12 }}>
                <Button className='dashboardBtn' startIcon={<HomeIcon />} >
                    <Link to='/dashboard' style={{ textDecoration: 'none', color: '#FFF' }}>Dashboard</Link>
                </Button>
                <ChevronRightIcon />
                <Button className='dashboardBtn'>
                    User Permissions
                </Button>
            </div>
            <Grid container justifyContent='space-between' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='User Permissions' fontSize={40} fontWeight={700} italic color='#144A94' />
                {user && <Grid container style={{
                    backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 24, boxShadow: '0 0 5px #9E9E9E',
                    height: 130, width: 300, marginRight: 80
                }}>
                    <Grid item xs={6} style={{ padding: '30px 0 0 36px' }}>
                        <FaUserAlt style={{ color: '#7792EE', fontSize: 52 }} />
                    </Grid>
                    <Grid item xs={6} style={{
                        display: 'flex', flexDirection: 'column', alignItems: 'flex-end',
                        paddingRight: 20, paddingTop: 20
                    }}>
                        <Typography style={{ color: '#707070', fontSize: 20, fontFamily: 'Nunito', fontStyle: 'italic' }}>Username</Typography>
                        <Typography style={{ color: '#000', fontSize: 30, fontFamily: 'Nunito', fontWeight: 500 }}>{user.username}</Typography>
                    </Grid>
                    <Grid container justifyContent='center'>
                        <FiUser style={{ fontSize: 20 }} />
                        <Typography style={{ color: '#707070', fontSize: 16, fontFamily: 'Nunito' }}>User Permissions</Typography>
                    </Grid>
                </Grid>}
            </Grid>
            {user && <Grid container alignItems="flex-start">
                <Grid item xs={6} style={{ paddingLeft: 120 }}>
                    <PermissionGroup permissionGroup={['User Role Management', 'View', 'Create', 'Edit/Delete']} />
                    <PermissionGroup permissionGroup={['Collection Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Approve Pending', 'Assign Permissions', 'Enable Whitelist']} />
                    <PermissionGroup permissionGroup={['School Management', 'View', 'Create', 'Edit/Delete', 'Merge/Approve']} />
                    <PermissionGroup permissionGroup={['Participant Management', 'View Participants', 'Create', 'Edit/Delete', 'Approve',
                        'Create Schools']} />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 65 }}>
                    <PermissionGroup permissionGroup={['Difficulty Management', 'View', 'Create', 'Edit/Delete']} />
                    <PermissionGroup permissionGroup={['Competition Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Marking and Grades', 'Read Only', 'Edit', 'Compute']} />
                    <PermissionGroup permissionGroup={['Task Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Approve Pending', 'Assign Permissions', 'Enable Whitelist']} />
                </Grid>
            </Grid>}
        </Box>
    )
}