import React from 'react'
import { Typography } from '@mui/material'
export default function NunitoText({ value, fontSize, fontWeight, italic, color,
    marginLeft, marginRight, marginTop, marginBottom, borderBottom, width, paddingBottom, textDecoration, align }) {
    const style = {
        width: width,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        fontFamily: 'Nunito',
        fontSize: fontSize,
        fontWeight: fontWeight,
        fontStyle: italic && 'italic',
        color: color,
        borderBottom: borderBottom,
        paddingBottom: paddingBottom,
        textDecoration: textDecoration,
        textAlign: align || 'center'
    }
    return <Typography style={style}>{value.replace('false', '')}</Typography>
}