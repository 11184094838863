import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { invalidName, invalidPhoneOrGrade, invalidEmail } from '../../functions/general'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { addSchool } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
export default function AddSchoolModal({ setOnAddSchool, afterAddingSchool }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const user = useSelector(state => state.user)
    const countryOptions = useSelector(state => state.countryOptions)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [postalCode, setPostalCode] = useState('')
    const [province, setProvince] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [isTuitionCentre, setIsTuitionCentre] = useState(false)
    const [country, setCountry] = useState('')
    const [email, setEmail] = useState('')
    const onSubmit = () => {
        let arr = [
            { name: 'School Name', state: name },
            { name: 'Phone Number', state: phoneNumber },
            { name: 'Email', state: email }
        ]
        if (user.role_id === 1) arr.splice(1, 0, { name: 'Country', state: country })
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        let msg = ''
        if (invalidName(name)) msg += 'Invalid school name. '
        if (invalidPhoneOrGrade(phoneNumber)) msg += 'Invalid phone number. '
        if (invalidEmail(email)) msg += 'Invalid email'
        if (msg.length) {
            showWarningSwal(msg)
            return
        }
        let payload = [{
            country_id: user.role_id === 1 ? country : user.country_id,
            name, email, private: Number(isTuitionCentre)
        }]
        if (address !== '') payload[0].address = address
        if (postalCode !== '') payload[0].postal = postalCode
        if (phoneNumber !== '') payload[0].phone = phoneNumber
        if (province !== '') payload[0].province = province
        console.log(payload)
        addSchool(payload).then(d => {
            console.log(d)
            if (d.status === 201) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                afterAddingSchool()
                setOnAddSchool(false)
            }
        })
    }
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 600 }}>
            <div>
                <Grid container alignItems='center' style={{ marginTop: 20 }}>
                    <Grid item xs={7.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NunitoText value='ADD NEW SCHOOL' fontSize={30} fontWeight={600} color='#5E75C3' />
                    </Grid>
                    <Grid item xs={4.5}>
                        <ReusableCheckbox type='default' label='Tuition Centre'
                            state={isTuitionCentre} setState={setIsTuitionCentre} fontSize={20} />
                    </Grid>
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='School Name*'
                        state={name} setState={setName} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Address'
                        state={address} setState={setAddress} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Postal Code'
                        state={postalCode} setState={setPostalCode} />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Province'
                        state={province} setState={setProvince} />
                </Grid>
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                        state={phoneNumber} setState={setPhoneNumber} required />
                    {user.role_id === 1 ? <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country*'
                        state={country} setState={setCountry} btnBgColor='#144A94' borderColor='#707070' required
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} /> :
                        <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                            state={email} setState={setEmail} required />}
                </Grid>
                {user.role_id === 1 && <Grid container style={{ marginBottom: 30, marginLeft: 33 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                        state={email} setState={setEmail} required />
                </Grid>}
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => setOnAddSchool(false)} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
            {/* {JSON.stringify({
                country_id: user.role_id === 1 ? country : user.country_id, name, address, postal: postalCode, phone: phoneNumber,
                province, private: Number(isTuitionCentre), email
            })} */}
        </Box >
    )
}