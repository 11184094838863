import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import AddUserModal from '../components/modal/AddUserModal'
import EditUserModal from '../components/modal/EditUserModal'
import '../css/school.css'
import '../css/global.css'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
    makeOptions, ensureObjectOfArraysArePopulated, makeSureIsArray, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getUsers } from '../functions/getData'
import { enableUser, disableUser } from '../functions/patchData'
import { deleteUser } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const userHeader = [
    { id: 'name', label: 'Name' },
    { id: 'role_name', label: 'Role' },
    // { id: 'permissionOverriden', label: 'Permission Overridden?' },
    { id: 'email', label: 'Email' },
    { id: 'country_name', label: 'Country' },
    { id: 'created_by_username', label: 'Created By' },
    { id: 'modified_by_username', label: 'Last Modified By' },
    { id: 'last_login', label: 'Last Login' },
    { id: 'status', label: 'Status' }
]
const initialParams = '?'
export default function Users() {
    document.title = 'Users'
    const user = useSelector(state => state.user)
    let location = useLocation()
    const [header, setHeader] = useState(userHeader)
    const [userTable, setUserTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Status', state: '', key: 'status', header: 'status', },
        { label: 'Country', state: '', key: 'country_id', header: 'countries' },
        { label: 'Role', state: '', key: 'role_id', header: 'role' },
        { label: 'School', state: '', key: 'school_id', header: 'schools' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        // { label: "Permission Overridden?", state: true, key: 'created_by_username' },
        { label: 'E-Mail', state: true, key: 'email' },
        { label: "Country", state: true, key: 'country_name' },
        { label: 'Created By', state: true, key: 'created_by_username' },
        { label: "Modified By", state: true, key: 'modified_by_username' },
        { label: 'Last Login', state: true, key: 'last_login' },
        { label: 'Status', state: true, key: 'status' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchUser, setSearchUser] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddNewUser, setOnAddNewUser] = useState(false)
    const [onEditUser, setOnEditUser] = useState(false)
    const [onEnable, setOnEnable] = useState(false)
    const [onDisable, setOnDisable] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelEdit = () => cancelAction(setOnEditUser, setSelected, setSelecting)
    const cancelEnable = () => cancelAction(setOnEnable, setSelected, setSelecting)
    const cancelDisable = () => cancelAction(setOnDisable, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteUser({ id: selected }).then(d => {
            console.log(d)
            getData(params)
            cancelDelete()
        })
    }
    const enableFunction = () => {
        enableUser({ id: selected }).then(d => {
            getData(params)
            cancelEnable()
        })
    }
    const disableFunction = () => {
        disableUser({ id: selected }).then(d => {
            getData(params)
            cancelDisable()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getUsers, params, setUserTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(userHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchUser, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchUser, params, setParams, getData)
    }
    const onChangeSearchUser = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchUser, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        if (['status', 'role_id'].every(el => location.search.includes(el))) {
            // If came to this page from clicking the hyperlink on Schools page or Competitions page
            let newFiltered = [...filtered]
            location.search.replace('?', '').split('&').forEach((l) => {
                let head = l.split('=')[0]
                if (['status', 'country_id', 'role_id', 'school_id'].includes(head))
                    newFiltered.find(n => n.key === head).state = l.split('=')[1]
            })
            if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
            else setFiltered(newFiltered)
            changeFiltered(initialParams, filtered, rowsPerPage, setSearchUser, params, setParams, getData)
            getData(location.search)
        } else {
            if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
            getData(params)
        }
    }, [])
    const onClickMassEnable = () => {
        return userTable && checkIfAllSelectedRowsMatchStatus(selected, userTable.data, 'disabled') ?
            setOnEnable(true) : showWarningSwal('Please select disabled users only to mass enable')
    }
    const onClickMassDisable = () => {
        return userTable && checkIfAllSelectedRowsMatchStatus(selected, userTable.data, 'active') ?
            setOnDisable(true) : showWarningSwal('Please select active users only to mass disable')
    }
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one user to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Users' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            {/* {JSON.stringify(params)}<br />
            {JSON.stringify(location.search)}<br />
            {location.search.replace('?', '').split('&').map(l => (
                <p>{JSON.stringify(l.split('=')[1])}</p>
            ))}<br /> */}
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Users' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchUser} setState={onChangeSearchUser} onBlur />
                    <div style={{ marginRight: 40 }}>
                        {user.role_id === 1 && <ReusableButton text='Mass Enable User' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassEnable()} iconType='enable' />}
                        {user.role_id === 1 && <ReusableButton text='Mass Disable User' fontSize={15} bgColor='#F16774' height={46}
                            marginRight={20} onClick={() => onClickMassDisable()} iconType='disable' />}
                        {user.role_id === 1 && <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                        <ReusableButton text='Add New User' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => setOnAddNewUser(true)} iconType='add' />
                    </div>
                </Grid>
                <NunitoText value='Search only in "Name" and "Email" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Loader height={600} /> : userTable && makeSureIsArray(userTable.data).length ?
                    <HeavyTable headers={header} list={userTable} title='users' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setOnEdit={setOnEditUser}
                        setOnEnable={setOnEnable} setOnDisable={setOnDisable}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </Modal>
            <Modal open={onAddNewUser} onClose={() => setOnAddNewUser(false)}>
                <AddUserModal setOnAddNewUser={setOnAddNewUser} afterAddingUser={() => getData(params)} />
            </Modal>
            <Modal open={onEditUser} onClose={() => cancelEdit()}>
                {userTable && selected.length &&
                    <EditUserModal afterEditingUser={() => getData(params)} users={userTable.data} id={selected[0]}
                        cancelEdit={cancelEdit} />}
            </Modal>
            <Modal open={onEnable} onClose={() => cancelEnable()}>
                <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
                    <NunitoText value='CONFIRM ENABLE' fontSize={30} fontWeight={600}
                        color='#5E75C3' marginTop={30} />
                    <NunitoText value='Are you sure you want to enable' fontSize={20} fontWeight={600} />
                    {selecting.map((name, i) => (
                        <NunitoText key={i} value={`${name}${i < selecting.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
                    ))}
                    <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63}
                            width={280} onClick={() => cancelEnable()} />
                        <ReusableButton text='Confirm' bgColor='#5E75C3' fontSize={25} height={63}
                            width={280} onClick={() => enableFunction()} />
                    </Grid>
                </Box>
            </Modal>
            <Modal open={onDisable} onClose={() => cancelDisable()}>
                <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
                    <NunitoText value='CONFIRM DISABLE' fontSize={30} fontWeight={600} color='#F16774' marginTop={30} />
                    <NunitoText value='Are you sure you want to disable' fontSize={20} fontWeight={600} />
                    {selecting.map((name, i) => (
                        <NunitoText key={i} value={`${name}${i < selecting.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
                    ))}
                    <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63}
                            width={280} onClick={() => cancelDisable()} />
                        <ReusableButton text='Confirm' bgColor='#F16774' fontSize={25} height={63}
                            width={280} onClick={() => disableFunction()} />
                    </Grid>
                </Box>
            </Modal>
        </Box >
    )
}