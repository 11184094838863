import React from 'react'
export default function Copyright() {
    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
            backgroundColor: '#144A94', height: '5vh', color: '#FFF', fontFamily: 'Roboto', fontSize: '1.17em', fontWeight: 'bolder'
        }}>
            Copyright @SIMCC 2021(Built on 28/06/2022 Tues 13:30)
        </div>
    )
}