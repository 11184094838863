import React, { useEffect, useState } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { isEmpty, warningMessage } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { addParticipant } from '../../functions/postData'
import { getCompetitions, getSchools, getUsers } from '../../functions/getData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
export default function AddParticipantModal({ setOnAddParticipant, afterAddingParticipant, selectedSchool, cancelAddParticipant }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const user = useSelector(state => state.user)
    const [competitionOptions, setCompetitionOptions] = useState([])
    const allCountryOptions = useSelector(state => state.countryOptions)
    const [countryOptions, setCountryOptions] = useState([])
    const [allSchoolOptions, setAllSchoolOptions] = useState([])
    const [schoolOptions, setSchoolOptions] = useState([])
    const [allTuitionCentreOptions, setAllTuitionCentreOptions] = useState([])
    const [tuitionCentreOptions, setTuitionCentreOptions] = useState([])
    const [partnerCentreOptions, setPartnerCentreOptions] = useState([])
    const [allPartnerOptions, setAllPartnerOptions] = useState([])
    const [partnerOptions, setPartnerOptions] = useState([])

    const [name, setName] = useState('')
    const [studentClass, setStudentClass] = useState('')
    const [competition, setCompetition] = useState('')
    const [country, setCountry] = useState('')
    const [partner, setPartner] = useState('')
    const [school, setSchool] = useState('')
    const [tuitionCentre, setTuitionCentre] = useState('')
    const [grade, setGrade] = useState('')
    const [forPartner, setForPartner] = useState(false)
    const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)
    let allowedStatus = ['active', user.role_id < 3 && 'ready', user.role_id < 2 && 'lock'].filter(Boolean)
    useEffect(() => {
        if (selectedSchool) {
            setCountryOptions([{ id: selectedSchool.country_id, display_name: selectedSchool.country_name }])
            setCountry(selectedSchool.country_id)
            getCompetitions('?limits=50&status=active').then(c => {
                let competitionsWithPartner = c.competitionList.data
                    .filter(d =>
                        d.partners.filter(p => allowedStatus.includes(p.partnerRegDateStatus)) &&
                        d.partners.map(p => p.country_id).includes(selectedSchool.country_id)
                    )
                setCompetitionOptions(competitionsWithPartner)
            })
            if (user.role_id < 3) {
                if (user.role_id === 1) {
                    getUsers(`?limits=100&status=active&role_id=2`).then(u => setAllPartnerOptions(u.userLists.data))
                }
            }
            if (selectedSchool.private) {
                getSchools(`?limits=100&status=active&country_id=${selectedSchool.country_id}&private=0`).then(s => {
                    setSchoolOptions(s.SchoolLists.data)
                })
                setTuitionCentreOptions([selectedSchool])
                setTuitionCentre(selectedSchool.id)
            } else {
                setSchoolOptions([selectedSchool])
                setSchool(selectedSchool.id)
            }
        } else {
            getCompetitions('?limits=50&status=active').then(c => {
                if (user.role_id === 1) setCompetitionOptions(c.competitionList.data)
                else if (user.role_id === 2) {
                    setCompetitionOptions(c.competitionList.data.filter(d => d.partners.filter(d => ['ready', 'active']
                        .includes(d.partnerRegDateStatus)).map(d => d.id).includes(user.user_id)))
                } else {
                    setCompetitionOptions(c.competitionList.data.filter(d => d.partners.filter(d => ['active']
                        .includes(d.partnerRegDateStatus)).map(d => d.id).includes(user.parent_id)))
                }
            })
            if (user.role_id === 1) {
                getUsers(`?limits=100&status=active&role_id=2`).then(u => {
                    setAllPartnerOptions(u.userLists.data)
                    setPartnerCentreOptions(u.userLists.data.map((c, i) => (
                        { id: c.school_id || i, name: c.school_name || `owo - ${i}`, user_id: c.id }
                    )))
                })
            } else if (user.role_id === 2) {
                getSchools('?limits=100&status=active').then(s => {
                    setAllSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                    setAllTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
                })
                setPartnerCentreOptions([{ id: user.school_id, name: `${user.username} - School`, user_id: user.user_id }])
            } else if (user.role_id === 3) {
                getSchools(`?country_id=${user.country_id}&limits=100&status=active`).then(s => {
                    setTeacherBelongsToTuitionCentre(s.SchoolLists.data.find(a => a.id === user.school_id).private)
                    if (s.SchoolLists.data.find(a => a.id === user.school_id).private) {
                        setSchoolOptions(s.SchoolLists.data.filter(d => !d.private))
                    }
                })
            }
        }
    }, [])
    const getPartners = value => {
        let partnerIDs = competitionOptions.find(c => c.id === value).partners
            .filter(p => allowedStatus.includes(p.partnerRegDateStatus) && new Date(p.partnerCompetitionDates.at(-1)) > new Date())
        let userOutput = []
        userOutput = allPartnerOptions.filter(a => partnerIDs.map(p => p.id).includes(a.id))
        return userOutput
    }
    const onChangeCompetition = value => {
        setCompetition(value)
        setPartner('')
        setPartnerOptions([])
        if (!selectedSchool) {
            setCountry('')
            if (school !== -1) setSchool('')
            if (user.role_id !== 2) setTuitionCentre('')
            setCountryOptions([])
            setSchoolOptions([])
            if (user.role_id !== 2) setTuitionCentreOptions([])
        }
        if (value !== '') {
            if (user.role_id === 1) {
                if (selectedSchool) {
                    // setPartnerOptions(allPartnerOptions.filter(u => u.country_id === country))
                    setPartnerOptions(getPartners(value).filter(u => u.country_id === country))
                } else {
                    let countryIDs = [...new Set(competitionOptions.find(c => c.id === value).partners
                        .filter(p => ['active', 'ready', 'lock'].includes(p.partnerRegDateStatus)).map(p => p.country_id))]
                    let output = []
                    countryIDs.forEach(c => output.push(allCountryOptions.find(d => d.id === c)))
                    setCountryOptions(output)
                }
            } else if (user.role_id === 2) {
                setSchoolOptions(allSchoolOptions)
                setTuitionCentreOptions(allTuitionCentreOptions)
            }
        }
    }
    // onChangeCountry and onChangePartner is only used for Admin
    const onChangeCountry = value => {
        setCountry(value)
        setPartner('')
        if (school !== -1) setSchool('')
        setTuitionCentre('')
        setPartnerOptions([])
        setSchoolOptions([])
        setTuitionCentreOptions([])
        if (value !== '') {
            getSchools(`?country_id=${value}&status=active&limits=100`).then(s => {
                setAllSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                setAllTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
                setSchoolOptions(s.SchoolLists.data.filter(s => !s.private))
                setTuitionCentreOptions(s.SchoolLists.data.filter(s => s.private))
            })
            setPartnerOptions(getPartners(competition).filter(u => u.country_id === value))
        }
    }
    const onChangePartner = value => {
        setPartner(value)
        if (!selectedSchool) {
            if (school !== -1) setSchool('')
            setTuitionCentre('')
        }
        if (value !== '' && !selectedSchool) {
            let selectedUser = partnerOptions.find(u => u.id === value)
            setSchoolOptions(allSchoolOptions.filter(s => s.country_id === selectedUser.country_id))
            setTuitionCentreOptions(allTuitionCentreOptions.filter(t => t.country_id === selectedUser.country_id))
            if (forPartner) setTuitionCentre(partnerCentreOptions.find(p => p.user_id === selectedUser.id).id)
        }
    }
    const onChangeForPartner = value => {
        setForPartner(value)
        if (user.role_id === 1 && partner === '') return
        if (value) {
            setSchool('')
            if (user.role_id === 1) {
                if (partnerOptions.find(u => u.id === partner)) {
                    setTuitionCentre(partnerCentreOptions.find(p => p.user_id === partner).id)
                }
            } else {
                setTuitionCentre(partnerCentreOptions.find(p => p.user_id === user.user_id).id)
            }
        } else setTuitionCentre('')
    }
    const onSubmit = () => {
        let arr = [
            { name: 'Name', state: name }, { name: 'Grade', state: grade }, { name: 'Competition', state: competition }
        ]
        if (user.role_id < 3) {
            if (user.role_id === 1) {
                arr.splice(2, 0, { name: 'School', state: school }, { name: 'Country', state: country }, { name: 'Partner', state: partner })
            }
            if (school === -1) arr.push({ name: 'Tuition Centre', state: tuitionCentre })
        }
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        let payload = [{
            name, grade, competition_id: competition,
            for_partner: Number(forPartner)
        }]
        if (studentClass !== '') payload[0].class = studentClass
        // Handle country_id and parent_userid
        let addedPartner = user.role_id === 1 ? partner : user.role_id === 2 ? user.user_id : user.parent_id
        payload[0].parent_userid = addedPartner
        if (user.role_id === 1) payload[0].country_id = country
        else payload[0].country_id = user.country_id
        // Handle school_id and tuition_centre_id
        if (user.role_id < 3) {
            payload[0].tuition_centre_id = tuitionCentre === '' ? null : tuitionCentre
            payload[0].school_id = ['', -1].includes(school) ? null : school
        } else {
            // This part need differentiate between tuition centre cher and school cher
            if (teacherBelongsToTuitionCentre) {
                payload[0].tuition_centre_id = user.school_id
                payload[0].school_id = ['', -1].includes(school) ? null : school
            } else {
                payload[0].tuition_centre_id = null
                payload[0].school_id = user.school_id
            }
        }
        let competitionPartners = competitionOptions.find(c => c.id === competition).partners
        payload[0].competition_partner_id = competitionPartners.find(p => p.id === addedPartner).partnerRegDateId
        console.log(payload)
        console.log(JSON.stringify(payload))
        addParticipant(payload).then(d => {
            console.log(d)
            if (d.status === 201) {
                showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
                cancelFunction()
                afterAddingParticipant()
            }
        })
    }
    const cancelFunction = () => cancelAddParticipant ? cancelAddParticipant() : setOnAddParticipant(false)
    const disableSchoolAndTuitionCentre = user.role_id === 1 ? country === '' : competition === ''
    const isPrivateCandidate = forPartner || tuitionCentre !== '' || teacherBelongsToTuitionCentre
    return (
        <Box className='scrollablePopUpModal' style={{ width: 1200, maxHeight: 800 }}>
            <div>
                <NunitoText value='ADD NEW PARTICIPANT' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                {user.role_id < 3 && !selectedSchool && <Grid container justifyContent='center'>
                    <ReusableCheckbox type='default' label='Belongs to partner'
                        state={forPartner} setState={onChangeForPartner} fontSize={20} />
                </Grid>}
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Name*'
                        state={name} setState={setName} required />
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Class'
                        state={studentClass} setState={setStudentClass} />
                </Grid>
                <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Grade*'
                        state={grade} setState={setGrade} btnBgColor='#144A94' borderColor='#707070' required
                        options={gradeOptions.map(c => ({ id: c.id, option: c.name }))} />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Competition*'
                        state={competition} setState={onChangeCompetition} btnBgColor='#144A94' borderColor='#707070' required
                        options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />
                </Grid>
                {user.role_id === 1 && <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country*'
                        state={country} setState={onChangeCountry} btnBgColor='#144A94' borderColor='#707070' required
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))}
                        grayedOut={!competition || selectedSchool} readOnly={!competition || selectedSchool} />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60}
                        placeholder='Partner*' state={partner} setState={onChangePartner} btnBgColor='#144A94'
                        borderColor='#707070' required options={partnerOptions.map(u => ({ id: u.id, option: u.name }))}
                        grayedOut={!country} readOnly={!country} />
                </Grid>}
                {user.role_id < 3 && <Grid container justifyContent="space-evenly" alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='School*'
                        state={school} setState={setSchool} btnBgColor='#144A94' borderColor='#707070' required
                        options={[{ id: -1, name: 'Home School' }, ...schoolOptions].map(c => ({ id: c.id, option: c.name }))}
                        grayedOut={disableSchoolAndTuitionCentre || (selectedSchool && !selectedSchool.private)}
                        readOnly={disableSchoolAndTuitionCentre || (selectedSchool && !selectedSchool.private)} />
                    <ReusableAutocompleteWithID type='withLabel' width={550} height={60}
                        placeholder={`Tuition Centre${school === -1 ? '*' : ''}`} required={school === -1}
                        state={tuitionCentre} setState={setTuitionCentre} btnBgColor='#144A94' borderColor='#707070'
                        options={(forPartner ? partnerCentreOptions : tuitionCentreOptions).map(c => ({ id: c.id, option: c.name }))}
                        grayedOut={disableSchoolAndTuitionCentre || selectedSchool}
                        readOnly={disableSchoolAndTuitionCentre || selectedSchool || forPartner} />
                </Grid>}
                {Boolean(user.role_id === 3 && teacherBelongsToTuitionCentre) &&
                    <Grid container alignItems="center" style={{ marginBottom: 30, marginLeft: 32 }}>
                        <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='School'
                            state={school} setState={setSchool} btnBgColor='#144A94' borderColor='#707070' required
                            options={[{ id: -1, name: 'Home School' }, ...schoolOptions].map(c => ({ id: c.id, option: c.name }))}
                            grayedOut={!schoolOptions.length} readOnly={!schoolOptions.length} />
                    </Grid>}
                <NunitoText value={`*This participant is a ${isPrivateCandidate ? 'private' : 'school'} candidate`}
                    fontSize={20} fontWeight={400} />
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelFunction()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
            {/* {JSON.stringify([{
                name, class: studentClass, grade, competition,
                country, partner, school, tuitionCentre, forPartner
            }])} */}
        </Box>
    )
}