import React, { useState, useEffect } from 'react'
import { Grid, Box, IconButton, Divider } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClearIcon from '@mui/icons-material/Clear'
import { useDispatch, useSelector } from 'react-redux'
import { resetCompetition } from '../actions'
import { getCompetitions, getUsers } from '../functions/getData'
import { formatDateTime } from '../functions/general'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableToggle from '../components/general/ReusableToggle'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import TitleBar from '../components/general/TitleBar'
const containerStyle = {
    backgroundColor: '#F3F0F4', marginInline: 40, border: '1px solid', borderTopStyle: 'none',
    borderRadius: '0 0 12px 12px', marginBottom: 20
}
const titleContainerStyle = expand => ({ backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2, marginBottom: !expand && 20 })
export const CountryPartner = ({ count, competition, userOptions, partner, updatePartners, deletePartner, competitionDates }) => {
    const user = useSelector(state => state.user)
    const countryOptions = useSelector(state => state.countryOptions)
    let sortedCompetitionDates = partner.competitionDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    let minCompetitionDate = sortedCompetitionDates[0] || competition.competition_start_date
    let maxRegisterDate = new Date(minCompetitionDate).setDate(new Date(minCompetitionDate).getDate()
        - Number(partner.registration_days_apart))
    const statusStyle = () => {
        let color, bgColor
        switch (partner.partnerRegDateStatus) {
            case 'active':
            case 'Active':
                color = 'rgb(26, 152, 72)'
                bgColor = 'rgb(26, 152, 72, .25)'
                break;
            case 'ready':
            case 'Ready':
                color = 'rgb(0, 142, 204)'
                bgColor = 'rgb(0, 142, 204, .25)'
                break;
            case 'lock':
            case 'Locked':
                color = 'rgb(232, 61, 77)'
                bgColor = 'rgb(232, 61, 77, .25)'
                break;
            default: return
        }
        return {
            color: color, backgroundColor: bgColor,
            fontSize: 12, width: 84, height: 39, borderRadius: 20, marginBottom: 10,
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }
    }
    const decideStatus = () => {
        switch (partner.partnerRegDateStatus) {
            case 'active':
            case 'Active': return user.role_id === 1 ? {
                text: 'Lock', to: 'Locked', bgColor: '#E83D4D', iconType: 'lock'
            } : {
                text: 'Ready', to: 'Ready', bgColor: '#008ECC', iconType: 'check'
            }
            case 'ready':
            case 'Ready': return user.role_id === 1 ? {
                text: 'Lock', to: 'Locked', bgColor: '#E83D4D', iconType: 'lock'
            } : {
                text: 'Un-Ready', to: 'Active', bgColor: '#144A94', iconType: 'unlock'
            }
            case 'lock':
            case 'Locked': return {
                text: 'Unlock', to: 'Ready', bgColor: '#144A94', iconType: 'unlock'
            }
            default: return
        }
    }
    return (
        <Grid container alignItems='flex-end' justifyContent='space-between' style={{ marginBottom: 30, paddingInline: 20 }}>
            <ClearIcon style={{ fontSize: 36, marginBottom: 12, cursor: 'pointer' }} onClick={() => deletePartner(count)} />
            <ReusableAutocompleteWithID type='default' placeholder='Country*' width={280} height={55} bgColor='#FFF'
                btnBgColor='#144A94' btnWidth={64} state={partner.country_id} setState={updatePartners} borderColor='#707070'
                count={count} target='country_id' marginTop={48}
                options={countryOptions.map(o => ({ id: o.id, option: o.display_name }))} />
            <ReusableAutocompleteWithID type='default' placeholder='Country Partner*' width={280} height={55} bgColor='#FFF'
                btnBgColor='#144A94' btnWidth={64} state={partner.id} setState={updatePartners} borderColor='#707070'
                count={count} target='id' marginTop={48}
                options={userOptions.filter(u => u.country_id === partner.country_id).map(u => ({ id: u.id, option: u.name }))} />
            <div>
                <NunitoText value='Registration Opens' fontSize={15} />
                <ReusableDatePicker width={210} state={new Date(partner.registrationOpen).getTime()} required
                    setState={updatePartners} count={count} target='registrationOpen'
                    minDate={new Date()} maxDate={maxRegisterDate} />
            </div>
            <div>
                <NunitoText value='Days between' fontSize={15} />
                <ReusableTextField type='default' width={160} placeholder='Day between' height={55} bgColor='#FFF' number
                    state={partner.registration_days_apart} setState={updatePartners} count={count}
                    target='registration_days_apart' borderColor='#707070' />
            </div>
            <div>
                <NunitoText value='Competition Dates' fontSize={15} />
                <ReusableDatePicker width={210} state={partner.competitionDates.map(d => new Date(d).getTime())} multiple required
                    setState={updatePartners} count={count} target='competitionDates'
                    minDate={new Date(competitionDates[0])}
                    maxDate={new Date(competitionDates[1])} />
            </div>
            <div style={statusStyle()}>{partner.partnerRegDateStatus}</div>
            {/* <ReusableButton text={tus().icondecideStatus().text} bgColor={decideStatus().bgColor} fontSize={16}
                onClick={() => updatePartners(count, 'partnerRegDateStatus', decideStatus().to)}
                iconType={decideStaType} marginBottom={6} /> */}
        </Grid>
    )
}
export const CountryPartners = ({ competition, userOptions, competitionDates }) => {
    const [expandCountryPartners, setExpandCountryPartners] = useState(false)
    const [allPartners, setAllPartners] = useState(competition.partners)
    const [partners, setPartners] = useState(competition.partners)
    const [show, setShow] = useState('all')
    let statuses = ['All', 'Pending', 'Active', 'Ready', 'Locked', 'Rejected']
    const addPartner = () => {
        setPartners([...partners, {
            country_id: '',
            id: '',
            registrationOpen: new Date().getTime(),
            competitionDates: [new Date(competition.competition_end_date).getTime()],
            registration_days_apart: 7,
            allowEdit: false,
            languagesToView: [],
            languagesToTranslate: []
        }])
    }
    const updatePartners = (count, getter, value) => {
        let newPartners = [...partners]
        newPartners[count][getter] = value
        setPartners(newPartners)
    }
    const deletePartner = count => {
        let newPartners = [...partners]
        newPartners.splice(count, 1)
        setPartners(newPartners)
    }
    const [allowPartnerEditSessions, setAllowParterEditSessions] = useState(false)
    const btnContainerStyle = { marginTop: 20 }
    const btnStyle = status => ({
        width: 120, height: 50, fontSize: 20, marginInline: 10, borderRadius: 8, border: '1px solid #000',
        display: 'flex', justifyContent: 'center', alignItems: 'center', userSelect: 'none', cursor: 'pointer',
        backgroundColor: status.toLowerCase() === show ? 'goldenrod' : '#FFF',
        color: status.toLowerCase() === show && '#F2F2F2'
    })
    const handleChangeShow = e => {
        setShow(e.target.innerText.toLowerCase())
        if (e.target.innerText.toLowerCase() === 'all') setPartners(allPartners)
        else setPartners(allPartners.filter(a => a.partnerRegDateStatus === e.target.innerText.toLowerCase()))
    }
    return (
        <Grid container>
            <Grid container alignItems='center' justifyContent='space-between' style={titleContainerStyle(expandCountryPartners)}>
                <NunitoText value='Country Partner' fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Country Partner' bgColor='#5E75C3' height={46} fontSize={16} marginRight={20}
                            onClick={() => addPartner()} iconType='add' />
                    </div>
                    <IconButton style={{ color: '#FFF' }} onClick={() => setExpandCountryPartners(!expandCountryPartners)} edge="start">
                        {expandCountryPartners ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </IconButton>
                </div>
            </Grid>
            {Boolean(expandCountryPartners) &&
                <Grid container style={containerStyle}>
                    <Grid container justifyContent='center' style={btnContainerStyle}>
                        {statuses.map((status, index) => (
                            <div key={index} style={btnStyle(status)} onClick={handleChangeShow}>{status}</div>
                        ))}
                    </Grid>
                    <Grid container alignItems='center' justifyContent='space-around' style={{ paddingInline: 20, marginTop: 30 }}>
                        <NunitoText value='Allow all partners edit sessions' fontSize={20} fontWeight={600} italic />
                        <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                            state={allowPartnerEditSessions} setState={setAllowParterEditSessions} />
                    </Grid>
                    {partners.map((partner, index) => (
                        <CountryPartner key={index} count={index} competition={competition} userOptions={userOptions}
                            partner={partner} updatePartners={updatePartners} deletePartner={deletePartner}
                            competitionDates={competitionDates} />
                    ))}
                    <Grid container justifyContent="flex-end" style={{ marginRight: 30, marginBottom: 20 }}>
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} />
                    </Grid>
                </Grid>}
        </Grid>
    )
}
const competitionTitleContainerStyle = { backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2.4 }
const competitionContainerStyle = { marginInline: 24, backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const allComponentsContainerStyle = {
    backgroundColor: '#FFF', marginInline: 40, marginTop: 40,
    border: '1px solid #707070', borderRadius: 12
}
export const Competition = ({ competition, competitionOptions, userOptions }) => {
    const [expandCompetition, setExpandCompetition] = useState(false)
    const [expandSetting, setExpandSetting] = useState(false)
    const [competitionName, setCompetitionName] = useState(competition.name)
    const [startDate, setStartDate] = useState(new Date(competition.competition_start_date).getTime())
    const [endDate, setEndDate] = useState(new Date(competition.competition_end_date).getTime())
    const [rerun, setRerun] = useState(competition.parent_competition_id === null ? false : true)
    const [rerunMarkingPreference, setReRunMarkingPreference] = useState(competition.parent_competition_id || '')
    const [format, setFormat] = useState(Boolean(competition.format))
    const [instructions, setInstructions] = useState('')

    const onChangeReRun = () => {
        setRerun(!rerun)
        setReRunMarkingPreference(competition.parent_competition_id || '')
        if (!rerun) setFormat(0)
    }
    return (
        <Grid container>
            <Grid container alignItems='center' justifyContent='space-between' style={competitionTitleContainerStyle}>
                <NunitoText value={competition.name} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <IconButton style={{ color: '#FFF' }} onClick={() => setExpandCompetition(!expandCompetition)} edge="start">
                    {expandCompetition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Grid>
            {expandCompetition && <Grid container style={competitionContainerStyle}>
                <Grid container style={allComponentsContainerStyle}>
                    <TitleBar title='Setting' state={expandSetting} setState={setExpandSetting} marginBottom={!expandSetting && 20} />
                    {expandSetting && <Grid container style={containerStyle}>
                        <Grid container style={{ marginInline: 40, marginBlock: 42 }}>
                            <Grid item xs={2.5} style={{ display: 'flex', flexDirection: 'column' }}>
                                <NunitoText value='Competition Name*' fontSize={20} fontWeight={600} marginTop={20} />
                                <NunitoText value='Competition Date' fontSize={20} fontWeight={600} marginTop={70} />
                                <NunitoText value='Re-Run of Competition?' fontSize={20} fontWeight={600} marginTop={70} />
                                <NunitoText value='Competition Format' fontSize={20} fontWeight={600} marginTop={70} />
                                {/* <NunitoText value='Grades Participating' fontSize={20} fontWeight={600} marginTop={70} /> */}
                                {/* <NunitoText value='Tags' fontSize={20} fontWeight={600} marginTop={70} /> */}
                                {/* <NunitoText value='Difficulty Group' fontSize={20} fontWeight={600} marginTop={70} /> */}
                                <NunitoText value='Competition Instructions' fontSize={20} fontWeight={600} marginTop={140} />
                            </Grid>
                            <Divider orientation="vertical" flexItem
                                style={{ height: 450, width: 2, backgroundColor: '#000', marginTop: 16 }} />
                            <Grid item xs style={{ marginInline: '210px 30px', display: 'flex', flexDirection: 'column' }}>
                                <ReusableTextField type='default' placeholder='Competition Name' state={competitionName}
                                    setState={setCompetitionName} marginTop={12} borderColor='#000' required />
                                <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <ReusableDatePicker width={250} state={startDate} setState={setStartDate} required
                                        minDate={new Date()} />
                                    <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                                    <ReusableDatePicker width={250} state={endDate} setState={setEndDate} required
                                        minDate={new Date(startDate)}
                                    />
                                </div>
                                <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <ReusableToggle type='text' width={160} falseValue='No' trueValue='Yes'
                                        state={rerun} setState={onChangeReRun} />
                                    {rerun && <ReusableAutocompleteWithID type='default' placeholder='Marking Preference'
                                        width={450} height={55} bgColor='#FFF' btnBgColor='#144A94' btnWidth={64}
                                        state={rerunMarkingPreference} setState={setReRunMarkingPreference} borderColor='#707070'
                                        options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} />}
                                </div>
                                <ReusableToggle type='text' width={250} falseValue='Local' trueValue='Global'
                                    state={format} setState={setFormat} marginTop={40} />
                                <ReusableTextField type='default' placeholder='Description'
                                    state={instructions} setState={setInstructions} marginTop={48} multiline={8} borderColor='#707070' />
                            </Grid>
                        </Grid>
                        <Grid container justifyContent="flex-end" style={{ marginRight: 30, marginBlock: 20 }}>
                            <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} />
                        </Grid>
                    </Grid>}
                    <CountryPartners competition={competition} userOptions={userOptions}
                        competitionDates={[startDate, endDate]} />
                </Grid>
            </Grid>}
        </Grid>
    )
}
export const CompetitionForPartner = ({ competition }) => {
    const user = useSelector(state => state.user)
    const [expandCompetition, setExpandCompetition] = useState(true)
    const [competitionName, setCompetitionName] = useState(competition.name)
    const [startDate, setStartDate] = useState(new Date(competition.competition_start_date).getTime())
    const [endDate, setEndDate] = useState(new Date(competition.competition_end_date).getTime())
    let editingPartner = competition.partners.find(p => p.id === user.user_id)
    const [registrationOpen, setRegistrationOpen] = useState(editingPartner.registrationOpen)
    const [competitionDates, setCompetitionDates] = useState(editingPartner.competitionDates)
    const [status, setStatus] = useState(editingPartner.partnerRegDateStatus)
    let sortedCompetitionDates = competitionDates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
    let minCompetitionDate = sortedCompetitionDates[0] || competition.competition_start_date
    let maxRegisterDate = new Date(minCompetitionDate).setDate(new Date(minCompetitionDate).getDate()
        - Number(editingPartner.registration_days_apart))
    const statusStyle = () => {
        let color, bgColor
        switch (status) {
            case 'active':
            case 'Active':
                color = 'rgb(26, 152, 72)'
                bgColor = 'rgb(26, 152, 72, .25)'
                break;
            case 'ready':
            case 'Ready':
                color = 'rgb(0, 142, 204)'
                bgColor = 'rgb(0, 142, 204, .25)'
                break;
            case 'lock':
            case 'Locked':
                color = 'rgb(232, 61, 77)'
                bgColor = 'rgb(232, 61, 77, .25)'
                break;
            default: return
        }
        return {
            color: color, backgroundColor: bgColor,
            fontSize: 20, width: 100, height: 50, borderRadius: 20,
            display: 'flex', alignItems: 'center', justifyContent: 'center'
        }
    }
    const decideStatus = () => {
        switch (status) {
            case 'active':
            case 'Active': return user.role_id === 1 ? {
                text: 'Lock', to: 'lock', bgColor: '#E83D4D', iconType: 'lock'
            } : {
                text: 'Ready', to: 'ready', bgColor: '#008ECC', iconType: 'check'
            }
            case 'ready':
            case 'Ready': return user.role_id === 1 ? {
                text: 'Lock', to: 'lock', bgColor: '#E83D4D', iconType: 'lock'
            } : {
                text: 'Un-Ready', to: 'active', bgColor: '#144A94', iconType: 'unlock'
            }
            case 'lock':
            case 'Locked': return {
                text: 'Unlock', to: 'ready', bgColor: '#144A94', iconType: 'unlock'
            }
            default: return
        }
    }
    return (
        <Grid container>
            <Grid container alignItems='center' justifyContent='space-between' style={competitionTitleContainerStyle}>
                <NunitoText value={competition.name} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <IconButton style={{ color: '#FFF' }} onClick={() => setExpandCompetition(!expandCompetition)} edge="start">
                    {expandCompetition ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Grid>
            {expandCompetition && <Grid container style={competitionContainerStyle}>
                <Grid container style={allComponentsContainerStyle}>
                    <Grid container style={{ marginInline: 40, marginBlock: 42 }}>
                        <Grid item xs={2.5} style={{ display: 'flex', flexDirection: 'column' }}>
                            <NunitoText value='Competition Name' fontSize={20} fontWeight={600} marginTop={20} />
                            <NunitoText value='Competition Date' fontSize={20} fontWeight={600} marginTop={70} />
                            {/* <NunitoText value='Tags' fontSize={20} fontWeight={600} marginTop={70} />
                                    <NunitoText value='Re-Run of Competition?' fontSize={20} fontWeight={600} marginTop={70} />
                                    <NunitoText value='Difficulty Group' fontSize={20} fontWeight={600} marginTop={70} /> */}
                            <NunitoText value='Registration Open' fontSize={20} fontWeight={600} marginTop={70} />
                            <NunitoText value='Competition Dates' fontSize={20} fontWeight={600} marginTop={70} />
                            <NunitoText value='Status' fontSize={20} fontWeight={600} marginTop={70} />
                        </Grid>
                        <Divider orientation="vertical" flexItem
                            style={{ height: 460, width: 2, backgroundColor: '#000', marginTop: 16 }} />
                        <Grid item xs style={{ marginInline: '210px 30px', display: 'flex', flexDirection: 'column' }}>
                            <ReusableTextField type='default' placeholder='Competition Name' state={competitionName}
                                setState={setCompetitionName} marginTop={12} borderColor='#000' required readOnly />
                            <div style={{ marginTop: 40, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <ReusableDatePicker width={250} state={startDate} setState={setStartDate} required
                                    minDate={new Date()} disabled />
                                <NunitoText value='TO' fontSize={20} fontWeight={600} italic />
                                <ReusableDatePicker width={250} state={endDate} setState={setEndDate} required
                                    minDate={new Date(startDate)} disabled />
                            </div>
                            <ReusableDatePicker width={250} state={new Date(registrationOpen).getTime()} required marginTop={40}
                                setState={setRegistrationOpen} maxDate={maxRegisterDate} />
                            <ReusableDatePicker width={250} state={competitionDates.map(d => new Date(d).getTime())}
                                multiple required marginTop={40} setState={setCompetitionDates}
                                minDate={new Date(competition.competition_start_date)}
                                maxDate={new Date(competition.competition_end_date)} />
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: 40 }}>
                                <div style={statusStyle()}>{status}</div>
                                <ReusableButton text={decideStatus().text} bgColor={decideStatus().bgColor} fontSize={16}
                                    onClick={() => setStatus(decideStatus().to)} iconType={decideStatus().iconType} />
                            </div>
                            sortedCompetitionDates : {JSON.stringify(sortedCompetitionDates.map(s => new Date(s)))}<br />
                            minCompetitionDate: {JSON.stringify(new Date(minCompetitionDate))}<br />
                            maxRegisterDate: {JSON.stringify(new Date(maxRegisterDate))}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>}
        </Grid>
    )
}
export default function EditCompetition() {
    document.title = 'Edit Competition'
    const user = useSelector(state => state.user)
    let location = useLocation()
    const dispatch = useDispatch()
    const [competition, setCompetition] = useState()
    const [competitionOptions, setCompetitionOptions] = useState([])
    const [userOptions, setUserOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [difficultyGroupOptions, setDifficultyGroupOptions] = useState([])
    useEffect(() => {
        if (location.search.slice(0, 4) === '?id=') {
            getCompetitions(`?limits=50&status=closed`).then(c => {
                setCompetitionOptions(c.competitionList.data.filter(d => d.status === 'closed'))
            })
            getCompetitions(`?limits=50&id=${location.search.slice(4)}`).then(c => {
                if (c.competitionList.data.length === 0) {
                    alert('Cant find')
                    return
                } else {
                    let foundCompetition = c.competitionList.data.find(d => d.id === Number(location.search.slice(4)))
                    foundCompetition.partners.forEach((partner, i) => {
                        partner.registrationOpen = partner.partnerRegOpenDate
                        partner.competitionDates = partner.partnerCompetitionDates
                        partner.registration_days_apart = partner.partnerRegDaysApart
                        partner.allowEdit = false
                        partner.languagesToView = []
                        partner.languageToTranslate = []
                    })
                    setCompetition(foundCompetition)
                }
                if (user.role_id === 1) {
                    getUsers('?role_id=2&limits=1000').then(d => setUserOptions(d.userLists.data))
                }
            })
        } else {
            alert('Wrong query')
        }
    }, [])
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18}
                    onClick={() => dispatch(resetCompetition())} />
            </div>
            <Grid item xs={12} style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Edit Competition' fontSize={40} fontWeight={700} italic color='#144A94'
                    align='left' marginBottom={24} />
                {user.role_id === 1 && competition && <div>
                    <Competition competition={competition} userOptions={userOptions} competitionOptions={competitionOptions} />
                </div>}
                {user.role_id === 2 && competition && <div>
                    <CompetitionForPartner competition={competition} />
                </div>}
                {competition && JSON.stringify(competition)}
            </Grid>
        </Box>
    )
}