import React, { useState, useEffect } from 'react'
import { Box, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { checkPassword, invalidName, invalidPhoneOrGrade, invalidEmail } from '../../functions/general'
import { showWarningSwal, isEmpty, warningMessage } from '../../functions/alert'
import { showNotification } from '../../functions/snackbar'
import { getUsers, getSchools } from '../../functions/getData'
import { addUser } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableButton from '../general/ReusableButton'
import { useSnackbar } from 'notistack'
const organisationOptions = ['organisation 1', 'random organisation', 'organisation 3']
export default function AddUserModal({ setOnAddNewUser, afterAddingUser, selectedSchool, cancelAddUser }) {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const [name, setName] = useState('')
    const [role, setRole] = useState('')
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [organization, setOrganization] = useState('')
    const [email, setEmail] = useState('')
    const [school, setSchool] = useState('')
    const [country, setCountry] = useState('')
    const [partner, setPartner] = useState('')

    const [teacherBelongsToTuitionCentre, setTeacherBelongsToTuitionCentre] = useState(false)

    const user = useSelector(state => state.user)
    const countryOptions = useSelector(state => state.countryOptions)
    const roleOptions = useSelector(state => state.roleOptions)
    const [partnerOptions, setPartnerOptions] = useState([])
    const [schoolOptions, setSchoolOptions] = useState([])
    // Only Country Partner will use getSchools
    useEffect(() => {
        if (selectedSchool) {
            onChangeTeacherBelongs(selectedSchool.private)
            setRole(3)
            setCountry(selectedSchool.country_id)
            setSchoolOptions([selectedSchool])
            setSchool(selectedSchool.id)
            getUsers(`?country_id=${selectedSchool.country_id}&role_id=2&status=active&limits=100`).then(d => setPartnerOptions(d.userLists.data))
        } else {
            if (user.role_id === 2) {
                getSchools(`?status=active&limits=100`).then(s => setSchoolOptions(s.SchoolLists.data))
            }
        }
    }, [])
    // Only Admin will use onChangeCountry
    const onChangeCountry = value => {
        setPartner('')
        setSchool('')
        setCountry(value)
        if (value !== '') {
            getUsers(`?country_id=${value}&role_id=2&status=active&limits=100`).then(d => setPartnerOptions(d.userLists.data))
            getSchools(`?country_id=${value}&status=active&limits=100`).then(d => setSchoolOptions(d.SchoolLists.data))
        } else {
            setPartnerOptions([])
            setSchoolOptions([])
        }
    }
    const onChangeTeacherBelongs = value => {
        setTeacherBelongsToTuitionCentre(value)
        setSchool('')
    }
    const onSubmit = () => {
        let arr = [
            { name: 'Full Name', state: name },
            { name: 'Username', state: username },
            { name: 'Role', state: role },
            { name: 'Password', state: password },
            { name: 'Password Confirmation', state: confirmPassword }
        ]
        if (role === 2) arr.push({ name: 'Organization', state: organization })
        if (user.role_id === 1 && role !== 1) arr.push({ name: 'Country', state: country })
        if (role === 3) {
            arr.push({ name: teacherBelongsToTuitionCentre ? 'Tuition Centre' : 'School', state: school })
            if (user.role_id === 1) arr.push({ name: 'Partner', state: partner })
        }
        arr.push({ name: 'E-Mail', state: email })
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }

        let msg = ''
        if (invalidName(name)) msg += 'Invalid name. '
        if (invalidName(username)) msg += 'Invalid username. '
        if (invalidEmail(email)) msg += 'Invalid email. '
        if (checkPassword(password)) msg += 'Password does not meet requirement. '
        if (password !== confirmPassword) msg += 'Password and Confirm Password must match.'
        if (msg.length) {
            showWarningSwal(msg)
            return
        }
        let payload = [{
            name, username, password, password_confirmation: confirmPassword, about: 'test',
            role_id: role, email, organization_id: null, school_id: null, parent_id: null
        }]
        if (role === 2) {
            payload[0].organization_id = 1
            payload[0].country_id = user.role_id === 1 ? country : user.country_id
            if (user.role_id === 1) payload[0].parent_id = null
        } else if (role === 3) {
            payload[0].country_id = user.role_id === 1 ? country : user.country_id
            if (user.role_id === 1) payload[0].parent_id = partner
            payload[0].school_id = school
        }
        console.log(payload)
        console.log(JSON.stringify(payload))
        addUser(payload).then(d => {
            console.log(d)
            if (d.status !== 201) return
            showNotification('success', d.message, enqueueSnackbar, closeSnackbar)
            cancelFunction()
            afterAddingUser()
        })
    }
    const cancelFunction = () => cancelAddUser ? cancelAddUser() : setOnAddNewUser(false)
    return (
        <Box className='popUpModal' style={{ width: 1200, minHeight: 600 }}>
            {/* {JSON.stringify({
                name: name, username, password, password_confirmation: confirmPassword,
                email: email ? email : null, about: null, organization: organization ? organization : null, role_id: role, school
            })} */}
            <div>
                <NunitoText value={selectedSchool ? 'ADD NEW TEACHER' : 'ADD NEW USER'} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
                <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Full Name*'
                        state={name} setState={setName} required={!name} />
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#000' placeholder='Role*' state={role} setState={setRole} required
                        options={roleOptions.filter(r => r.id > user.role_id).map(r => ({ id: r.id, option: r.name }))}
                        grayedOut={selectedSchool} readOnly={selectedSchool} />
                </Grid>
                {role === 3 ? <Grid container alignItems="center" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                        state={username} setState={setUsername} required divMarginLeft={110} />
                    {selectedSchool ?
                        <NunitoText value={`Teacher belongs to a ${teacherBelongsToTuitionCentre ? 'tuition centre' : 'school'}`}
                            fontSize={20} marginLeft={110} /> :
                        <ReusableCheckbox type='default' label='Teacher belongs to a Tuition Centre?' marginLeft={110}
                            state={teacherBelongsToTuitionCentre} setState={onChangeTeacherBelongs} fontSize={20} />}
                </Grid> : <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Username*'
                        state={username} setState={setUsername} required />
                    {role === 2 ? <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63} btnBgColor='#144A94'
                        borderColor='#000' placeholder='Organization*' state={organization} setState={setOrganization}
                        options={organisationOptions.map((o, i) => ({ id: i + 1, option: o }))} required /> :
                        <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                            state={email} setState={setEmail} required />}
                </Grid>}
                <Grid container justifyContent="space-evenly" style={{ marginBottom: 10 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Password*'
                        state={password} setState={setPassword} required={checkPassword(password)} />
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='Confirm Password*'
                        state={confirmPassword} setState={setConfirmPassword}
                        required={checkPassword(password) || password !== confirmPassword} />
                </Grid>
                <NunitoText value='Minimum 8 characters with one uppercase, one lowercase letter, one number and one of the following 
                letters: !$#%@'  fontSize={16} fontWeight={400} marginTop={10} color={checkPassword(password) && '#E83D4D'} />
                <NunitoText value='Password must match confirm password' fontSize={16} fontWeight={400} marginTop={10}
                    color={(checkPassword(password) || password !== confirmPassword) && '#E83D4D'} />
                {[2, 3].includes(role) && <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableTextField type='withLabel' width={435} height={55} bgColor='#F2F2F2' placeholder='E-Mail*'
                        state={email} setState={setEmail} required />
                    {user.role_id === 1 ? <ReusableAutocompleteWithID type='withLabel'
                        width={435} height={55} btnWidth={63} btnBgColor='#144A94' borderColor='#000' placeholder='Country*'
                        state={country} setState={onChangeCountry} readOnly={selectedSchool} grayedOut={selectedSchool}
                        options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} required /> :
                        role === 3 && <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63}
                            btnBgColor='#144A94' borderColor='#000'
                            placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'} state={school} setState={setSchool}
                            options={teacherBelongsToTuitionCentre ?
                                schoolOptions.filter(s => s.private).map(s => ({ id: s.id, option: s.name })) :
                                schoolOptions.filter(s => !s.private).map(s => ({ id: s.id, option: s.name }))}
                            required grayedOut={!role || selectedSchool} readOnly={!role || selectedSchool} />}
                </Grid>}
                {role === 3 && user.role_id === 1 && <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                    <ReusableAutocompleteWithID type='withLabel' width={435} height={55} btnWidth={63}
                        btnBgColor='#144A94' borderColor='#000' state={school} setState={setSchool}
                        placeholder={teacherBelongsToTuitionCentre ? 'Tuition Centre*' : 'School*'}
                        options={selectedSchool ? schoolOptions.map(s => ({ id: s.id, option: s.name })) :
                            teacherBelongsToTuitionCentre ?
                                schoolOptions.filter(s => s.private).map(s => ({ id: s.id, option: s.name })) :
                                schoolOptions.filter(s => !s.private).map(s => ({ id: s.id, option: s.name }))}
                        required grayedOut={!country || selectedSchool} readOnly={!country || selectedSchool} />
                    {user.role_id === 1 && <ReusableAutocompleteWithID type='withLabel' width={435} height={55}
                        btnWidth={63} btnBgColor='#144A94' borderColor='#000' placeholder='Country Partner*' state={partner}
                        setState={setPartner} options={partnerOptions.map(p => ({ id: p.id, option: p.name }))}
                        required grayedOut={!country} readOnly={!country} />}
                </Grid>}
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => cancelFunction()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}