import React, { useState } from 'react'
import { Box, Grid, FormControlLabel, RadioGroup, Radio } from '@mui/material'
import { showWarningSwal } from '../../functions/alert'
import { addDomain } from '../../functions/postData'
import ClearIcon from '@mui/icons-material/Clear'
import NunitoText from '../general/NunitoText'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocomplete from '../general/ReusableAutocomplete'
import ReusableButton from '../general/ReusableButton'
import ReusableCheckbox from '../general/ReusableCheckbox'
export default function AddDomainModal({ setOnAddDomain, afterAddingDomain, domainOptions }) {
    const [add, setAdd] = useState(1)
    const [addDomainOnly, setAddDomainOnly] = useState(false)
    const [domainName, setDomainName] = useState('')
    const [topicNames, setTopicNames] = useState([''])
    const [tagName, setTagName] = useState('')
    const onChangeTopicNames = (count, value) => {
        let newTopicNames = [...topicNames]
        newTopicNames[count] = value
        setTopicNames(newTopicNames)
    }
    const onDeleteTopicName = count => {
        let newTopicNames = [...topicNames]
        newTopicNames.splice(count, 1)
        setTopicNames(newTopicNames)
    }
    const allInArrayIsEmpty = arr => {
        if (!arr.length) return true
        return arr.every((val, i, arr) => val === arr[0]) && arr[0] === ''
    }
    const onSubmit = () => {
        let warningMessage = ''
        if (add === 1) {
            if (!domainName) warningMessage += 'Please fill in Domain Name.'
            // Need account for when selecting existing domain
            if (domainOptions.map(d => d.name).includes(domainName)) {
                if (addDomainOnly) warningMessage += 'Domain name already exists.'
                else {
                    if (allInArrayIsEmpty(topicNames)) {
                        warningMessage += 'If adding topics to an existing domain, please fill in at least one topic.'
                    } else {
                        if (topicNames.includes('')) {
                            warningMessage += 'Please fill in all empty topic fields.'
                        }
                    }
                }
            }
        } else {
            if (!tagName) warningMessage += 'Please fill in Tag Name.'
        }
        if (warningMessage) {
            showWarningSwal(warningMessage)
            return
        }
        let payload = [{ is_tag: Number(tagName !== '') }]
        if (tagName !== '') payload[0].name = [tagName]
        else {
            if (domainOptions.map(d => d.name).includes(domainName)) {
                payload[0].domain_id = domainOptions.find(d => d.name === domainName).id
                payload[0].name = topicNames
            }
            else {
                payload[0].name = [domainName]
                for (let i = 0; i < topicNames.length; i++) if (topicNames[i] !== '') payload[0].name.push(topicNames[i])
            }
        }
        console.log(payload)
        console.log(JSON.stringify(payload))
        addDomain(payload).then(d => {
            console.log(d)
            if (Number(d.status) === 201) {
                afterAddingDomain()
                setOnAddDomain(false)
            }
        })
    }
    const topicGridStyle = {
        display: 'flex', alignItems: 'center', marginBottom: 20
    }
    const iconStyle = { fontSize: 32, marginTop: 20, cursor: 'pointer' }
    const addOptions = [{ id: 1, option: 'Add Domain and Topic' }, { id: 2, option: 'Add Tag' }]
    const handleChange = e => {
        setDomainName('')
        setTopicNames([''])
        setTagName('')
        setAdd(Number(e.target.value))
    }
    const onChangeAddDomainOnly = value => {
        setTopicNames([''])
        setAddDomainOnly(value)
    }
    return (
        <Box className='scrollablePopUpModal' style={{ width: 1200, height: 600 }}>
            <div>
                {add === 1 ? <Grid container alignItems='center' style={{ marginTop: 20 }}>
                    <Grid item xs={8} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <NunitoText value='ADD DOMAIN AND TOPIC' fontSize={30} fontWeight={600} color='#5E75C3' />
                    </Grid>
                    <Grid item xs={4}>
                        <ReusableButton text='Add Topic' fontSize={14} bgColor='#5E75C3'
                            onClick={() => setTopicNames([...topicNames, ''])} iconType='add' />
                    </Grid>
                </Grid> : <NunitoText value='ADD TAG' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />}
                <Grid container justifyContent='space-between' style={{ paddingInline: '40px 30px', marginTop: 10 }}>
                    <RadioGroup value={add} onChange={handleChange} row>
                        {addOptions.map(({ id, option }, i) => (
                            <FormControlLabel key={i} value={id} control={<Radio style={{ color: '#000' }} />} label={option} />
                        ))}
                    </RadioGroup>
                    {add === 1 && <ReusableCheckbox type='modal' label='Add Domain Only'
                        state={addDomainOnly} setState={onChangeAddDomainOnly} fontSize={16} />}
                </Grid>
                {add === 1 ? <Grid container justifyContent="space-between" style={{ marginTop: 20, paddingInline: '40px 30px' }}>
                    {addDomainOnly ? <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2'
                        placeholder='Domain Name' state={domainName} setState={setDomainName} /> :
                        <ReusableAutocomplete type='withLabel' width={530} height={60} placeholder='Domain Name*' freeSolo
                            state={domainName} setState={setDomainName}
                            btnBgColor='#144A94' borderColor='#707070'
                            options={domainOptions.map(d => ({ id: d.id, option: d.name }))}
                            readOnly={tagName} grayedOut={tagName} required={domainName === ''} />}
                    <div>
                        {topicNames.map((name, i) => (
                            <div key={i} style={topicGridStyle}>
                                <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2' placeholder='Topic*'
                                    state={name} setState={onChangeTopicNames} count={i}
                                    readOnly={addDomainOnly} grayedOut={addDomainOnly} required={name === '' && !addDomainOnly} />
                                {Boolean(i) && <ClearIcon style={iconStyle} onClick={() => onDeleteTopicName(i)} />}
                            </div>
                        ))}
                    </div>
                </Grid> : <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                    <ReusableTextField type='withLabel' width={530} height={60} bgColor='#F2F2F2' placeholder='Tag*'
                        state={tagName} setState={setTagName} readOnly={domainName} grayedOut={domainName} required={tagName === ''} />
                </Grid>}
            </div>
            {/* {JSON.stringify({ domainName, tagName, topicNames, add, addDomainOnly })} */}
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => setOnAddDomain(false)} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={300} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}