import React, { useState } from 'react'
import { Box, Grid, CircularProgress, Tabs, Tab } from '@mui/material'
import { useSelector } from 'react-redux'
import { invalidName, invalidPhoneOrGrade, invalidEmail } from '../../functions/general'
import { isEmpty, warningMessage, showWarningSwal } from '../../functions/alert'
import { getBase64 } from '../../functions/upload'
import { addOrganization } from '../../functions/postData'
import NunitoText from '../general/NunitoText'
import ReusableCheckbox from '../general/ReusableCheckbox'
import ReusableTextField from '../general/ReusableTextField'
import ReusableAutocompleteWithID from '../general/ReusableAutocompleteWithID'
import ReusableButton from '../general/ReusableButton'
const EachForm = ({ forms, setForms, count }) => {
    const form = forms[count]
    const countryOptions = useSelector(state => state.countryOptions)
    const updateForm = (identifier, value) => {
        let newForms = [...forms]
        newForms[count][identifier] = value
        setForms(newForms)
    }
    const openFile = () => document.getElementById(`file-upload-${count}`).click()
    const handleFileInputChange = (file) => {
        updateForm('hasImage', true)
        updateForm('loadingImage', true)
        getBase64(file)
            .then(result => {
                updateForm('image', result)
                updateForm('loadingImage', false)
            })
            .catch(err => {
                console.log(err);
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
                updateForm('loadingImage', false)
            });
    }
    const { image, ...uwu } = form
    return (
        <Grid container justifyContent='center'>
            {/* {JSON.stringify(uwu)}<br /> */}
            {forms.image && JSON.stringify(form.image.length)}
            <Grid container justifyContent="space-evenly" style={{ marginTop: 20, marginBottom: 30 }}>
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Organization Name*'
                    state={form.name} setState={updateForm} required onBlur target='name' />
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Address*'
                    state={form.address} setState={updateForm} required target='address' />
            </Grid>
            <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Email*'
                    state={form.email} setState={updateForm} required target='email' />
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Phone Number*'
                    state={form.phoneNumber} setState={updateForm} required target='phoneNumber' />
            </Grid>
            <Grid container justifyContent="space-evenly" style={{ marginBottom: 30 }}>
                <ReusableAutocompleteWithID type='withLabel' width={550} height={60} placeholder='Country*'
                    state={form.country} setState={updateForm} btnBgColor='#144A94' borderColor='#707070' required
                    options={countryOptions.map(c => ({ id: c.id, option: c.display_name }))} target='country' />
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Person-in-charge'
                    state={form.personInCharge} setState={updateForm} target='personInCharge' />
            </Grid>
            <Grid container justifyContent="space-evenly">
                <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Billing Address*'
                    state={form.billingAddress} setState={updateForm} required target='billingAddress' />
                <div style={{ textAlign: 'left' }}>
                    <ReusableTextField type='withLabel' width={550} height={60} bgColor='#F2F2F2' placeholder='Shipping Address*'
                        state={form.useSameAsBilling ? form.billingAddress : form.shippingAddress} setState={updateForm} required
                        readOnly={form.useSameAsBilling} target='shippingAddress' />
                    <ReusableCheckbox type='modal' label='Same as Billing Address' state={form.useSameAsBilling} setState={updateForm}
                        fontSize={16} target='useSameAsBilling' />
                </div>
            </Grid>
            {/* <input id={`file-upload-${count}`} type="file" style={{ display: 'none' }}
                onChange={e => handleFileInputChange(e.target.files[0])} />
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 20 }}>
                <ReusableTextField type='withLabel' width={1120} height={60} bgColor='#F2F2F2' placeholder='Select Image'
                    state={form.image} setState={updateForm} onClick={openFile} readOnly target='image' />
            </div>
            {form.hasImage ? !form.loadingImage && form.image ? <div style={{
                display: 'flex', justifyContent: 'center',
                alignItems: 'center', marginBottom: 20
            }}>
                <img src={form.image} alt="Org Image" style={{ maxHeight: 400, maxWidth: 1120 }} />
            </div> : <Grid container justifyContent='center' alignItems='center' style={{ height: 400, border: '1px solid gild' }}>
                <CircularProgress style={{ color: '#000' }} disableShrink />
            </Grid> : <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{
                    display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 20,
                    backgroundColor: '#F2F2F2', height: 400, width: 1120
                }}>
                    <p>No preview available</p>
                </div>
            </div>} */}
        </Grid>
    )
}
export default function AddOrganizationModal({ setOnAddOrganization, afterAddingOrganization, forms, setForms, openNewForm }) {
    const user = useSelector(state => state.user)
    const countryOptions = useSelector(state => state.countryOptions)
    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [country, setCountry] = useState('')
    const [personInCharge, setPersonInCharge] = useState('')
    const [billingAddress, setBillingAddress] = useState('')
    const [shippingAddress, setShippingAddress] = useState('')
    const [useSameAsBilling, setUseSameAsBilling] = useState(false)
    const [image, setImage] = useState(null)
    const [hasImage, setHasImage] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)


    const [value, setValue] = useState(0)
    const handleChange = (e, newValue) => setValue(newValue)
    const openFile = () => document.getElementById(`file-upload`).click()
    const handleFileInputChange = (file) => {
        setHasImage(true)
        setLoadingImage(true)
        getBase64(file)
            .then(result => {
                console.log(file)
                console.log(result)
                setImage(result)
                setLoadingImage(false)
            })
            .catch(err => {
                console.log(err);
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
                setLoadingImage(false)
            });
    }
    const onSubmit = () => {
        let arr = [
            { name: 'School Name', state: name },
            { name: 'Address', state: address },
            { name: 'Email', state: email },
            { name: 'Country', state: country },
            { name: 'Billing Address', state: billingAddress },
        ]
        if (!useSameAsBilling) arr.push({ name: 'Shipping Address', state: shippingAddress })
        if (isEmpty(arr.map(a => a.state))) {
            warningMessage(arr)
            return
        }
        let msg = ''
        if (invalidName(name)) msg += 'Invalid school name. '
        if (invalidPhoneOrGrade(phoneNumber)) msg += 'Invalid phone number. '
        if (invalidEmail(email)) msg += 'Invalid email'
        if (msg.length) {
            showWarningSwal(msg)
            return
        }
        let payload = [{

        }]
    }
    return (
        <Box className='scrollablePopUpModal' style={{ width: 1200, height: 750 }}>
            {/* {JSON.stringify({
                name, address, email, phoneNumber, country, personInCharge, billingAddress, shippingAddress, useSameAsBilling
            })} */}
            <NunitoText value='ADD NEW ORGANISATION' fontSize={30} fontWeight={600} color='#5E75C3' marginTop={20} />
            <div style={{
                borderRadius: 10,
                boxShadow: '0px 3px 6px rgb(0,0,0,0.2), 0 3px 6px rgb(0,0,0,0.2)',
                marginInline: 20
            }}>
                <Tabs value={value} onChange={handleChange} TabIndicatorProps={{
                    style: {
                        backgroundColor: "#F0F0F0", height: 1
                    }
                }}>
                    {forms.map((f, i) => (
                        <Tab key={f.id} label={`Organization ${f.id}`}
                            style={{
                                color: value === i && 'darkblue',
                                backgroundColor: value !== i && '#E7E6E6',
                                borderRadius: '10px 10px 0 0',
                                textTransform: 'none'
                            }} />
                    ))}
                </Tabs>
                <EachForm forms={forms} setForms={setForms} count={value} />
            </div>
            <Grid container justifyContent='space-evenly' style={{ marginBottom: 30, paddingInline: 100 }}>
                {/* <ReusableButton text='Cancel' fontSize={22} height={60} width={300} bgColor='#8D8D8D'
                    onClick={() => setOnAddOrganization(false)} /> */}
                <ReusableButton text='Add another organization' fontSize={22} height={60} width={340} bgColor='#5E75C3' iconType='add'
                    onClick={() => openNewForm()} />
                <ReusableButton text='Submit' fontSize={22} height={60} width={150} bgColor='#5E75C3'
                    onClick={() => onSubmit()} />
            </Grid>
        </Box>
    )
}