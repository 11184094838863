import React from 'react'
import { Grid } from '@mui/material'
export default function AddPageWrapper({ children }) {
    const containerStyle = {
        marginInline: 30,
        backgroundColor: '#FFF',
        borderLeft: '1px solid #000',
        borderRight: '1px solid #000',
        borderBottom: '1px solid #000',
        borderRadius: '0 0 6px 6px',
        marginBottom: 20
    }
    return <Grid container style={containerStyle}>{children}</Grid>
}