import { patchMethod } from "./httpMethods"
// School
export const patchSchool = data => {
    return new Promise((resolve, reject) => {
        patchMethod('school', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const approveSchool = data => {
    return new Promise((resolve, reject) => {
        patchMethod('school/approve', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const rejectSchool = data => {
    return new Promise((resolve, reject) => {
        patchMethod('school/reject', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const restoreSchool = data => {
    return new Promise((resolve, reject) => {
        patchMethod('school/undelete', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Organization
export const patchOrganization = data => {
    return new Promise((resolve, reject) => {
        patchMethod('organisation', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const approveOrganization = data => {
    return new Promise((resolve, reject) => {
        patchMethod('organisation/approve', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// User
export const patchUser = data => {
    return new Promise((resolve, reject) => {
        patchMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const enableUser = data => {
    return new Promise((resolve, reject) => {
        patchMethod('user/undisable', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const disableUser = data => {
    return new Promise((resolve, reject) => {
        patchMethod('user/disable', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Participant
export const patchParticipant = data => {
    return new Promise((resolve, reject) => {
        patchMethod('participant', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const swapIndex = data => {
    return new Promise((resolve, reject) => {
        patchMethod('participant/swapIndex', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Domain and Tag
export const patchDomain = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const approveDomain = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tag/approve', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Competition
export const patchCompetition = data => {
    return new Promise((resolve, reject) => {
        patchMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const closeCompetition = data => {
    return new Promise((resolve, reject) => {
        patchMethod('competition/close', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const patchCompetitionPartner = data => {
    return new Promise((resolve, reject) => {
        patchMethod('competition/partners', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const approveCompetitionPartner = data => {
    return new Promise((resolve, reject) => {
        patchMethod('competition/partners/approve', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const rejectCompetitionPartner = data => {
    return new Promise((resolve, reject) => {
        patchMethod('competition/partners/reject', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const toggleCompetitionPartner = params => {
    return new Promise((resolve, reject) => {
        patchMethod('competition/partners/togglelock', undefined, params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Task
export const patchTaskSettings = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tasks/settings', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const patchTaskRecommendations = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tasks/recommendation', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const patchTaskContent = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tasks/content', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const patchTaskAnswers = data => {
    return new Promise((resolve, reject) => {
        patchMethod('tasks/answer', data).then(d => resolve(d)).catch(e => reject(e))
    })
}