import React, { useState } from 'react'
import { FormControl, Select, IconButton, InputLabel, MenuItem, Box, Chip } from '@mui/material'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CancelIcon from '@mui/icons-material/Cancel'
export default function ReusableSelect({ type, width, height, bgColor, borderColor, btnBgColor, btnWidth,
    marginLeft, marginRight, marginTop, marginBottom,
    label, state, setState, required, readOnly, disabled, grayedOut,
    count, head, target,
    // For ReusableSelect
    options }) {
    const [open, setOpen] = useState(false)
    const formControlStyle = {
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom
    }
    const selectStyle = {
        minWidth: width,
        height: height,
        borderRadius: 12,
        backgroundColor: bgColor,
        border: required ? '1px solid red' : `1px solid ${borderColor}`
    }
    const btnStyle = {
        color: '#FFF',
        backgroundColor: btnBgColor,
        borderRadius: '0 12px 12px 0',
        height: borderColor ? height - 2 : height, // If there is border, -2 for the button height
        width: btnWidth
    }
    const iconStyle = { fontSize: 36 }
    const inputLabelStyle = { paddingTop: 2 }
    const menuProps = {
        anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
        },
        transformOrigin: {
            vertical: "top",
            horizontal: "left"
        },
        getContentAnchorEl: null
    }
    switch (type) {
        case 'filter':
            return (
                <FormControl variant="outlined" style={formControlStyle} size='small'>
                    <InputLabel style={inputLabelStyle}>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(target, e.target.value)}
                        label={label} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'multipleFilter':
            return (
                <FormControl variant="outlined" style={formControlStyle} size='small'>
                    <InputLabel style={inputLabelStyle}>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(target, e.target.value)}
                        label={label} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                        multiple
                        renderValue={(selected) => (
                            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={options.find(o => o.value === value)?.option} clickable style={{ backgroundColor: '#f16774', color: '#FFF' }}
                                        deleteIcon={<CancelIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />}
                                        onDelete={() => setState(target, selected.filter(s => s !== value))} />
                                ))}
                            </Box>
                        )}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'type1':
            return (
                <FormControl variant="outlined" style={formControlStyle} size='small'>
                    <InputLabel>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={{ ...selectStyle, width: width }} value={state} onChange={e => setState(e.target.value)} label={label}
                        open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'type2':
            return (
                <Select style={{ ...selectStyle, ...formControlStyle, width: width }} value={state}
                    onChange={e => label ? setState(count, label, e.target.value) : setState(e.target.value)}
                    open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} disableUnderline MenuProps={menuProps}
                    IconComponent={() => (
                        <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                            {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                        </IconButton>)}
                >
                    {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                </Select>
            )
        case 'modal':
            return (
                <FormControl variant="outlined" style={formControlStyle} size='small'>
                    <InputLabel style={inputLabelStyle}>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(e.target.value)} label={label}
                        open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'redux':
            return (
                <FormControl variant="outlined" style={formControlStyle}>
                    <InputLabel>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(count, head, target, e.target.value)}
                        label={label} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        case 'reduxMultiple':
            return (
                <FormControl variant="outlined" style={formControlStyle}>
                    <InputLabel>{label.charAt(0).toUpperCase() + label.slice(1)}</InputLabel>
                    <Select style={selectStyle} value={state} onChange={e => setState(count, head[0], target, '', e)}
                        label={label} open={open} onClose={() => setOpen(false)} onOpen={() => setOpen(true)} MenuProps={menuProps}
                        IconComponent={() => (
                            <IconButton onClick={() => setOpen(!open)} style={btnStyle}>
                                {open ? <ExpandLessIcon style={iconStyle} /> : <ExpandMoreIcon style={iconStyle} />}
                            </IconButton>)}
                        multiple
                        renderValue={(selected) => (
                            <Box style={{ display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                                {selected.map((value) => (
                                    <Chip key={value} label={value} clickable style={{ backgroundColor: '#f16774', color: '#FFF' }}
                                        deleteIcon={
                                            <CancelIcon style={{ color: '#FFF' }} onMouseDown={(event) => event.stopPropagation()} />
                                        }
                                        onDelete={() => setState(count, head[1], target, value)}
                                    />
                                ))}
                            </Box>
                        )}
                    >
                        {options.map(({ value, option }, index) => <MenuItem key={index} value={value}>{option}</MenuItem>)}
                    </Select>
                </FormControl>
            )
        default: return null
    }
}