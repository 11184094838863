//Import libraries
import React, { useState, useEffect, useCallback } from 'react'
import { BrowserRouter as Router, Switch, Route, Link, Redirect, useLocation, useHistory, HashRouter } from "react-router-dom"
import {
    Box, Toolbar, List, ListItem, ListItemIcon, ListItemText, CssBaseline,
    IconButton, Collapse, Grid, Popper, styled, useTheme, ClickAwayListener, Button, Paper, Divider
} from '@mui/material'
import MuiDrawer from '@mui/material/Drawer'
import MuiAppBar from '@mui/material/AppBar'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import MailIcon from '@mui/icons-material/Mail'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import HomeIcon from '@mui/icons-material/Home'
import NotificationsIcon from '@mui/icons-material/Notifications'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SettingsIcon from '@mui/icons-material/Settings'
import { FaTools, FaUsers, FaGraduationCap, FaNewspaper, FaDatabase } from 'react-icons/fa'
import { IoIosDocument } from 'react-icons/io'
import SchoolIcon from '@mui/icons-material/School'
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
//Import pages and components
import Error404 from './pages/Error404'
import SignIn from './pages/SignIn'
import SignUp from './pages/SignUp'
import ForgotPassword from './pages/ForgotPassword'
import Homepage from './pages/Homepage'
import Role from './pages/Role'
import AddRole from './pages/AddRole'
import Profile from './pages/Profile'
import Users from './pages/Users'
import UserPermissions from './pages/UserPermissions'
import Organization from './pages/Organization'
import School from './pages/School'
import Participant from './pages/Participant'
import CSVUpload from './pages/CSVUpload'
import Task from './pages/Task'
import AddTask from './pages/AddTask'
import EditTask from './pages/EditTask'
import TaskModerationOverview from './pages/TaskModerationOverview'
import Translations from './pages/Translations'
import TranslateTasks from './pages/TranslateTasks'
import ModerateTask from './pages/ModerateTasks'
import Collection from './pages/Collection'
import AddCollection from './pages/AddCollection'
import AddTaskToCollection from './pages/AddTaskToCollection'
import Difficulty from './pages/Difficulty'
import Domain from './pages/Domain'
import Competitions from './pages/Competitions'
import AddCompetition from './pages/AddCompetition'
import EditCompetition from './pages/EditCompetition'
import PendingCompetitionPartners from './pages/PendingCompetitionPartners'
import AssignDifficulty from './pages/AssignDifficulty'
import Copyright from './components/Copyright'
// import Notification from './components/Notification'
import ReusableButton from './components/general/ReusableButton'
import NunitoText from './components/general/NunitoText'
//Import CSS
import './css/global.css'
import './css/sidebar.css'
//Import redux stuff
import { useSelector, useDispatch } from 'react-redux'
import { setCountryOptions, setRoleOptions, setLanguageOptions, userLogout, userLogin } from './actions'
import { isLoggedIn, isAdmin, isPartner, isTeacher } from './functions/checkrole'
//Import functions
import { logout, login } from './functions/httpMethods'
import { getCountries, getRoles, getLanguages } from './functions/getData'
import { isEmpty, warningMessage } from './functions/alert'
import { showNotification } from './functions/snackbar'
import { SnackbarProvider, useSnackbar } from 'notistack'
//Customise sidebar
const drawerWidth = 300
const openedMixin = theme => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
    overflowX: 'hidden',
    backgroundColor: '#5E75C3',
    color: '#FFFFFF'
})
const closedMixin = theme => ({
    width: 60,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.short,
    }),
    overflowX: 'hidden',
    [theme.breakpoints.up('sm')]: {
        width: 60
    },
    backgroundColor: '#5E75C3',
    color: '#FFFFFF'
})
const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}))
const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    height: 64,
    backgroundColor: '#5E75C3',
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}))
const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        })
    }),
)
export function NavBar({ open, toggleDrawer }) {
    //Open either notification or user popper, only one at a time
    const [anchorElNotif, setAnchorElNotif] = useState(null)
    const [anchorElUser, setAnchorElUser] = useState(null)
    const user = useSelector(state => state.user)
    const history = useHistory()
    const dispatch = useDispatch()
    const toggleNotification = e => {
        setAnchorElNotif(anchorElNotif ? null : e.currentTarget)
        setAnchorElUser(null)
    }
    const toggleUser = e => {
        setAnchorElUser(anchorElUser ? null : e.currentTarget)
        setAnchorElNotif(null)
    }
    const logoutUser = () => {
        logout().then(d => {
            console.log(d)
            dispatch(userLogout())
            dispatch(setLanguageOptions([]))
            dispatch(setCountryOptions([]))
            dispatch(setRoleOptions([]))
            history.push('/signin')
        })
        setAnchorElUser(null)
    }
    const roles = ['Admin', 'Partner', 'Teacher']
    const popperContainerStyle = {
        backgroundColor: '#FFF',
        borderRadius: 12,
        boxShadow: '0 0 3px #9E9E9E',
        padding: 15
    }
    const menuIconButtonStyle = { marginRight: 36, display: open && 'none', color: '#FFF' }
    const notificationIconButtonStyle = { color: '#FFF' }
    const arrowIconStyle = { position: 'relative', left: 6 }
    const accountCircleIconStyle = { fontSize: 64 }
    const buttonWrapperStyle = { marginTop: 20, display: 'flex', justifyContent: 'center' }
    return (
        isLoggedIn() ? <AppBar position="fixed" open={open}>
            <Toolbar>
                <IconButton onClick={() => toggleDrawer(true)} edge="start" style={menuIconButtonStyle}>
                    <MenuIcon />
                </IconButton>
                <Grid container justifyContent='flex-end' alignItems='center'>
                    <IconButton onClick={toggleNotification} style={notificationIconButtonStyle}>
                        <NotificationsIcon />
                    </IconButton>
                    {Boolean(anchorElNotif) && <ClickAwayListener onClickAway={toggleNotification}>
                        <Popper open={Boolean(anchorElNotif)} anchorEl={anchorElNotif} style={popperContainerStyle}>
                            {['Test1', 'Test2', 'Test3'].map((notification, index) => (
                                <div className='notificationText' key={index}>
                                    <h4>Notification</h4>
                                    <p>{notification}</p>
                                </div>
                            ))}
                            <ReusableButton text='View all notifications' bgColor='#F16774' iconType='notification' fontWeight={600} />
                        </Popper>
                    </ClickAwayListener>}
                    <IconButton onClick={toggleUser} style={notificationIconButtonStyle}>
                        <AccountCircleIcon />
                        <p style={{ fontSize: 12, paddingLeft: 10 }}>{user.username}</p>
                        {Boolean(anchorElUser) ? <ArrowDropUpIcon style={arrowIconStyle} /> : <ArrowDropDownIcon style={arrowIconStyle} />}
                    </IconButton>
                    {Boolean(anchorElUser) && <ClickAwayListener onClickAway={toggleUser}>
                        <Popper open={Boolean(anchorElUser)} anchorEl={anchorElUser} style={popperContainerStyle}>
                            <div style={{ textAlign: 'center' }}>
                                <AccountCircleIcon style={accountCircleIconStyle} />
                                <NunitoText value={user.username} fontSize={22} fontWeight={700} />
                                <NunitoText value={roles[user.role_id - 1]} fontWeight={500} marginTop={10} />
                            </div>
                            <div style={buttonWrapperStyle}>
                                <ReusableButton text='Home' bgColor='#5E75C3' width={100} height={36} iconType='home'
                                    to={isTeacher() ? '/participants' : '/schools'} marginRight={10} />
                                <ReusableButton text='Profile' bgColor='#5E75C3' width={100} height={36} iconType='profile'
                                    to='/profile' marginLeft={10} />
                            </div>
                            <div style={buttonWrapperStyle}>
                                <ReusableButton text='Log Out' bgColor='#E83042' width={160} height={36}
                                    iconType='logout' fontWeight={600} onClick={logoutUser} />
                            </div>
                        </Popper>
                    </ClickAwayListener>}
                </Grid>
            </Toolbar>
        </AppBar> : null
    )
}
export function SideBar({ open, toggleDrawer, hoverDrawer, unhoverDrawer }) {
    const faIconStyle = { fontSize: 28 }
    const muiIconStyle = { fontSize: 30 }
    const ioIconStyle = { fontSize: 32 }
    const user = useSelector(state => state.user)
    const nested = [
        [
            (isAdmin() || isPartner()) && 'Schools',
            isAdmin() && 'Organizations',
            // 'Translations',
            // 'Difficulty',
            isAdmin() && 'Domain',
            // 'Award',
            // 'Countries',
            // 'Grades',
            // 'Topics',
            // 'Currencies',
            'CSV Upload',
            isAdmin() && 'Task',
            // 'Task Moderation',
            isAdmin() && 'Collection'
        ],
        [
            'Users',
            // 'Administrators',
            // 'Country Partners',
            // 'Teacher Super Admins',
            // 'Role',
            // 'Add Role'
        ],
        [
            'Students',
            'Merge Student'
        ],
        [
            (isAdmin() || isPartner()) && 'Competitions',
            isAdmin() && 'Pending Competition Partners',
            'Participants',
            // 'Competition Sessions'
        ],
        [
            'Books',
            'Announcements',
            'Advertisements'
        ],
        [
            'Competition Participation',
            'Competition Session Status'
        ]
    ].map(n => n.filter(Boolean))
    const allBtns = [
        // { title: 'Dashboard', icon: <HomeIcon style={muiIconStyle} /> },
        { title: 'Setup', icon: <FaTools style={faIconStyle} />, child: nested[0], openLinks: false },
        // user.role_id < 3 && { title: 'Manage Users', icon: <FaUsers style={faIconStyle} />, child: nested[1], openLinks: false },
        user.role_id < 3 && { title: 'Users', icon: <FaUsers style={faIconStyle} /> },
        // { title: 'Manage Students', icon: <FaGraduationCap style={faIconStyle} />, child: nested[2], openLinks: false },
        { title: 'Manage Competitions', icon: <FaNewspaper style={faIconStyle} />, child: nested[3], openLinks: false },
        // { title: 'Administrations', icon: <FaDatabase style={faIconStyle} />, child: nested[4], openLinks: false },
        // { title: 'Reports', icon: <IoIosDocument style={ioIconStyle} />, child: nested[5], openLinks: false },
        // { title: 'Mail Templates', icon: <MailIcon style={muiIconStyle} /> },
        // { title: 'Settings', icon: <SettingsIcon style={muiIconStyle} /> }
    ]
    const [btns, setBtns] = useState(allBtns)
    useEffect(() => setBtns(allBtns), [user])
    const theme = useTheme()
    let location = useLocation()
    //Open one sub list from the drawer at a time(Will close other sub list if any is open at the moment)
    const openNested = title => {
        let newBtns = [...btns]
        newBtns.filter(b => b.child !== undefined).forEach(btn => {
            if (btn.title === title) btn.openLinks = !btn.openLinks
            else btn.openLinks = false
        })
        setBtns(newBtns)
    }
    const headListItemStyle = child => ({
        color: child.some(el => location.pathname.slice(1) === el.replace(/\s+/g, '').toLowerCase()) ? '#454E6F' : '#FFF'
    })
    const listItemStyle = link => ({
        color: location.pathname.slice(1) === link.replace(/\s+/g, '').toLowerCase() ? '#454E6F' : '#FFF'
    })
    const CollapsibleLink = ({ title, icon, child, openLinks }) => (
        <>
            <ListItem button key={title} onClick={() => openNested(title)}>
                <ListItemIcon style={headListItemStyle(child)}>{icon}</ListItemIcon>
                <ListItemText primary={title} style={headListItemStyle(child)} />
                {openLinks ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={openLinks && open} timeout='auto'>
                {child.map((link, i) => (
                    <List component="div" disablePadding key={i}>
                        <Link to={`/${link.replace(/\s+/g, '').toLowerCase()}`} style={{ textDecoration: 'none' }}>
                            <ListItem button style={{ ...listItemStyle(link), paddingLeft: 80 }}>
                                <ListItemText primary={link} />
                            </ListItem>
                        </Link>
                    </List>
                ))}
            </Collapse>
        </>
    )
    const NormalLink = ({ title, icon }) => (
        <Link to={`/${title.replace(/\s+/g, '').toLowerCase()}`} style={{ textDecoration: 'none' }}>
            <ListItem button style={listItemStyle(title)}>
                <ListItemIcon style={listItemStyle(title)}>{icon}</ListItemIcon>
                <ListItemText primary={title} />
            </ListItem>
        </Link>
    )
    return (
        isLoggedIn() ? <>
            <Drawer variant="permanent" open={open} onMouseEnter={() => hoverDrawer()} onMouseLeave={() => unhoverDrawer()}>
                <DrawerHeader>
                    <IconButton onClick={() => toggleDrawer(false)} style={{ color: '#FFF' }}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <List>
                    {btns.filter(Boolean).map(({ title, icon, child, openLinks }, index) => (
                        <div key={index}>
                            {child === undefined ? NormalLink({ title, icon }) : CollapsibleLink({ title, icon, child, openLinks })}
                        </div>
                    ))}
                </List>
            </Drawer>
            <DrawerHeader />
        </> : null
    )
}
export function EveryLink({ path, content, exact, defaultContent, justShow }) {
    const user = useSelector(state => state.user)
    let history = useHistory()
    let whatToShow
    if (isLoggedIn()) {
        if (defaultContent) whatToShow = defaultContent
        else whatToShow = content
    } else {
        if (defaultContent) whatToShow = content
        else {
            if (justShow) whatToShow = content
            else history.push('/signin')
        }
    }
    // if(!isLoggedIn() && !justShow) history.push('/signin')
    // whatToShow = isLoggedIn() ? defaultContent ? defaultContent : content : justShow ? content : null
    return (
        <Route path={path} exact={exact}>
            {whatToShow}
        </Route>
    )
}
export function BodyContent({ open, toggleDrawer, hoverDrawer, unhoverDrawer }) {
    const user = useSelector(state => state.user)
    let dispatch = useDispatch()
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const contentContainerStyle = {
        marginTop: isLoggedIn() && 64,
        // borderRadius: '0 0 0 8px', backgroundColor: '#FFF'
        // display: 'flex', flexDirection: 'column', justifyContent: 'space-between'
    }
    let history = useHistory()
    const onLogin = (username, password, setWrongLogin) => {
        if (isEmpty([username, password])) {
            warningMessage([{ name: 'Username', state: username }, { name: 'Password', state: password }])
            return
        }
        login({ username, password }).then(d => {
            console.log(d)
            if (d.status !== 200) {
                showNotification('error', d.message, enqueueSnackbar, closeSnackbar)
                setWrongLogin(true)
                return
            }
            dispatch(userLogin(true, username, d.role_id, d.user_id, d.country_id, d.school_id, d.parent_id, d.token))
            // history.goBack()
        })
    }
    const defaultContent = <Redirect to={isTeacher() ? '/participants' : '/schools'} />
    const routes = [
        { path: '/', content: <School />, exact: true },
        { path: '/signin', content: <SignIn onLogin={onLogin} />, defaultContent },
        // { path: '/signup', content: <SignUp />, defaultContent },
        // { path: '/forgotpassword', content: <ForgotPassword />, defaultContent },
        // { path: '/dashboard', content: <Homepage /> },
        (isAdmin() || isPartner()) && { path: '/schools', content: <School /> },
        { path: '/participants', content: <Participant /> },
        { path: '/csvupload', content: <CSVUpload /> },
        { path: '/profile', content: <Profile /> },
        (isAdmin() || isPartner()) && { path: '/users', content: <Users /> },
        // { path: '/userpermissions/:id', content: <UserPermissions /> },
        isAdmin() && { path: '/organizations', content: <Organization /> },
        isAdmin() && { path: '/role', content: <Role /> },
        isAdmin() && { path: '/addrole', content: <AddRole /> },
        isAdmin() && { path: '/task', content: <Task /> },
        isAdmin() && { path: '/addtask', content: <AddTask /> },
        isAdmin() && { path: '/edittask', content: <EditTask /> },
        // { path: '/taskmoderation', content: <TaskModerationOverview /> },
        // { path: '/translations', content: <Translations /> },
        // { path: '/translateTasks/:id?', content: <TranslateTasks /> },
        // { path: '/moderatetask', content: <ModerateTask /> },
        isAdmin() && { path: '/collection', content: <Collection /> },
        isAdmin() && { path: '/addcollection', content: <AddCollection /> },
        // { path: '/addtasktocollection/:collectionID/:sectionID/:taskGroupIndex/:taskGroupID?/:taskIndex?', content: <AddTaskToCollection /> },
        { path: '/domain', content: <Domain /> },
        // { path: '/difficulty', content: <Difficulty /> },
        (isAdmin() || isPartner()) && { path: '/competitions', content: <Competitions /> },
        isAdmin() && { path: '/addcompetition', content: <AddCompetition /> },
        (isAdmin() || isPartner()) && { path: '/editcompetition', content: <EditCompetition /> },
        isAdmin() && { path: '/pendingcompetitionpartners', content: <PendingCompetitionPartners /> },
        // { path: '/assignDifficulty/:competitionID/:roundIndex/:levelIndex/:collectionID', content: <AssignDifficulty /> },
        { path: '', content: <Error404 />, justShow: true },
    ].filter(Boolean)
    return (
        <Box style={{ display: 'flex' }}>
            <SideBar open={open} toggleDrawer={toggleDrawer} hoverDrawer={hoverDrawer} unhoverDrawer={unhoverDrawer} />
            {/* {SideBar({ open, toggleDrawer, hoverDrawer, unhoverDrawer })} */}
            <Grid container style={contentContainerStyle}>
                {/* Content of the web app */}
                <Switch>
                    {routes.filter(Boolean).map(({ path, content, exact, defaultContent, justShow }, i) => (
                        <EveryLink key={i} path={path} content={content} exact={exact}
                            defaultContent={defaultContent} justShow={justShow} />
                    ))}
                </Switch>
                {/* <Copyright /> */}
            </Grid>
        </Box>
    )
}
export default function App() {
    const [open, setOpen] = useState(false)
    const [forceOpen, setForceOpen] = useState(false)
    const user = useSelector(state => state.user)
    const roleOptions = useSelector(state => state.roleOptions)
    const countryOptions = useSelector(state => state.countryOptions)
    const languageOptions = useSelector(state => state.languageOptions)
    const dispatch = useDispatch()
    const toggleDrawer = state => {
        setOpen(state)
        setForceOpen(state)
    }
    const hoverDrawer = () => !forceOpen && setOpen(true)
    const unhoverDrawer = () => !forceOpen && setOpen(false)
    useEffect(() => {
        if (isLoggedIn() && !roleOptions.length && !countryOptions.length && !languageOptions.length) {
            getCountries().then(d => dispatch(setCountryOptions(d)))
            getRoles().then(d => dispatch(setRoleOptions(d)))
            getLanguages().then(d => dispatch(setLanguageOptions(d)))
        }
    }, [user])
    return (
        <SnackbarProvider maxSnack={4}>
            <Router>
                <Box className='wrapper'>
                    <CssBaseline />
                    {/* NavBar({ open, toggleDrawer }) */}
                    <NavBar open={open} toggleDrawer={toggleDrawer} />
                    {/* {BodyContent({ open, toggleDrawer, hoverDrawer, unhoverDrawer })} */}
                    <BodyContent open={open} toggleDrawer={toggleDrawer}
                        hoverDrawer={hoverDrawer} unhoverDrawer={unhoverDrawer} />
                    <div style={{ zIndex: 1300 }}>
                        <Copyright />
                    </div>
                </Box >
            </Router>
        </SnackbarProvider>
    )
}