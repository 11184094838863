import React from 'react'
import { Button } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
export default function ViewAndFilterButton({ text, state, height, fontSize, marginLeft, marginRight, marginTop, marginBottom,
    onClick, width }) {
    const btnStyle = {
        height: height,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        backgroundColor: state ? '#F16774' : '#5E75C3',
        color: '#FFF',
        textTransform: 'none',
        borderRadius: 6,
        fontWeight: 'normal',
        fontSize: fontSize,
        padding: '10px 10px 10px 20px',
        width: width
    }
    const iconStyle = { fontSize: 36 }
    return (
        <Button style={btnStyle} onClick={() => onClick && onClick()} endIcon={state ? <ArrowDropUpIcon style={iconStyle} /> :
            <ArrowDropDownIcon style={iconStyle} />}>{text}</Button>
    )
}