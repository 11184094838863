import React, { useEffect } from 'react'
import { Grid, Box } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Competitions from '../components/Competitions'
import { useDispatch } from 'react-redux'
import { updateCompetition, resetCompetition } from '../actions'
import { getCompetitions } from '../functions/getData'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
export default function AddCompetition() {
    document.title = 'Add Competition'
    let location = useLocation()
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCompetition(count, head, target, text, event))
    useEffect(() => {
        if (location.search.slice(0, 14) === '?duplicate_id=') {
            getCompetitions('?limits=50').then(c => {
                if (c.competitionList.data.find(d => d.id === Number(location.search.slice(14))) === undefined) {
                    alert('cant find')
                } else {
                    let competition = c.competitionList.data.find(d => d.id === Number(location.search.slice(14)))
                    update(0, 'competitionBoolean', 'globalCompetition', Boolean(competition.format))
                    update(0, 'settingDate', 'competitionStart', '', new Date().getTime() > new Date(competition.competition_start_date).getTime() ?
                        new Date().getTime() : new Date(competition.competition_start_date).getTime() || new Date().getTime())
                    update(0, 'settingDate', 'competitionEnd', '', new Date().getTime() > new Date(competition.competition_end_date).getTime() ?
                        new Date().getTime() : new Date(competition.competition_end_date).getTime() || new Date().getTime())
                    update(0, 'setCountryPartners', 'countryPartners', competition.partners.map(p => ({
                        countryPartner: p.id,
                        registrationOpen: new Date().getTime() > new Date(p.partnerRegStartDate).getTime() ? new Date().getTime() :
                            new Date(p.partnerRegStartDate).getTime(),
                        competitionDates: [new Date().getTime()],
                        registration_days_apart: 7,
                        status: '',
                        allowEdit: false,
                        languagesToVerify: []
                    })))
                }
            })
        }
    }, [])
    // window.onbeforeunload = (event) => {
    //     const e = event || window.event;
    //     // Cancel the event
    //     e.preventDefault();
    //     dispatch(resetCompetition())
    //     if (e) {
    //         e.returnValue = ''; // Legacy method for cross browser support
    //         return
    //     }
    //     dispatch(resetCompetition())
    //     return ''; // Legacy method for cross browser support
    // };
    return (
        <Box style={{ flexGrow: 1 }}>
            {/* {JSON.stringify(location.search)}<br />
            {JSON.stringify(location.search.slice(0, 14) === '?duplicate_id=')}<br />
            {JSON.stringify(location.search.slice(14))} */}
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Competitions' fontSize={14} bgColor='#F16774' height={36} br={18}
                    onClick={() => dispatch(resetCompetition())} />
            </div>
            <Grid container flexDirection='column' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Add Competition' fontSize={40} fontWeight={700} italic color='#144A94' align='left' marginBottom={24} />
                <Competitions />
            </Grid>
        </Box>
    )
}