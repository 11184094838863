import React, { useState, useEffect } from 'react'
import {
    Box, IconButton, Checkbox, Table, TableRow, TableHead, styled, tooltipClasses, Grid,
    TableCell, TableContainer, TableBody, Tooltip, Paper, TablePagination, Chip
} from '@mui/material'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import AddIcon from '@mui/icons-material/Add'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import VisibilityIcon from '@mui/icons-material/Visibility'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { FiAlertOctagon } from 'react-icons/fi'
import { useSelector, useDispatch } from 'react-redux'
import { updateCollection } from '../../actions'
import '../../css/global.css'
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: props.rollover === true ? {
        backgroundColor: theme.palette.common.white,
        color: '#144A94',
        position: 'absolute',
        top: -40,
        border: '1px solid gray',
        borderRadius: props.rollover === true ? 16 : 6,
        maxWidth: 950,
        display: props.hide === true && 'none',
        boxShadow: props.rollover === true && '5px 5px 12px rgba(0, 0, 0, 16%)'
    } : {
        backgroundColor: theme.palette.common.white,
        color: '#144A94',
        position: 'relative',
        bottom: props.type === 'Button' ? 10 : props.type === true ? 40 : 90,
        right: 0,
        left: props.type === 'Button' ? 0 : props.type === 'AddOrRemove' ? -10 : 10,
        border: '1px solid gray',
        borderRadius: props.rollover === true ? 16 : 6,
        maxWidth: 950,
        display: props.hide === true && 'none',
        boxShadow: props.rollover === true && '5px 5px 12px rgba(0, 0, 0, 16%)'
    }
}));
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}
const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}
const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
function EnhancedTableHead({ order, orderBy, handleRequestSort, headCells }) {
    return (
        <TableHead>
            <TableRow>
                {headCells.map((headCell, index) => (
                    <TableCell key={index} align='left' padding='normal'
                        sortDirection={orderBy === headCell.id ? order : false}
                        style={{ fontSize: 20, fontWeight: 'bolder', color: '#144a94', cursor: 'pointer', height: 61 }}
                        onClick={(e) => handleRequestSort(e, headCell.id)}
                    >
                        <div style={{ display: 'flex', width: index === 0 ? 320 : headCell.id !== 'tags' && 300 }}>
                            <div style={{ position: 'relative', top: 10 }}>{headCell.label}</div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <ArrowDropUpIcon sx={{ margin: 0, position: 'relative', top: 8.2, color: '#000' }} />
                                <ArrowDropDownIcon sx={{ margin: 0, position: 'relative', bottom: 8.2, color: '#000' }} />
                            </div>
                        </div>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
export function PaginationButton({ isSelected, selecting, setPage }) {
    return (
        <div style={{
            backgroundColor: isSelected && '#144A94', borderRadius: 32, width: 32,
            marginInline: 8, height: 32, display: 'flex', alignItems: 'center',
            justifyContent: 'center', cursor: 'pointer'
        }} onClick={() => setPage(selecting)}>
            <h4 style={{ color: isSelected ? '#FFF' : '#000', fontWeight: 'normal' }}>{selecting + 1}</h4>
        </div>
    )
}
export default function TaskTable({ headers, data, getters, collectionID, sectionID, taskGroupIndex, taskGroupID, taskIndex, collection,
    rowsPerPage, setRowsPerPage, selecting, setSelecting, selected, setSelected, filtered, setOnAddTaskToCollection }) {
    //headCells function has to be loaded from parent
    const headCells = headers
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('id');
    const [page, setPage] = useState(0);
    const taskIsAlreadyInCollection = (id) => sections.map(s => s.taskGroups).flat(1).map(t => t.taskIDs).flat(1).includes(id)
    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };
    const handleSelectAllClick = (event, names) => {
        if (event.target.checked) {
            const newSelecteds = data.map(n => !taskIsAlreadyInCollection(n.id) && n.id)
            setSelected(newSelecteds)
            setSelecting(names.map((n, i) => newSelecteds[i] && n))
            return;
        }
        setSelected([])
        setSelecting([])
    };
    const handleClick = (event, id, name) => {
        if (taskIsAlreadyInCollection(id)) return
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        if (event.target.checked) setSelecting([...selecting, name])
        else setSelecting(selecting.filter(d => d !== name))
    };
    const isSelected = (id) => selected.indexOf(id) !== -1
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
    const EnhancedHover = ({ getter, data, type, structure, row }) => {
        switch (getter) {
            case 'recommendedDifficulty':
            case 'sections':
                let w = 220
                if (getter === 'recommendedDifficulty') w = 400
                return (
                    <>
                        {getter === 'recommendedDifficulty' && <h2 style={{
                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid gray', textAlign: 'center',
                            borderRadius: 6, paddingBlock: 2, marginInline: 80, marginTop: 5, fontWeight: 400
                        }}>Difficulty Group - {row.difficultyGroup}</h2>}
                        {data && <div style={{ display: 'flex', justifyContent: 'space-between', width: w }}>
                            {Object.keys(data[0]).map((a, index) => (
                                <div key={index}>
                                    <h2 style={{
                                        margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid gray', textAlign: 'center',
                                        borderRadius: 6, paddingInline: 4, paddingBlock: 2, marginInline: 3, marginTop: 5, fontWeight: 400
                                    }}>{a === 'partner' ? 'Country Partner' : a.charAt(0).toUpperCase() + a.slice(1)}
                                        {getter === 'recommendedDifficulty' && '(recommended)'}</h2>
                                    {data.map((d, index) => (
                                        <h2 key={index} style={{
                                            margin: '0 0 0 3px', fontWeight: 300, color: '#000',
                                            textAlign: getter === 'recommendedDifficulty' ? 'left' : 'center'
                                        }}>{d[a]}{a === 'percentage' && '%'}</h2>
                                    ))}
                                </div>
                            ))}
                        </div>}
                    </>
                )
            case 'tags':
            case 'language':
            case 'competition':
                let pInline = 10
                if (getter === 'tags') pInline = 36
                return (
                    data && <div>
                        <h2 style={{
                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF', border: '1px solid gray', textAlign: 'center',
                            borderRadius: 6, paddingInline: pInline, paddingBlock: 2, marginInline: 3, marginTop: 5, fontWeight: 400
                        }}>{getter.charAt(0).toUpperCase() + getter.slice(1)}</h2>
                        {data.map((d, index) => (
                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d}</h2>
                        ))}
                    </div>
                )
            case 'permissions':
                return (
                    <div style={{ height: 270, width: 400 }}>
                        <h3 style={{ margin: 0, color: '#5e75c3' }}>
                            Role Permission</h3>
                        <div style={{ display: 'flex', justifyContent: 'space-between', padding: 8, boxShadow: '0 0 8px #9E9E9E' }}>
                            {['ID', 'Role', 'User', 'Date'].map((header, index) => (
                                <div key={index}>
                                    <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                                        <h4 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 3, fontWeight: 'normal',
                                            paddingInline: '16%', paddingBlock: 2
                                        }}>{header}</h4>
                                    </div>
                                    {data.map((d, i) => {
                                        let a = ''
                                        switch (index) {
                                            case 0: a = 'id'; break;
                                            case 1: a = 'role'; break;
                                            case 2: a = 'user'; break;
                                            case 3: a = 'date'; break;
                                            default: break;
                                        }
                                        return (<h4 key={i} style={{ margin: 0, fontWeight: 'lighter', textAlign: 'center' }}>{d[a]}</h4>)
                                    })}
                                </div>
                            ))}
                        </div>
                        <h3 style={{ marginTop: 40, color: '#5e75c3' }}>
                            Assigned Permission</h3>
                    </div>
                )
            case 'lastVerifiedBy':
                return (
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding: 8 }}>
                        {['ID', 'Role', 'User', 'Date'].map((header, index) => (
                            <div key={index}>
                                <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'space-around' }}>
                                    <h4 style={{
                                        margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                        borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                        paddingTop: 2, paddingBottom: 2, fontWeight: 'normal'
                                    }}>{header}</h4>
                                </div>
                                {data.map((d, i) => {
                                    let a = ''
                                    switch (index) {
                                        case 0: a = 'id'; break;
                                        case 1: a = 'role'; break;
                                        case 2: a = 'user'; break;
                                        case 3: a = 'date'; break;
                                        default: break;
                                    }
                                    return (<h4 key={i} style={{ margin: 0, fontWeight: 'lighter', textAlign: 'center' }}>{d[a]}</h4>)
                                })}
                            </div>
                        ))}
                    </div>
                )
            case 'answers':
                if (type === 'MCQ') {
                    if (structure === 'Sequence') {
                        return (
                            <div>
                                <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>MCQ</h1>
                                <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Sequence correct answers</h2>
                                <div style={{
                                    display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Order</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.order}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Answers</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Points</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    } else {
                        return (
                            <div>
                                <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>MCQ</h1>
                                <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>{structure === 'Default' ?
                                    'Single/Multiple' : structure} correct answers</h2>
                                <div style={{
                                    display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Answers</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Points</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                } else {
                    if (structure === 'Open') {
                        return (
                            <div>
                                <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Input</h1>
                                <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Open</h2>
                                <div style={{
                                    display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Field Label</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.fieldLabel}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Points</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                        ))}
                                    </div>
                                </div>
                            </div>

                        )
                    } else {
                        return (
                            <div>
                                <h1 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>Input</h1>
                                <h2 style={{ margin: 0, color: '#5E75C3', fontWeight: 'normal' }}>In sequence answers{structure === 'Default'
                                    ? ' with different points per field' : ', with fixed points'}</h2>
                                <div style={{
                                    display: 'flex', border: '1px solid gray', borderRadius: 16, padding: '3px 6px',
                                    justifyContent: 'space-evenly'
                                }}>
                                    <div style={{ textAlign: 'center' }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Field Label</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.fieldLabel}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Answers</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.answer}</h2>
                                        ))}
                                    </div>
                                    <div style={{ textAlign: 'center', marginLeft: 20 }}>
                                        <h2 style={{
                                            margin: 0, backgroundColor: '#5e75c3', color: '#FFF',
                                            borderRadius: 6, paddingLeft: 4, paddingRight: 4,
                                            paddingTop: 2, paddingBottom: 2, fontWeight: 'lighter'
                                        }}>
                                            Points</h2>
                                        {data.map((d, index) => (
                                            <h2 key={index} style={{ margin: 0, fontWeight: 'lighter' }}>{d.points}</h2>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        )
                    }
                }
            default:
                return null
        }
    }
    useEffect(() => {
        setRowsPerPage(parseInt(rowsPerPage, 10));
        setPage(0);
    }, [rowsPerPage, setRowsPerPage])
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCollection(count, head, target, text, event))
    const addTask = (taskNameName, id) => {
        setOnAddTaskToCollection(true)
        setSelecting([taskNameName])
        setSelected([id])
    }
    let sections = collection.sections
    const collections = useSelector(state => state.collection)
    const Btns = ({ row }) => {
        // This part is to find where the task is in the collection, if the task is already in the collection
        let a = sections.map(s => ({ sectionID: s.sectionID, taskGroup: s.taskGroups }))
        let b = a.map(a => a.taskGroup).flat(1).map(b => b.taskIDs)
        let c = b.map(b => b.includes(row.id) && b).find(d => d)
        let d = a.find(a => a.taskGroup.find(t => t.taskIDs === c))
        let e = d?.taskGroup.find(d => d.taskIDs === c)
        let g = e?.taskIDs.indexOf(row.id)

        let collectionCount = collections.findIndex(c => c.collectionID === Number(collectionID))
        let sectionCount = sections.findIndex(s => s.sectionID === Number(d?.sectionID))
        let taskGroupCount = b.findIndex(b => b === c)
        let icons = [{
            title: taskIsAlreadyInCollection(row.id) ? <div style={{ display: 'flex', alignItems: 'center', height: 20 }}>
                <FiAlertOctagon style={{ color: 'red', fontSize: 20, marginRight: 8 }} />
                <p>Task already in Section {d?.sectionID} Group {e?.taskGroupID} as Task {g + 1}!</p>
            </div> : 'Add to Collection', item: <AddIcon />,
            onClick: () => !taskIsAlreadyInCollection(row.id) && addTask(row.taskIdentifier, row.id)
        },
        taskIsAlreadyInCollection(row.id) && {
            title: 'Remove Task', item: <RemoveCircleIcon />,
            onClick: () => update([collectionCount, sectionCount, taskGroupCount], 'deleteTaskFromGroup', 'taskGroups', row.id)
        },
        { title: 'Preview', item: <VisibilityIcon /> }]
        return (
            icons.map((icon, index) => (
                icon && <LightTooltip key={index} title={icon.title} placement='bottom-start' type='Button'>
                    <IconButton onClick={() => icon.onClick && icon.onClick()}
                        style={{ color: !index && taskIsAlreadyInCollection(row.id) ? '#707070' : '#000' }}>{icon.item}</IconButton>
                </LightTooltip>
            ))
        )
    }
    const Row = ({ getters, row, isItemSelected }) => {
        const [color, setColor] = useState('#FFF')
        return (
            <TableRow hover tabIndex={-1} selected={isItemSelected} style={{
                height: 80, overFlow: 'auto',
                backgroundColor: isItemSelected ? '#EDF4FB' : color
            }} onMouseEnter={() => setColor('#F6F6F6')} onMouseLeave={() => setColor('#FFF')}>
                {getters.map((getter, index) => (
                    !['id', 'isTuition', 'correct', 'wrong', 'blank'].includes(getter) &&
                    <LightTooltip key={index} title={typeof row[getter] === 'object' ?
                        <EnhancedHover getter={getter} data={row[getter]} type={row.answerType} structure={row.answerStructure} />
                        : row[getter]} placement='bottom-start'
                        type={typeof row[getter] === 'object'} hide={row[getter] === null || index === 1}
                        rollover={typeof row[getter] === 'object'}>
                        <TableCell align='left' component="th" scope="row" style={{
                            paddingLeft: 15, position: 'sticky', left: 0, zIndex: index === 1 && 999
                        }}>
                            {index === 1 ?
                                <div style={{
                                    whiteSpace: "nowrap", textOverflow: "ellipsis", display: "block", overflow: "hidden",
                                    backgroundColor: isItemSelected ? '#EDF4FB' : color
                                }}>{row[getter]}
                                    <div><Btns row={row} getter={getter} /></div>
                                </div> : getter === 'tags' ?
                                    <div style={{ display: 'flex' }}>
                                        {row[getter].map((tag, index) => {
                                            if (index < 4) {
                                                return (
                                                    <Chip key={index} sx={{
                                                        backgroundColor: 'red',
                                                        color: '#FFF', mr: 0.3, border: '0.5px solid gray'
                                                    }} label={index === 3 ? `+${row[getter].length - index}` : tag} />
                                                )
                                            } return null
                                        })}
                                    </div> :
                                    <div style={{
                                        whiteSpace: "nowrap", textOverflow: "ellipsis",
                                        display: "block", overflow: "hidden", width: 280
                                    }}>{row[getter] !== null ? typeof row[getter] === 'object' ? row[getter].length : row[getter] : '-'}
                                    </div>}
                        </TableCell>
                    </LightTooltip>
                ))}
            </TableRow>
        )
    }
    return (
        <Box style={{ maxWidth: 1700, marginTop: '2%' }}>
            {JSON.stringify({ selecting, selected })}
            <Grid container>
                <Grid item xs={0.5}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <Checkbox style={{ color: "#000", height: 61, paddingTop: 12 }}
                            indeterminate={selected.length > 0 && selected.length < data.length}
                            checked={data.length > 0 && selected.length === data.length}
                            onChange={(e) => handleSelectAllClick(e, data.map(d => d[getters[1]]))}
                        />
                        {stableSort(data, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, i) => (
                                <Checkbox key={i} onClick={(event) => handleClick(event, row.id, row[getters[1]])}
                                    style={{ color: "#000", height: 80 }} checked={isSelected(row.id)} />
                            ))}
                    </div>
                </Grid>
                <Grid item xs={11.5}>
                    <Paper sx={{ borderRadius: 0, boxShadow: '0 0 3px #9E9E9E' }} >
                        <TableContainer>
                            <Table style={{ minWidth: 350 }} aria-labelledby="tableTitle" size='small'>
                                <EnhancedTableHead order={order} orderBy={orderBy} handleRequestSort={handleRequestSort} headCells={headCells} />
                                <TableBody>
                                    {stableSort(data, getComparator(order, orderBy))
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((row, i) => (
                                            <Row key={i} getters={getters} row={row} count={i} isItemSelected={isSelected(row.id)} />
                                        ))}
                                    {emptyRows > 0 && <TableRow style={{ height: 80 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination showFirstButton showLastButton
                            component={() => {
                                if (rowsPerPage >= 1) {
                                    let a = []
                                    let z = data.length
                                    while (z > 0) { z -= rowsPerPage; a.push(z); }
                                    return (
                                        <Grid container direction="row" justifyContent="flex-end" alignItems="center"
                                            sx={{ mt: 6, mb: 6 }}>
                                            <IconButton disabled={!page} onClick={() => setPage(page - 1)}>
                                                <NavigateBeforeIcon />
                                            </IconButton>
                                            <PaginationButton isSelected={!page} selecting={0} setPage={setPage} />
                                            {page > 4 && '...'}
                                            {a.map((c, index) => {
                                                //All pages between first and last, and only display if its 3 page or less
                                                //away from the current page
                                                if (index && index < a.length - 1 && index <= page + 3 && index >= page - 3) {
                                                    return (
                                                        <PaginationButton key={index} isSelected={page === index}
                                                            selecting={index} setPage={setPage} />
                                                    )
                                                }
                                            })}
                                            {a.length > page + 5 && '...'}
                                            {a.length > 1 && <PaginationButton isSelected={page === a.length - 1}
                                                selecting={a.length - 1} setPage={setPage} />}
                                            <IconButton disabled={page === a.length - 1} onClick={() => setPage(page + 1)}>
                                                <NavigateNextIcon />
                                            </IconButton>
                                        </Grid>)
                                } return null
                            }}
                            count={data.length} onPageChange={() => { }}
                            page={page} rowsPerPage={rowsPerPage}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    )
}