import React, { useState } from 'react'
import { Box, Grid, Button, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import VisibilityIcon from '@mui/icons-material/Visibility'
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight'
import ReusableToggle from '../components/general/ReusableToggle'
import ReusableTextField from '../components/general/ReusableTextField'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
export function Permission({ index, permission, lastIndex, header, permissions, setPermissions }) {
    const updateByValue = (object, value, changedTo) => {
        let number = Object.keys(object).find(key => Object.keys(object[key])[0] === value);
        object[number][value] = changedTo
    }
    const updatePermission = (value) => {
        let newPermissions = [...permissions]
        newPermissions[index][Object.keys(newPermissions[index])] = value
        //If setting a permission to true
        //And the permission getting set to true is either 'View All' or 'View Own Entries Only', set the other one to false
        if (value && Object.keys(newPermissions[index])[0] === 'View All') updateByValue(permissions, 'View Own Entries Only', false)
        if (value && Object.keys(newPermissions[index])[0] === 'View Own Entries Only') updateByValue(permissions, 'View All', false)
        //If setting the permission 'Marking and Grades', set all of its related permission to the same condition
        if (Object.keys(newPermissions[index])[0] === 'Marking and Grades') {
            updateByValue(permissions, 'Read Only', value)
            updateByValue(permissions, 'Edit', value)
            updateByValue(permissions, 'Compute', value)
        }
        setPermissions(newPermissions)
    }
    const containerStyle = {
        marginInline: 30,
        marginTop: ['Read Only', 'Edit', 'Compute'].includes(Object.keys(permission)[0]) && index ? 30 : 20,
        marginBottom: index === lastIndex && 100
    }
    return (
        ['Read Only', 'Edit', 'Compute'].includes(Object.keys(permission)[0]) ?
            <Grid container justifyContent='space-between' alignItems='center' style={containerStyle}>
                <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                    <SubdirectoryArrowRightIcon />
                    <Typography style={{ fontSize: 18, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic', marginLeft: 10 }}>
                        {Object.keys(permission)}</Typography>
                </div>
                <ReusableToggle type='icon' width={120} state={permission[Object.keys(permission)]}
                    setState={updatePermission} disabled={index && !header} />
            </Grid> :
            <Grid container justifyContent='space-between' alignItems='center' style={containerStyle}>
                {!index ? <Typography style={{
                    color: '#144A94', textDecoration: 'underline', fontSize: 30, fontFamily: 'Nunito', fontWeight: 700,
                    fontStyle: 'italic'
                }}>{Object.keys(permission)}</Typography> : Object.keys(permission)[0] === 'Enable Whitelist' ?
                    <div style={{ display: 'flex', alignItems: 'center', color: '#000' }}>
                        <Typography style={{
                            fontSize: 20, fontFamily: 'Nunito', fontWeight: 600,
                            fontStyle: 'italic'
                        }}>{Object.keys(permission)}</Typography>
                        <VisibilityIcon style={{ marginLeft: 30 }} /></div> :
                    <Typography style={{
                        color: '#000', fontSize: 20, fontFamily: 'Nunito', fontWeight: 600,
                        fontStyle: 'italic'
                    }}>{Object.keys(permission)}</Typography>}
                <ReusableToggle type='icon' width={120} state={permission[Object.keys(permission)]}
                    setState={updatePermission} disabled={index && !header} />
            </Grid>
    )
}
export function PermissionGroup({ permissionGroup }) {
    //Get an array of objects with label as key and boolean as value
    const [permissions, setPermissions] = useState(permissionGroup.map(p => { return { [p]: false } }))
    const permissionGroupStyle = {
        border: '1px solid #707070',
        borderRadius: 16,
        boxShadow: '0 0 5px #9E9E9E',
        width: 735,
        marginBlock: '30px 40px'
    }
    return (
        <Grid container style={permissionGroupStyle}>
            {permissions.map((permission, index) => (
                <Permission key={index} index={index} permission={permission} lastIndex={permissions.length - 1}
                    header={permissions[0][Object.keys(permissions[0])]} permissions={permissions} setPermissions={setPermissions} />
            ))}
        </Grid>
    )
}
export default function AddRole() {
    document.title = 'Add Role'
    const [roleName, setRoleName] = useState('')
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Role' fontSize={14} bgColor='#F16774' height={36} br={18} />
            </div>
            <Grid container justifyContent='space-between' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Add Role' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems='center' style={{ marginTop: 40 }}>
                    <Grid item xs={0.2} />
                    <Grid item xs={2.2}>
                        <NunitoText value='Role Name*' fontSize={20} fontWeight={600} italic />
                    </Grid>
                    <Grid item xs={9.6}>
                        <ReusableTextField type='fullWidth' height={45} bgColor='#F2F2F2' placeholder='Role Name'
                            state={roleName} setState={setRoleName} required />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container alignItems="flex-start" style={{ marginTop: 20 }}>
                <Grid item xs={6} style={{ paddingLeft: 120 }}>
                    <PermissionGroup permissionGroup={['User Role Management', 'View', 'Create', 'Edit/Delete']} />
                    <PermissionGroup permissionGroup={['Collection Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Approve Pending', 'Assign Permissions', 'Enable Whitelist']} />
                    <PermissionGroup permissionGroup={['School Management', 'View', 'Create', 'Edit/Delete', 'Merge/Approve']} />
                    <PermissionGroup permissionGroup={['Participant Management', 'View Participants', 'Create', 'Edit/Delete', 'Approve',
                        'Create Schools']} />
                </Grid>
                <Grid item xs={6} style={{ paddingLeft: 65 }}>
                    <PermissionGroup permissionGroup={['Difficulty Management', 'View', 'Create', 'Edit/Delete']} />
                    <PermissionGroup permissionGroup={['Competition Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Marking and Grades', 'Read Only', 'Edit', 'Compute']} />
                    <PermissionGroup permissionGroup={['Task Management', 'View All', 'View Own Entries Only', 'Create', 'Edit/Delete',
                        'Approve Pending', 'Assign Permissions', 'Enable Whitelist']} />
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 130, alignItems: 'flex-end', height: 270 }}>
                        <Button className='btn6' style={{ marginRight: 20 }}>Cancel</Button>
                        <Button className='btn1'>Submit</Button>
                    </div>
                </Grid>
            </Grid>
        </Box>
    )
}