import React from 'react'
import { Grid } from '@mui/material';
import GradeIcon from '@mui/icons-material/Grade';
import CollectionsIcon from '@mui/icons-material/Collections';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { IoIosPaper } from 'react-icons/io'
import { FaLayerGroup } from 'react-icons/fa'
import NunitoText from './NunitoText';
export default function Widget({ title, content, caption, marginBottom }) {
    let bigIcon, smallIcon;
    const bigIconStyle = {
        fontSize: 54,
        marginLeft: 20
    }
    const smallIconStyle = {
        color: '#707070',
        fontSize: 20,
        marginRight: 12
    }
    //Set icons base on title
    switch (title) {
        case 'Grade':
            bigIcon = <GradeIcon style={{ ...bigIconStyle, color: '#F16774' }} />
            smallIcon = <GradeIcon style={smallIconStyle} />
            break;
        case 'Collection':
            bigIcon = <CollectionsIcon style={{ ...bigIconStyle, color: '#569EFF' }} />
            smallIcon = <CollectionsIcon style={smallIconStyle} />
            break;
        case 'Competition':
            bigIcon = <IoIosPaper style={{ ...bigIconStyle, color: '#FF7600' }} />
            smallIcon = <AccountBoxIcon style={smallIconStyle} />
            break;
        case 'Section':
        case 'Level':
            bigIcon = <FaLayerGroup style={{ ...bigIconStyle, color: '#27AD58' }} />
            smallIcon = <FaLayerGroup style={smallIconStyle} />
            break;
        case 'Group':
            bigIcon = <QuestionAnswerIcon style={{ ...bigIconStyle, color: '#E83D4D' }} />
            smallIcon = <QuestionAnswerIcon style={smallIconStyle} />
            break;
        default:
    }
    const divStyle = {
        border: '1px solid #707070',
        borderRadius: 20,
        boxShadow: '0 0 5px #9E9E9E',
        height: 120,
        marginRight: 20,
        marginBottom: marginBottom
    }
    return (
        <div style={divStyle}>
            <Grid container alignItems="center" justifyContent="center" style={{ paddingInline: 16, marginTop: 12 }}>
                {bigIcon}
                <div style={{ marginLeft: 100 }}>
                    <NunitoText value={title} fontSize={20} fontWeight={600} color='#707070' italic />
                    <NunitoText value={content} fontSize={30} fontWeight={600} marginTop={10} />
                </div>
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
                {smallIcon}
                <NunitoText value={caption} fontSize={12} fontWeight={600} color='#707070' />
            </Grid>
        </div>
    )
}