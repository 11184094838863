import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import HeavyTable from '../components/table/HeavyTable'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import RejectModal from '../components/modal/RejectModal'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import {
    makeOptions, ensureObjectOfArraysArePopulated, checkIfAllSelectedRowsMatchStatus, makeSureIsArray,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getCompetitionsPartnerPending } from '../functions/getData'
import { approveCompetition, approveCompetitionPartner, rejectCompetitionPartner } from '../functions/patchData'
import { deleteDomain } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const pendingCompetitionPartnerHeader = [
    { id: 'competition_name', label: 'Competiton Name' },
    { id: 'partner_name', label: 'Partner' },
    { id: 'country_name', label: 'Country' },
    { id: 'organization_name', label: 'Organization' },
    { id: 'registration_open_date', label: 'Registration Date' },
    { id: 'competition_date', label: 'Competition Dates' },
    { id: 'reject_reason', label: 'Has been rejected' }
    // { id: 'modified_by_username', label: 'Languages to View' },
    // { id: 'modified_by_username', label: 'Languages to Translate' },
]
const initialParams = '?'
export default function PendingCompetitionPartners() {
    document.title = 'Pending Partners\' Competition Date'
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(pendingCompetitionPartnerHeader)
    const [pendingCompetitionPartnerTable, setPendingCompetitionPartnerTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "Competiton", state: '', key: 'competition_id', header: 'Competition' },
        { label: "Country", state: '', key: 'country_id', header: 'Countries' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Competition", state: true, key: 'competition_name' },
        { label: "Country", state: true, key: 'country_name' },
        { label: "Partner", state: true, key: 'partner_name' },
        { label: "Organization", state: true, key: 'organization_name' },
        { label: 'Has been rejected', state: true, key: 'reject_reason' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchPendingCompetitionPartner, setSearchPendingCompetitionPartner] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onReject, setOnReject] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelReject = () => cancelAction(setOnReject, setSelected, setSelecting)
    const cancelApprove = () => cancelAction(setOnApprove, setSelected, setSelecting)
    const approveFunction = () => {
        approveCompetitionPartner({ id: selected }).then(d => {
            console.log(d)
            getData(params)
            cancelApprove()
        })
    }
    const rejectFunction = reason => {
        rejectCompetitionPartner({ id: selected, reject_reason: [reason] }).then(d => {
            getData(params)
            cancelReject()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getCompetitionsPartnerPending, params, setPendingCompetitionPartnerTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(pendingCompetitionPartnerHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchPendingCompetitionPartner, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchPendingCompetitionPartner, params, setParams, getData)
    }
    const onChangeSearchPendingCompetitionPartner = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchPendingCompetitionPartner, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        getData(params)
        if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
    }, [])
    const onClickMassApprove = () => {
        return Boolean(selected.length) ? setOnApprove(true) : showWarningSwal('Please select at least one pending row to mass approve')
    }
    const onClickMassReject = () => {
        return checkIfAllSelectedRowsMatchStatus(selected, pendingCompetitionPartnerTable.data, 'pending') ? setOnReject(true) :
            showWarningSwal('Please select only pending row(s) only for mass reject')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text={'Pending Partners\' Competition Date'} fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value="Pending Partners' Competition Date" fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchPendingCompetitionPartner} setState={onChangeSearchPendingCompetitionPartner} onBlur />
                    <div style={{ marginRight: 40 }}>
                        {user.role_id === 1 && <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassApprove()} iconType='check' />}
                        {user.role_id === 1 && <ReusableButton text='Mass Reject' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassReject()} iconType='close' />}
                    </div>
                </Grid>
                <NunitoText value='Search only in "Competition Name", "Partner", "Country" and "Organisation" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Loader height={600} /> : pendingCompetitionPartnerTable && makeSureIsArray(pendingCompetitionPartnerTable.data).length ?
                    <HeavyTable headers={header} list={pendingCompetitionPartnerTable} title='pending partners competition date'
                        rowsPerPage={rowsPerPage} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setOnApprove={setOnApprove} setOnReject={setOnReject}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            <Modal open={onApprove} onClose={() => cancelApprove()}>
                {selected.length && selecting.length && <ApproveModal selecting={selecting.map((s, i) => `Competition Name: ${s}, Partner Name: ${makeSureIsArray(pendingCompetitionPartnerTable.data).find(p => p.id === selected[i]).partner_name}`)}
                    approveFunction={approveFunction} cancelApprove={cancelApprove} object={`pending competition partners' date`} />}
            </Modal>
            <Modal open={onReject} onClose={() => cancelReject()}>
                {selected.length && selecting.length && <RejectModal selecting={selecting.map((s, i) => `Competition Name: ${s}, Partner Name: ${makeSureIsArray(pendingCompetitionPartnerTable.data).find(p => p.id === selected[i]).partner_name}`)}
                    rejectFunction={rejectFunction} cancelReject={cancelReject} />}
            </Modal>
        </Box>
    )
}