import React, { useState } from "react"
import { Grid } from '@mui/material'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import TitleBar from "./general/TitleBar"
import AddPageWrapper from "./general/AddPageWrapper"
import DescriptionEditor from "./DescriptionEditor"
export default function Solution({ count, solution, update }) {
    const [expandSolution, setExpandSolution] = useState(true)
    return (
        <Grid container style={{ marginTop: 20 }}>
            <TitleBar title='Task Solution/Working' state={expandSolution} setState={setExpandSolution} />
            {expandSolution && <AddPageWrapper>
                <Grid container style={{ margin: '36px 60px' }}>
                    <DescriptionEditor state={solution} setState={update} height={500}
                        placeholder='Solution' count={count} head={count === undefined ? undefined : 'taskText'}
                        target={count === undefined ? undefined : 'solution'} />
                </Grid>
            </AddPageWrapper>}
            {JSON.stringify(solution)}
        </Grid >
    )
}