import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal, CircularProgress, Button, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import AddOrganizationModal from '../components/modal/AddOrganizationModal'
import EditSchoolModal from '../components/modal/EditSchoolModal'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import HeavyTable from '../components/table/HeavyTable'
import '../css/school.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, ensureObjectOfArraysArePopulated,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getOrganizations } from '../functions/getData'
import { approveOrganization } from '../functions/patchData'
import { deleteOrganization } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
const organizationHeader = [
    { id: 'name', label: 'Organization Name' },
    { id: 'country_name', label: 'Country' },
    { id: 'email', label: 'Email' },
    { id: 'address', label: 'Address' },
    { id: 'phone', label: 'Phone Number' },
    { id: 'partner', label: 'No. of Partners' },
    { id: 'person_in_charge', label: 'Person-in-charge' },
    { id: 'billing_address', label: 'Billing Address' },
    { id: 'shipping_address', label: 'Shipping Address' },
    { id: 'created_by_username', label: 'Created By' },
    { id: 'modified_by_username', label: 'Last Modified By' }
]
const initialParams = '?'
export default function Organization() {
    document.title = 'Organization'
    const [header, setHeader] = useState(organizationHeader)
    const user = useSelector(state => state.user)
    const [organizationTable, setOrganizationTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: 'Country', state: '', key: 'country_id', header: 'countries' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Address", state: true, key: 'address' },
        { label: "Phone Number", state: true, key: 'phone' },
        { label: 'No. of Partners', state: true, key: 'partner' },
        { label: 'Person-in-charge', state: true, key: 'person_in_charge' },
        { label: 'Billing Address', state: true, key: 'billing_address' },
        { label: 'Shipping Address', state: true, key: 'shipping_address' },
        { label: "Created By", state: true, key: 'created_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchOrganization, setSearchOrganization] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddOrganization, setOnAddOrganization] = useState(false)
    const [onEditOrganization, setOnEditOrganization] = useState(false)
    const [onApprove, setOnApprove] = useState(false)

    const [name, setName] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [country, setCountry] = useState('')
    const [personInCharge, setPersonInCharge] = useState('')
    const [billingAddress, setBillingAddress] = useState('')
    const [shippingAddress, setShippingAddress] = useState('')
    const [useSameAsBilling, setUseSameAsBilling] = useState(false)
    const [image, setImage] = useState(null)
    const [hasImage, setHasImage] = useState(false)
    const [loadingImage, setLoadingImage] = useState(false)
    const [forms, setForms] = useState([{
        id: 1, name: '', address: '', email: '', phoneNumber: '', country: '', personInCharge: '',
        billingAddress: '', shippingAddress: '', useSameAsBilling: false, image: null, hasImage: false, loadingImage: false
    }])
    const openNewForm = () => setForms([...forms, {
        id: forms.map(f => f.id).reduce((a, b) => Math.max(a, b), 0) + 1,
        name: '', address: '', email: '', phoneNumber: '', country: '', personInCharge: '',
        billingAddress: '', shippingAddress: '', useSameAsBilling: false, image: null, hasImage: false, loadingImage: false
    }])
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelEdit = () => cancelAction(setOnEditOrganization, setSelected, setSelecting)
    const cancelApprove = () => cancelAction(setOnApprove, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteOrganization({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const approveFunction = () => {
        approveOrganization({ id: selected }).then(d => {
            getData(params)
            cancelApprove()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getOrganizations, params, setOrganizationTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(organizationHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchOrganization, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchOrganization, params, setParams, getData)
    }
    const onChangeSearchOrganization = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchOrganization, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        // getData(params)
        if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
    }, [])
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} />
            </Collapse>
            {Boolean(filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Organizations' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Organizations' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchOrganization} setState={onChangeSearchOrganization} onBlur />
                    {user.role_id === 1 && <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add Organization' fontSize={15} bgColor='#5E75C3' height={46} marginRight={20}
                            onClick={() => setOnAddOrganization(true)} iconType='add' />
                        <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46} marginRight={20}
                            onClick={() => selecting.length > 0 ? setOnDelete(true) :
                                showWarningSwal('Please select a organization to mass delete')} iconType='delete' />
                    </div>}
                </Grid>
                <NunitoText value='Search only in "Name", "Address", and "Phone Number" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {loading ? <Grid container justifyContent='center' alignItems='center' style={{ height: 600 }}>
                    <CircularProgress style={{ color: '#000' }} />
                </Grid> : organizationTable && makeSureIsArray(organizationTable.data).length ?
                    <>
                        Now now, that truck isn't your eating disorder
                        {/* <HeavyTable headers={header} list={organizationTable} title='schools' rowsPerPage={rowsPerPage}
                            setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                            selected={selected} setSelected={setSelected} setOnMerge={setOnMerge} setOnApprove={setOnApprove}
                            setOnEdit={setOnEditSchool} setOnAddUser={setOnAddNewUser} setOnAddParticipant={setOnAddParticipant}
                        /> */}
                    </> : firstLoaded && <Grid container justifyContent='center' alignItems='center' style={{ height: 600 }}>
                        <NunitoText value='No data available' fontSize={25} />
                    </Grid>}
            </Grid>
            <Modal open={onAddOrganization} onClose={() => setOnAddOrganization(false)}>
                <AddOrganizationModal setOnAddOrganization={setOnAddOrganization} afterAddingOrganization={() => getData(params)}
                    forms={forms} setForms={setForms} openNewForm={openNewForm} />
            </Modal>
        </Box>
    )
}