import { postMethod } from './httpMethods'
// School
export const addSchool = data => {
    return new Promise((resolve, reject) => {
        postMethod('school', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Organization
export const addOrganization = data => {
    return new Promise((resolve, reject) => {
        postMethod('organisation', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// User
export const addUser = data => {
    return new Promise((resolve, reject) => {
        postMethod('user', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Participant
export const addParticipant = data => {
    return new Promise((resolve, reject) => {
        postMethod('participant', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Domain and Tag
export const addDomain = data => {
    return new Promise((resolve, reject) => {
        postMethod('tag', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Competition
export const addCompetition = data => {
    return new Promise((resolve, reject) => {
        postMethod('competition', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const addCompetitionPartner = data => {
    return new Promise((resolve, reject) => {
        postMethod('competition/partners', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Task
export const addTask = data => {
    return new Promise((resolve, reject) => {
        postMethod('tasks', data).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Difficulty
export const addDifficulty = data => {
    postMethod('difficulty', data).then(() => window.location.reload())
}
// Collection
export const addCollection = data => {
    postMethod('collection', data)
}