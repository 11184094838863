import React, { useState, useEffect } from 'react'
import { Box, Grid, Collapse, Modal, Button, Typography } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ViewOption from '../components/ViewOption'
import Filter from '../components/Filter'
import AddSchoolModal from '../components/modal/AddSchoolModal'
import AddUserModal from '../components/modal/AddUserModal'
import AddParticipantModal from '../components/modal/AddParticipantModal'
import EditSchoolModal from '../components/modal/EditSchoolModal'
import DeleteModal from '../components/modal/DeleteModal'
import ApproveModal from '../components/modal/ApproveModal'
import RejectModal from '../components/modal/RejectModal'
import HeavyTable from '../components/table/HeavyTable'
import '../css/school.css'
import '../css/global.css'
import { useSelector } from 'react-redux'
import {
    makeSureIsArray, makeOptions, ensureObjectOfArraysArePopulated, checkIfAllSelectedRowsMatchStatus,
    changeViewOptions, changeRowsPerPage, changeFiltered, changeSearch, loadData, controlView, controlFilter, cancelAction
} from '../functions/general'
import { showWarningSwal } from '../functions/alert'
import { getSchools } from '../functions/getData'
import { approveSchool, rejectSchool, restoreSchool } from '../functions/patchData'
import { deleteSchool } from '../functions/deleteData'
import NunitoText from '../components/general/NunitoText'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableButton from '../components/general/ReusableButton'
import ViewAndFilterButton from '../components/ViewAndFilterButton'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const schoolHeader = [
    { id: 'name', label: 'School/Tuition' },
    { id: 'country_name', label: 'Country' },
    { id: 'teachers', label: 'Teachers' },
    { id: 'private', label: 'Tuition Centre' },
    { id: 'address', label: 'Address' },
    { id: 'postal', label: 'Postal Code' },
    { id: 'province', label: 'Province' },
    { id: 'phone', label: 'Phone Number' },
    { id: 'status', label: 'Status' },
    { id: 'created_by_username', label: 'Submit By' },
    { id: 'approved_by_username', label: 'Approved By' },
    { id: 'rejected_by_username', label: 'Rejected By' },
    { id: 'modified_by_username', label: 'Last Modified By' },
    { id: 'reject_reason', label: 'Has been rejected' }
]
const initialParams = '?'
export default function School() {
    document.title = 'School'
    const user = useSelector(state => state.user)
    const [header, setHeader] = useState(schoolHeader)
    const [schoolTable, setSchoolTable] = useState()
    const [filtered, setFiltered] = useState([
        { label: "School/Tuition", state: '', key: 'private', header: 'schooltype' },
        { label: 'Country', state: '', key: 'country_id', header: 'countries' },
        { label: 'Status', state: '', key: 'status', header: 'status' }
    ])
    const [filterOptions, setFilterOptions] = useState()
    const [viewOptions, setViewOptions] = useState([
        { label: "Tuition Centre", state: true, key: 'private' },
        { label: "Address", state: true, key: 'address' },
        { label: "Postal Code", state: true, key: 'postal' },
        { label: "Province", state: true, key: 'province' },
        { label: "Phone Number", state: true, key: 'phone' },
        { label: "Status", state: true, key: 'status' },
        { label: "Submit By", state: true, key: 'created_by_username' },
        { label: 'Approve By', state: true, key: 'approved_by_username' },
        { label: 'Rejected By', state: true, key: 'rejected_by_username' },
        { label: 'Last Modified By', state: true, key: 'modified_by_username' },
        { label: 'Has been rejected', state: true, key: 'reject_reason' }
    ])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [searchSchool, setSearchSchool] = useState('')
    const [params, setParams] = useState(initialParams)
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const [viewing, setViewing] = useState(false)
    const [filtering, setFiltering] = useState(false)
    const [selected, setSelected] = useState([])
    const [selecting, setSelecting] = useState([])
    const [onDelete, setOnDelete] = useState(false)
    const [onAddSchool, setOnAddSchool] = useState(false)
    const [onEditSchool, setOnEditSchool] = useState(false)
    const [onApprove, setOnApprove] = useState(false)
    const [onReject, setOnReject] = useState(false)
    const [onRestore, setOnRestore] = useState(false)
    const [onAddNewUser, setOnAddNewUser] = useState(false)
    const [onAddParticipant, setOnAddParticipant] = useState(false)
    const [onMerge, setOnMerge] = useState(false)
    const handleView = () => controlView(viewing, setViewing, setFiltering)
    const handleFilter = () => controlFilter(filtering, setFiltering, setViewing)
    const cancelDelete = () => cancelAction(setOnDelete, setSelected, setSelecting)
    const cancelEdit = () => cancelAction(setOnEditSchool, setSelected, setSelecting)
    const cancelApprove = () => cancelAction(setOnApprove, setSelected, setSelecting)
    const cancelReject = () => cancelAction(setOnReject, setSelected, setSelecting)
    const cancelRestore = () => cancelAction(setOnRestore, setSelected, setSelecting)
    const cancelAddUser = () => cancelAction(setOnAddNewUser, setSelected, setSelecting)
    const cancelAddParticipant = () => cancelAction(setOnAddParticipant, setSelected, setSelecting)
    const deleteFunction = () => {
        deleteSchool({ id: selected }).then(d => {
            getData(params)
            cancelDelete()
        })
    }
    const approveFunction = () => {
        approveSchool({ id: selected }).then(d => {
            console.log(d)
            getData(params)
            cancelApprove()
        })
    }
    const rejectFunction = reason => {
        rejectSchool({ id: selected, reject_reason: [reason] }).then(d => {
            getData(params)
            cancelReject()
        })
    }
    const restoreFunction = () => {
        restoreSchool({ id: selected }).then(d => {
            getData(params)
            cancelRestore()
        })
    }
    const getData = params => loadData(setLoading, setFirstLoaded, getSchools, params, setSchoolTable, setFilterOptions)
    const onChangeViewOptions = value => changeViewOptions(schoolHeader, setViewOptions, setHeader, value)
    const onChangeRowsPerPage = value => {
        changeRowsPerPage(initialParams, filtered, searchSchool, setRowsPerPage, params, setParams, getData, value)
    }
    const onChangeFiltered = value => {
        setFiltered(value)
        changeFiltered(initialParams, filtered, rowsPerPage, setSearchSchool, params, setParams, getData)
    }
    const onChangeSearchSchool = value => {
        changeSearch(initialParams, filtered, rowsPerPage, setSearchSchool, params, setParams, getData, value)
    }
    const onChangePage = pageNo => getData(`${params}&page=${pageNo}`)
    useEffect(() => {
        getData(params)
        if (user.role_id > 1) setFiltered(filtered.filter(f => f.key !== 'country_id'))
    }, [])
    const onClickMassApprove = () => {
        return schoolTable && checkIfAllSelectedRowsMatchStatus(selected, schoolTable.data, 'pending', user) ?
            setOnApprove(true) : showWarningSwal('Please select pending schools only to mass approve')
    }
    const onClickMassDelete = () => {
        return Boolean(selected.length) ? setOnDelete(true) : showWarningSwal('Please select at least one school to mass delete')
    }
    return (
        <Box style={{ flexGrow: 1 }}>
            <Collapse in={viewing}>
                <ViewOption viewOptions={viewOptions} rowsPerPage={rowsPerPage}
                    onChangeRowsPerPage={onChangeRowsPerPage} onChangeViewOptions={onChangeViewOptions} viewing={viewing} />
            </Collapse>
            {Boolean(filterOptions) && <Collapse in={filtering}>
                <Filter type='collapse' filterOptions={filtered.map(f => makeOptions(filterOptions, f.header))}
                    filtered={filtered} onChangeFiltered={onChangeFiltered} />
            </Collapse>}
            <Grid container style={{ padding: 12 }} justifyContent="space-between">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                    <ChevronRightIcon />
                    <ReusableButton text='Schools' fontSize={14} bgColor='#F16774' height={36} br={18} />
                </div>
                <div style={{ marginRight: 80 }}>
                    <ViewAndFilterButton text='View Options' state={viewing} fontSize={14} height={42}
                        onClick={handleView} marginRight={10} />
                    <ViewAndFilterButton text='Filter' state={filtering} fontSize={14} height={42}
                        onClick={Boolean(filtered && filterOptions && ensureObjectOfArraysArePopulated(filterOptions)) && handleFilter} />
                </div>
            </Grid>
            <Grid container style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Schools' fontSize={40} fontWeight={700} italic color='#144A94' />
                <Grid container alignItems="center" justifyContent="space-between" style={{ marginTop: 24 }}>
                    <ReusableTextField type='search' width={500} height={60} bgColor='#F2F2F2' placeholder='Search using keyword'
                        state={searchSchool} setState={onChangeSearchSchool} onBlur />
                    {user.role_id < 3 && <div style={{ marginRight: 40 }}>
                        <ReusableButton text='Add School' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => setOnAddSchool(true)} iconType='add' />
                        {user.role_id === 1 && <ReusableButton text='Mass Delete' fontSize={15} bgColor='#E83042' height={46}
                            marginRight={20} onClick={() => onClickMassDelete()} iconType='delete' />}
                        {user.role_id === 1 && <ReusableButton text='Mass Approve' fontSize={15} bgColor='#5E75C3' height={46}
                            marginRight={20} onClick={() => onClickMassApprove()} iconType='check' />}
                    </div>}
                </Grid>
                <NunitoText value='Search only in "School/Tuition", "Address", "Postal Code", "Province" and "Phone Number" column'
                    fontSize={20} fontWeight={400} italic color='#144A94' />
                {/* {schoolTableData && JSON.stringify(makeSureIsArray(schoolTableData))} */}
                {loading ? <Loader height={600} /> : schoolTable && makeSureIsArray(schoolTable.data).length ?
                    <HeavyTable headers={header} list={schoolTable} title='schools' rowsPerPage={rowsPerPage}
                        setOnDelete={setOnDelete} onChangePage={onChangePage} selecting={selecting} setSelecting={setSelecting}
                        selected={selected} setSelected={setSelected} setOnMerge={setOnMerge} setOnApprove={setOnApprove}
                        setOnReject={setOnReject} setOnRestore={setOnRestore} setOnEdit={setOnEditSchool}
                        setOnAddUser={setOnAddNewUser} setOnAddParticipant={setOnAddParticipant}
                    /> : firstLoaded && <NoData height={600} />}
            </Grid>
            {/* {schoolTable && JSON.stringify(makeSureIsArray(schoolTable.data).map(s => s.name))} */}
            {/* {schoolTableData && makeSureIsArray(schoolTableData).map(s => (
                <p>{JSON.stringify(s.created_by_userid === user.user_id)}</p>
            ))} */}
            {/* {schoolTableData && makeSureIsArray(schoolTableData).map((s, i) => (
                <p key={i}>{JSON.stringify(s.teachers)}</p>
            ))} */}
            <Modal open={onDelete} onClose={() => cancelDelete()}>
                <DeleteModal selecting={selecting} deleteFunction={deleteFunction} cancelDelete={cancelDelete} />
            </Modal>
            <Modal open={onApprove} onClose={() => cancelApprove()}>
                <ApproveModal selecting={selecting} approveFunction={approveFunction} cancelApprove={cancelApprove}
                    object='schools' />
            </Modal>
            <Modal open={onReject} onClose={() => cancelReject()}>
                <RejectModal selecting={selecting} rejectFunction={rejectFunction} cancelReject={cancelReject} />
            </Modal>
            <Modal open={onRestore} onClose={() => cancelRestore()}>
                <ApproveModal selecting={selecting} approveFunction={restoreFunction} cancelApprove={cancelRestore}
                    object='schools' restore />
            </Modal>
            <Modal open={onAddSchool} onClose={() => setOnAddSchool(false)}>
                <AddSchoolModal setOnAddSchool={setOnAddSchool} afterAddingSchool={() => getData(params)} />
            </Modal>
            <Modal open={onEditSchool} onClose={() => cancelEdit()}>
                {schoolTable && selected.length && <EditSchoolModal afterEditingSchool={() => getData(params)} id={selected[0]}
                    schools={makeSureIsArray(schoolTable.data)} cancelEdit={cancelEdit} />}
            </Modal>
            <Modal open={onAddNewUser} onClose={() => cancelAddUser()}>
                {schoolTable && <AddUserModal setOnAddNewUser={setOnAddNewUser} afterAddingUser={() => getData(params)}
                    selectedSchool={makeSureIsArray(schoolTable.data).find(s => s.id === selected[0])} cancelAddUser={cancelAddUser} />}
            </Modal>
            <Modal open={onAddParticipant} onClose={() => cancelAddParticipant()}>
                {schoolTable && <AddParticipantModal setOnAddParticipant={setOnAddParticipant}
                    afterAddingParticipant={() => getData(params)}
                    selectedSchool={makeSureIsArray(schoolTable.data).find(s => s.id === selected[0])}
                    cancelAddParticipant={cancelAddParticipant} />}
            </Modal>
            <Modal open={onMerge} onClose={() => setOnMerge(false)}>
                <Box className='popUpModal' style={{ width: 1200, height: 860 }}>
                    <Typography variant="h5" sx={{ color: '#5E75C3', mt: 2 }}>
                        MERGE SCHOOL
                    </Typography>
                    <Typography variant="h5" sx={{ color: '#5E75C3', mt: 2 }}>
                        MERGE <span style={{ color: '#F16774' }}>TUITION CENTRE</span> WITH:
                    </Typography>
                    <div id='modalBtnWrapper'>
                        <Button className='modalAddBtn' style={{ backgroundColor: '#5E75C3' }} onClick={() => setOnMerge(false)}>
                            Submit
                        </Button>
                        <Button className='modalCancelBtn' onClick={() => setOnMerge(false)}>
                            Cancel
                        </Button>
                    </div>
                </Box>
            </Modal>
        </Box>
    )
}