import React from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function DeleteModal({ selecting, deleteFunction, cancelDelete }) {
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value='CONFIRM DELETE?' fontSize={30} fontWeight={600} color='#F16774' marginTop={30} />
            <NunitoText value='Are you sure you want to delete' fontSize={20} fontWeight={600} />
            {selecting.map((name, i) => (
                <NunitoText key={i} value={`${name}${i < selecting.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
            ))}
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelDelete()} />
                <ReusableButton text='Delete' bgColor='#E83042' fontSize={25} height={63} width={280}
                    onClick={() => deleteFunction()} />
            </Grid>
        </Box>
    )
}