import React, { useState } from 'react'
import {
    MenuItem, Collapse, List, Grid, TextField, Popper, Chip,
    InputAdornment, Checkbox, IconButton, Typography, Fade, Paper
} from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SearchIcon from '@mui/icons-material/Search'
export default function CustomSelectMultiple({ placeholder, data, select, setSelect, width, outerKey, innerKey }) {
    const [searchText, setSearchText] = useState('')
    const [anchorEl, setAnchorEl] = useState(null)
    const [open, setOpen] = useState(false)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };
    const [nestedOpen, setNestedOpen] = useState(data.map(() => false))
    const changeNestedOpen = (index, value) => {
        let newNestedOpen = [...nestedOpen]
        newNestedOpen[index] = value
        setNestedOpen(newNestedOpen)
    }
    const handleSelect = (outerValue, innerValue, selectOuter, totalInnersInOuter) => {
        let newSelect = [...select]
        //If selecting by role(which is using the outerKey)
        if (selectOuter) {
            //If not all users(using innerKey) in role(outerKey) is selected, select the role and all user in the role
            if (!newSelect.find(s => s[outerKey] === outerValue) || newSelect.find(s => s[outerKey] === outerValue)[innerKey].length < totalInnersInOuter) {
                //Push a new object if no user in the role has been selected
                if (!newSelect.find(s => s[outerKey] === outerValue)) newSelect.push({ [outerKey]: outerValue, [innerKey]: [] })
                for (const value of innerValue) {
                    //Only push user into the array if it is not selected
                    if (!newSelect.find(s => s[outerKey] === outerValue)[innerKey].includes(value)) {
                        newSelect.find(s => s[outerKey] === outerValue)[innerKey].push(value)
                    }
                }
            }
            //Remove the entire object with the role(outerKey)
            else newSelect = newSelect.filter(s => s[outerKey] !== outerValue)
        } else {
            if (newSelect.map(s => s[outerKey]).includes(outerValue)) {
                let innerObject = newSelect.find(s => s[outerKey] === outerValue)[innerKey]
                //If selecting a user from a role that already has user selected, push the selected user into the users array into the
                //pre-existing object, else remove the user from the users array
                if (innerObject.includes(innerValue)) {
                    newSelect.find(s => s[outerKey] === outerValue)[innerKey] = innerObject.filter(i => i !== innerValue)
                    if (!newSelect.find(s => s[outerKey] === outerValue)[innerKey].length) {
                        newSelect = newSelect.filter(s => JSON.stringify(s) !== JSON.stringify({ [outerKey]: outerValue, [innerKey]: [] }))
                    }
                }
                else newSelect.find(s => s[outerKey] === outerValue)[innerKey].push(innerValue)
            } else newSelect.push({ [outerKey]: outerValue, [innerKey]: [innerValue] })
        }
        setSelect(newSelect)
    }

    const checkIndeterminate = (selectedUsers, totalUsers) => {
        if (selectedUsers) return selectedUsers[innerKey].length > 0 && selectedUsers[innerKey].length < totalUsers
    }

    return (
        <div>
            <TextField
                variant='filled'
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                onClick={open ? null : handleClick}
                placeholder={placeholder}
                InputProps={{
                    disableUnderline: true,
                    hiddenLabel: true,
                    startAdornment:
                        (<InputAdornment position="start" style={{ marginBottom: 18 }}>
                            <SearchIcon style={{ fontSize: 28, color: '#707070' }} />
                        </InputAdornment>),
                    endAdornment:
                        (<InputAdornment position="end">
                            <IconButton onClick={handleClick}
                                style={{
                                    backgroundColor: '#5E75C3', border:'1px solid #707070', borderRadius: '0 12px 12px 0', 
                                    position: 'relative', left: 10, height: 58, width: 54, color: '#FFF'
                                }}>
                                {open ? <ExpandLess style={{ fontSize: 32 }} /> : <ExpandMore style={{ fontSize: 32 }} />}
                            </IconButton>
                        </InputAdornment>),
                    style: { height: 60, width: width, borderRadius: 16 }
                }}
                inputProps={{ style: { marginLeft: 20 } }} />
            <Popper open={open} anchorEl={anchorEl} transition placement='bottom-end' disablePortal
                style={{ width: width, maxHeight: 320, overflowY: 'scroll', zIndex: 999 }}>
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Paper style={{ display: !open && 'none' }}>
                            {!searchText ? data.map((options, index) => (
                                <>
                                    <Grid key={index} container className='optionStyle' justifyContent='space-between'
                                        onClick={() => handleSelect(options[outerKey], options[innerKey], true, options[innerKey].length)}>
                                        <div style={{ display: 'flex' }}>
                                            <Checkbox
                                                indeterminate={checkIndeterminate(select.find(s => s[outerKey] === options[outerKey]), options[innerKey].length)}
                                                checked={select.map(s => s[outerKey]).includes(options[outerKey])}
                                                style={{ color: "#000" }} />
                                            <Typography key={index} style={{ color: '#144A94', padding: 16 }}>{options[outerKey]}</Typography>
                                        </div>
                                        <IconButton onClick={(e) => { e.stopPropagation(); changeNestedOpen(index, !nestedOpen[index]) }}
                                            style={{ marginRight: 10 }}>
                                            {nestedOpen[index] ? <ExpandLess style={{ fontSize: 30 }} /> : <ExpandMore style={{ fontSize: 30 }} />}
                                        </IconButton>
                                    </Grid>
                                    <Collapse in={nestedOpen[index]} timeout="auto" unmountOnExit>
                                        <List component="div" disablePadding>
                                            <Grid container direction="column">
                                                {options[innerKey].map((child, i) => (
                                                    <MenuItem key={i} alignItems='center' style={{ marginLeft: 40, cursor: 'pointer' }}
                                                        onClick={e => {
                                                            e.stopPropagation()
                                                            handleSelect(options.role, child)
                                                        }}>
                                                        <Checkbox checked={select.map(s => s[innerKey]).flat(1).includes(child)}
                                                            style={{ color: "#000" }} />
                                                        <Typography style={{ color: '#144A94' }}>{child}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Grid>
                                        </List>
                                    </Collapse>
                                </>
                            )) :
                                <>
                                    {data.map((options, index) => (
                                        options[outerKey].toLowerCase().includes(searchText.toLowerCase()) && <>
                                            <Grid key={index} container className='optionStyle' justifyContent='space-between'
                                                onClick={() => handleSelect(options[outerKey], options[innerKey], true, options[innerKey].length)}>
                                                <div style={{ display: 'flex' }}>
                                                    <Checkbox
                                                        indeterminate={checkIndeterminate(select.find(s => s[outerKey] === options[outerKey]), options[innerKey].length)}
                                                        checked={select.map(s => s[outerKey]).includes(options[outerKey])}
                                                        style={{ color: "#000" }} />
                                                    <Typography key={index} style={{ color: '#144A94', padding: 16 }}>{options[outerKey]}</Typography>
                                                </div>
                                                <IconButton onClick={(e) => { e.stopPropagation(); changeNestedOpen(index, !nestedOpen[index]) }}
                                                    style={{ marginRight: 10 }}>
                                                    {nestedOpen[index] ? <ExpandLess style={{ fontSize: 30 }} /> : <ExpandMore style={{ fontSize: 30 }} />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={nestedOpen[index]} timeout="auto" unmountOnExit>
                                                {!options[innerKey].join(' ').toLowerCase().includes(searchText.toLowerCase()) ? <List component="div" disablePadding>
                                                    <Grid container direction="column">
                                                        {options[innerKey].map((child, i) => (
                                                            <MenuItem key={i} alignItems='center' style={{ marginLeft: 40, cursor: 'pointer' }}
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    handleSelect(options.role, child)
                                                                }}>
                                                                <Checkbox checked={select.map(s => s[innerKey]).flat(1).includes(child)}
                                                                    style={{ color: "#000" }} />
                                                                <Typography style={{ color: '#144A94' }}>{child}</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Grid>
                                                </List> : <List component="div" disablePadding>
                                                    <Grid container direction="column">
                                                        {options[innerKey].map((child, i) => (
                                                            child.toLowerCase().includes(searchText.toLowerCase()) && <MenuItem key={i} alignItems='center' style={{ marginLeft: 40, cursor: 'pointer' }}
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    handleSelect(options.role, child)
                                                                }}>
                                                                <Checkbox checked={select.map(s => s[innerKey]).flat(1).includes(child)}
                                                                    style={{ color: "#000" }} />
                                                                <Typography style={{ color: '#144A94' }}>{child}</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Grid>
                                                </List>}
                                            </Collapse>
                                        </>
                                    ))}
                                    {data.map((options, index) => (
                                        options[innerKey].join(' ').toLowerCase().includes(searchText.replace(/ /g, '').trim().toLowerCase()) && <>
                                            <Grid key={index} container className='optionStyle' justifyContent='space-between'
                                                onClick={() => handleSelect(options[outerKey], options[innerKey], true, options[innerKey].length)}
                                                style={{ backgroundColor: '#F0F8FF' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <Checkbox
                                                        indeterminate={checkIndeterminate(select.find(s => s[outerKey] === options[outerKey]), options[innerKey].length)}
                                                        checked={select.map(s => s[outerKey]).includes(options[outerKey])}
                                                        style={{ color: "#000" }} />
                                                    <Typography key={index} style={{ color: '#144A94', padding: 16 }}>{options[outerKey]}</Typography>
                                                </div>
                                                <IconButton onClick={(e) => { e.stopPropagation(); changeNestedOpen(index, !nestedOpen[index]) }}
                                                    style={{ marginRight: 10 }}>
                                                    {nestedOpen[index] ? <ExpandLess style={{ fontSize: 30 }} /> : <ExpandMore style={{ fontSize: 30 }} />}
                                                </IconButton>
                                            </Grid>
                                            <Collapse in={nestedOpen[index]} timeout="auto" unmountOnExit>
                                                <List component="div" disablePadding>
                                                    <Grid container direction="column">
                                                        {options[innerKey].map((child, i) => (
                                                            child.toLowerCase().includes(searchText.toLowerCase()) &&
                                                            <MenuItem key={i} alignItems='center' style={{ marginLeft: 40, cursor: 'pointer' }}
                                                                onClick={e => {
                                                                    e.stopPropagation()
                                                                    handleSelect(options.role, child)
                                                                }}>
                                                                <Checkbox checked={select.map(s => s[innerKey]).flat(1).includes(child)}
                                                                    style={{ color: "#000" }} />
                                                                <Typography style={{ color: '#144A94' }}>{child}</Typography>
                                                            </MenuItem>
                                                        ))}
                                                    </Grid>
                                                </List>
                                            </Collapse>
                                        </>
                                    ))}
                                </>}
                        </Paper>
                    </Fade>
                )}
            </Popper>
            <Grid container style={{ paddingInline: 50, paddingTop: 20 }}>
                {select.map((s, index) => (
                    s[innerKey].map((innerValue, i) => (
                        s[innerKey].length === data.find(d => d[outerKey] === s[outerKey])[innerKey].length ?
                            !i && <Chip key={i} label={s[outerKey]} sx={{ backgroundColor: '#f16774', color: '#FFF', ml: index && 1, mb: 2 }} /> :
                            <Chip key={i} label={innerValue} sx={{ backgroundColor: '#f16774', color: '#FFF', ml: 1, mb: 2 }} />
                    ))
                ))}
            </Grid>
        </div>
    )
}