import React from 'react'
import { Box, Grid } from '@mui/material'
import NunitoText from '../general/NunitoText'
import ReusableButton from '../general/ReusableButton'
export default function ApproveModal({ selecting, approveFunction, cancelApprove, object, restore }) {
    return (
        <Box className='popUpModal' style={{ width: 705, minHeight: 365 }}>
            <NunitoText value={`CONFIRM ${restore ? 'RESTORE' : 'APPROVE'}`} fontSize={30} fontWeight={600} color='#5E75C3' marginTop={30} />
            <NunitoText value={`Are you sure you want to ${restore ? 'restore' : 'approve'} these ${object}`} fontSize={20} fontWeight={600} />
            {selecting.map((name, i) => (
                <NunitoText key={i} value={`${name}${i < selecting.length - 1 && ', '}`} fontSize={20} fontWeight={700} />
            ))}
            <Grid container justifyContent="space-evenly" style={{ marginBlock: "30px 20px" }}>
                <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={25} height={63} width={280}
                    onClick={() => cancelApprove()} />
                <ReusableButton text='Approve' bgColor='#5E75C3' fontSize={25} height={63} width={280}
                    onClick={() => approveFunction()} />
            </Grid>
        </Box>
    )
}