import React, { useState, useEffect } from 'react'
import { Grid, Box, IconButton, Divider, ButtonGroup, Button, Checkbox } from '@mui/material'
import { useLocation } from 'react-router-dom'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ClearIcon from '@mui/icons-material/Clear'
import AddCircleSharpIcon from '@mui/icons-material/AddCircleSharp'
import RemoveCircleSharpIcon from '@mui/icons-material/RemoveCircleSharp'
import ArrowUpwardSharpIcon from '@mui/icons-material/ArrowUpwardSharp'
import ArrowDownwardSharpIcon from '@mui/icons-material/ArrowDownwardSharp'
import { useDispatch, useSelector } from 'react-redux'
import { getCompetitions, getUsers, getTasks, getDomains } from '../functions/getData'
import { patchTaskSettings, patchTaskRecommendations, patchTaskContent, patchTaskAnswers } from '../functions/patchData'
import { formatDateTime, loadData, invalidName } from '../functions/general'
import { isEmpty, showWarningSwal } from '../functions/alert'
import { getBase64 } from '../functions/upload'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
import ReusableTextField from '../components/general/ReusableTextField'
import ReusableDatePicker from '../components/general/ReusableDatePicker'
import ReusableToggle from '../components/general/ReusableToggle'
import ReusableAutocompleteWithID from '../components/general/ReusableAutocompleteWithID'
import ReusableCheckbox from '../components/general/ReusableCheckbox'
import TitleBar from '../components/general/TitleBar'
import Solution from '../components/Solution'
import DescriptionEditor from '../components/DescriptionEditor'
import AddPageWrapper from '../components/general/AddPageWrapper'
import Recommendations from '../components/Recommendations'
import Loader from '../components/general/Loader'
import NoData from '../components/general/NoData'
const titleBarStyle = { backgroundColor: '#144A94', borderRadius: 10, paddingBlock: 4, height: 60 }
const taskContainerStyle = { marginInline: 24, backgroundColor: '#F3F0F4', borderRadius: '0 0 12px 12px' }
const typeOptions = [{ id: 'mcq', name: 'MCQ' }, { id: 'input', name: 'Input' }]
const structureOptions = [
    { id: 'default', name: 'Default' }, { id: 'group', name: 'Group' },
    { id: 'sequence', name: 'Sequence' }, { id: 'open', name: 'Open' }
]
const layoutOptions = [{ id: 'vertical', name: 'Vertical' }, { id: 'horizontal', name: 'Horizontal' }]
const sortingOptions = [{ id: 'fix', name: 'Fix Order' }, { id: 'random', name: 'Random' }]
const Preview = ({ count, width, image, onChangeAnswers }) => {
    const previewImage = () => {
        var newTab = window.open();
        newTab.document.title = 'Image Preview'
        newTab.document.body.innerHTML = `<div style="text-align:center;">
            <h1>Image preview</h1>
            <img src=${image} alt="Preview of image" width=600 />
        </div>`
    }
    return (
        <Grid container alignItems="center" style={{ width }}>
            <ReusableButton text='Preview Image' height={44} bgColor='#144A94' fontSize={15}
                onClick={() => previewImage()} iconType='preview' />
            <ReusableButton text='Clear' height={40} bgColor='#E83D4D' fontSize={15} marginLeft={10}
                onClick={() => onChangeAnswers(count, 'image', '')} iconType='delete' />
        </Grid>
    )
}
const Arrows = ({ count, onChangeAnswers, answers }) => {
    if (answers.length > 1) {
        if (!count) {
            //First answer with only downward arrow
            return (
                <IconButton edge="start" onClick={() => onChangeAnswers(count, 'moveDown')} style={{ marginLeft: 18 }}>
                    <ArrowDownwardSharpIcon style={{ color: "#000" }} />
                </IconButton>
            )
        } else if (count === answers.length - 1) {
            //Last answer with only upward arrow
            return (
                <IconButton edge="start" onClick={() => onChangeAnswers(count, 'moveUp')} style={{ marginRight: 28 }}>
                    <ArrowUpwardSharpIcon style={{ color: "#000" }} />
                </IconButton>
            )
        } else {
            //Any answers in between would have both upward and downward arrows
            return (
                <div>
                    <IconButton edge="start" onClick={() => onChangeAnswers(count, 'moveUp')}>
                        <ArrowUpwardSharpIcon style={{ color: "#000" }} />
                    </IconButton>
                    <IconButton edge="start" onClick={() => onChangeAnswers(count, 'moveDown')}>
                        <ArrowDownwardSharpIcon style={{ color: "#000" }} />
                    </IconButton>
                </div>
            )
        }
    }
    //Don't show any arrow if there is only 1 answer
    return null
}
const Answer = ({ count, answer, answerSettings, onChangeAnswerSettings, uploadImage, setUploadImage, answers, onChangeAnswers }) => {
    const handleFileInputChange = (count, file) => {
        getBase64(file)
            .then(result => {
                console.log(result)
                onChangeAnswers(count, 'image', result)
            })
            .catch(err => {
                console.log(err);
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            });
    }
    const openFile = () => document.getElementById(`file-upload-${count}`).click()
    const arrowWrapperStyle = {
        display: 'flex', justifyContent: 'center', alignItems: 'center'
    }
    const TaskLabelOrImageTextField = () => <ReusableTextField type='default'
        placeholder={uploadImage ? "Click to upload image" : "Enter Task Label"}
        state={uploadImage ? answer.image : answer.label} setState={onChangeAnswers} onClick={uploadImage && openFile}
        count={count} target={uploadImage ? 'image' : 'label'} readOnly={uploadImage} onBlur={!uploadImage} />
    const HiddenInput = () => <input id={`file-upload-${count}`} type="file" style={{ display: 'none' }}
        onChange={e => handleFileInputChange(count, e.target.files[0])} />
    const AddAndRemoveAnswerButtons = () => (
        <>
            <IconButton color="inherit" onClick={() => onChangeAnswers(count, 'add')}>
                <AddCircleSharpIcon />
            </IconButton>
            {Boolean(count) && <IconButton color="inherit" onClick={() => onChangeAnswers(count, 'remove')}>
                <RemoveCircleSharpIcon />
            </IconButton>}
        </>
    )
    const { type, structure } = answerSettings
    const titleStyle = { display: 'flex', justifyContent: 'center' }
    const taskLabelContainerStyle = { display: 'flex', flexDirection: 'column', paddingInline: 30 }
    const IdAndCheckBoxContainerStyle = { textAlign: 'center' }
    if (['mcq', 'MCQ'].includes(type)) {
        if (['sequence', 'Sequence'].includes(structure)) {
            return (
                <>
                    {count === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Sequence' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={7.5} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={IdAndCheckBoxContainerStyle}>
                            <NunitoText value={answer.id} fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <NunitoText value={(count + 1).toString()} fontSize={25} marginRight={20} />
                            <Arrows count={count} onChangeAnswers={onChangeAnswers} answers={answers} />
                        </Grid>
                        <Grid item xs={4.5 + ((answer.image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && uploadImage && <Grid item xs={3}>
                            <Preview count={count} image={answer.image} onChangeAnswers={onChangeAnswers} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    {count === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={5.5} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Correct Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={IdAndCheckBoxContainerStyle}>
                            <NunitoText value={answer.id} fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} onChangeAnswers={onChangeAnswers} answers={answers} />
                        </Grid>
                        <Grid item xs={2.5 + ((answer.image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && uploadImage && <Grid item xs={3}>
                            <Preview count={count} image={answer.image} onChangeAnswers={onChangeAnswers} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                        <Grid item xs={2} style={IdAndCheckBoxContainerStyle}>
                            <Checkbox style={{ color: '#000' }} checked={answer.correct}
                                onChange={() => onChangeAnswers(count, 'correct', !answer.correct)} />
                        </Grid>
                    </Grid>
                </>
            )
        }
    } else {
        if (['open', 'Open'].includes(structure)) {
            return (
                <>
                    {count === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={7.5} style={titleStyle}>
                            <NunitoText value='Task Label' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center">
                        <Grid item xs={2} style={IdAndCheckBoxContainerStyle}>
                            <NunitoText value={answer.id} fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} onChangeAnswers={onChangeAnswers} answers={answers} />
                        </Grid>
                        <Grid item xs={4.5 + ((answer.image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && uploadImage && <Grid item xs={3}>
                            <Preview count={count} image={answer.image} onChangeAnswers={onChangeAnswers} />
                        </Grid>}
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        } else {
            return (
                <>
                    {count === 0 && <Grid container alignItems="center">
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer ID' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={1.5} style={titleStyle}>
                            <NunitoText value='Position' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={5.5} style={titleStyle}>
                            <NunitoText value='Task Label' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                        <Grid item xs={2} style={titleStyle}>
                            <NunitoText value='Answer' fontSize={26} fontWeight={700} marginTop={20}
                                borderBottom='1px solid' width={200} paddingBottom={5} />
                        </Grid>
                    </Grid>}
                    <Grid container style={{ marginTop: 10 }} alignItems="center" justifyContent='space-between'>
                        <Grid item xs={2} style={IdAndCheckBoxContainerStyle}>
                            <NunitoText value={answer.id} fontSize={24} />
                        </Grid>
                        <Grid item xs={1.5} style={arrowWrapperStyle}>
                            <Arrows count={count} onChangeAnswers={onChangeAnswers} answers={answers} />
                        </Grid>
                        <Grid item xs={2.5 + ((answer.image === '' || !uploadImage) && 3)} style={taskLabelContainerStyle}>
                            <TaskLabelOrImageTextField />
                            <HiddenInput />
                        </Grid>
                        {answer.image !== '' && uploadImage && <Grid item xs={3}>
                            <Preview count={count} image={answer.image} onChangeAnswers={onChangeAnswers} />
                        </Grid>}
                        <Grid item xs={2} style={{ display: 'flex', flexDirection: 'column' }}>
                            <ReusableTextField type='default' placeholder="Enter answer" state={answer.answer}
                                setState={onChangeAnswers} count={count} target='answer' onBlur />
                        </Grid>
                        <Grid item xs={1}>
                            <AddAndRemoveAnswerButtons />
                        </Grid>
                    </Grid>
                </>
            )
        }
    }
}
const AnswerKey = ({ answerSettings, onChangeAnswerSettings, uploadImage, setUploadImage, answers, onChangeAnswers }) => {
    return (
        <Grid container style={{ marginTop: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value='Answer Key' fontSize={25} fontWeight={700} italic color='#FFF' marginLeft={50} />
            </Grid>
            <AddPageWrapper>
                <Grid container justifyContent='center' alignItems='center' style={{ marginTop: 30 }}>
                    <NunitoText value='Upload Answers as Image' fontSize={22} fontWeight={600} marginRight={10} />
                    <ReusableToggle type='icon' width={124} state={uploadImage}
                        setState={() => setUploadImage(!uploadImage)} marginRight={20} />
                </Grid>
                <Grid container style={{ margin: 30, marginTop: 10 }}>
                    {answers.map((answer, index) => (
                        <Answer key={index} count={index} answer={answer} answerSettings={answerSettings}
                            onChangeAnswerSettings={onChangeAnswerSettings} uploadImage={uploadImage} setUploadImage={setUploadImage}
                            answers={answers} onChangeAnswers={onChangeAnswers} />
                    ))}
                </Grid>
            </AddPageWrapper>
        </Grid >
    )
}
const AnswerSetting = ({ answerSettings, onChangeAnswerSettings, uploadImage, setUploadImage, answers, onChangeAnswers }) => {
    const { type, structure, layout, sorting } = answerSettings
    const buttonGroupWrapperStyle = {
        border: '1px solid', borderRadius: 5,
        backgroundColor: '#F3F0F4', width: 240, marginRight: 60
    }
    const buttonGroupStyle = { paddingInline: 8, paddingBottom: 10 }
    const titleWrapperStyle = { display: 'flex', justifyContent: 'space-around' }
    const titleUnderlineStyle = { borderBottom: '1px solid' }
    const [expandAnswerSetting, setExpandAnswerSetting] = useState(true)
    const [settingOptions, setSettingOptions] = useState([{ target: 'type', options: typeOptions }])
    const normalStyle = { textTransform: 'none' }
    const selectedStyle = { textTransform: 'none', color: '#E83D4D' }
    useEffect(() => {
        if (type === 'mcq') {
            setSettingOptions([
                { target: 'type', options: typeOptions }, { target: 'layout', options: layoutOptions },
                { target: 'structure', options: structureOptions.filter(s => s.name !== 'Open') },
                { target: 'sorting', options: sortingOptions }
            ])
        } else if (type === 'input') {
            setSettingOptions([
                { target: 'type', options: typeOptions },
                { target: 'structure', options: structureOptions.filter(s => s.name !== 'Sequence') }
            ])
        } else setSettingOptions([{ target: 'type', options: typeOptions }])
    }, [type])
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <TitleBar title='Answer Setting' state={expandAnswerSetting} setState={setExpandAnswerSetting} />
            {expandAnswerSetting && <AddPageWrapper>
                <Grid container style={{ margin: 36, userSelect: 'none' }}>
                    <div style={{ display: 'flex' }}>
                        {settingOptions.map(({ target, options }, index) => (
                            <div key={index} style={buttonGroupWrapperStyle}>
                                <div style={titleWrapperStyle}>
                                    <h2 style={titleUnderlineStyle}>{target.charAt(0).toUpperCase() + target.slice(1)}</h2>
                                </div>
                                <ButtonGroup fullWidth variant="outlined" style={buttonGroupStyle}>
                                    {options.map(({ id, name }, i) => (
                                        <Button key={i} style={answerSettings[target] === id ? selectedStyle : normalStyle}
                                            onClick={() => onChangeAnswerSettings(target, id)}>{name}</Button>
                                    ))}
                                </ButtonGroup>
                            </div>
                        ))}
                    </div>
                    {JSON.stringify(answerSettings)}
                    {(type === 'mcq' && layout !== '' && sorting !== '' && structure !== '') ||
                        (type === 'input' && structure !== '') ? <AnswerKey answerSettings={answerSettings}
                            onChangeAnswerSettings={onChangeAnswerSettings} uploadImage={uploadImage} setUploadImage={setUploadImage}
                            answers={answers} onChangeAnswers={onChangeAnswers} /> : null}
                </Grid>
            </AddPageWrapper>}
        </Grid>
    )
}
const TranslatedContent = ({ update, languages, languageOptions }) => {
    const [viewingLanguage, setViewingLanguage] = useState(languages.find(l => l.name !== 'English').id)
    const count = languages.findIndex(l => l.id === viewingLanguage)
    const editingLanguage = languages.find(l => l.id === viewingLanguage)
    return (
        <Grid container style={{ marginBlock: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value={languageOptions.find(l => l.id === viewingLanguage).name || ''} fontSize={25} fontWeight={700}
                    italic color='#FFF' marginLeft={50} />
            </Grid>
            <AddPageWrapper>
                <Grid container style={{ margin: '36px 60px' }}>
                    <Grid container alignItems='center' style={{ marginBottom: 40 }}>
                        <Grid item xs={3}>
                            {JSON.stringify(viewingLanguage)}
                            <ReusableAutocompleteWithID type='withLabel' width='100%' placeholder='Language' state={viewingLanguage}
                                setState={setViewingLanguage} options={languageOptions.map(l => ({ id: l.id, option: l.name }))} />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title*' state={editingLanguage.task_title} setState={update}
                                fullWidth count={count} target='task_title' onBlur required bgColor='#F2F2F2' />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={editingLanguage.content} setState={update} height={500}
                        placeholder={`Task Content For ${editingLanguage.name}`} count={count} target='content' />
                </Grid>
            </AddPageWrapper>
        </Grid>
    )
}
const MainContent = ({ count, language, update }) => {
    return (
        <Grid container style={{ marginBlock: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value={language.name} fontSize={25} fontWeight={700} italic color='#FFF' marginLeft={50} />
            </Grid>
            <AddPageWrapper>
                <Grid container style={{ margin: '36px 60px' }}>
                    <Grid container alignItems='center' style={{ marginBottom: 40 }}>
                        <Grid item xs={3}>
                            <ReusableTextField type='withLabel' placeholder='Language' state='English' setState={() => { }}
                                grayedOut fullWidth />
                        </Grid>
                        <Grid item xs={2} />
                        <Grid item xs={7}>
                            <ReusableTextField type='withLabel' placeholder='Task Title*' state={language.task_title} setState={update}
                                fullWidth count={count} target='task_title' onBlur required bgColor='#F2F2F2' />
                        </Grid>
                    </Grid>
                    <DescriptionEditor state={language.content} setState={update} height={500}
                        placeholder='Task Content For English' count={count} target='content' />
                </Grid>
            </AddPageWrapper>
        </Grid>
    )
}
const Contents = ({ languages, update, saveLanguages, remoderate, setRemoderate, loading }) => {
    const [expandContents, setExpandContents] = useState(false)
    const mainCount = languages.findIndex(l => l.name === 'English')
    const mainLanguage = languages.find(l => l.name === 'English')
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <TitleBar title='Languages' state={expandContents} setState={setExpandContents} />
            {expandContents && <AddPageWrapper>
                {loading ? <Loader height={600} /> : <>
                    <MainContent count={mainCount} language={mainLanguage} update={update} />
                    {languages.find(l => l.name !== 'English') ? <TranslatedContent update={update} languages={languages}
                        languageOptions={languages.map(l => ({ id: l.id, name: l.name }))} /> : 'owo'}
                    <Grid container alignItems='center' justifyContent='space-between' style={{ marginRight: 20, marginBottom: 20 }}>
                        <ReusableCheckbox type='default' label='Re-Moderate Other Languages' state={remoderate} setState={setRemoderate} />
                        <ReusableButton text='Save' bgColor='#5E75C3' width={140} height={50} fontSize={16}
                            onClick={() => saveLanguages()} />
                    </Grid>
                </>}
            </AddPageWrapper>}
        </Grid >
    )
}
const deepCopy = obj => JSON.parse(JSON.stringify(obj))
const Task = ({ task }) => {
    const [expand, setExpand] = useState(false)
    const [title, setTitle] = useState(task.title)
    const [identifier, setIdentifier] = useState(task.identifier)
    const [domains, setDomains] = useState(task.task_tags.filter(t => !t.is_tag).map(t => t.id))
    const [tags, setTags] = useState(task.task_tags.filter(t => t.is_tag).map(t => t.id))
    const [description, setDescription] = useState(task.description)
    const [solutions, setSolutions] = useState(task.solutions)
    const [image, setImage] = useState(task.image)
    const [recommendations, setRecommendations] = useState(deepCopy(task.grade_difficulty))
    const [languages, setLanguages] = useState(deepCopy(task.languages))
    const [remoderate, setRemoderate] = useState(false)
    const [answerSettings, setAnswerSettings] = useState({
        type: task.answer_type, structure: task.answer_structure, layout: task.answer_layout, sorting: task.answer_sorting
    })
    const [uploadImage, setUploadImage] = useState(task.image_label)
    const [answers, setAnswers] = useState(task.task_answers.map(answer => ({
        id: answer.id.toString(), label: uploadImage ? '' : answer.task_labels[0].content, answer: answer.answer,
        position: answer.position.toString(), image: uploadImage ? answer.task_labels[0].content : '',
        correct: answer.answer === '1' && task.answer_type === 'mcq'
    })))

    const [loadingSetting, setLoadingSetting] = useState(false)
    const [loadingRecommendation, setLoadingRecommendation] = useState(false)
    const [loadingContent, setLoadingContent] = useState(false)

    const [allTopicOptions, setAllTopicOptions] = useState([])
    const [tagOptions, setTagOptions] = useState([])
    const [inputValue, setInputValue] = useState('')
    // Settings
    const onChangeDomainAndTopic = value => {
        let newArr = value
        let old = domains
        let allDomains = allTopicOptions.map(a => a.id)
        let allTopics = allTopicOptions.map(a => a.children).flat().map(t => t.id)
        if (newArr.length > old.length) {
            let added = newArr.at(-1)
            if (allTopics.includes(added)) {
                let domainOfAddedTopic = allTopicOptions.find(a => a.children.find(c => c.id === added)).id
                if (!newArr.includes(domainOfAddedTopic)) newArr.push(domainOfAddedTopic)
            }
        } else {
            let removed = old.filter(x => !newArr.includes(x))[0]
            if (allDomains.includes(removed)) {
                let topicsOfRemovedDomain = allTopicOptions.find(a => a.id === removed).children.map(c => c.id)
                console.log(topicsOfRemovedDomain)
                newArr = newArr.filter(n => !topicsOfRemovedDomain.includes(n))
            }
        }
        setDomains(newArr)
    }
    const saveSettings = () => {
        let warningMessage = ''
        if (invalidName(title)) warningMessage += 'Invalid Task Title. '
        if (invalidName(identifier)) warningMessage += 'Invalid Task Identifier. '
        if (isEmpty([languages])) warningMessage += 'Task Content cannot be empty. '
        if (warningMessage) {
            showWarningSwal(warningMessage)
            return
        }
        let payload = {
            id: task.id,
            identifier,
            tag_id: [domains, tags].flat(),
            description,
            solutions,
            image
        }
        console.log(JSON.stringify(payload))
        setLoadingSetting(true)
        patchTaskSettings(payload).then(d => {
            if (d.status === 200) {
                getTasks(`?id=${task.id}`).then(res => {
                    let newTask = res.taskLists.data[0]
                    setTitle(newTask.title)
                    setIdentifier(newTask.identifier)
                    setDomains(newTask.task_tags.filter(t => !t.is_tag).map(t => t.id))
                    setTags(newTask.task_tags.filter(t => t.is_tag).map(t => t.id))
                    setDescription(newTask.description)
                    setSolutions(newTask.solutions)
                    setImage(newTask.image)
                    setLoadingSetting(false)
                })
                return
            }
            setLoadingSetting(false)
        })
    }
    // Recommendations
    const onChangeRecommendations = (count, identifier, value) => {
        let newRecommendations = [...recommendations]
        newRecommendations[count][identifier] = value
        setRecommendations(newRecommendations)
    }
    const addRecommendation = count => {
        let newRecommendations = [...recommendations]
        newRecommendations.splice(count, 0, { grade: '', difficulty: '' })
        setRecommendations(newRecommendations)
    }
    const removeRecommendation = count => {
        let newRecommendations = [...recommendations]
        newRecommendations.splice(count, 1)
        setRecommendations(newRecommendations)
    }
    const saveRecommendations = () => {
        let warningMessage = ''
        if (recommendations.map(r => r.grade).includes('')) {
            warningMessage += 'Please fill in all grades fields or remove recommended grade. '
        }
        if (recommendations.map(r => r.difficulty).includes('')) {
            warningMessage += 'Please fill in all difficulty fields or remove recommended difficulty. '
        }
        if (warningMessage) {
            showWarningSwal(warningMessage)
            return
        }
        setLoadingRecommendation(true)
        let payload = {
            id: task.id,
            recommended_grade: recommendations.map(r => r.grade),
            recommended_difficulty: recommendations.map(r => r.difficulty)
        }
        patchTaskRecommendations(payload).then(d => {
            if (d.status === 200) {
                getTasks(`?id=${task.id}`).then(res => {
                    let newTask = res.taskLists.data[0]
                    setRecommendations(deepCopy(newTask.grade_difficulty))
                    setLoadingRecommendation(false)
                })
                return
            }
            setLoadingRecommendation(false)
        })
    }
    // Content
    const onChangeLanguages = (count, identifier, value) => {
        let newLanguages = [...languages]
        newLanguages[count][identifier] = value
        if (newLanguages[count].name === 'English' && identifier === 'task_title') setTitle(value)
        setLanguages(newLanguages)
    }
    const saveLanguages = () => {
        let payload = {
            id: task.id,
            taskContents: [],
            "re-moderate": Number(remoderate)
        }
        languages.forEach(language => {
            let original = task.languages.find(l => l.id === language.id)
            console.log(language)
            console.log(original)
            if (JSON.stringify(language) !== JSON.stringify(original)) {
                payload.taskContents.push({
                    lang_id: language.id,
                    title: language.task_title,
                    content: language.content
                })
            }
        })
        console.log(payload)
        if (payload.taskContents.length) {
            setLoadingContent(true)
            patchTaskContent(payload).then(d => {
                if (d.status === 200) {
                    getTasks(`?id=${task.id}`).then(res => {
                        let newTask = res.taskLists.data[0]
                        setLanguages(deepCopy(newTask.languages))
                        setLoadingContent(false)
                    })
                    return
                }
                setLoadingContent(false)
            })
        } else showWarningSwal('Please make some changes before you submit')
    }
    // Answer Setting and Answer
    const onChangeAnswerSettings = (identifier, value) => {
        let newAnswerSettings = deepCopy(answerSettings)
        newAnswerSettings[identifier] = value
        setAnswerSettings(newAnswerSettings)
    }
    const onChangeAnswers = (count, identifier, value) => {
        let newAnswers = deepCopy(answers)
        switch (identifier) {
            case 'add':
                newAnswers.splice(count + 1, 0, {
                    id: '-', label: '', answer: '',
                    position: '', image: '', correct: false
                })
                break;
            case 'remove':
                newAnswers.splice(count, 1)
                break;
            case 'moveUp':
            case 'moveDown':
                let temp = newAnswers[count]
                let newCount = identifier === 'moveDown' ? count + 1 : count - 1
                newAnswers[count] = newAnswers[newCount]
                newAnswers[newCount] = temp
                break;
            default: newAnswers[count][identifier] = value
        }
        setAnswers(newAnswers)
    }
    const saveAnswers = () => {
        let warningMessage = ''
        if (answers.length === 0) warningMessage += 'Must have at least one answer for task. '
        if (answerSettings.type === 'mcq') {
            if (answers.map(a => a.label === '').includes(true)) {
                warningMessage += 'Answer cannot be empty if answer type is MCQ. '
            }
            if (['default', 'group'].includes(answerSettings.structure) && !answers.map(a => a.correct).includes(true)) {
                warningMessage += 'Must have at least one correct answer if answer type is MCQ and answer structure is Default or Group. '
            }
        }
        if (answerSettings.type === 'input') {
            if (['default', 'group'].includes(answerSettings.structure) && answers.map(a => a.answer === '').includes(true)) {
                warningMessage += 'Answer cannot be empty if answer type is Input and answer structure is Default or Group.'
            }
            if (answerSettings.structure === 4 && answers.map(a => a.label === '').includes(true)) {
                warningMessage += 'Task Label cannot be empty if answer type is Input and answer structure is Open.'
            }
        }
        if (warningMessage) {
            showWarningSwal(warningMessage)
            return
        }
        let payload = {
            id: task.id,
            answer_type: answerSettings.type,
            answer_structure: answerSettings.structure,
            answer_sorting: answerSettings.sorting,
            answer_layout: answerSettings.layout,
            labels: [
                "a",
                "b",
                "c",
                ""
            ],
            answers: [
                "aaa",
                "bbb",
                "ccc",
                "ddd"
            ],
            image_label: uploadImage
        }
    }
    const handleFileInputChange = file => {
        getBase64(file)
            .then(result => setImage(result))
            .catch(err => {
                console.log(err)
                alert(`Error at handeFileInputChange: ${JSON.stringify(err)}`)
            });
    }
    useEffect(() => {
        getDomains('?limits=50').then(d => {
            let domains = d.tagLists.data.filter(c => !c.is_tag && c.domain_name === null)
            let topics = d.tagLists.data.filter(c => !c.is_tag && c.domain_name !== null)
            let outputTopics = domains.map(d => ({ id: d.id, option: d.name, children: [] }))
            outputTopics.map(o => {
                topics.map(t => {
                    if (t.domain_id === o.id) o.children.push({ id: t.id, option: t.name })
                })
            })
            setAllTopicOptions(outputTopics)
            setTagOptions(d.tagLists.data.filter(c => c.is_tag))
        })
    }, [])
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between' style={titleBarStyle}>
                <NunitoText value={task.identifier} fontSize={25} fontWeight={700} italic color='#FFF' marginLeft={50} />
                <IconButton style={{ color: '#FFF' }} onClick={() => setExpand(!expand)} edge="start">
                    {expand ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Grid>
            {expand && <Grid container style={taskContainerStyle}>
                {loadingSetting ? <Loader height={600} /> : <AddPageWrapper>
                    <Grid container style={{ margin: '60px 36px' }}>
                        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <NunitoText value='Task Title*' fontSize={20} fontWeight={600} marginTop={12} />
                                {['Task Identifier*', 'Domain and Topic', 'Tags', 'Description', 'Select Image'].map((t, i) => (
                                    <NunitoText key={i} value={t} fontSize={20} fontWeight={600} marginTop={[3, 4].includes(i) ? 235 : 75} />
                                ))}
                            </div>
                            <Divider orientation="vertical" flexItem
                                style={{ marginTop: 20, height: 850, width: 2, backgroundColor: '#000' }} />
                        </Grid>
                        <Grid item xs={10} style={{ paddingLeft: 120, display: 'flex', flexDirection: 'column' }}>
                            <ReusableTextField type='default' placeholder='Task Title' state={title} setState={setTitle}
                                borderColor='#000' grayedOut readOnly />
                            <ReusableTextField type='default' placeholder='Task Identifier' state={identifier}
                                setState={setIdentifier} marginTop={48} borderColor='#000' required onBlur />
                            <ReusableAutocompleteWithID type='multiple' placeholder='Domain and Topic' width={610} height={55}
                                btnBgColor='#144A94' btnWidth={64} state={domains} setState={onChangeDomainAndTopic}
                                inputValue={inputValue} setInputValue={setInputValue} limitMultiple={3}
                                marginTop={48} borderColor='#707070' nested options={allTopicOptions} allowSelectHead />
                            <ReusableAutocompleteWithID type='multiple' placeholder='Tags' width={610} height={55}
                                btnBgColor='#144A94' btnWidth={64} state={tags} setState={setTags} limitMultiple={3}
                                marginTop={46} borderColor='#707070' options={tagOptions.map(t => ({ id: t.id, option: t.name }))} />
                            <DescriptionEditor state={description} setState={setDescription} height={360}
                                placeholder='Description' marginTop={60} />
                            <input id={`raised-button-file`} accept="image/*" style={{ display: 'none' }} multiple
                                type="file" onChange={e => handleFileInputChange(e.target.files[0])} />
                            <ReusableButton text='Browse' bgColor='#5E75C3' width={140} height={50} fontSize={16} marginTop={52}
                                onClick={() => document.getElementById(`raised-button-file`).click()} iconType='upload' />
                        </Grid>
                        <Grid container flexDirection='column' style={{ marginTop: 20 }}>
                            <NunitoText value={image !== '' ? 'rando img' : "No image"} fontSize={20} color='#707070' />
                            {image !== '' ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <img src={image} alt="Task" style={{ maxHeight: 600, maxWidth: 1500 }} />
                            </div> : <ReusableTextField type='imagePlaceholder' placeholder='Image placeholder' bgColor='#F2F2F2'
                                height={600} />}
                        </Grid>
                        <Solution solution={solutions} update={setSolutions} />
                    </Grid>
                    <Grid container justifyContent='flex-end' style={{ marginRight: 20, marginBottom: 20 }}>
                        <ReusableButton text='Save' bgColor='#5E75C3' width={140} height={50} fontSize={16}
                            onClick={() => saveSettings()} />
                    </Grid>
                </AddPageWrapper>}
                <Recommendations recommendations={recommendations} update={onChangeRecommendations}
                    add={addRecommendation} remove={removeRecommendation} save={saveRecommendations}
                    loading={loadingRecommendation} />
                <Contents languages={languages} update={onChangeLanguages} saveLanguages={saveLanguages}
                    remoderate={remoderate} setRemoderate={setRemoderate} loading={loadingContent} />
                <AnswerSetting answerSettings={answerSettings} onChangeAnswerSettings={onChangeAnswerSettings}
                    uploadImage={uploadImage} setUploadImage={setUploadImage} answers={answers} onChangeAnswers={onChangeAnswers} />
            </Grid>}
        </Grid>
    )
}
export default function EditTask() {
    document.title = 'Edit Task'
    let location = useLocation()
    const [task, setTask] = useState()
    const [loading, setLoading] = useState(false)
    const [firstLoaded, setFirstLoaded] = useState(false)
    const getData = params => loadData(setLoading, setFirstLoaded, getTasks, params, setTask, null, true)
    useEffect(() => {
        if (location.search.slice(0, 4) === '?id=') getData(location.search)
    }, [])
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard' iconType='home' />
                <ChevronRightIcon />
                <ReusableButton text='Tasks' fontSize={14} bgColor='#F16774' height={36} br={18} />
            </div>
            <Grid container flexDirection='column' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Edit Task' fontSize={40} fontWeight={700} italic color='#144A94' align='left' marginBottom={24} />
                {loading ? <Loader height={600} /> : task ? <Task task={task[0]} /> : firstLoaded && <NoData height={600} />}
            </Grid>
        </Box>
    )
}