import React from 'react'
// import { IconButton, TextField } from '@mui/material'
// import ClearIcon from '@mui/icons-material/Clear'
// import EventIcon from '@mui/icons-material/Event'
// import AdapterDateFns from '@mui/lab/AdapterDateFns'
// import LocalizationProvider from '@mui/lab/LocalizationProvider'
// import DatePicker from '@mui/lab/DatePicker'
import { default as NewDatePicker } from "react-multi-date-picker"
import DatePanel from "react-multi-date-picker/plugins/date_panel"
import { dateHasPassed } from '../../functions/general'
export default function ReusableDatePicker({ type, width,
    marginLeft, marginRight, marginTop, marginBottom,
    state, setState, required, readOnly, grayedOut, disabled, count, head, target, minDate, maxDate, multiple, allowExceedMinMax }) {
    // const textFieldStyle = {
    //     width: width,
    //     marginLeft: marginLeft,
    //     marginRight: marginRight,
    //     marginTop: marginTop,
    //     marginBottom: marginBottom,
    //     height: 55,
    //     borderRadius: 10,
    //     border: `1px solid ${required && !state ? '#E83D4D' : '#707070'}`,
    //     backgroundColor: '#FFF'
    // }
    // const inputStyle = {
    //     color: '#000',
    //     fontSize: !state && 28,
    //     marginLeft: !state && 10
    // }
    let errorBorder = () => {
        if (allowExceedMinMax) return false
        if (multiple) {
            if (minDate) {
                for (let i = 0; i < state.length; i++) {
                    if (dateHasPassed(new Date(state[i]), minDate)) return true
                }
            }
            if (maxDate) {
                for (let i = 0; i < state.length; i++) {
                    if (dateHasPassed(maxDate, new Date(state[i]))) return true
                }
            }
        } else {
            if (minDate && dateHasPassed(new Date(state), minDate)) return true
            if (maxDate && dateHasPassed(maxDate, new Date(state))) return true
        }
        return false
    }
    const onChangeState = value => {
        // Force re-render so as to ensure there is at least one date for multiple date
        if (multiple && value.length === 0) value = [...state]
        // Set all hours and minutes to 00:00:00
        if (multiple === undefined) value = new Date(value).setHours(0, 0, 0, 0)
        else value = value.map(v => new Date(v).setHours(0, 0, 0, 0))
        if (type === 'redux') {
            setState(count, head, target, '', value)
        } else {
            if (count !== undefined && target !== undefined) {
                setState(count, target, value)
            } else {
                if (count !== undefined) setState(count, value)
                else if (target !== undefined) setState(target, value)
                else setState(value)
            }
        }
    }
    const newDatePickerStyle = {
        width: width,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        paddingLeft: 16,
        height: 55,
        borderRadius: 10,
        border: `1px solid ${(required && state === null) || errorBorder() ? '#E83D4D' : '#707070'}`,
        backgroundColor: '#FFF',
        fontSize: state !== null ? 18 : 28
    }
    const newDatePickerAttributes = {
        value: state,
        onChange: e => onChangeState(e),
        format: 'DD/MM/YYYY',
        placeholder: '--/--/----',
        style: newDatePickerStyle,
        disabled: disabled
    }
    if (multiple) {
        newDatePickerAttributes.multiple = true
        newDatePickerAttributes.sort = true
        newDatePickerAttributes.plugins = [<DatePanel />]
    }
    if (minDate) newDatePickerAttributes.minDate = minDate.setHours(0, 0, 0, 0)
    if (maxDate) newDatePickerAttributes.maxDate = maxDate.setHours(0, 0, 0, 0)
    return (
        <>
            <NewDatePicker {...newDatePickerAttributes} />
            {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker value={state}
                    minDate={minDate ? minDate : new Date()}
                    maxDate={maxDate ? maxDate : null}
                    onChange={(newValue) => type === 'redux' ? setState(count, head, target, '', newValue) : setState(newValue)}
                    components={{
                        OpenPickerIcon: () => <EventIcon style={{ color: '#000' }} />
                    }}
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                        <TextField variant='filled' ref={inputRef} {...inputProps} placeholder='--/--/----'
                            InputProps={{
                                disableUnderline: true,
                                hiddenLabel: true,
                                style: textFieldStyle,
                                endAdornment:
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <IconButton edge='end' style={{ color: '#000' }}
                                            onClick={() => type === 'redux' ? setState(count, head, target, '', null) : setState(null)}>
                                            <ClearIcon />
                                        </IconButton>
                                        {InputProps?.endAdornment}
                                    </div>,
                                readOnly: true
                            }}
                            inputProps={{ style: inputStyle }} />
                    )}
                />
            </LocalizationProvider> */}
        </>
    )
}