import React, { useState, useEffect } from 'react'
import { TextField, IconButton } from '@mui/material'
import ReusableButton from './ReusableButton'
import SearchIcon from '@mui/icons-material/Search'
export default function ReusableTextField({ type, width, height, bgColor, borderColor,
    marginLeft, marginRight, marginTop, marginBottom,
    placeholder, state, setState, required, readOnly, grayedOut, disabled, errorFunction,
    count, head, target, onClick, divMarginLeft, divMarginRight,
    // For ReusableTextField
    multiline, onBlur, number, fullWidth }) {
    const [tempState, setTempState] = useState(state)
    useEffect(() => setTempState(state), [state])
    const [error, setError] = useState()
    //Some TextField uses this tempState to change first
    //In these cases, the actual update of the state passed in occurs onBlur
    const checkError = () => {
        if (errorFunction) return errorFunction(onBlur ? tempState : state)
        return false
    }
    let border, borderRadius;
    //Set border
    if (onBlur) {
        border = `1px solid ${required && tempState === '' ? '#E83D4D' : borderColor ? borderColor : bgColor}`
    } else {
        border = `1px solid ${required && state === '' ? '#E83D4D' : borderColor ? borderColor : bgColor}`
    }
    //Set borderRadius
    if (['default', 'clickOnly', 'editInTable', 'immutableDisplay'].includes(type)) borderRadius = 10
    if (['search', 'withLabel'].includes(type)) borderRadius = 12
    if (['fullWidth', 'imagePlaceholder'].includes(type)) borderRadius = 6
    const withLabelDivStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginLeft: divMarginLeft,
        marginRight: divMarginRight,
        minWidth: width,
    }
    const textFieldStyle = {
        minWidth: width,
        height: height,
        marginLeft: marginLeft,
        marginRight: marginRight,
        marginTop: marginTop,
        marginBottom: marginBottom,
        borderRadius: borderRadius,
        border: checkError() ? '1px solid #E83D4D' : border,
        backgroundColor: grayedOut ? '#707070' : bgColor
    }
    const inputStyle = {
        textAlign: 'center',
        width: '100%',
        color: grayedOut && '#FFF',
        cursor: readOnly && 'context-menu'
    }
    const searchIconStyle = {
        fontSize: 32,
        color: 'rgb(112, 112, 112, 0.5)'
    }
    const searchButtonStyle = {
        width: 52, height: height, backgroundColor: '#5E75C3', borderRadius: 12, color: '#FFF'
    }
    const onChangeState = value => {
        let a = value
        let allow = true
        if (number) {
            if (isNaN(a)) allow = false
            else a = Number(a)
        }
        if (allow) {
            setError()
            if (count !== undefined && head !== undefined && target !== undefined) {
                // For redux textfields
                setState(count, head, target, a)
                return
            }
            if (count !== undefined && target !== undefined) {
                // For states in an array of objects
                setState(count, target, a)
                return
            }
            // For states in an array only, object only or by itself
            if (count !== undefined) setState(count, a)
            else if (target !== undefined) setState(target, a)
            else setState(a)
        } else {
            setError('Only numeric characters')
        }
    }
    const textFieldAttributes = {
        variant: 'filled',
        placeholder: placeholder,
        disabled: disabled,
        value: onBlur ? tempState : state,
        onChange: e => onBlur ? setTempState(e.target.value) : onChangeState(e.target.value),
        onBlur: () => onBlur && onChangeState(tempState),
        onClick: () => onClick && onClick(),
        fullWidth
    }
    if (number) textFieldAttributes.type = 'number'
    if (multiline !== undefined) {
        textFieldAttributes.fullWidth = true
        textFieldAttributes.multiline = true
        textFieldAttributes.rows = multiline
    }
    const textFieldInputProps = {
        style: textFieldStyle,
        disableUnderline: true,
        hiddenLabel: true,
        readOnly: readOnly
    }
    const textFieldinputProps = {
        style: inputStyle
    }
    const imagePlaceholderDivStyle = {
        width: width, height: height, border: border,
        borderRadius: borderRadius, backgroundColor: bgColor, color: '#B8B8B8', fontSize: 24,
        display: 'flex', alignItems: 'center', justifyContent: 'center'
    }
    const onEnter = e => {
        if (e.key === 'Enter') onChangeState(tempState)
    }
    switch (type) {
        case 'default':
            return (
                <TextField {...textFieldAttributes}
                    InputProps={textFieldInputProps}
                    inputProps={textFieldinputProps}
                />
            )
        case 'search':
            return (
                <TextField variant='filled' placeholder={placeholder} disabled={disabled} onKeyDown={onEnter}
                    value={tempState} onChange={e => setTempState(e.target.value)}
                    InputProps={{
                        ...textFieldInputProps,
                        startAdornment: <SearchIcon style={searchIconStyle} />,
                        endAdornment:
                            <IconButton style={searchButtonStyle} onClick={() => onChangeState(tempState)} edge='end'>
                                <SearchIcon style={{ fontSize: 36 }} />
                            </IconButton>
                    }}
                    inputProps={{ style: { marginLeft: 20 } }}
                />
            )
        case 'withLabel':
            return (
                <div style={withLabelDivStyle}>
                    <div style={{ display: 'flex' }}>
                        <label style={{ color: '#000', fontSize: 16, fontFamily: 'Nunito', fontWeight: 600, fontStyle: 'italic' }}>
                            {placeholder}</label>
                        {error && <label style={{ color: 'red', marginLeft: 10 }}>{error}</label>}
                    </div>
                    <TextField {...textFieldAttributes}
                        InputProps={textFieldInputProps}
                        inputProps={textFieldinputProps}
                    />
                </div>
            )
        case 'clickOnly':
            return (
                <TextField variant='filled' onClick={() => onClick()} disabled={disabled}
                    InputProps={{
                        ...textFieldInputProps,
                        endAdornment: <div style={{ position: 'relative', left: 14 }}>
                            <ReusableButton text='Browse' bgColor='#5E75C3' fontSize={15} width={120} height={60} />
                        </div>
                    }}
                />
            )
        case 'fullWidth':
            return (
                <TextField {...textFieldAttributes} fullWidth
                    onChange={e => setTempState(e.target.value)}
                    onBlur={() => setState(count, head, target, tempState)}
                    InputProps={textFieldInputProps}
                    inputProps={textFieldinputProps}
                />
            )
        case 'editInTable':
            return (
                <TextField {...textFieldAttributes} fullWidth
                    // onChange={e => setTempState(e.target.value)}
                    // onBlur={() => setState(count, getter, tempState)}
                    InputProps={textFieldInputProps}
                />
            )
        case 'immutableDisplay':
            return (
                <TextField {...textFieldAttributes} fullWidth
                    InputProps={textFieldInputProps}
                    inputProps={{ style: { ...inputStyle, color: '#D4D2D2', fontWeight: 400 } }}
                />
            )
        case 'imagePlaceholder':
            return <div style={imagePlaceholderDivStyle}>{placeholder}</div>
        default: return null
    }
}