import React, { useState, useEffect } from 'react'
import { Grid, Button, IconButton, Divider } from '@mui/material'
import { Link, useHistory } from "react-router-dom"
import AddIcon from '@mui/icons-material/Add'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import SettingsIcon from '@mui/icons-material/Settings'
import ForumIcon from '@mui/icons-material/Forum'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import CloseIcon from '@mui/icons-material/Close'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { useSelector, useDispatch } from 'react-redux'
import { getCompetitions, getDomains } from '../functions/getData'
import { addCollection as addCollectionToDatabase } from '../functions/postData'
import {
    addCollection, deleteCollection, duplicateCollection, updateCollection, resetCollection
} from '../actions'
import '../css/school.css'
import '../css/participant.css'
import '../css/global.css'
import NunitoText from './general/NunitoText'
import TitleBar from './general/TitleBar'
import AddPageWrapper from './general/AddPageWrapper'
import ReusableTextField from './general/ReusableTextField'
import ReusableToggle from './general/ReusableToggle'
import ReusableAutocomplete from './general/ReusableAutocomplete'
import ReusableAutocompleteWithID from './general/ReusableAutocompleteWithID'
import ReusableButton from './general/ReusableButton'
const gradeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(g => (
    { id: g, name: `Grade ${g} / ${g < 7 ? 'Primary' : g < 11 ? 'Secondary' : 'Junior College'} ${g < 7 ? g : g < 11 ? g - 6 : '1 / 2'}` }
))
const difficultyOptions = ['Easy', 'Intermediate', 'Hard', 'Advanced']
export function Recommendations({ count, collection }) {
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCollection(count, head, target, text, event))
    const [expandRecommendations, setExpandRecommendations] = useState(true)
    return (
        <Grid container style={{ marginBottom: !expandRecommendations && 20 }}>
            <TitleBar title='Recommendations' state={expandRecommendations} setState={setExpandRecommendations} />
            {expandRecommendations && <AddPageWrapper>
                {collection.recommendations.map((recommendation, index) => (
                    <Grid key={index} container alignItems="center"
                        style={{ marginTop: !index ? 50 : 30, marginBottom: index === collection.recommendations.length - 1 && 30 }}>
                        <Grid item xs={0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {index !== 0 && <CloseIcon style={{ fontSize: 36, color: '#000', cursor: 'pointer' }}
                                onClick={() => { update([count, index], 'deleteRecommendation', 'recommendations') }} />}
                        </Grid>
                        <Grid item xs={11.2}>
                            <Grid key={index} container
                                style={{ backgroundColor: '#FFF', border: '1px solid #707070', borderRadius: 16, paddingBlock: 15 }}>
                                <Grid item xs={6}>
                                    <Grid container alignItems="center" justifyContent="space-around">
                                        <NunitoText value='Recommended Grade:' fontSize={24} fontWeight={600} italic />
                                        <ReusableAutocomplete type='redux' width={400} height={60} bgColor='#F2F2F2'
                                            btnBgColor='#5E75C3' btnWidth={56} placeholder='Grade'
                                            state={recommendation.grade} setState={update} options={gradeOptions}
                                            count={[count, index]} head='recommendationText' target='grade' />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6}>
                                    <Grid container alignItems="center" justifyContent="space-around">
                                        <NunitoText value='Recommended Difficulty:' fontSize={24} fontWeight={600} italic />
                                        <ReusableAutocomplete type='redux' width={400} height={60} bgColor='#F2F2F2'
                                            btnBgColor='#5E75C3' btnWidth={56} placeholder='Difficulty'
                                            state={recommendation.difficulty} setState={update} options={difficultyOptions}
                                            count={[count, index]} head='recommendationText' target='difficulty' />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={0.4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <AddIcon style={{ fontSize: 36, color: '#000', cursor: 'pointer' }}
                                onClick={() => update([count, index + 1], 'addRecommendation', 'recommendations')} />
                        </Grid>
                    </Grid>
                ))}
            </AddPageWrapper>}
        </Grid>
    )
}
export function Section({ count, sectionCount, collection, section }) {
    const collections = useSelector(state => state.collection)
    const dispatch = useDispatch()
    const update = (count, head, target, text, event) => dispatch(updateCollection(count, head, target, text, event))
    // Get the maximum task group ID and use it when adding task group(Auto-increment for adding task group)
    let nextTaskGroupID = section.taskGroups.map(t => t.taskGroupID).reduce((a, b) => Math.max(a, b), 0) + 1
    const SortableItem = SortableElement(({ level, value, groupIndex, questionIndex, items, taskGroupID }) => {
        const taskBtnStyle = {
            marginBlock: 10,
            marginRight: items.length > 1 && questionIndex < items.length - 1 && 10,
            height: 50, minWidth: 210
        }
        const addBtnStyle = {
            backgroundColor: '#144A94', textTransform: 'none', color: '#FFF',
            borderRadius: 12, border: '1px solid gray', fontSize: 12,
            marginRight: 20,
            height: 40, width: 100
        }
        const wrapperStyle = { display: 'flex', alignItems: 'center', marginTop: 10, height: 80 }
        const divStyle = {
            border: '1px solid gray', borderRadius: 12, boxShadow: '0 0 3px #9E9E9E',
            backgroundColor: '#F2F2F2', display: 'flex', paddingInline: 10
        }
        const cancelStyle = {
            fontSize: 32, color: '#FFF', backgroundColor: '#E83D4D', cursor: 'pointer', borderRadius: 16,
            border: '1px solid gray', padding: 3,
            position: 'relative', bottom: value.length > 1 ? 26 : 16, right: 16
        }
        if (level) {
            return (
                <Button key={questionIndex} id='newQnBtn' style={taskBtnStyle}
                    endIcon={
                        <>
                            {questionIndex > 0 && <IconButton style={{ width: 26 }} onClick={() =>
                                update([count, sectionCount, groupIndex], 'deleteTaskFromGroup', 'taskGroups', value)}>
                                <RemoveCircleIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />
                            </IconButton>}
                            <Link to={`/addtasktocollection/${collection.collectionID}/${section.sectionID}/${groupIndex}/${taskGroupID}/${questionIndex + 1}`} target="_blank" >
                                <IconButton style={{ width: 26 }}>
                                    <AddCircleIcon style={{ color: '#FFF' }} onMouseDown={e => e.stopPropagation()} />
                                </IconButton>
                            </Link>
                        </>}>
                    Question {groupIndex + 1}{`.${questionIndex + 1}`} (Task {value})
                </Button>
            )
        }
        return (
            <div style={wrapperStyle}>
                <div style={value.length > 1 ? divStyle : { display: 'flex' }}>
                    <SortableList level={1} items={value} groupIndex={groupIndex} taskGroupID={taskGroupID} axis='x' pressDelay={200}
                        onSortEnd={(e) => update([count, sectionCount, groupIndex], 'sortTask', 'taskGroups', '', e)} />
                </div>
                <CloseIcon onMouseDown={e => e.stopPropagation()} style={cancelStyle}
                    onClick={() => update([count, sectionCount, groupIndex], 'deleteTaskGroup', 'taskGroups')} />
                <Button style={addBtnStyle} startIcon={<AddIcon />}>
                    <Link to={`/addtasktocollection/${count}/${sectionCount}/${groupIndex}/${nextTaskGroupID}`}
                        target="_blank" style={{ textDecoration: 'none', color: '#FFF' }}>Add Task</Link>
                </Button>
            </div>
        )
    })
    const SortableList = SortableContainer(({ level, items, groupIndex, taskGroupID }) => {
        const containerStyle = { display: 'flex', alignItems: 'center', border: !level && '1px solid red' }
        const style1 = {
            backgroundColor: 'lightgray', display: 'contents', width: 1460
        }
        if (level) {
            return (
                <div style={containerStyle}>
                    {items.map((value, index) =>
                        <SortableItem level={1} key={`item-${index}`} index={index} value={value} groupIndex={groupIndex}
                            questionIndex={index} items={items} taskGroupID={taskGroupID} />
                    )}
                </div>
            )
        }
        return (
            <div style={style1}>
                {items.map(({ taskGroupID, taskIDs }, index) => (
                    <SortableItem level={0} key={`item-${index}`} index={index} value={taskIDs} groupIndex={index}
                        items={items} taskGroupID={taskGroupID} />
                ))}
            </div>
        )
    })
    return (
        <Grid container style={{ margin: 16, marginBottom: sectionCount === collections[count].sections.length - 1 ? 16 : 0 }}>
            <Grid container alignItems='center' justifyContent='space-between'
                style={{ backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2.2 }}>
                <NunitoText value={`Section ${sectionCount + 1}`} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    {Boolean(sectionCount) && <ReusableButton text='Delete Section' height={46} bgColor='#E83042' fontSize={16}
                        marginRight={20} onClick={() => update([count, sectionCount], 'deleteSection', 'sections')} iconType='delete' />}
                    <IconButton style={{ color: '#FFF' }} onClick={() => update([count, sectionCount], 'sectionBoolean', 'expandSection')}
                        edge="start">{section.expandSection ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </Grid>
            {section.expandSection &&
                <Grid container style={{
                    marginInline: 12, backgroundColor: '#f3f0f4', border: '1px solid gray',
                    borderRadius: '0 0 10px 10px', borderTopStyle: 'none'
                }}>
                    <Grid container style={{
                        margin: 32, backgroundColor: '#FFF', padding: '10px 32px',
                        border: '1px solid gray', borderRadius: 10
                    }}>
                        <Grid item xs={12}>
                            <ReusableButton text='Add Task' height={48} bgColor='#5E75C3' fontSize={14}
                                to={`/addtasktocollection/${collection.collectionID}/${section.sectionID}/${section.taskGroups.length}/${nextTaskGroupID}`}
                                blank iconType='add' />
                        </Grid>
                        <Grid container>
                            <SortableList level={0} items={section.taskGroups} axis='xy' pressDelay={100}
                                onSortEnd={(e) => update([count, sectionCount], 'sortTaskGroup', 'taskGroups', '', e)} />
                        </Grid>
                    </Grid>
                    taskGroups: {JSON.stringify(section.taskGroups)}<br />
                    next taskGroupID: {nextTaskGroupID}
                    <h1>{JSON.stringify(section.taskGroups)}</h1>
                    <Grid container style={{ marginInline: 36, marginTop: 24 }}>
                        <Grid item xs={2.5} style={{ paddingLeft: 24 }}>
                            <NunitoText value='Sort Randomly' fontSize={20} fontWeight={600} />
                            <NunitoText value='Skip' fontSize={20} fontWeight={600} marginTop={76} />
                            <NunitoText value='Total Questions' fontSize={20} fontWeight={600} marginTop={60} />
                            <NunitoText value='Description' fontSize={20} fontWeight={600} marginTop={120} />
                        </Grid>
                        <Divider orientation="vertical" flexItem
                            style={{ height: 400, width: 2, backgroundColor: '#000', marginTop: 16 }} />
                        <Grid item xs style={{ display: 'flex', flexDirection: 'column', marginLeft: 200 }}>
                            <ReusableToggle type='reduxIcon' width={124} state={section.sortRandomly} setState={update}
                                count={[count, sectionCount]} head='sectionBoolean' target='sortRandomly' />
                            <ReusableToggle type='reduxIcon' width={124} state={section.skip} setState={update}
                                count={[count, sectionCount]} head='sectionBoolean' target='skip' marginTop={48} />
                            <ReusableTextField type='immutableDisplay' width={240} height={45} placeholder='Total Questions'
                                state={section.taskGroups.map(t => t.taskIDs).flat().length} marginTop={60} grayedOut readOnly />
                            <ReusableTextField type='default' placeholder='Description' state={section.sectionDescription}
                                setState={update} count={[count, sectionCount]} head='sectionText' target='sectionDescription'
                                marginTop={60} multiline={8} />
                        </Grid>
                    </Grid>
                </Grid>}
        </Grid >
    )
}
export function Collection({ count, collection, competition }) {
    const dispatch = useDispatch()
    const [domainOptions, setDomainOptions] = useState()
    const update = (count, head, target, text, event) => dispatch(updateCollection(count, head, target, text, event))
    const user = useSelector(state => state.user)
    const history = useHistory()
    let d = new Date()
    let date = `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()} ${d.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: false })}`
    const onSubmit = () => {
        addCollectionToDatabase({
            collectionName: collection.collectionName, collectionIdentifier: collection.collectionIdentifier,
            description: collection.collectionDescription, tags: collection.tags,
            recommendedDifficulty: collection.recommendations.map(r => ({ grade: r.grade, difficulty: r.difficulty })),
            sections: collection.sections, initialPoints: collection.initialPoints, timeToSolve: collection.timeToSolve,
            competition: [collection.competitionName], permissionToVerify: `${user.username} (${date})`,
            createdBy: `${user.username} (${date})`, lastModifiedBy: `${user.username} (${date})`,
            lastVerifiedBy: `${user.username} (${date})`, status: 'Pending Verification'
        })
        dispatch(resetCollection())
        history.push('./collection')
    }
    useEffect(() => update(count, 'settingText', 'competitionName', competition), [competition])
    useEffect(() => {
        getDomains('').then(d => setDomainOptions(d.tagLists.data.filter(t => t.is_tag)))
    }, [])
    return (
        <Grid container style={{ marginBottom: 20 }}>
            <Grid container alignItems='center' justifyContent='space-between'
                style={{ backgroundColor: '#144A94', borderRadius: 10, lineHeight: 2.4 }}>
                <NunitoText value={`Collection ${count + 1}`} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: 40 }}>
                        <ReusableButton text='New Collection' height={46} bgColor='#5E75C3' fontSize={16} marginRight={20}
                            onClick={() => dispatch(addCollection(count + 1))} iconType='add' />
                        {Boolean(count) && <ReusableButton text='Delete Collection' height={46} bgColor='#E83042' fontSize={16}
                            marginRight={20} onClick={() => dispatch(deleteCollection(count))} iconType='delete' />}
                        <ReusableButton text='Duplicate Collection' height={46} bgColor='#F16774' fontSize={16} marginRight={20}
                            onClick={() => dispatch(duplicateCollection(count + 1, collection))} iconType='copy' />
                    </div>
                    <IconButton style={{ color: '#FFF' }} onClick={() => update(count, 'settingBoolean', 'expandCollection')} edge="start">
                        {collection.expandCollection ? <ExpandLess /> : <ExpandMore />}
                    </IconButton>
                </div>
            </Grid>
            {collection.expandCollection &&
                <Grid container style={{ marginInline: 24, backgroundColor: '#f3f0f4' }}>
                    <Grid container style={{
                        marginInline: 30, backgroundColor: '#FFF', border: '1px solid',
                        borderRadius: '0 0 10px 10px', borderTopStyle: 'none', marginBottom: 20
                    }}>
                        <Grid container style={{
                            margin: 12, backgroundColor: '#f3f0f4',
                            border: '1px solid', borderRadius: 10, display: 'none'
                        }}>
                            <div style={{ margin: 24 }}>
                                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <h3 style={{ color: '#5e75c3', borderBottom: '2px solid black', width: 76 }}>Remarks</h3>
                                    <ReusableButton text='View in new tab' height={40} bgColor='#F16774' fontSize={14} marginRight={20}
                                        iconType='copy' />
                                </div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <h3 style={{ color: '#5e75c3', fontWeight: 'lighter', margin: 0 }}>Test Name(Chief Examiner)</h3>
                                    <h5 style={{ color: '#f16774', fontWeight: 'lighter', margin: 0, marginLeft: 10 }}>(22/10/2021 14:00)</h5>
                                </div>
                                <p style={{ margin: 0, marginTop: 2 }}>
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Rhoncus dolor purus non enim praesent elementum facilisis leo
                                    vel. Risus at ultrices mi tempus imperdiet. Semper risus in hendrerit gravida rutrum quisque
                                    non tellus. Convallis convallis tellus id interdum velit laoreet id donec ultrices. Odio morbi
                                    quis commodo odio aenean sed adipiscing. Amet nisl suscipit adipiscing bibendum est ultricies
                                    integer quis. Cursus euismod quis viverra nibh cras. Metus vulputate eu scelerisque felis
                                    imperdiet proin fermentum leo. Mauris commodo quis imperdiet massa tincidunt.
                                </p>
                            </div>
                        </Grid>
                        <Grid container alignItems='center' style={{
                            backgroundColor: '#144a94', borderRadius: 10, marginTop: 30, lineHeight: 2
                        }}>
                            <NunitoText value={`Settings`} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                            <SettingsIcon style={{ color: "#FFF", marginLeft: 10 }} />
                        </Grid>
                        <Grid container style={{ margin: '60px 36px' }}>
                            <Grid item xs={2.5} style={{ marginLeft: 30 }}>
                                <NunitoText value='Collection Name' fontSize={20} fontWeight={600} />
                                <NunitoText value='Collection Identifier*' fontSize={20} fontWeight={600} marginTop={70} />
                                <NunitoText value='Description' fontSize={20} fontWeight={600} marginTop={160} />
                                <NunitoText value='Time to Solve*' fontSize={20} fontWeight={600} marginTop={160} />
                                <NunitoText value='Initial Points*' fontSize={20} fontWeight={600} marginTop={70} />
                                <NunitoText value='Tags' fontSize={20} fontWeight={600} marginTop={70} />
                            </Grid>
                            <Divider orientation="vertical" flexItem
                                style={{ height: 720, width: 2, backgroundColor: '#000', marginTop: 20 }} />
                            <Grid item xs style={{ alignItems: 'center' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 180 }}>
                                    <ReusableTextField type='default' height={45} bgColor='#F2F2F2' placeholder='Collection Name'
                                        state={collection.collectionName} setState={update} count={count}
                                        head='settingText' target='collectionName' required />
                                    <ReusableTextField type='default' height={45} bgColor='#F2F2F2' placeholder='Collection Identifier'
                                        state={collection.collectionIdentifier} setState={update} count={count}
                                        head='settingText' target='collectionIdentifier' marginTop={48} />
                                    <ReusableTextField type='default' placeholder='Description' state={collection.collectionDescription}
                                        setState={update} count={count} head='settingText' target='collectionDescription' marginTop={48}
                                        multiline={8} />
                                    <ReusableTextField type='default' width={420} height={45} bgColor='#F2F2F2' placeholder='Time to Solve'
                                        state={collection.timeToSolve} setState={update} count={count}
                                        head='settingText' target='timeToSolve' required marginTop={48} />
                                    <ReusableTextField type='default' width={420} height={45} bgColor='#F2F2F2' placeholder='Initial Points'
                                        state={collection.initialPoints} setState={update} count={count}
                                        head='settingText' target='initialPoints' required marginTop={48} />
                                    {domainOptions && <ReusableAutocompleteWithID type='multiple' placeholder='Tags' width={590}
                                        height={55} btnBgColor='#144A94' btnWidth={64} state={collection.tags} setState={update}
                                        count={count} head='settingMultiple' target='tags' marginTop={48} borderColor='#707070'
                                        options={domainOptions.map(d => ({ id: d.id, option: d.name }))} limitMultiple={3}/>}
                                </div>
                            </Grid>
                        </Grid>
                        <Recommendations count={count} collection={collection} />
                        <Grid container alignItems='center' justifyContent='space-between' style={{
                            backgroundColor: '#144a94', borderRadius: 10, marginTop: 30, lineHeight: 2.2
                        }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <NunitoText value={`Questions`} fontSize={25} fontWeight={800} italic color='#FFF' marginLeft={50} />
                                <ForumIcon style={{ color: "#FFF", marginLeft: 10 }} />
                            </div>
                            <ReusableButton text='New Section' height={46} bgColor='#5E75C3' fontSize={16} marginRight={20}
                                onClick={() => update([count], 'addSection', 'sections')} iconType='add' />
                        </Grid>
                        {collection.sections.map((section, index) => (
                            <Section key={index} count={count} sectionCount={index} collection={collection} section={section} />
                        ))}
                    </Grid>
                    <Grid container justifyContent="flex-end" style={{ marginRight: 30, marginBlock: 20 }}>
                        <ReusableButton text='Cancel' bgColor='#8D8D8D' fontSize={16} marginRight={20} />
                        <ReusableButton text='Submit' bgColor='#5E75C3' fontSize={16} onClick={() => onSubmit()} />
                    </Grid>
                </Grid>}
            {JSON.stringify(collection)}<br /><br />
            <h1>{JSON.stringify({
                collectionName: collection.collectionName, collectionIdentifier: collection.collectionIdentifier,
                description: collection.collectionDescription, tags: collection.tags,
                recommendedDifficulty: collection.recommendations.map(r => ({ grade: r.grade, difficulty: r.difficulty })),
                sections: collection.sections, initialPoints: collection.initialPoints, timeToSolve: collection.timeToSolve,
                competition: [collection.competitionName], permissionToVerify: `${user.username} (${date})`,
                createdBy: `${user.username} (${date})`, lastModifiedBy: `${user.username} (${date})`,
                lastVerifiedBy: `${user.username} (${date})`, status: 'Pending Verification'
            })}</h1>
        </Grid>
    )
}
export default function Collections() {
    const collections = useSelector(state => state.collection)
    const [competitionOptions, setCompetitionOptions] = useState()
    useEffect(() => {
        getCompetitions('').then(d => {
            console.log(d)
            setCompetitionOptions(d.competitionList.data)
        })
    }, [])
    const [competition, setCompetition] = useState('')
    return (
        competitionOptions ? <>
            <Grid container alignItems='center' style={{ marginBottom: 20 }}>
                <NunitoText value='Competition Name*' fontSize={20} fontWeight={600} italic />
                <ReusableAutocompleteWithID type='default' width={450} height={55} btnWidth={63} btnBgColor='#144A94'
                    borderColor='#707070' placeholder='Competition' state={competition} setState={setCompetition}
                    options={competitionOptions.map(c => ({ id: c.id, option: c.name }))} marginLeft={200} />
            </Grid>
            {collections.map((collection, index) => (
                <Collection key={index} count={index} collection={collection} competition={competition} />
            ))}
        </> : null
    )
}