import React from 'react'
import { Grid, Box } from '@mui/material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Tasks from '../components/Tasks'
import { useDispatch } from 'react-redux'
import { resetTask } from '../actions'
import NunitoText from '../components/general/NunitoText'
import ReusableButton from '../components/general/ReusableButton'
export default function AddTask() {
    document.title = 'Add Task'
    const dispatch = useDispatch()
    return (
        <Box style={{ flexGrow: 1 }}>
            <div style={{ display: 'flex', alignItems: 'center', padding: 12 }}>
                <ReusableButton text='Dashboard' fontSize={14} bgColor='#F16774' height={36} width={125} br={18} to='/dashboard'  iconType='home'/>
                <ChevronRightIcon />
                <ReusableButton text='Tasks' fontSize={14} bgColor='#F16774' height={36} br={18} onClick={() => dispatch(resetTask())} />
            </div>
            <Grid container flexDirection='column' style={{ paddingInline: 42, marginTop: 30 }}>
                <NunitoText value='Add Tasks' fontSize={40} fontWeight={700} italic color='#144A94' align='left' marginBottom={24} />
                <Tasks />
            </Grid>
        </Box>
    )
}