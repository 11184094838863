import { getMethod } from './httpMethods'
// To populate options only
export const getCountries = () => {
    return new Promise((resolve, reject) => {
        getMethod('info/countrylist', '').then(d => resolve(d)).catch(e => reject(e))
    })
}
export const getRoles = () => {
    return new Promise((resolve, reject) => {
        getMethod('info/roles', '').then(d => resolve(d)).catch(e => reject(e))
    })
}
export const getLanguages = () => {
    return new Promise((resolve, reject) => {
        getMethod('info/languages', '').then(d => resolve(d)).catch(e => reject(e))
    })
}
// To actually display on the page, some of them is also used to populate options
// School
export const getSchools = params => {
    return new Promise((resolve, reject) => {
        getMethod('school', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Organization
export const getOrganizations = params => {
    return new Promise((resolve, reject) => {
        getMethod('organisation', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// User
export const getUsers = params => {
    return new Promise((resolve, reject) => {
        getMethod('user', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// User Profile
export const getProfile = () => {
    return new Promise((resolve, reject) => {
        getMethod('user/profile', '').then(d => resolve(d)).catch(e => reject(e))
    })
}
// Participant
export const getParticipants = params => {
    return new Promise((resolve, reject) => {
        getMethod('participant', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Domain and Tag
export const getDomains = params => {
    return new Promise((resolve, reject) => {
        getMethod('tag', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Competition
export const getCompetitions = params => {
    return new Promise((resolve, reject) => {
        getMethod('competition', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const getCompetitionsPartnerPending = params => {
    return new Promise((resolve, reject) => {
        getMethod('competition/partners/pending', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
// Task
export const getTasks = params => {
    return new Promise((resolve, reject) => {
        getMethod('tasks', params).then(d => resolve(d)).catch(e => reject(e))
    })
}
export const getTask = (id) => getMethod('task', id)
export const getCollections = () => getMethod('collection', '').then(data => data.map(data => ({
    id: data.id,
    collectionName: data.collectionName,
    collectionIdentifier: data.collectionIdentifier,
    description: data.description,
    tags: data.tags,
    recommendedDifficulty: data.recommendedDifficulty,
    noOfSection: data.sections,
    noOfQuestions: data.sections.map(s => ({ sectionID: s.sectionID, taskGroup: s.taskGroups })),
    initialPoints: data.initialPoints,
    timeToSolve: data.timeToSolve,
    competitions: data.competitions,
    createdBy: data.createdBy,
    permissionToVerify: data.permissionToVerify,
    lastModifiedBy: data.lastModifiedBy,
    lastVerifiedBy: data.lastVerifiedBy,
    status: data.status
})))
export const getDifficulties = () => getMethod('difficulty', '')
export const getUser = (id) => getMethod('user', id).then(data => ({
    id: data.id,
    username: data.username,
    role: data.role,
    permissionOverridden: true,
    email: data.email,
    country: data.country,
    createdBy: data.createdBy,
    lastModifiedBy: data.lastModifiedBy,
    lastLogin: data.lastLogin,
    status: data.status,
    password: data.password
}))
export const getTranslations = () => {
    return new Promise((resolve, reject) => {
        getMethod('task', '')
            .then((data) => {
                if (data.length) {
                    let a = Object.getOwnPropertyNames(data[0]).slice()
                    let temp = a[3]
                    a[3] = a[4]
                    a[4] = temp
                    resolve([a.filter(b => !['lastVerified', 'createdBy', 'displayOrder',
                        'answers', 'answerStructure', 'collections', 'competitions', 'recommendedDifficulty', 'tags'].includes(b)), data])
                } else {

                }
            })
    })
}